import React, { useRef, useState } from 'react';
import { i18n } from '@/utils';
import { fabric } from 'sunzi-fabric';
import { Wrapper } from '@/components';
import Icon from '../icon';
import TrackCanvas from './track-canvas';
import styles from './style.less';
import { SpotifyLayout } from './group';
import { SpotifyTrack, SpotifyColor, SpotifyBottomImage, SpotifyLineText } from '../spotify-code';


interface CropaiProps {
  track?: SpotifyTrack;
  layout: SpotifyLayout;
  color: SpotifyColor;
  bottomImage: SpotifyBottomImage;
  defaultValue: string[];
  onClose: () => void;
  onConfirm: (values: string[]) => void;
}

const Component:  React.FC<CropaiProps> = ({ defaultValue, track, layout, color, bottomImage, onClose, onConfirm }) => {

  /** canvas */
  const _canvas = useRef<fabric.Canvas>(new fabric.Canvas(document.createElement('canvas')));

  const [ values, setValues ] = useState<string[]>(defaultValue);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, lineTextCoord?: SpotifyLineText) => {
    if (e.target.value.length <= (lineTextCoord?.maxLength || Number.MAX_SAFE_INTEGER)) {
      values[index] = e.target.value;
      setValues([...values]);
    }
  }

  return (
    <Wrapper className={styles.lineTextWrapper}>
      {track &&
        <div className={styles.canvasWrapper}>
          <TrackCanvas
            canvas={_canvas.current}
            track={track}
            layout={layout}
            color={color}
            bottomImage={bottomImage}
            lineTextValue={values}
          />
        </div>
      }
      <div className={styles.textWrapper}>
        {layout.children.map((item, index) => (
          <div
            key={index}
            className={styles.text}
          >
            <div className={styles.label}>{item.lineTextCoord?.label}</div>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                value={values[index]}
                onChange={e => handleValueChange(e, index, item.lineTextCoord)}
              />
              <div className={styles.counter}>{values[index].length}/{item.lineTextCoord?.maxLength}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div
          className={styles.previous}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={styles.next}
          onClick={() => onConfirm(values)}
        >
          <span>{i18n.format('modules.global.next')}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Component;
