import React, { useRef, useState, useEffect } from 'react';
import { fabric } from 'sunzi-fabric';
import { i18n, loadImageFromURL, canvasToBlobURL, loadHTMLImage } from '@/utils';
import { Wrapper } from '@/components';
import Icon from '../icon';
import styles from './style.less';


interface cropaiProps {
  aiImage: string;
  /** 裁剪图 */
  image: string;
  /** 关闭回调 */
  onClose(): void;
  /** 确认回调 */
  onConfirm(result: string): void;
}

const Component:  React.FC<cropaiProps> = ({ image, aiImage, onClose, onConfirm }) => {
  const _content = useRef<HTMLDivElement>(null);
  const _element = useRef<HTMLCanvasElement>(null);
  const _canvas = useRef<fabric.Canvas>();
  const _source = useRef<fabric.Image>();
  const _scale = useRef<number>(1);

  const [ rangeValue, setRangeValue ] = useState<number>(1);
  const [ loading, setLoading ] = useState<boolean>(false);

  /** 裁剪初始化 */
  useEffect(() => {
    _setCroppaiContent();
  }, []);

  useEffect(() => {
    if (_source.current) {
      console.log(_scale.current, rangeValue)
      _source.current.scale(_scale.current * rangeValue);
      _canvas.current?.renderAll();
    }
  }, [ rangeValue ]);

  /** 裁剪初始化 */
  const _setCroppaiContent = async () => {
    setLoading(true);
    if (_content.current && _element.current) {
      const offsetWidth = _content.current.offsetWidth;
      const _aiImage = await loadImageFromURL(aiImage);
      const { width: aiWidth = 0, height: aiHeight = 0 } = _aiImage;
      _canvas.current = new fabric.Canvas(_element.current, {
        selection: false,
      });
      let width = offsetWidth;
      let height = offsetWidth * aiHeight / aiWidth;
      _canvas.current.setDimensions({ width, height });

      const scale = width / aiWidth;
      _aiImage.set({
        opacity: 0.8,
        scaleX: scale,
        scaleY: scale
      });
      await setOverlayImage(_aiImage);

      _source.current = await loadImageFromURL(image);
      const {
        width: sourceWidth = 0,
        height: sourceHeight = 0
      } = _source.current;

      _scale.current = Math.max(width / sourceWidth, height / sourceHeight);

      _source.current.set({
        left: width / 2,
        top: height / 2,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
      }).scale(_scale.current);
      _canvas.current.add(_source.current);
      _canvas.current.renderAll();

      setLoading(false);
    }
  }

  /** 设置遮照图 */
  const setOverlayImage = (image: fabric.Image) => new Promise(resolve => {
    _canvas.current?.setOverlayImage(image, resolve);
  });

  /** 确认回调 */
  const handleConfirm = async () => {
    if (_canvas.current) {
      _canvas.current.overlayImage?.set({ opacity: 1 });
      _canvas.current.renderAll();
      const image = await canvasToBlobURL(_canvas.current?.getElement());
      const result = await trimImage(image);
      console.log(result)
      onConfirm(result);
    }
  }

  /** 根据左上角像素点, 切割四周留白 */
  const trimImage = async (image: string) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const _image = await loadHTMLImage(image)

    canvas.width = _image.width;
    canvas.height = _image.height;
    ctx?.drawImage(_image, 0, 0);

    if (ctx) {
      let x, y, left, top, right, bottom, width, height;
      const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const l = pixels.data.length;
      const ltr = pixels.data[0], ltg = pixels.data[1],
        ltb = pixels.data[2], lta = pixels.data[3];

      for (let i = 0; i < l; i += 4)
        if (!(ltr === pixels.data[i] && ltg === pixels.data[i + 1] && ltb === pixels.data[i + 2] && lta === pixels.data[i + 3])) {
          x = (i / 4) % canvas.width;
          y = ~~((i / 4) / canvas.width);
          // 获取上下左右坐标
          if (top === undefined) top = y; // 上
          if (left === undefined) left = x; // 左
          else if (x < left) left = x;
          if (right === undefined) right = x; // 右
          else if (right < x) right = x;
          if (bottom === undefined) bottom = y; // 下
          else if (bottom < y) bottom = y;
        }
      left = left || 0,
      top = top || 0,
      height = (bottom || 0)  - top,
      width = (right || 0) - left;
      const trimmed = ctx.getImageData(left, top, width, height);

      // 重新绘制像素点
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = width;
      canvas.height = height;
      ctx.putImageData(trimmed, 0, 0);
    }
    const result = await canvasToBlobURL(canvas);
    canvas.width = canvas.height = 0;
    return result;
  }

  return (
    <Wrapper className={styles.cropaiWrapper}>
      <div
        ref={_content}
        className={styles.cropai}
      >
        <canvas ref={_element} />
        <div className={styles.cropaiTips}>
          <span>{i18n.format('modules.global.zoom.pinch')}</span>
        </div>
        <div className={styles.cropaiRange}>
          <input
            type="range"
            min={0.8}
            max={1.2}
            step={0.01}
            value={rangeValue}
            onChange={e => setRangeValue(parseFloat(e.target.value))}
          />
        </div>
      </div>
      <div className={styles.cropaiFooter}>
        <div
          className={styles.previous}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={styles.next}
          onClick={handleConfirm}
        >
          <span>{i18n.format('modules.global.confirm')}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Component;
