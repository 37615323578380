import React from 'react';
import { SongLyric } from '../lyric';
import styles from '../../style.less';

export interface TrackItemProps {
  track: SongLyric;
  onClick?: () => void;
}

const TrackItem: React.FC<TrackItemProps> = ({ track, onClick }) => (
  <div
    className={styles.track}
    onClick={onClick}
  >
    <div
      className={styles.trackAlbum}
      style={{
        backgroundImage: `url(${track.header_image_thumbnail_url})`
      }}
    />
    <div className={styles.trackContent}>
      <div className={styles.trackName}>{track.title}</div>
      <div className={styles.trackArtists}>{track.primary_artist.name}</div>
    </div>
  </div>
);

export default TrackItem;
