import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import animationJson from '@/assets/spotify-lyric-loading.json';
import { Wrapper } from '@/components';
import { i18n } from '@/utils';
import Icon from '../../icon';
import styles from './style.less';


export interface LoadingProps {
  onClose: () => void;
}

const Loading: React.FC<LoadingProps> = ({
  onClose
}) => {
  /** 动画挂载 dom */
  const _animation = useRef<any>(null);
  /** 动画实例 */
  const lottieAnimation = useRef<AnimationItem>();

  useEffect(() => {
    lottie.setLocationHref(location.href);

    if (_animation.current) {
      lottieAnimation.current = lottie.loadAnimation({
        container: _animation.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationJson,
      });
    }

    return () => {
      lottieAnimation.current?.destroy();
    }
  }, []);

  return (
    <Wrapper className={styles.lyricContentLoading}>
      <div
        className={styles.closeWrapper}
        onClick={onClose}
      >
        <Icon type="close" size={24} />
      </div>
      <div className={styles.lottieAnimation} ref={_animation} />
      <div className={styles.description}>{i18n.format('modules.spotify.code.lyric.loading')}</div>
    </Wrapper>
  )
};

export default Loading;
