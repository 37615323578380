import React, { useState } from 'react';
import { Wrapper } from '@/components';
import { i18n } from '@/utils';
import Icon from '../../icon';
import styles from './style.less';

export interface HandleModifyProps {
  maxLyricLines: number;
  album: string;
  onClose: () => void;
  onConfirm: (lyrics: string[]) => void;
}

const HandleModify: React.FC<HandleModifyProps> = ({
  maxLyricLines,
  album,
  onClose,
  onConfirm
}) => {

  /** 输入歌词的值 */
  const [ value, setValue ] = useState<string>('');

  /** 歌词变化监听 */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.split('\n').length <= maxLyricLines)
      setValue(e.target.value)
  }

  return (
    <Wrapper className={styles.lyricContentHandle}>
      <Wrapper
        className={styles.backgroundImage}
        style={{
          backgroundImage: `url(${album})`
        }}
      />
      <div className={styles.content}>
        <div className={styles.count}>{value.split('\n').length}/{maxLyricLines}</div>
        <textarea
          className={styles.textarea}
          value={value}
          onChange={handleChange}
        >
        </textarea>
      </div>
      <div className={styles.footer}>
        <div
          className={styles.previous}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={styles.next}
          onClick={() => onConfirm(value.split('\n'))}
        >
          <span>{i18n.format('modules.global.next')}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
    </Wrapper>
  )
};

export default HandleModify;
