import React, { Dispatch, useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { fabric } from "sunzi-fabric";
import dayjs from "dayjs";
import { Font } from "@/sunzi";
import { Wrapper, Loading } from "@/components";
import {
  loadImageFromURL,
  loadFont,
  canvasToBlobURL,
  thumbnail7n,
} from "@/utils";
import { spotifyCodeHost } from "@/config";
import {
  SpotifyBottomImage,
  SpotifyColor,
  SpotifyLayout,
  SpotifyLineText,
  SpotifyTextCoord,
  SpotifyTrack,
  SpotifyTrackCoord,
  SpotifyTrackLineText,
  SpotifyCodeCoord,
} from "./spotify-code";
import styles from "../style.less";

export interface TrackCanvasProps {
  /** canvas对象 */
  canvas: fabric.Canvas;
  /** 歌曲信息 */
  track: SpotifyTrack;
  /** 颜色 */
  color: SpotifyColor;
  /** 绘制布局信息 */
  layout: SpotifyLayout;
  /** 歌曲信息 */
  bottomImage: SpotifyBottomImage;
  /** 刻字信息 */
  lineText?: SpotifyTrackLineText;
  /** 演奏者 */
  artists?: string;
  /** 封面图 */
  album?: string;
  /** 是否只显示封面图 */
  isOnlyShowAlbum?: boolean;
  /** 封面刻字 */
  albumLineText?: string[];
  /** 当前激活封面刻字 */
  activeAlbumLineTextIndex?: number;
  /** 同步loading状态 */
  asyncLoading?: Dispatch<React.SetStateAction<boolean>>;
}

let canvasWidth = 300;

const TrackCanvas: React.FC<TrackCanvasProps> = ({
  canvas,
  track,
  layout,
  color,
  album,
  artists,
  bottomImage,
  lineText,
  isOnlyShowAlbum = false,
  albumLineText,
  activeAlbumLineTextIndex,
  asyncLoading,
}) => {
  /** 容器实例 */
  const _content = useRef<HTMLDivElement>(null);
  /** 加载状态 */
  const [loading, setLoading] = useState<boolean>();
  /** 高度 */
  const [offsetHeight, setOffsetHeight] = useState<number>(0);
  /** 结果图 */
  const [trackImage, setTrackImage] = useState<string>();
  /** 仅显示封面图时的位置信息 */
  const [backgroundPosition, setBackgroundPosition] = useState<
    { left: number; top: number; scale: number } | undefined
  >();

  // 如果只显示封面，则调整生成的canvas的大小
  useEffect(() => {
    if (isOnlyShowAlbum && layout.albumCoord && _content.current) {
      const { offsetWidth } = _content.current;
      const width =
        ((offsetWidth / layout.albumCoord.width) *
          layout.makeWidth *
          bottomImage.width) /
        bottomImage.contentCoord.width;
      canvasWidth = width;
    } else {
      canvasWidth = 300;
    }
  }, [isOnlyShowAlbum, layout, _content.current]);

  useEffect(() => {
    _canvasCreate();
  }, [color, lineText, albumLineText, activeAlbumLineTextIndex]);

  /** 初始化画布 */
  const _canvasCreate = async () => {
    if (_content.current) {
      const { offsetWidth } = _content.current;
      setLoading(true);
      asyncLoading && asyncLoading(true);
      // 缩放倍数
      const scale = offsetWidth / bottomImage.width;
      setOffsetHeight(bottomImage.height * scale);
      // 生成预览图
      const result = await renderTrack(
        canvas,
        bottomImage,
        track,
        layout,
        color,
        artists,
        album,
        lineText,
        isOnlyShowAlbum,
        albumLineText,
        activeAlbumLineTextIndex
      );
      if (typeof result === "string") {
        setTrackImage(result);
      } else {
        setTrackImage(result.image);
        // left和top是按照300px宽计算的，需要转换成仅显示专辑图的宽
        // 仅显示专辑图的宽是超出容器的宽，然后超出隐藏显示的，所以需要乘上缩放倍速
        setBackgroundPosition({
          left:
            (result.albumLeft / canvasWidth) * offsetWidth * result.albumScale,
          top:
            (result.albumTop / canvasWidth) * offsetWidth * result.albumScale,
          scale: result.albumScale,
        });
      }
      setLoading(false);
      asyncLoading && asyncLoading(false);
    }
  };

  return (
    <div
      ref={_content}
      className={classnames(styles.trackCanvas, {
        [styles.trackCanvasLoading]: loading,
      })}
      style={{
        height: offsetHeight,
        backgroundImage: `url(${trackImage})`,
        backgroundPosition: backgroundPosition
          ? `-${backgroundPosition.left}px -${backgroundPosition.top}px`
          : undefined,
        backgroundSize: backgroundPosition
          ? `${backgroundPosition.scale * 100}%`
          : undefined,
      }}
    >
      {loading && (
        <Wrapper className={styles.loadingWrapper}>
          <Loading size={30} />
        </Wrapper>
      )}
    </div>
  );
};

/**
 * 绘制方法
 * @param canvas HTMLCanvasElement
 * @param bottomImage 底图信息
 * @param layout 绘制布局信息
 * @param color 颜色信息
 * @param track 歌曲信息
 * @param artists 演奏者
 * @param album 封面图
 * @param isOnlyShowAlbum 是否只展示封面
 * @param activeAlbumLineTextIndex 当前激活的唱片刻字
 * @param albumLineText 唱片刻字
 */
export const renderTrack = async (
  canvas: fabric.Canvas,
  bottomImage: SpotifyBottomImage,
  track: SpotifyTrack,
  layout: SpotifyLayout,
  color: SpotifyColor,
  artists?: string,
  album?: string,
  lineText?: SpotifyTrackLineText,
  isOnlyShowAlbum?: boolean,
  albumLineText?: string[],
  activeAlbumLineTextIndex?: number,
  catchError: boolean = false
) => {
  const width = canvasWidth;
  canvas.clear();
  // 设置宽高
  canvas.setDimensions({
    width,
    height: (bottomImage.height * width) / bottomImage.width,
  });

  let scale =
    (bottomImage.contentCoord.width * width) /
    bottomImage.width /
    layout.makeWidth;
  if (layout.layoutCoord)
    scale =
      (bottomImage.contentCoord.width * width) /
      bottomImage.width /
      layout.layoutCoord[0].width;

  /** left top 增加偏移量 */
  const _left = (value: number) =>
    (bottomImage.contentCoord.left * width) / bottomImage.width + value * scale;
  const _top = (value: number) =>
    (bottomImage.contentCoord.top * width) / bottomImage.width + value * scale;

  /** 绘制底图 */
  const _renderBottomImage = () =>
    new Promise<void>((resolve) => {
      loadImageFromURL(thumbnail7n(bottomImage.image, 400)).then((bottom) => {
        bottom.scaleToWidth(width);
        canvas.setBackgroundImage(bottom, () => {
          canvas.renderAll();
          resolve();
        });
      });
    });

  /** 绘制遮照图 */
  const _renderCropImage = (image: string) =>
    new Promise<void>((resolve) => {
      loadImageFromURL(thumbnail7n(image, 400)).then((crop) => {
        crop.scaleToWidth(width);
        canvas.setOverlayImage(crop, () => {
          canvas.renderAll();
          resolve();
        });
      });
    });

  /** 绘制文字 */
  const _renderTrackText = async (
    value: string,
    textCoord: SpotifyTextCoord,
    active?: boolean,
    textColor?: string
  ) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);
      const _text = new fabric.Text(value, {
        left: _left(textCoord.left),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: textColor ? textColor : color.value,
        originY: "center",
        angle: textCoord.angle,
      });

      // 如果文字居中
      if (textCoord.textAlign === "center")
        _text.set({
          left: _left(textCoord.left + textCoord.width / 2),
          originX: "center",
          textAlign: "center",
        });

      // 如果文字居右
      if (textCoord.textAlign === "right") {
        _text.set({
          left: _left(textCoord.left + textCoord.width),
          originX: "right",
          textAlign: "right",
        });
      }

      const { width = 0, height = 0 } = _text;
      const textScale = Math.min(
        (textCoord.width * scale) / width,
        (textCoord.height * scale) / height
      );
      _text.scale(textScale);

      if (active) {
        const { left, top, width, height, textAlign, angle } = _text;
        if (left && top && width && height) {
          // 缩放系数大于1时，指示框的高度需要放大到相应的倍数才能框住所有单词
          const rectHeight = textScale > 1 ? height * textScale : height;
          // padding的值为实际的pading + stroke外框的宽度
          const padding = 6;
          const activeRect = new fabric.Rect({
            left: left - padding / 2,
            top: top,
            height: rectHeight + padding,
            width: width * textScale + padding,
            stroke: "#1DB954",
            strokeWidth: 1,
            fill: "rgba(0,0,0,0)",
            originY: "center",
            angle,
          });
          if (textAlign === "center") {
            activeRect.set({ originX: "center", left });
          } else if (textAlign === "right") {
            activeRect.set({ originX: "right", left: left + padding / 2 });
          }
          canvas.add(activeRect);
        }
      }

      canvas.add(_text);
    } catch {
      if (catchError) throw Error();
    }
  };

  /** 绘制文字 */
  const _renderTrackNameText = async (
    value: string,
    textCoord: SpotifyTextCoord
  ) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);

      const _text = new fabric.Text(value, {
        left: _left(textCoord.left),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: color.value,
        originY: "center",
      });

      // 如果文字居中
      if (textCoord.textAlign === "center")
        _text.set({
          left: _left(textCoord.left + textCoord.width / 2),
          originX: "center",
          textAlign: "center",
        });

      const { width = 0, height = 0 } = _text;
      _text.scale(
        Math.min(
          (textCoord.width * scale) / width,
          (textCoord.height * scale) / height
        )
      );

      canvas.add(_text);
    } catch {
      if (catchError) throw Error();
    }
  };

  /** 绘制直线刻字 */
  const _renderTrackLineText = async (
    value: string,
    font: Font,
    textCoord: SpotifyLineText
  ) => {
    try {
      // 加载字体
      await loadFont(font.name);
      const _text = new fabric.Text(value, {
        left: _left(textCoord.left + textCoord.width / 2),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: font.name,
        fill: color.value,
        originX: "center",
        originY: "center",
      });

      const { width = 0, height = 0 } = _text;
      _text.scale(
        Math.min(
          (textCoord.width * scale) / width,
          (textCoord.height * scale) / height
        )
      );

      canvas.add(_text);
    } catch {
      if (catchError) throw Error();
    }
  };

  /** 绘制图片 */
  const _renderTrackImage = async (
    image: string,
    left: number,
    top: number,
    width: number,
    height: number,
    cover?: boolean
  ): Promise<fabric.Object | undefined> => {
    try {
      const _image = await loadImageFromURL(image);
      const _width = width * scale,
        _height = height * scale;

      const { width: imgWidth = 0, height: imgHeight = 0 } = _image;

      _image.set({
        left: _left(left + width / 2),
        top: _top(top + height / 2),
        originX: "center",
        originY: "center",
      });
      _image.scale(
        Math[cover ? "max" : "min"](_width / imgWidth, _height / imgHeight)
      );

      canvas.add(_image);
      return _image;
    } catch {
      if (catchError) throw Error();
      return;
    }
  };

  /** 绘制进度条 */
  const _renderTrackTrail = async (coord: SpotifyTrackCoord) => {
    try {
      let { left, top, width, height, time } = coord;

      left = _left(left);
      top = _top(top);
      (width *= scale), (height *= scale);

      // 绘制底层进度条
      const trail = new fabric.Rect({
        left,
        top,
        width,
        height,
        fill: color.value,
        rx: height / 2,
        ry: height / 2,
      });

      // 绘制激活部分
      const played = new fabric.Circle({
        left: left + width * 0.2,
        top: top + height / 2,
        radius: height,
        originX: "center",
        originY: "center",
        fill: color.value,
      });

      canvas.add(trail);
      canvas.add(played);

      /** 绘制时间进度 */
      const _renderTrackTime = async (
        value: string,
        left: number,
        originX: "left" | "right" = "left"
      ) => {
        const _text = new fabric.Text(value, {
          left,
          top: _top(time.top),
          fontSize: time.fontSize * scale,
          fontFamily: time.font.name,
          fill: color.value,
          originX,
        });

        canvas.add(_text);
      };

      // 加载字体
      await loadFont(time.font.name);

      _renderTrackTime(dayjs(track.duration_ms * 0.2).format("mm:ss"), left);
      _renderTrackTime(
        `-${dayjs(track.duration_ms * 0.8).format("mm:ss")}`,
        left + width,
        "right"
      );
    } catch {
      if (catchError) throw Error();
    }
  };

  /** 绘制 Spotify Code */
  const _renderSpotifyCode = async (
    color: "black" | "white",
    coord: SpotifyCodeCoord
  ) => {
    try {
      /** 记载spotify svg code */
      const _loadSVGFromURL = (code: string) =>
        new Promise<fabric.Group | fabric.Object>((resolve) =>
          fabric.loadSVGFromURL(code, (objects) => {
            objects.forEach((item) => {
              item.set({
                fill: color,
              });
            });
            objects[0].fill = "transparent";
            resolve(fabric.util.groupSVGElements(objects));
          })
        );
      // 获取 SpotifyCode
      const _code = await _loadSVGFromURL(
        `${spotifyCodeHost}/svg/000000/white/640/${track.uri}`
      );
      const { left, top, width, height } = coord;
      _code
        .set({
          left: _left(left + width / 2),
          top: _top(top + height / 2),
          originX: "center",
          originY: "center",
          angle: coord.angle,
        })
        .scaleToWidth(coord.width * scale);

      canvas.add(_code);
    } catch {
      if (catchError) throw Error();
    }
  };

  const {
    albumCoord,
    heartCoord,
    controlCoord,
    trackCoord,
    spotifyCodeCoord,
    nameCoord,
    artistCoord,
    lineTextCoord,
  } = layout;

  const queue = [];

  // 如果存在封面
  if (albumCoord) {
    // 如果存在封面刻字
    if (albumCoord.lineTextCoord) {
      if (isOnlyShowAlbum === false && bottomImage.contentCoord.bottomImage) {
        const contentImage = bottomImage.contentCoord.bottomImage;
        queue.push(
          _renderTrackImage(
            contentImage.image,
            contentImage.contentCoord.left,
            contentImage.contentCoord.top,
            layout.makeWidth,
            layout.makeHeight
          )
        );
      }
      albumCoord.lineTextCoord.forEach((item, index) => {
        queue.push(
          _renderTrackText(
            albumLineText && albumLineText[index]
              ? albumLineText[index]
              : `Name ${index + 1}`,
            item,
            index === activeAlbumLineTextIndex,
            "black"
          )
        );
      });
    } else {
      queue.push(
        _renderTrackImage(
          album || track.album,
          albumCoord.left,
          albumCoord.top,
          albumCoord.width,
          albumCoord.height,
          albumCoord.cover
        )
      );
    }
  }

  // 如果存在❤
  heartCoord &&
    !isOnlyShowAlbum &&
    queue.push(
      _renderTrackImage(
        heartCoord.image,
        heartCoord.left,
        heartCoord.top,
        heartCoord.width,
        heartCoord.height
      )
    );

  // 如果存在控制器
  controlCoord &&
    !isOnlyShowAlbum &&
    queue.push(
      _renderTrackImage(
        color.controlImage,
        controlCoord.left,
        controlCoord.top,
        controlCoord.width,
        controlCoord.height
      )
    );

  // 如果存在进度条
  trackCoord && !isOnlyShowAlbum && queue.push(_renderTrackTrail(trackCoord));

  // 如果存在歌名
  nameCoord &&
    !isOnlyShowAlbum &&
    queue.push(_renderTrackNameText(track.name, nameCoord));

  // 如果存在演奏者
  artistCoord &&
    !isOnlyShowAlbum &&
    queue.push(_renderTrackText(artists || track.artists, artistCoord));

  // 如果存在刻字信息 & 刻字值
  if (lineTextCoord && lineText?.font && !isOnlyShowAlbum)
    queue.push(
      _renderTrackLineText(
        lineText.value || lineTextCoord.defaultValue,
        lineText.font,
        lineTextCoord
      )
    );

  queue.push(_renderBottomImage());
  !isOnlyShowAlbum &&
    queue.push(_renderSpotifyCode(color.value, spotifyCodeCoord));

  // 如果存在遮照图
  if (bottomImage.cropImage)
    queue.push(_renderCropImage(bottomImage.cropImage));

  // 并行绘制(?猜测应该不是并行，原因为使用了await，且图片加载需要时间，理论上并行会唱片图片会覆盖唱片刻字)
  const [_album] = await Promise.all<any>(queue);
  _album?.sendToBack();

  canvas.renderAll();
  const image = await canvasToBlobURL(canvas.getElement());
  if (!isOnlyShowAlbum || !albumCoord) return image;
  return {
    image,
    albumLeft: _left(albumCoord.left),
    albumTop: _top(albumCoord.top),
    albumScale: width / (albumCoord.width * scale),
  };
};

export default TrackCanvas;
