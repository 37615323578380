import React from 'react';
import classnames from 'classnames';
import styles from './style.less';

interface DrawerProps {
  /** 样式 */
  className?: string;
  /** 抽屉位置 */
  position?: 'top' | 'bottom' | 'right' | 'left',
  /** 动画时间 */
  duration?: number;
  /** 是否展示遮罩 */
  mask?: boolean;
  /** 遮照层样式 */
  maskStyle?: React.CSSProperties;
  /** 关闭回调 */
  onClose?: () => void;
}

const Drawer: React.SFC<DrawerProps> = ({
  className,
  mask,
  maskStyle,
  duration = 0.3,
  position = 'bottom',
  onClose,
  children
}) => {
  return (
    <div
      className={classnames(styles.drawer, {
        [styles.drawerBottom]: position === 'bottom',
        [styles.drawerTop]: position === 'top',
        [styles.drawerRight]: position === 'right',
        [styles.drawerLeft]: position === 'left',
      })}
    >
      <div
        className={classnames(styles.drawerMask, {
          [styles.drawerMaskShow]: mask === true,
          [styles.drawerMaskHide]: mask === false
        })}
        onClick={onClose}
        style={maskStyle}
      />
      <div
        className={classnames(styles.drawerContent, className)}
        style={{
          animationDuration: `${duration}s`
        }}
      >
        { children }
      </div>
    </div>
  );
}

export default Drawer;
