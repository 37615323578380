export default {
  "modules.global.add.cart": "ADD TO CART", // 加车
  "modules.global.previous": "PREVIOUS", // 上一步
  "modules.global.next": "NEXT", // 下一步
  "modules.global.continue": "CONTINUE", // 继续

  "modules.global.confirm": "CONFIRM", // 确认
  "modules.global.processing": "Processing Your Image", // 处理你的图片
  "modules.global.confirm.design": "CONFIRM DESIGN", // 确认设计
  "modules.global.free": "FREE", // 免费
  "modules.global.add.text": "Add Texts", // 添加刻字
  "modules.global.add.photo": "Add Photos", // 添加图片
  "modules.global.preview.confirm": "Uploading Photo", // 正常上传图片
  "modules.global.preview.tips":
  "Our designer will adjust your design for better printing effect.", // 预览提示
  "modules.global.preview": "PREVIEW", // 预览
  "modules.global.loading": "Loading", // 正在加载
  "modules.global.uploading": "uploading", // 正在上传
  "modules.global.uoload.photo": "upload photos", // 上传图片
  "modules.global.upload": "upload", // 上传
  "modules.global.delete.tips": "Are you sure to delete this?", // 删除提示
  "modules.global.delete": "delete", // 删除
  "modules.global.cancel": "Cancel", // 取消
  "modules.global.done": "Done", // 完成
  "modules.global.order": "Order", // 结算
  "modules.global.recommend": "Recommend", // 推荐

  "modules.global.change": "Change", // 更换
  "modules.global.cropper": "Crop", // 裁剪
  "modules.global.cropper.image": "Cropper Image", // 裁剪图片

  "modules.global.checkout": "checkout", // 结账
  "modules.global.replace": "replace", // 替换
  "modules.global.complete": "complete", // 完成
  "modules.global.revoke": "Revoke", // 撤销
  "modules.global.warning.title": "warning", // 警告

  "modules.global.network.error": "Network Error!", // 网络错误
  "modules.global.ai.crop.error": "AI Crop Error!", // 切图服务错误
  "modules.global.replace.photo": "Replace Photo", // 替换照片
  "modules.global.crop.myself": "Crop by myself", // 自己切图
  "modules.global.try.again": "Try again", // 重试

  "modules.global.zoom.pinch": "pinch and zoom", // 移动和放大
  "modules.global.edit.confirm":
    "You have not finished editing. Are you sure to quit?", // 您还没有编辑完，确定要退出吗

  "modules.global.ai.crop.complete": "Yes, Complete!", // 切图完成
  "modules.global.ai.croping.photo": "AI Croping your photo", // ai切图中

  "components.edit.title": "EDIT MODEL", // 编辑模式
  "components.edit.type": "TYPE", // 类别
  "components.edit.apply": "APPLY", // 应用
  "components.oop.title": "OOP", // OOP
  "components.oop.description":
    "Please complete the customized content according to the following conditions.", // 请根据以下条件完成定制内容
  "components.oop.submit": "I’ll change it", // 我会改变的
  "components.guide.title": "Instructions", // 使用说明
  "components.edit.font.text": "Text", // 文字内容
  "components.edit.font.family": "Font", // 字体
  "components.edit.font.size": "Font Size", // 文字大小
  "components.edit.font.color": "Font Color", // 文字颜色
  "components.edit.font.shadow.color": "Font Shadow Color", // 文字阴影颜色
  "components.cropper.title": "Crop Image", // 裁剪照片
  "components.cropper.back": "Back", // 返回
  "components.cropper.crop": "Crop", // 裁剪
  "components.cropper.move": "Move", // 移动

  "modules.mug.font.validator": "Please edit ", // 请编辑
  "modules.bottom.background": "scenery", // 背景
  "modules.bottom.layout": "layout", // 图层
  "modules.bottom.help": "help", // 帮助
  "modules.bottom.next": "next", // 下一步
  "modules.bottom.confirm": "confirm", //确认
  "modules.bottom.layout.mug.tip":
    "Clicking on the current option will temporarily be placed and editable", // 图层操作提示文案
  "modules.bottom.layout.close": "CLOSE", //关闭
  "modules.bottom.operator.top": "Top", // 置顶
  "modules.bottom.operator.up": "Up", // 上一层
  "modules.bottom.operator.down": "Down", // 下一层
  "modules.bottom.operator.bottom": "Bottom", // 置底
  "modules.error.load.font": "Font loading error, please try again", // 字体加载失败 请重试
  "modules.error.load.photo": "Photo loading error, please try again", // 图片加载失败 请重试
  "modules.error.upload.result": "Upload Error, Please try again", // 上传失败 请重试

  // 袜子
  "components.footer.revoke": "Revoke", // 撤回
  "components.footer.gesture.erasure": "Erasure", // 橡皮擦模式
  "components.footer.gesture.pan": "Trace", // 画线模式
  "components.footer.gesture.move": "Move", // 移动模式
  "components.footer.zoom.in": "Zoom In", // 放大
  "components.footer.zoom.out": "Zoom Out", // 缩小
  "components.footer.help": "Help", // 缩小
  "components.footer.rotate.left": "Rotate Left", // 向左旋转
  "components.footer.rotate.right": "Rotate Right", // 向右旋转

  "modules.sock.change": "Change", // 更换
  "modules.sock.preview": "Preview", // 使用头像预览
  "modules.sock.preview.hot": "Hot", // 热门
  "modules.sock.erasure": "Erase Extra Part", // 擦掉多余部分
  "modules.sock.clip": "Crop by Yourself", // 擦掉多余部分
  "modules.sock.cropper.tips":
    "We can crop only one face at a time. Please keep your full face in the crop area.", // 请确保您的面部五官在所选取裁剪区域内, 且裁剪区内仅限一人.
  "modules.sock.cuting.testing": "Examining...", // 正在检测...
  "modules.sock.change.photo": "Change Photo", // 更换图片
  "modules.sock.preview.avatar.delete": "Are you sure to delete this?", // 确定要删除该头像吗
  "modules.sock.cut.recommend": "Please", // 推荐您
  "modules.sock.cut.or": "or", // 或者
  "modules.sock.compose.title": "Make your Sock", // 制作你的袜子
  "modules.sock.compose.background": "background", // 背景

  "utils.meitu.error.20001": "Error", // 处理错误
  "utils.meitu.error.20003": "No Face Found", // 人脸缺失
  "utils.meitu.error.20004": "More Than One Face Detected", // 人脸数大于1
  "utils.meitu.error.20005": "Only Accept JPG and PNG Photos", // 不支持的type
  "utils.meitu.error.other": "Error", // 系统错误，请稍后再试

  // 影刻
  "modules.sketch.cropper.tips":
    "Please adjust people's face and body in the middle", // 请保证照片中人像边缘尽量清晰可见
  "modules.sketch.title": "3D PREVIEW", // 3d预览
  "modules.sketch.request.stage0": "Uploading…", // 正在上传照片…
  "modules.sketch.request.stage1": "Removing background…", // 正在将人像从背景抠出…
  "modules.sketch.request.stage2": "Adding filter…", // 正在加入滤镜效果…
  "modules.sketch.request.stage3": "Processing to white and black image…", // 正在去色处理…
  "modules.sketch.request.stage4": "Hold on, preparing 3D preview…", // 请稍后, 预览结果即将呈现…
  "modules.sketch.request.accomplish": "Completed, please click to preview", // 影刻处理完成, 请点击预览查看
  "modules.sketch.accomplish.later": "Check Later", // 稍后再说
  "modules.sketch.accomplish.prompt": "3D preview is ready, Check Now!", // 稍后再说
  "modules.sketch.request.error": "Failed, you can add it to cart", // 预览处理失败，您可以跳过直接加车
  "modules.sketch.try.again": "Preview again", // 重新预览
  "modules.sketch.add.text.placeholder":
    "Type words here... (We'll make it exactly the same as shown in the preview above.)", // 刻字提示文案
  "modules.sketch.footer.preview": "Preview", // 预览并加车
  "modules.sketch.footer.skip": "Skip Preview", // 跳过预览

  // 撕拉盒子
  "modules.box.step": "STEP", // 步数
  "modules.box.side": "Side", // 面
  "modules.box.image.upload": "Upload", // 上传
  "modules.box.image.change": "Change", // 切换
  "modules.box.step1.title": "ADD IMAGE", // 添加图片
  "modules.box.step1.tips":
    "You can customize it with your photo uploaded, or use the current image.", // 添加图片说明文案
  "modules.box.step2.title": "ADD CONFETTI", // 添加纸屑
  "modules.box.step2.tips": "Create your personalised exploding boomf bomb！", // 添加纸屑说明文案
  "modules.box.oop.title": "TIPS", // 添加纸屑说明文案
  "modules.box.oop.description":
    "We will produce it in the order of the pictures below.", // 添加纸屑说明文案

  // 多图
  "modules.photo.film.title": "Edit Image", // 编辑照片
  "modules.photo.film.tips.sort":
    "Long press and drag the picture to adjust its position.", // 长按拖动图片来排序
  "modules.photo.film.tips.count": "You need to upload $value photos.", // 需要传多少张图
  "modules.photo.film.tips.count.min":
    "You need to upload at least $value photos.", // 最少需要传多少张图
  "modules.photo.film.change": "Change", // 更换
  "modules.photo.film.cropper": "Crop", // 裁剪
  "modules.photo.film.sort": "Sort", // 排序
  "modules.photo.film.delete": "Delete", // 删除

  // 多图模版
  "modules.template.tips": "Click photo to change it or crop", // 点击图片区域可以更换图片或裁剪
  "modules.template.validator.images": "You need to upload $value photos.", // 需要传多少张图
  "modules.template.validator.texts": "You need to fill in $value texts.", // 需要输入多少段文字
  "modules.template.bottom.template": "Template", // 模版
  "modules.template.default.texts": "Enter Text", // 文字

  // 拼图
  "modules.puzzle.pieces": "Pieces", // 片数
  "modules.puzzle.sizefor": "Puzzle for", // 拼图给..
  "modules.puzzle.size": "Size", // 尺寸
  "modules.puzzle.puzzle.size": "Puzzle Size", // 拼图尺寸
  "modules.puzzle.text.tips": "Click To Edit", // 点击文字进行编辑
  "modules.puzzle.dragger.tips": "Please drag the picture to the above.", // 图片可以直接拖动到上面
  "modules.puzzle.fuzzy.tips":
    "The image is low quality, please replace with a higher resolution one.", // 建议您替换掉检测模糊的图片
  "modules.puzzle.cropper.reset": "Reset", // 重置
  "modules.puzzle.cropper.delete": "Delete", // 删除
  "modules.puzzle.bottom.album": "My Album", // 我的相册
  "modules.puzzle.bottom.recent": "Recent", // 最近
  "modules.puzzle.bottom.template": "Template", // 模版
  "modules.puzzle.choose.all": "ALL", // 全部
  "modules.puzzle.choose.photos": "Photos", // 照片
  "modules.puzzle.choose.templates": "Choose Your Template", // 选择你的模版
  "modules.puzzle.choose.hot": "hot", // 热门
  "modules.puzzle.free.package": "Personalized Gift Package for Free", // 盒子免费送文案

  // 视频书
  "modules.video.book.continue.description":
    "70% of users choose double-sided flip books", // 70% 的用户选择双面翻页书哟~
  "modules.video.book.one.page": "Single-sided printing", // 单面翻页书
  "modules.video.book.double.page": "Double-sided printing", // 双面翻页书
  "modules.video.book.continue.add": "add content", // 继续添加
  "modules.video.book.limit.size": "The video size cannot be greater than", // 视频大小不能大于
  "modules.video.book.limit.duration":
    "The video duration cannot be longer than", // 视频时长不能大于
  "modules.video.book.uploading": "Uploading...", // 视频正在上传
  "modules.video.book.uploaded": "Upload completed", // 上传完成
  "modules.video.book.page.count": "There are $value pages in this book.", // 本书共 $value 页
  "modules.video.book.page.turn":
    "Recommend: Keep the page turning speed at 4 sheets / second", // 建议翻页速度保持在3张/秒
  "modules.video.book.change.cover": "change cover", // 更换封面
  "modules.video.book.front.play": "Play Forward", // 正面播放
  "modules.video.book.back.play": "Play Backward", // 背面播放
  "modules.video.book.uploading.tips": "In the process of making", // 正在制作你的翻页数
  "modules.video.book.time.cut": "Cut", // 裁剪
  "modules.video.book.time.total": "Total", // 总计
  "modules.video.book.cover.title": "title", // 标题
  "modules.video.book.cover.title.placeholder": "Enter Please", // 请输入
  "modules.video.book.edit.cover": "Edit Cover", // 请输入

  // 照片书
  "modules.photo.book.design.type": "design type", // 设计类型
  "modules.photo.book.design.type.auto":
    "Our designers will design it for you.", // 我们的设计师为你设计
  "modules.photo.book.design.type.user": "I'd like to design it by myself.", // 我自己设计
  "modules.photo.book.auto.design": "Designed by professional designers", // 我们的设计师设计
  "modules.photo.book.need.images": "The Photo Book Need", // 照片书需要
  "modules.photo.book.photos": "Photos", // 照片
  "modules.photo.book.pages": "Pages", // 页数
  "modules.photo.book.add.photos": "Add Photos", // 添加照片
  "modules.photo.book.photos.received": "We have received $value photos.", // 我们已经收到了 $value 张照片
  "modules.photo.book.photos.upload": "Need $value photos", // 需要 $value 张照片
  "modules.photo.book.pages.included": "$value pages included", // 含有页数
  "modules.photo.book.manage.photos": "Manage photos", // 管理图片
  "modules.photo.book.title.size": "Size", // 图书尺寸
  "modules.photo.book.title.page": "Pages", // 图书页数
  "modules.photo.book.auto.typesetting.tips":
    "You have $value photos left to arrange. Do you want to use intelligent typesetting technology to make the layout?", // 您还有 $value 个照片位没完成，剩余的是否使用自动排版帮您完成？
  "modules.photo.book.auto.typesetting": "Use intelligent typesetting", // 使用自动排版
  "modules.photo.book.continue.edit": "Continue editing", // 继续编辑
  "modules.photo.book.method.typesetting.tips":
    "Use your existing $value Photos or reupload new photos for layout?", // 使用您现有的 $value 张照片或重新上传新的照片进行排版？
  "modules.photo.book.uploaded.typesetting": "Use uploaded photos", // 使用已经上传的图片
  "modules.photo.book.new.typesetting": "Upload new photos", // 上传新的照片
  "modules.photo.book.pagination.front.cover": "Front Cover", // 封面
  "modules.photo.book.pagination.inset": "Inner Page", // 里面
  "modules.photo.book.pagination.inset.text": "This page is not for printing", // 里面
  "modules.photo.book.pagination.last.page": "Last Page", // 最后一页
  "modules.photo.book.pagination.back.cover": "Back Cover", // 封底
  "modules.photo.book.tabbar.image": "Photos", // 照片
  "modules.photo.book.tabbar.layout": "Layouts", // 布局
  "modules.photo.book.tabbar.background": "Backgrounds", // 底图
  "modules.photo.book.tabbar.text": "Texts", // 文字
  "modules.photo.book.before.enter.page":
    "Before entering $value, please select the page you want to edit?", // 进入 $value 前，请选择要在哪个页面进行编辑？
  "modules.photo.book.front.cover.tips":
    "Drag the picture below to the position on the right side.", // 拖拽图片到照片位
  "modules.photo.book.back.cover.tips": "Preview", // 去看看效果啊
  "modules.photo.book.uncomplete.tips":
    "$value photos do not arrange the positions, return", // 还有 $value 个照片位 未完成，返回
  "modules.photo.book.exit.tips":
    "You have not finished editing. Are you sure to quit?", // 您还没有编辑完，确定要退出吗
  // 简版照片书
  "modules.photo.book.deleted.page": "Deleted page", // 已删除页面
  "modules.photo.book.delete.page": "delete page", // 删除页面
  "modules.photo.book.change.layout": "change layout", // 更改布局
  "modules.photo.book.add.photo.page": "Add photo page", // 添加照片页面
  "modules.photo.book.add.compose.page": "Add collage page", // 添加拼贴页面
  "modules.photo.book.validator": "Add $value more pages to buy", // 再添加 $value 页即可购买
  "modules.photo.book.replace.image": "Replace Photo", // 替换照片
  "modules.photo.book.remove.image": "Remove", // 移除
  "modules.photo.book.page": "Page", // 页
  "modules.photo.book.type": "book material", // 图书材质
  "modules.photo.book.size": "book size", // 图书尺寸
  "modules.photo.book.order.qty": "qty", // 数量
  "modules.photo.book.order.total": "total", // 总计
  "modules.photo.book.warning.title": "Warning", // 警告
  "modules.photo.book.warning.cover.title": "Add title to photo book cover", // 为照片书添加标题
  "modules.photo.book.warning.cover.description":
    "Click on the cover to add a title to the cover of the photo book.", // 点击封面即可在照片书封面添加标题。
  "modules.photo.book.warning.image.title": "Low resolution images found", // 发现低分辨率图片
  "modules.photo.book.warning.image.description":
    "It may be fuzzy when printed.", // 打印出来可能比较模糊
  "modules.photo.book.add.title": "Add Title", // 添加标题
  "modules.photo.book.zuploader.subtitle": "At least need $value photos", // 至少需要 value 张照片
  "modules.photo.book.guide.title": "Purchase Guide", // 操作引导
  "modules.photo.book.guide.position": "Long press and drag it to adjust order", // 长按图片可调整顺序
  "modules.photo.book.guide.layout":
    "Click the icon on the side to change layout", // 点击侧边更多可切换图片样式
  "modules.photo.book.guide.button": "Ok Thanks", // 好的 我知道了

  // 照片墙
  "modules.photo.wall.choose.frame": "choose frame", // 选择边框
  "modules.photo.wall.choose.style": "choose style", // 选择样式
  "modules.photo.wall.zoom.pinch": "pinch and zoom", // 移动和放大
  "modules.photo.wall.zoom.scroll": "scroll to zoom", // 滚动放大
  "modules.photo.wall.choose.design": "Choose a Deisgn", // 选择设计
  "modules.photo.wall.choose.design.tips":
    "Choose from gallery walls to customize", // 从库墙中选择要自定义的墙
  "modules.photo.wall.frames": "frames", // 框
  "modules.photo.wall.image.list.title":
    "Add photos to view more design options", // 添加照片显示更多设计选项
  "modules.photo.wall.container.photo": "My Photos", // 我的图片
  "modules.photo.wall.zuploader.subtitle": "You can upload up to $value photos", // 你最多可以上传 $value 张照片
  "modules.photo.wall.image.max.count":
    "We support up to $value photos, the excess will be ignored", // 我们最多支持 $value 张照片，超出的将被忽略

  // mixtiles照片墙
  "modules.photo.wall.mixtiles.upload.tips": "pick some photos", //
  "modules.photo.wall.mixtiles.title": "customized", //
  "modules.photo.wall.mixtiles.black.none": "bold", //
  "modules.photo.wall.mixtiles.white.none": "clean", //
  "modules.photo.wall.mixtiles.black.padding_sm": "classic", //
  "modules.photo.wall.mixtiles.white.padding_sm": "ever", //

  // 3d人像
  "modules.avatar.3d.front.tips": "Please upload a front view", // 请上传正面照
  "modules.avatar.3d.replace.photo": "Replace Photo", // 替换照片
  "modules.avatar.3d.error.example": "Error demonstration", // 错误范例
  "modules.avatar.3d.wear.cap": "Wear Cap/Hat", // 戴帽子
  "modules.avatar.3d.lateral.body": "Lateral Body", // 侧身
  "modules.avatar.3d.hair.down": "Hair Down", // 头发盖住脸
  "modules.avatar.3d.hair.accessories": "Hair Accessories", // 头饰
  "modules.avatar.3d.tilt.head": "Tilt Head", // 侧头
  "modules.avatar.3d.no.color": "No Color", // 黑白照
  "modules.avatar.3d.upload.image": "Upload image", // 上传照片
  "modules.avatar.3d.camera": "Camera", // 相机
  "modules.avatar.3d.gallery": "Gallery", // 相册
  "modules.avatar.3d.converting.model": "Converting to 3D model", // 正在转换成 3d 模型
  "modules.avatar.3d.download.model": "Downloading 3D model for preview", // 正在下载 3d 模型进行渲染
  "modules.avatar.3d.confirm.again":
    "Do you really make your 3D portrait according to the previewed model?", // 确实按照预览的模型制作您的3d人像吗？

  // 卡通T恤
  "modules.cartoon.Tshirt.enter.names": "Enter names here", // 在下面输入名字
  "modules.cartoon.Tshirt.please.enter": "Please enter", // 请输入
  "modules.cartoon.Tshirt.enter.comma": "between two names. For example: ", // 在两个名字间（输入逗号）。例如：
  "modules.cartoon.Tshirt.example.names": "Liam, Tony", // 常用名字
  "modules.cartoon.Tshirt.names.max": "Enter up to $value names", // 最多多少个名字
  "modules.cartoon.Tshirt.type.words": "Type words here...", // 在此输入单词

  // zuploader
  "plugin.zuploader.from.album": "Choose from your album", // 从相册中选择
  "plugin.zuploader.from.camera": "Take a photo", // 拍照
  "plugin.zuploader.from.social": "Choose from Online Services", // 从在线服务中选择
  "plugin.zuploader.wait.upload": "Waiting for upload", // 等待上传
  "plugin.zuploader.choose.source": "Choose photos", // 选择照片来源
  "plugin.zuploader.choose.image.title": "Photos uploaded", // 已上传照片
  "plugin.zuploader.choose.image.choose": "Select photos", // 选择照片
  "plugin.zuploader.choose.image.range": "Select $value items", // 选择 $value 照片

  // lowQuality
  "plugin.low.quality.title": "Low Image Quality", // 低图像质量
  "plugin.low.quality.tips":
    "This photo is actually pretty small. It will probably make a blurry tile!", // 这张照片其实很小。它可能会变得模糊
  "plugin.low.quality.keep": "Keep Anyway", // 不管怎样都保留
  "plugin.low.quality.remove": "Remove From Order", // 从订单中删除

  // 台历
  "modules.desktop.calendar.cover": "Cover", // 封面
  "modules.desktop.calendar.change.layout": "Change Photo Layout", // 更改照片布局
  "modules.desktop.calendar.validator": "Please upload $value more photos for", // 缺 $value 张照片
  "modules.desktop.calendar.replace.image": "Replace Photo", // 替换照片
  "modules.desktop.calendar.remove.image": "Remove", // 移除

  // 增值产品-relatedProduct
  "increment.related.product.title":
    "Choose a super surprise package for your gift", // 选择超惊喜套餐作为礼物
  "increment.related.product.no.thanks": "No Thanks", // 不用，谢谢

  // 增值产品-vip
  "increment.vip.tip":
    "The best choice to process orders with priority producing & shipping", // 优先生产和运输订单的最佳选择
  "increment.vip.service": "VIP SERVICE", // vip 服务

  // 新袜子
  "modules.new.sock.network.error": "Network Error!", // 网络错误
  "modules.new.sock.ai.crop.error": "AI Crop Error!", // 切图服务错误
  "modules.new.sock.replace.photo": "Replace Photo", // 替换照片
  "modules.new.sock.crop.myself": "Crop by myself", // 自己切图
  "modules.new.sock.try.again": "Try again", // 重试
  "modules.new.sock.edit.avatar": "Edit Avatar", // 编辑头像
  "modules.new.sock.add.line.text.title": "Add Text?", // 是否需要刻字
  "modules.new.sock.add.line.text.promotion":
    "80% of customers prefer adding personalised text to make perfect gifts!", // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  "modules.new.sock.add.line.text": "ADD TEXT", // 添加文字
  "modules.new.sock.without.text": "WITHOUT TEXT", // 不刻字
  "modules.new.sock.color": "COLOR", // 颜色
  "modules.new.sock.size": "SIZE", // 尺寸
  "modules.new.sock.sock": "SOCK", // 袜子

  // 猫头杯子
  "modules.clip.mug.tip": "Make sure the cat's head is aligned with the area", // 请确认猫的头对齐了裁剪区域
  "modules.clip.mug.example": "example", // 例子
  "modules.clip.mug.correct": "correct", // 正确
  "modules.clip.mug.profile": "profile", // 脸对不准
  "modules.clip.mug.not.clear": "not clear", // 不清晰
  "modules.clip.mug.not.aligned": "not aligned", // 未对齐
  "modules.clip.mug.cup": "Cup is being generated", // 杯子正在生成
  "modules.clip.mug.next": "next", // 下一步
  "modules.clip.mug.warn":
    "The current line has exceeded $value words which is too long", // 字数太长，当前行已超出$value字

  // 地垫
  "modules.division.crop.error": "AI Crop Error", // AI裁剪错误
  "modules.division.network.error": "Network Error", // 网络错误
  "modules.division.erase": "Erase excess", // 橡皮擦
  "modules.division.crop.myself": "Crop by myself", // 自己裁剪
  "modules.division.replace.photo": "Replace photo", // 换照片
  "modules.division.try": "Try again", // 再试一次
  "modules.division.or": "or", // 或者
  "modules.division.back": "back", // 回退
  "modules.division.photo.edit": "photo editor", // 照片编辑
  "modules.division.edit.avatar": "edit avatar", // 编辑avatar
  "modules.new.sock.share.title": "Share To Get", // 分享后你将会得到
  "modules.new.sock.social.avatar": "Custom Social Profile Picture.", // 你的社交新头像
  "modules.new.sock.free.download": "Free Download", // 免费下载
  "modules.new.sock.new.mobile.wallpaper": "Custom Mobile Wallpaper.", // 你的手机新壁纸
  "modules.new.sock.new.pc.wallpaper": "Custom PC Wallpaper.", // 你的电脑新壁纸
  "modules.new.sock.discount": "Discount", // 折扣
  "modules.new.sock.discount.price": "$value OFF", // 立减 $value 块
  "modules.new.sock.share.now": "SHARE NOW", // 立刻分享
  "modules.new.sock.no.thanks": "NO, THANKS", // 不分享
  "modules.new.sock.shared.title": "CONGRATULATIONS!", // 恭喜
  "modules.new.sock.download": "Download", // 下载
  "modules.new.sock.generating.image": "Generating share image", // 正在生成分享图
  "modules.new.sock.total": "total", // 总计

  // 挂历
  "modules.wall.calendar.tap.day": "Tap Date & Add Calendar Event", // 点击日期和添加日历事件
  "modules.wall.calendar.cover": "Cover", // 封面
  "modules.wall.calendar.change.layout": "Change Photo Layout", // 更改照片布局
  "modules.wall.calendar.validator": "Please upload $value more photos for", // 缺 $value 张照片
  "modules.wall.calendar.replace.image": "Replace Photo", // 替换照片
  "modules.wall.calendar.remove.image": "Remove", // 移除
  "modules.wall.calendar.and": "And", // 和
  "modules.wall.calendar.event.add": "Add your event", // 添加你的事件
  "modules.wall.calendar.event.choose": "Choose event type", // 选择你的事件类型
  "modules.wall.calendar.event.edit": "Edit Event", // 编辑事件
  "modules.wall.calendar.event.clear": "Clear Event", // 清除事件
  "modules.wall.calendar.event.create": "Create new event", // 创建新事件
  "modules.wall.calendar.event.graduation": "graduation", // 毕业季
  "modules.wall.calendar.event.holidays": "holidays", // 度假
  "modules.wall.calendar.event.wedding": "wedding", // 婚礼
  "modules.wall.calendar.event.bar": "bar", // 酒吧
  "modules.wall.calendar.event.party": "party", // 派对
  "modules.wall.calendar.event.birthday": "birthday", // 生日
  "modules.wall.calendar.event.anniversarie": "anniversarie", // 周年纪念日
  "modules.wall.calendar.event.other": "other", // 其他
  "modules.wall.calendar.event.tips": "Tap and Edit", // 点击编辑
  "modules.wall.calendar.cover.title.show": "Show Title", // 显示标题
  "modules.wall.calendar.cover.title.hide": "Hide Title", // 隐藏标题

  // mini-me
  "modules.mini.me.choose.costume": "Choose Your Costume", // 选择你的服装
  "modules.mini.me.replace.or.edit": "Replace & Edit", // 替换或者编辑
  "modules.mini.me.type.word": "Type words here...", // 在这里输入单词
  "modules.mini.me.double.side.print": "Double-sided print", // 双面印刷
  "modules.mini.me.one.side.only.print": "One side only print", // 单面打印
  "modules.mini.me.add.photo.back.side": "Add new photo on back side", // 在背面添加新的照片
  "modules.mini.me.one.side.print": "One photo one side print", // 单照片打一面
  "modules.mini.me.add.text.back.side": "Add text on back side", // 在背面刻字
  "modules.mini.me.print.customization": "PRINTING CUSTOMIZATION", // 印刷自定义
  "modules.mini.me.or": "Or", // 或者
  "modules.mini.me.share.title": "MiniMe Xmas Greeting Card", // MiniME 圣诞贺卡
  "modules.mini.me.shared.title": "CONGRATULATIONS!", // 恭喜
  "modules.mini.me.share.tip":
    "Reward Share. Get free Xmas MiniMe image & extra $value off your pillow by sharing it.", // 奖励分享。共享即可免费获得 Xmas MiniMe 图片和额外枕头产品的 $value 优惠。
  "modules.mini.me.share.now": "SHARE NOW", // 立刻分享
  "modules.mini.me.no.thanks": "No Thanks & Add To Cart", // 不分享并加车
  "modules.mini.me.shared.card.discount": "You've enjoyed $value off.", // 你享受了 $value 的折扣
  "modules.mini.me.download.card": "Download image", // 下载图片
  "modules.mini.me.generating.image": "Generating share image", // 正在生成分享图

  // 音频照片
  "modules.sonic.photo.title": "Sonicphotos", // 音频照片标题
  "modules.sonic.photo.title.sizes": "Sizes", // 尺寸
  "modules.sonic.photo.select.type": "Select Your Type", // 选择你的类型
  "modules.sonic.photo.selected": "Selected", // 已选择
  "modules.sonic.photo.choose.style": "Choose Your Voice Style", // 选择音频方式
  "modules.sonic.photo.record": "Record", // 录音
  "modules.sonic.photo.record.voice": "Record your own voice", // 录下自己的声音
  "modules.sonic.photo.text": "Text", // 文字
  "modules.sonic.photo.convert.text": "Convert text to speech", // 将文字转换为语音
  "modules.sonic.photo.file": "File", // 文件
  "modules.sonic.photo.choose.file": "Choose a music file", // 选择音频文件
  "modules.sonic.photo.mic.not.authority":
    "Sorry, you do not have the microphone permission.", // 对不起，您没有麦克风权限
  "modules.sonic.photo.mic.not.found": "Sorry, no microphone was found.", // 对不起，找不到麦克风设备
  "modules.sonic.photo.mic.occupied":
    "Sorry, your microphone is occupied by other software.", // 对不起，您的麦克风以被其他软件占用
  "modules.sonic.photo.mic.not.require":
    "Sorry, your microphone cannot meet the recording requirements.", // 对不起，您的麦克风不能满足录音要求
  "modules.sonic.photo.browser.not.support":
    "Sorry, the current browser does not support recording.", // 对不起，当前浏览器不支持录音
  "modules.sonic.photo.click.record": "CLICK TO RECORD", // 点击开始录音
  "modules.sonic.photo.record.too.short": "The recording time is too short.", // 录音时间太短
  "modules.sonic.photo.convert.text.title": "WHAT DO YOU WANNA SAY", // 你想说什么
  "modules.sonic.photo.convert.text.tip":
    "You can also try our recommendation.", // 你也可以尝试我们的建议
  "modules.sonic.photo.voice.male": "MALE", // 男
  "modules.sonic.photo.voice.female": "FEMALE", // 女
  "modules.sonic.photo.confirm": "Confirm", // 确认
  "modules.sonic.photo.retry": "Retry", // 重试
  // 'modules.sonic.photo.add.photo': 'Add photo', // 添加照片
  "modules.sonic.photo.next": "Next", // 下一步
  "modules.sonic.photo.not.satisfied": "Not satisfied?", // 不满意？
  "modules.sonic.photo.try": "Try the", // 尝试...
  "modules.sonic.photo.mode": "Mode", // 模式

  // 无限魔方
  "modules.infinite.cube.zuploader.title": "Need $value photos", // 需要 $value 张照片
  "modules.infinite.cube.input.tip": "Drag or click to adjust the picture", // 拖动或点击来调整图片
  "modules.infinite.cube.preview3d.tip": "Drag the cube to view", // 拖动观察魔方
  "modules.infinite.cube.preview3d.tap": "Tap me", // 点击我(按钮)
  "modules.infinite.cube.preview.choose": "Choose your cover photo", // 选择你的封面
  "modules.infinite.cube.save": "Save", // 保存
  "modules.infinite.cube.share": "share and $elm $value off $end", // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  "modules.infinite.cube.share.and": "share and", // 分享并且
  "modules.infinite.cube.share.off": "off", // 减价
  "modules.infinite.cube.or": "or", // 或者
  "modules.infinite.cube.without.share": "without share & add to cart", // 不分享直接加车

  // 图片模糊提示组件
  "components.blur.tip.low.quality.title": "Low image quality", // 低画质
  "components.blur.tip.low.quality.detail":
    "This picture will probably come out blurry.lt’d be best to use a different one!", // 这张图片可能会变得模糊。最好使用其他图片！
  "components.blur.tip.detect.text": "Detecting blur…", // 正在检测模糊…
  "components.blur.tip.detect.skip": "Skip", // 跳过
  "components.blur.tip.failed.text": "Test failed!", // 测试失败
  "components.blur.tip.failed.retry": "Retry", // 重试
  "components.blur.tip.failed.close": "Close", // 重试

  // Spotify Code
  "modules.spotify.code.search.hot": "Hot Search", // 热门搜索
  "modules.spotify.code.search.reuslt": "Search Results", // 搜索结果
  "modules.spotify.code.placeholder": "Search Song、Artist、Album", // 搜索歌曲、演奏者、专辑名
  "modules.spotify.code.album.upload": "Upload Your Photo", // 上传你的图片
  "modules.spotify.code.album.track": "Use The Album Cover", // 使用相册封面
  "modules.spotify.code.change.color": "Choose Your Color", // 选择你的颜色
  "modules.spotify.code.custom.artists": "Custom Name of Artist", // 你可以自己定制演奏者
  "modules.spotify.code.custom.line.text": "Enter Your Words",
  "modules.spotify.code.search.no.result": "Oops, No Result.", // 没结果
  "modules.spotify.code.add.text.ask": "Would You Need To Add Text", // 你是否需要添加刻字
  "modules.spotify.code.add.text": "Add The Text", // 添加刻字
  "modules.spotify.code.skip.add.cart": "No, Thanks & Add To Cart", // 不谢谢 加车
  "modules.spotify.code.add.text.title": "Add your text here", // 在这添加你的刻字
  "modules.spotify.code.add.text.or": "or", // 或
  "modules.spotify.code.buy.it": "Sure, buy it now", // 是的 买它
  "modules.spotify.code.template.title": "Choose Your Favorite Style",
  "modules.spotify.code.side.front": "Front Side", // 前面
  "modules.spotify.code.side.back": "Back Side", // 背面
  "modules.spotify.code.multi.upload": "Upload Your Photos", // 上传你的照片

  "modules.spotify.code.lyric.no.found": "No Lyrics Found", // 没找到歌词
  "modules.spotify.code.lyric.try.another": "Try another song", // 尝试其他歌曲
  "modules.spotify.code.lyric.write.own": "Write your own lyrics", // 自己写歌词
  "modules.spotify.code.lyric.revise": "Revise Lyrics", // 自己写歌词
  "modules.spotify.code.lyric.search": "Search Lyrics", // 搜索歌词
  "modules.spotify.code.lyric.wrong": "The lyrics are wrong?", // 歌词有误
  "modules.spotify.code.lyric.modify": "Modify it", // 修改他
  "modules.spotify.code.lyric.loading": "Lyrics are generating Preview", // 歌词正在生成预览
  "modules.spotify.code.lyric.limit": "The number of lines of lyrics exceeds the limit.", // 歌词的行数超过了限制


  // Spotify Code Album
  "modules.spotify.code.album.line.text": "Name", // 输入刻字

  // 情侣地图
  "modules.couple.map.position": "Position", // 位置
  "modules.couple.map.drag":
    "Drag the map above to zoom in and out, or enter the address search", //
  "modules.couple.map.search.address": "Search address", // 搜索地址
  "modules.couple.map.prev": "Prev", // 前一步
  "modules.couple.map.generating": "Generating...", // 正在生成...
  "modules.couple.map.tip":
    "The current line has exceeded $value words which is too long", // 字数太长，当前行已超出$value字
  "modules.couple.map.cancel": "cancel", // 取消
  "modules.couple.map.next": "Next", // 下一步
  "modules.couple.map.confirm": "Confirm", // 确定
  "modules.couple.map.size": "size", // 尺寸
};
