import { fabric } from 'sunzi-fabric';
import Fontfaceobserver from 'fontfaceobserver';
import fetch from 'isomorphic-fetch';
import jhttp from 'jhttp';
import FBXLoader from 'three-fbxloader-offical';
import { parseGIF, decompressFrames } from 'sunzi-gifuct';
import Gif from 'gif.js';
import fuzzy from 'sunzi-image-fuzzy';
import exif from 'exif-js';
import { FuzzyThreshold, fileStackHost } from '@/config';
import JSZip from 'jszip';
export { default as i18n } from './i18n';
export { default as Zen3d } from './zen3d';
export { meituFaceCut, aliSketch, versaSegment, versaStyleTransfer, myFaceCut, imageCartoon, textToSpeech, faceRecognition, cutoutVersaSegment } from './request';
export { hsv2rgb, rgb2hsv, getImageColor } from './color';

/** 判断是否是手机 */
export const isMobile = () => {
  const userAgent = navigator.userAgent;
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  for (let i = 0; i < agents.length; i++)
    if (userAgent.indexOf(agents[i]) > 0)
      return true;
  return false;
};

/** fabric 图片加载 URL */
export const loadImageFromURL = (URL: string, option: fabric.IImageOptions = {}) : Promise<fabric.Image> => new Promise((resolve, reject) => {
  fabric.Image.fromURL(URL, (image: fabric.Image) => {
    if (URL && (image.width === 0 || image.height === 0))
      reject('图片文件加载失败，请检查您的网络环境是否通畅！');
    resolve(image);
  }, {
    crossOrigin: 'anonymous',
    ...option
  });
});

/** 加载字体 */
export const loadFont = (name: string, timeout: number = 30000) => new Promise((resolve, reject) => {
  const _font = new Fontfaceobserver(name);
  _font.load(null, timeout).then(() => resolve(name), reject);
});

/** 加载图片 */
export const loadHTMLImage = (base64: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => resolve(image);
  image.onerror = reject;
  image.src = base64;
});

/** 加载GIF图片 */
export const loadGifImage = async (URL: string): Promise<{
  delay: number;
  frames: HTMLImageElement[];
}> => {
  // result canvas
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext('2d');
  // 解析帧
  const frames = await fetch(URL)
    .then(resp => resp.arrayBuffer())
    .then(buff => parseGIF(buff))
    .then(gif => {
      canvas.width = gif.lsd.width;
      canvas.height = gif.lsd.height;
      return decompressFrames(gif, true);
    });
  // patch canvas
  const patchCanvas = document.createElement("canvas");
  const patchCtx = patchCanvas.getContext('2d');
  // 绘制帧
  const _frames = await Promise.all<HTMLImageElement>(frames.map((item: any) => {
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    patchCanvas.width = item.dims.width;
    patchCanvas.height = item.dims.height;
    // 转换帧
    const frameImageData = patchCtx?.createImageData(item.dims.width, item.dims.height);
    frameImageData?.data.set(item.patch);
    if (frameImageData)
      patchCtx?.putImageData(frameImageData, 0, 0);
    // 渲染在结果canvas上
    ctx?.drawImage(patchCanvas, item.dims.left, item.dims.top);
    return canvas.toDataURL();
  }).map((item: string) => loadHTMLImage(item)));

  // 销毁canvas
  patchCanvas.width = patchCanvas.height = 0;
  canvas.width = canvas.height = 0;

  return {
    delay: frames[0].delay,
    frames: _frames
  }
}

/** 生成GIF */
export const toGifImage = async (workerjs: string, frames: (string | undefined)[], delay: number, onprogress?: (progress: number) => void) =>
  new Promise(async resove => {
    const gif = new Gif({
      workerScript: await getBlobUrl(workerjs),
      workers: 2,
      quality: 10,
    });
    const images = await Promise.all(frames.map(item => loadHTMLImage(item || '')));
    // 添加帧
    images.forEach(item => gif.addFrame(item, { delay }));
    gif.on('finished', (blob: Blob) => resove(blob));
    if (onprogress)
      gif.on('progress', onprogress);
    gif.render();
  }
);

/** 解析GIF帧 */
export const getGifFrames = async (gif: string) => fetch(gif)
  .then(resp => resp.arrayBuffer())
  .then(buff => parseGIF(buff))
  .then(gif => decompressFrames(gif, true));


/** 通过图片地址获取base64 */
export const loadBase64FromURL = (URL: string, maxWidth: number = 400, format: string = 'jpeg', quality: number = 0.5): Promise<string> => new Promise((resolve, reject) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = Math.min(maxWidth, image.width);
    canvas.height = canvas.width * image.height / image.width;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
    const base64 = canvas.toDataURL(`image/${format}`, quality);
    // 销毁canvas
    canvas.width = canvas.height = 0;
    resolve(base64);
  }
  image.onerror = reject;
  image.src = URL;
});

/** url 转 blob */
export const urlToBlob = (url: string): Promise<Blob> => new Promise(resolve => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200)
      resolve(this.response);
  }
  xhr.send();
})

/** 上传 blobUrl 到7牛 */
export const upload7nObjectURL = async (params: {
  blobURL: string;
  token: string;
  key: string,
  bucket?: string
}, onprogress?: (progress: number) => void) => {
  const file = await urlToBlob(params.blobURL);
  return upload7n({
    file: file,
    token: params.token,
    key: params.key,
    bucket: params.bucket
  }, onprogress)
};

/** 获取图片像素数据 */
export const loadImageData = async (source: string, maxWidth: number = 600): Promise<ImageData | undefined> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = await loadHTMLImage(source);
  canvas.width = Math.min(maxWidth, image.width);
  canvas.height = canvas.width * image.height / image.width;
  ctx?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
  const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
  // 释放canvas
  canvas.width = canvas.height = 0;
  return imageData;
};

/** 获取7牛的token */
export const getToken7n = async (bucket: string = 'sunzi_ai') => {
  const response: {
    error: number;
    token: string;
  } = await jhttp.get(`${host7n.token}/qiniu/token/bucket`, { bucket });
  if (response.error === 0) {
    return response.token;
  }
  return '';
}

export const uuid = () => {
  const s: any = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++)
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);

  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join("");
}

export const dateTime = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`;

export const delay = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

/** 文件流转换成base64 */
export const fileToBase64 = (file: Blob, callback: (b64: any) => void) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e: any) => {
    callback(e.target.result);
  }
}

/** base64转换成文件流 */
export const base64ToBlob = (base64: string) => {
  const b64Array = base64.split(',');
  let type = 'image/jpeg';
  const mime = b64Array[0].match(/:(.*?);/);
  if (mime) type = mime[1];
  const bytes = atob(b64Array[1]);
  // 字节写入
  const u8arr = new Uint8Array(bytes.length);
  for (let i = 0; i < bytes.length; i++)
    u8arr[i] = bytes.charCodeAt(i);

  if (u8arr.length === 0)
    throw new Error(`Error: base64ToBlob Fail, mime: ${type}`);

  return new Blob([ u8arr ], { type });
}

/** 上传base64到7牛 */
export const upload7nB64 = (params: {
  base64: string;
  token: string;
  key: string,
  bucket?: string
}, onprogress?: (progress: number) => void) => upload7n({
  file: base64ToBlob(params.base64),
  token: params.token,
  key: params.key,
  bucket: params.bucket
}, onprogress);


/** 7n上传文件流 */
export const upload7n = (params: {
  file: Blob;
  token: string;
  key: string,
  bucket?: string
}, onprogress?: (progress: number) => void): Promise<{ key: string }> => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  // 上传进度
  xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
    if (e.total > 0)
      onprogress && onprogress(e.loaded / e.total * 100);
  }
  // 构建表单参数
  const formData = new FormData();
  formData.append('file', params.file);
  formData.append('token', params.token);
  formData.append('key', params.key);

  // 超时时间
  xhr.timeout = 30 * 1000;

  xhr.onload = () => {
    if (xhr.status < 200 || xhr.status >= 300) reject();
    else {
      const text = xhr.responseText || xhr.response;
      try {
        return resolve(JSON.parse(text));
      } catch (e) {
        reject();
      }
    }
  }
  // 请求错误
  xhr.onerror = reject;
  // 请求超时
  xhr.ontimeout = reject;
  xhr.open('post', params.bucket || host7n['upload-na0'], true);
  xhr.send(formData);
});

/** 根据底图规则截取底图 */
export const bottom7nCrop = (url: string, {
  left,
  top,
  width,
  height,
}: {
  left: number;
  top: number;
  width: number;
  height: number;
}, format?: string) : string => {
  let path = `${url}?imageMogr2/crop/!${width}x${height}a${left}a${top}`;
  if (format) path += `/format/${format}`;
  return path;
}

/** 7牛图片瘦身 */
export const slim7n = (url: string) : string => `${url}?imageslim`;

/** 缩略图片 */
export const thumbnail7n = (url: string, size: number) : string => {
  if (url.indexOf(fileStackHost) > -1) {
    const handle = url.replace(fileStackHost, '');
    return `${fileStackHost}/resize=width:${size}${handle}`;
  }
  // 7牛链接
  return `${url}?imageView2/0/w/${size}/h/${size}/interlace/1|imageslim`;
};

/** 校验状态图标 */
export const validatorStyle: {
  type: 'wait' | 'warning' | 'check',
  color: string
}[] = [
  {
    type: 'wait',
    color: '#d0d0d0'
  },
  {
    type: 'warning',
    color: '#ff0136'
  },
  {
    type: 'check',
    color: '#09bb07'
  }
];

/** 3d模型预加载 */
export const preLoadFBX = (model: string) => new Promise(resolve => {
  new FBXLoader().load(model, (fbx: any) => resolve(fbx));
});

/** 阻止冒泡 */
export const eventStopBubbles = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>) => {
  event.preventDefault();
  event.stopPropagation();
}

/** 七牛样式图片合成 */
export const compose7n = (bottom: {
  image: string,
  width: number,
  height: number
}, images: string[]) => {
  const position = ['NorthWest', 'West', 'SouthWest', 'North', 'Center', 'South', 'NorthEast', 'East', 'SouthEast'];
  let cropStyle = '';
  if (images.length <= 3)
    cropStyle = `|imageMogr2/gravity/NorthWest/crop/!${bottom.width / 3}x${bottom.height / 3 * images.length}`;
  else if (images.length <= 6)
    cropStyle = `|imageMogr2/gravity/NorthWest/crop/!${bottom.width / 3 * 2}x${bottom.height}`;
  return bottom.image + '?' + images.slice(0, 9).map((item, index) => `watermark/1/gravity/${position[index]}/image/${btoa(item)}`).join("|") + cropStyle;
}

/** 手动图片合成 */
export const composeHand = async (sources: string[], maxWidth: number = 1200) => {
  const images = await Promise.all(sources.map(item => loadHTMLImage(item)));
  const canvas = document.createElement('canvas');
  canvas.width = Math.min(Math.max(...images.map(item => item.width)), maxWidth);
  canvas.height = images.map(item => item.height * Math.min(canvas.width, maxWidth) / item.width)
    .reduce((prev, curr) => prev + curr);
  const context = canvas.getContext('2d');
  let dy = 0;
  images.forEach(item => {
    const width = Math.min(canvas.width, maxWidth);
    const height = item.height * width / item.width
    context?.drawImage(
      item,
      0,
      dy,
      width,
      height
    );
    dy += height;
  });
  const dataURL = canvas.toDataURL('image/jpeg');
  canvas.width = canvas.height = 0;
  return dataURL;
}

/** 根据左上角像素点, 切割四周留白 */
export const getTrimImage = async (image: string, async?: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const loadQueue = [ loadHTMLImage(image) ];
  // 如果存在同步图
  if (async) loadQueue.push(loadHTMLImage(async));
  let [ _image, _async ] = await Promise.all(loadQueue);
  let resultAsync;
  canvas.width = _image.width;
  canvas.height = _image.height;
  if (async) { // 如果存在同步图片, 按照image同比例缩小
    ctx?.drawImage(_async, 0, 0, _async.width, _async.height, 0, 0, canvas.width, canvas.height);
    _async = await loadHTMLImage(canvas.toDataURL());
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
  }
  ctx?.drawImage(_image, 0, 0);
  let i, x, y, left, top, right, bottom, width, height;
  if (ctx) {
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const l = pixels.data.length;
    const ltr = pixels.data[0], ltg = pixels.data[1],
      ltb = pixels.data[2], lta = pixels.data[3];

    for (i = 0; i < l; i += 4) {
      if (!(ltr === pixels.data[i] && ltg === pixels.data[i + 1] && ltb === pixels.data[i + 2] && lta === pixels.data[i + 3])) {
        x = (i / 4) % canvas.width;
        y = ~~((i / 4) / canvas.width);
        // 获取上下左右坐标
        if (top === undefined) top = y; // 上
        if (left === undefined) left = x; // 左
        else if (x < left) left = x;
        if (right === undefined) right = x; // 右
        else if (right < x) right = x;
        if (bottom === undefined) bottom = y; // 下
        else if (bottom < y) bottom = y;
      }
    }
    left = left || 0,
    top = top || 0,
    height = (bottom || 0)  - top,
    width = (right || 0) - left;
    const trimmed = ctx.getImageData(left, top, width, height);
    // 重新绘制像素点
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = width;
    canvas.height = height;
    ctx.putImageData(trimmed, 0, 0);
  }
  const result = canvas.toDataURL();
  if (_async) {
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(_async, left || 0, top || 0, width || 0, height || 0, 0, 0, canvas.width, canvas.height);
    resultAsync = canvas.toDataURL();
  }
  canvas.width = canvas.height = 0;
  return {
    left,
    top,
    image: result,
    imageAsync: resultAsync
  };
}

/** 同步透明像素 */
export const asyncAlphaPixels = async (source: string, dest: string, pixels: number = 0) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const [ _source, _dest ] = await Promise.all([
    loadHTMLImage(source),
    loadHTMLImage(dest)
  ]);
  canvas.width = _source.width;
  canvas.height = _source.height;
  ctx?.drawImage(_source, 0, 0);
  const alpha = []
  if (ctx) {
    const spixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // 寻找透明像素点
    for (let i = 3; i < spixels.data.length; i += 4)
      if (spixels.data[i] === pixels)
        alpha.push(i);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(_dest, 0, 0, _dest.width, _dest.height, 0, 0, canvas.width, canvas.height);
    const dpixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 3; i < spixels.data.length; i += 4)
      if (alpha.indexOf(i) > -1)
        dpixels.data[i] = pixels;

    ctx.putImageData(dpixels, 0, 0);
  }
  const result = canvas.toDataURL();
  canvas.width = canvas.height = 0;
  return result;
}

/** 格式化视频时间 */
export const formatVideoTime = (value: number) => {
  const d = new Date(value);
  const ms = d.getMilliseconds();
  let sec: number | string = d.getSeconds();
  if (sec < 10) sec = '0' + sec;
  return d.getMinutes() + ':' + sec + '.' + ms.toString().charAt(0);
}

/**
 * 自动在原图按照比例截取最大矩形
 * @param source 需要裁剪的图片base64
 * @param width   目标宽度,与height比值一致即可,不管数值大小
 * @param height  目标高度,与width比值一致即可,不管数值大小
 */
export const autoCropperImage = async (source: string, width: number = 600, height: number = 600,
  options?: {left?: number, top?: number, width?: number, height?: number}): Promise<string> => {
  const image = await loadHTMLImage(source);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let imgWidth = image.width, imgHeight = imgWidth * height / width;
  // 根据宽高比赋值渲染尺寸
  if (image.width / image.height > width / height) {
    imgHeight = image.height;
    imgWidth = imgHeight * width / height;
  }
  console.log(width, height, imgWidth, imgHeight)
  canvas.width = options?.width || width;
  canvas.height = options?.height || height;
  // 绘制图片
  ctx?.drawImage(
    image,
    (image.width - imgWidth) / 2,
    (image.height - imgHeight) / 2,
    imgWidth,
    imgHeight,
    options?.left || 0,
    options?.top || 0,
    options?.width || width,
    options?.height || height
  );

  const result = await canvasToBlobURL(canvas);
  // 释放canvas
  canvas.width = canvas.height = 0;
  return result;
};

/**
 * 图片裁剪
 * @param source 需要裁剪的图片base64
 * @param left   左
 * @param top  上
 * @param width   宽度
 * @param height  高度
 * @param options 生成图片的宽高
 */
export const cropperImage = async (source: string, left: number, top: number, width: number, height: number,
  options?: {left?: number, top?: number, width?: number, height?: number}) : Promise<string> => {
  const image = await loadHTMLImage(source);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = options?.width || width;
  canvas.height = options?.height || height;
  // 绘制图片
  ctx?.drawImage(
    image,
    left,
    top,
    width,
    height,
    options?.left || 0,
    options?.top || 0,
    options?.width || width,
    options?.height || height
  );
  const result = canvas.toDataURL();
  // 释放canvas
  canvas.width = canvas.height = 0;
  return result;
};

/**
 * 自动在原图按照ai图截取图片
 * @param source 需要裁剪的图片base64
 * @param aiImage ai图
 */
export const autoCropaiImage = async (source: string, cropImage?: string, aiImage?: string): Promise<{
  crop: string;
  ai?: string;
}> => {
  // 构建canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let ai, _ai, _scale = 0;
  // 加载原图
  const image = await loadHTMLImage(source);
  if (ctx && cropImage) {
    const trimImage = await getTrimImage(cropImage, aiImage);
    _ai = trimImage.imageAsync;
    const _cropImage = await loadHTMLImage(trimImage.image);
    canvas.width = _cropImage.width;
    canvas.height = _cropImage.height;
    _scale = Math.min(image.width / _cropImage.width, image.height / _cropImage.height);
    ctx.drawImage(
      image,
      (image.width - canvas.width * _scale) / 2,
      (image.height - canvas.height * _scale) / 2,
      canvas.width * _scale,
      canvas.height * _scale,
      0,
      0,
      canvas.width,
      canvas.height
    );
    ctx.drawImage(_cropImage, 0, 0);
  }
  const crop = canvas.toDataURL('image/jpeg');
  if (ctx && _ai) {
    const _aiImage = await loadHTMLImage(_ai);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      (image.width - canvas.width * _scale) / 2,
      (image.height - canvas.height * _scale) / 2,
      canvas.width * _scale,
      canvas.height * _scale,
      0,
      0,
      canvas.width,
      canvas.height
    );
    ctx.drawImage(_aiImage, 0, 0);
    ai = (await getTrimImage(canvas.toDataURL('image/png'))).image;
  }
  // 释放canvas
  canvas.width = canvas.height = 0;
  return { crop, ai };
};

/** 图片压缩 */
export const compressImage = async (source: string, maxValue: number) => {
  // 构建canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = await loadHTMLImage(source);
  canvas.width = maxValue;
  canvas.height = maxValue * image.height / image.width;
  if (image.width < image.height) {
    canvas.height = maxValue;
    canvas.width = canvas.height * image.width / image.height;
  }
  ctx?.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
  const result = canvas.toDataURL('image/jpeg');
  // 释放canvas
  canvas.width = canvas.height = 0;
  return result;
}


export const getBlobUrl = (url: string): Promise<string> => new Promise(resolve => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status === 200)
      resolve(URL.createObjectURL(this.response));
  }
  xhr.send();
})

/** 忽略苹果 3dTouch */
export const ignore3dTouch = () => {
  window.addEventListener('touchforcechange', (event: any) => {
    var force = event.changedTouches[0].force;
    if (force > 0.5)
      event.preventDefault();
  });
}

/** 图片模糊检测（单进程） */
export const imageFuzzy = async (source: string): Promise<boolean> => {
  const imageData = await loadImageData(source);
  const score = fuzzy(imageData);
  return score.avg_edge_width_perc > FuzzyThreshold;
}


// 通过摄像头拍摄的图片才有exif信息 待优化
export const getOrientation = (file: Blob): Promise<string> => {
  return new Promise(async resolve => {
    const image: any = await loadHTMLImage(window.URL.createObjectURL(file));
    exif.getData(image, () => {
      const orientation: number = exif.getTag(image, 'Orientation');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      switch(orientation) {
        case 3:
          canvas.width = image.height;
          canvas.height = image.width;
          ctx?.translate(canvas.width / 2, canvas.height / 2);
          ctx?.rotate(180 * Math.PI / 180);
          ctx?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.width,
            canvas.height
          );
          break;
        case 6:
          canvas.width = image.height;
          canvas.height = image.width;
          ctx?.translate(canvas.width / 2, canvas.height / 2);
          ctx?.rotate(90 * Math.PI / 180);
          ctx?.translate(-canvas.height / 2, -canvas.width / 2);
          ctx?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.height,
            canvas.width
          );
          break;
        case 8:
          canvas.width = image.height;
          canvas.height = image.width;
          ctx?.translate(canvas.width / 2, canvas.height / 2);
          ctx?.rotate(270 * Math.PI / 180);
          ctx?.translate(-canvas.height / 2, -canvas.width / 2);
          ctx?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.height,
            canvas.width
          );
          break;
        default:
          canvas.width = image.width;
          canvas.height = image.height;
          ctx?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.width,
            canvas.height
          );
          break;
      }
      const result = canvas.toDataURL();
      canvas.width = canvas.height = 0;
      resolve(result);
    });
  });
}

/** 防抖函数 */
export const debounce = (fn: (...args: any) => void, timeout: number) => {
  console.log('init')
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    console.log('go')
    if (timer) {
      console.log('clear')
      clearTimeout(timer);
    }
    timer = setTimeout(() => {fn(...args)}, timeout);
  }
}

/** createObjectURL */
export const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

/** 获取上传key */
export const uploadKey = (type: string) => `${type}/${dateTime}/${uuid()}`;

/** 解压zip文件并拿到文件 */
export const unzipFile = async (file: any, type: JSZip.OutputType) => {
  const zipFile: JSZip = await JSZip.loadAsync(file);
  const filesName = Object.keys(zipFile.files);
  let filesBuffer: Array<any> = [];
  for (let i = 0; i < filesName.length; i++) {
    const name = filesName[i];
    const file = await zipFile.files[name].async(type);
    filesBuffer.push(file);
  }
  return filesBuffer;
}

/** 判断屏幕是否大于768 */
export const sceenGt768 = document.body.offsetWidth > 768;

/** 判断屏幕是否大于1200 */
export const sceenGt1200 = document.body.offsetWidth > 1200;

// scroll smooth
export const scrollSmoothTop = (element: HTMLElement, top: number, callback = () => {}) => {
  // 当前滚动高度
  let scrollTop = element.scrollTop;
  // 滚动step方法
  const step = () => {
    // 距离目标滚动距离
    const distance = top - scrollTop;
    // 目标滚动位置
    scrollTop = scrollTop + distance / 5;
    if (Math.abs(distance) < 1) {
      element.scrollTo(0, top);
      callback();
    } else {
      element.scrollTo(0, scrollTop);
      requestAnimationFrame(step);
    }
  };
  step();
}

// scroll smooth
export const scrollSmoothLeft = (element: HTMLElement, left: number) => {
  // 当前滚动高度
  let scrollLeft = element.scrollLeft;
  // 滚动step方法
  const step = () => {
    // 距离目标滚动距离
    const distance = left - scrollLeft;
    // 目标滚动位置
    scrollLeft = scrollLeft + distance / 5;
    if (Math.abs(distance) < 1) {
      element.scrollTo(left, 0);
    } else {
      element.scrollTo(scrollLeft, 0);
      requestAnimationFrame(step);
    }
  };
  step();
}

/**
 * 裁剪图片周围的透明色
 * @param source 需要裁剪的图片 base64
 */
export const cropImageTransparent = async (source: string) => {
  // 加载原图
  const image = await loadHTMLImage(source);
  // 构建canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;
  // 上下左右值
  let top = image.height;
  let bottom = 0;
  let left = image.width;
  let right = 0;
  // 获取像素信息
  context!.drawImage(image, 0, 0);
  const imageData = context!.getImageData(0, 0, image.width, image.height);
  for (let y = 0; y < image.height; y++) {
    for (let x = 0; x < image.width; x++) {
      // 拿到像素点下标
      const i = (y * image.width + x) * 4;
      // 拿到透明度
      const a = imageData.data[i + 3];
      if (a !== 0) {
        top = Math.min(y, top);
        bottom = Math.max(y, bottom);
        left = Math.min(x, left);
        right = Math.max(x, right);
      }
    }
  }
  top += 1;
  bottom += 1;
  left += 1;
  right += 1;
  const width = right - left;
  const height = bottom - top;
  // const result = await cropperImage(source, left, top, width, height);
  canvas.width = width;
  canvas.height = height;
  // 清除画布
  context!.clearRect(0, 0, width, height);
  // 绘制图片
  context!.drawImage(
    image,
    left,
    top,
    width,
    height,
    0,
    0,
    width,
    height
  );
  const result = canvas.toDataURL();
  // 释放canvas
  canvas.width = canvas.height = 0;

  return result;
}

/** 价格格式化 */
export const priceFormat = (symbol: string, price: string | number) => {
  if (symbol === '€') {
    const priceString = price.toString();
    const displayPrice = priceString.replace('.', ',');
    return `${displayPrice}${symbol}`;
  } else {
    return `${symbol}${price}`;
  }
}

/** 画布转blob链接 */
export const canvasToBlobURL = (canvas: HTMLCanvasElement, type?: string) => new Promise<string>(resolve => {
  canvas.toBlob(blob => {
    resolve(createObjectURL(blob));
  }, type)
});

export const removeEmojis = (str: string) => {
  const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return str.replace(regex, '');
}
