import { useState, useEffect } from 'react';
import { i18n } from '@/utils';

/** 设置国际化 */
export const useLocal = (local: string) => {
  const [ , render ] = useState<any>();

  useEffect(() => {
    i18n.setLocal(local);
    render({}); // 强制渲染
  }, []);
}
