import React from 'react';
import { RGB } from '@/sunzi';
import styles from './style.less';

interface LayoutProps {
  /** 主题色 */
  theme?: RGB;
  /** 插件集 */
  plugins?: React.ReactNode[];
}

const Layout: React.FC<LayoutProps> = ({
  theme = {
    r: 255,
    g: 83,
    b: 58
  },
  plugins = [],
  children
}) => {

  // 注入主题色
  const themeColor = `
    :root {
      --sunzi-theme-color: rgba(${theme.r}, ${theme.g}, ${theme.b});
      ${Array.from({ length: 8 })
        .map((_, index) =>
          `--sunzi-theme-color-o${index + 1}: rgba(${theme.r}, ${theme.g}, ${theme.b}, .${index + 1})`
        )
        .join(';')
      }
    }`;

  return (
    <div className={styles.layout}>
      <style>{themeColor}</style>
      { children }
      {plugins.map((plugin, index) => plugin && (
        <div
          key={index}
          className={styles.plugin}
          style={{
            zIndex: 9999 + index
          }}
        >
          { plugin }
        </div>
      ))}
    </div>
  );
};

export default Layout;
