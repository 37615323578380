export default {
  'modules.global.add.cart': 'TOVOEGEN AAN WINKELMANDJE', // 加车
  'modules.global.previous': 'VORIGE', // 上一步
  'modules.global.next': 'VOLGENDE', // 下一步
  'modules.global.continue': 'BLIJVEN', // 继续
  'modules.global.confirm': 'BEVESTIGEN', // 确认
  'modules.global.free': 'GRATIS', // 免费

  'modules.error.load.font': 'Fout bij het laden van het lettertype, probeer het opnieuw', // 字体加载失败 请重试
  'modules.error.load.photo': "Fout bij het laden van de foto's, probeer het nog eens", // 图片加载失败 请重试
  'modules.error.upload.result': 'Upload error. Probeer het opnieuw', // 上传失败 请重试

  // 增值产品-vip
  'increment.vip.tip': 'De beste keuze voor het verwerken van bestellingen met prioriteit produceren & verzenden', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'VIP-SERVICE', // vip 服务

  // Spotify Code
  'modules.spotify.code.search.hot': 'Hot zoeken', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Zoekresultaten', // 搜索结果
  'modules.spotify.code.placeholder': 'Zoek nummer,Kunstenaar,Album', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Upload uw foto', // 上传你的图片
  'modules.spotify.code.album.track': 'Gebruik de albumomslag', // 使用相册封面
  'modules.spotify.code.change.color': 'Kies uw kleur', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'Kies uw kleur', // 你可以自己定制演奏者
  "modules.spotify.code.multi.upload": "Upload je foto's", // 上传你的照片
}
