export default {
  'modules.global.uoload.photo': 'Subir Fotos', // 上传图片
  'modules.global.upload': 'Subir', // 上传
  'modules.global.confirm': 'CONFIRMAR', // 确认
  'modules.global.processing': 'Procesando Tu Imagen', // 确认
  'modules.global.confirm.design': 'CONFIRMAR DISEÑO', // 确认设计
  'modules.global.free': 'GRATIS', // 免费
  'modules.global.add.text': 'Añadir textos', // 添加刻字
  'modules.global.preview.confirm': 'Subiendo foto', // 正常上传图片
  'modules.global.preview.tips': 'Nuestro diseñador ajustará su diseño para un mejor efecto de impresión.', // 预览提示
  'modules.global.add.cart': 'AÑADIR AL CARRITO', // 加车
  'modules.global.previous': 'ANTERIOR', // 上一步
  'modules.global.next': 'SIGUIENTE', // 下一步

  'modules.global.preview': 'Vista previa', // 预览
  'modules.global.loading': 'Cargando', // 正在加载
  'modules.global.uploading': 'Cargando', // 正在上传

  'modules.global.add.photo': 'Agregar fotos', // 添加图片
  'modules.global.checkout': 'revisa', // 结账
  'modules.global.cancel': 'Cancelar', // 取消
  'modules.global.complete': 'completar', // 完成
  'modules.global.change': 'Cambiar', // 更换
  'modules.global.cropper': 'Cortar', // 裁剪
  'modules.global.cropper.image': 'Cortar Imagen', // 裁剪照片
  'modules.global.replace': 'Cambiar', // 替换
  'modules.global.done': 'completar', // 完成
  'modules.global.delete': 'Eliminar', // 删除

  'modules.global.ai.crop.complete': '¡Sí, Completo!', // 切图完成
  'modules.global.ai.croping.photo': 'AI Recortando su foto', // ai切图中

  'components.cropper.title': 'Cortar Imagen', // 裁剪照片
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': 'Complete el contenido personalizado de acuerdo con las siguientes condiciones', // 请根据以下条件完成定制内容
  'components.oop.submit': 'Lo cambiaré', // 我会改变的

  'components.footer.zoom.in': 'Acercar', // 放大
  'components.footer.zoom.out': 'Alejar', // 缩小
  'components.footer.rotate.left': 'Girar a izquierda', // 向左旋转
  'components.footer.rotate.right': 'Gira a derecha', // 向右旋转

   // 20200602 add
   'components.edit.title': 'EDITAR MODELO', //  编辑模式
   'components.edit.type': 'TIPO', //  类别
   'components.edit.apply': 'APLICAR', //  应用
   'components.guide.title': 'Instrucciones', //  操作说明
   'components.edit.font.text': 'Texto', //  文字内容
   'components.edit.font.family': 'Fuente', //  字体
   'components.edit.font.size': 'Tamaño de fuente', //  字号
   'components.edit.font.color': 'Color de fuente', //  字体颜色
   'components.edit.font.shadow.color': 'Color de sombra de fuente', //  字体阴影颜色

   'components.cropper.back': 'Atrás', // 返回
   'components.cropper.crop': 'Cosecha', // 裁剪
   'components.cropper.move': 'Moverse', // 移动

   'modules.mug.font.validator': 'Por favor edite "nombre 1" ', //  没有被更改，请编辑它
   'modules.bottom.background': 'Opciones de Paisaje', //  风景选项
   'modules.bottom.layout': 'disposición', //  图层
   'modules.bottom.help': 'ayuda', //  帮助
   'modules.bottom.next': 'siguiente', //  下一步
   'modules.bottom.confirm': 'Confirmar', //  确认
   'modules.bottom.layout.tip': 'Al hacer clic en la opción actual, se colocará temporalmente y se podrá editar.', //  点击当前选项将暂时被置顶并为可编辑状态
   'modules.bottom.layout.close': 'CERRAR', //  关闭
   'modules.bottom.operator.top': 'Cima', //  置顶
   'modules.bottom.operator.bottom': 'Fondo', //  置底
   'modules.bottom.operator.up': 'Arriba', //  上一层
   'modules.bottom.operator.down': 'Abajo', //  下一层
   'modules.error.load.font': 'Error al cargar la fuente. vuelve a intentarlo.', //  字体加载错误，请重新尝试
   'modules.error.upload.result': 'Error de carga. Por favor vuelva a intentarlo.', // 上传失败 请重试

   // 多图模板
  'modules.template.tips': 'Haga clic en la foto para cambiarla o recortarla', // 点击图片区域可以更换图片或裁剪
  'modules.template.validator.images': 'Debe subir fotos de $value.', // 需要传多少张图
  'modules.template.validator.texts': 'Debe entrar textos de $value.', // 需要输入多少段文字
  'modules.template.bottom.template': 'Modelo', // 模版
  'modules.template.default.texts': 'Texto', // 文字

  // 影刻
  'modules.sketch.cropper.tips': 'Ajusta la cara y el cuerpo de las personas en el medio', // 请保证照片中人像边缘尽量清晰可见
  'modules.sketch.title': 'VISTA PREVIA 3D', // 3d预览
  'modules.sketch.request.stage0': 'Cargando…', // 正在上传照片…
  'modules.sketch.request.stage1': 'Eliminar fondo…', // 正在将人像从背景抠出…
  'modules.sketch.request.stage2': 'Agregar filtro…', // 正在加入滤镜效果…
  'modules.sketch.request.stage3': 'Procesando a imagen en blanco y negro…', // 正在去色处理…
  'modules.sketch.request.stage4': 'Espera, preparando la vista previa en 3D…', // 请稍后, 预览结果即将呈现…
  'modules.sketch.request.accomplish': 'Completado, haga clic para obtener una vista previa', // 影刻处理完成, 请点击预览查看
  'modules.sketch.request.error': 'Falló, puedes agregarlo al carrito.', // 预览处理失败，您可以跳过直接加车
  'modules.sketch.try.again': 'Vista previa de nuevo', // 重新预览
  'modules.sketch.add.text.placeholder': 'Escriba palabras aquí ... (Lo haremos exactamente igual a como se muestra en la vista previa anterior).', // 刻字提示文案
  'modules.sketch.footer.preview': 'Vista previa', // 预览并加车
  'modules.sketch.footer.skip': 'Ignorar vista previa', // 跳过预览
  'modules.sketch.accomplish.later': 'Compruebe Tarde', // 稍后再说
  'modules.sketch.accomplish.prompt': 'La vista previa en 3D está lista, ¡compruebe ahora!', // 稍后再说

  // 撕拉盒子
  'modules.box.step': 'PASO', // 步数
  'modules.box.side': 'Lado', // 面
  'modules.box.image.upload': 'Subir', // 上传
  'modules.box.image.change': 'Cambiar', // 切换
  'modules.box.step1.title': 'AÑADIR IMAGEN', // 添加图片
  'modules.box.step1.tips': 'Puede personalizarlo con su foto cargada, o usar la imagen actual.', // 添加图片说明文案
  'modules.box.step2.title': 'AGREGAR CONFETTI', // 添加纸屑
  'modules.box.step2.tips': 'Crea tu caja bomba explosiva personalizada！', // 添加纸屑说明文案
  'modules.box.oop.title': '¡CONSEJOS', // 添加纸屑说明文案
  'modules.box.oop.description': 'Lo produciremos en el orden de las imágenes a continuación.', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': 'Editar imagen', // 编辑照片
  'modules.photo.film.tips.sort': 'Mantenga presionada y arrastre la imagen para ajustar su posición.', // 长按拖动图片来排序
  'modules.photo.film.tips.count': 'Necesitas subir $value fotos.', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'Necesita subir $value fotos al menos.', // 最少需要传多少张图
  'modules.photo.film.change': 'Cambiar', // 更换
  'modules.photo.film.cropper': 'Cortar', // 裁剪
  'modules.photo.film.sort': 'Ordenar', // 排序
  'modules.photo.film.delete': 'Eliminar', // 删除

  // 简版照片书
  'modules.photo.book.deleted.page': 'Página eliminada', // 已删除页面
  'modules.photo.book.delete.page': 'eliminar página', // 删除页面
  'modules.photo.book.change.layout': 'cambia el diseño', // 更改布局
  'modules.photo.book.add.photo.page': 'Agregar página de fotos', // 添加照片页面
  'modules.photo.book.add.compose.page': 'Agregar página de collage', // 添加拼贴页面
  'modules.photo.book.validator': 'Agregue $ value más páginas para comprar', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': 'Reemplazar foto', // 替换照片
  'modules.photo.book.remove.image': 'Quitar', // 移除
  'modules.photo.book.page': 'Página', // 页
  'modules.photo.book.type': 'material de libro', // 图书材质
  'modules.photo.book.size': 'tamaño del libro', // 图书尺寸
  'modules.photo.book.order.qty': 'quantité', // 数量
  'modules.photo.book.order.total': 'total', // 总计
  'modules.photo.book.warning.title': 'Advertencia', // 警告
  'modules.photo.book.warning.cover.title': 'Agregar título para la portada del libro de fotos', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': 'Haga clic en la portada del libro de fotos para agregar un título', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': 'Se encontraron imágenes de baja resolución', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': 'Puede aparecer borroso cuando se imprime.', // 打印出来可能比较模糊
  'modules.photo.book.add.title': 'Añadir título', // 添加标题
  'modules.photo.book.zuploader.subtitle': 'Necesito al menos $value fotos', // 至少需要 value 张照片
  'modules.photo.book.guide.title': `Guía de compra`, // 操作引导
  'modules.photo.book.guide.position': `Mantenga presionado y arrástrelo para ajustar el orden`, // 长按图片可调整顺序
  'modules.photo.book.guide.layout': `Haga clic en el icono del lateral para cambiar el diseño`, // 点击侧边更多可切换图片样式
  'modules.photo.book.guide.button': 'OK gracias', // 好的 我知道了

  'modules.photo.wall.choose.frame': 'elegir un marco', // 选择边框
  'modules.photo.wall.choose.style': 'elegir un estilo', // 选择样式
  'modules.photo.wall.zoom.pinch': 'mover y agrandar', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'desplazarse para agrandar', // 滚动放大
  'modules.photo.wall.choose.design': 'elegir un diseño', // 选择设计
  'modules.photo.wall.choose.design.tips': 'elegir paredes de la galería para personalizar', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'marcos', // 框
  'modules.photo.wall.image.list.title': 'Agregar fotos para ver más diseños', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': 'ADICIONAR AO CARRINHO', // 我的图片
  'modules.photo.wall.zuploader.subtitle': 'Puede subir fotos de hasta $value',  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': 'Apoyamos fotos de hasta $value, el exceso se ignorará',  // 我们最多支持 $value 张照片，超出的将被忽略

  // zuploader
  'plugin.zuploader.from.album': 'Eligir entre su álbum', // 从相册中选择
  'plugin.zuploader.from.social': 'Eligir entre los servicios en línea', // 从在线服务中选择
  'plugin.zuploader.from.camera': 'Tome una foto', // 拍照
  'plugin.zuploader.wait.upload': 'Esperando carga', // 等待上传
  'plugin.zuploader.choose.source': 'Eligir fotos', // 选择照片来源
  'plugin.zuploader.choose.image.title': 'Fotos subidas', // 已上传照片
  'plugin.zuploader.choose.image.choose': 'Seleccionar fotos', // 选择照片
  'plugin.zuploader.choose.image.range': 'Seleccionar artículos de $value', // 选择 $value 照片

  // lowQuality
  'plugin.low.quality.title': 'Baja calidad de imagen', // 低图像质量
  'plugin.low.quality.tips': 'Esta foto es bastante pequeña. ¡Probablemente hará un mosaico borroso!', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'Mantener de todos modos', // 不管怎样都保留
  'plugin.low.quality.remove': 'Eliminar del pedido', // 从订单中删除
  // 无限魔方
  'modules.infinite.cube.zuploader.title': 'Necesita fotos de $value', // 需要 $value 张照片
  'modules.infinite.cube.input.tip': 'Arrastre o haga clic para ajustar la imagen', // 拖动或点击来调整图片
  'modules.infinite.cube.preview3d.tip': 'Arrastre el cubo para ver', // 拖动观察魔方
  'modules.infinite.cube.preview3d.tap': 'Toqueme', // 点击我(按钮)
  'modules.infinite.cube.preview.choose': 'Elija su foto de portada', // 选择你的封面
  'modules.infinite.cube.save': 'Salvar', // 保存
  'modules.infinite.cube.share': 'compartir y $elm $value de descuento $end', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.and': 'compartir y', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.off': 'de descuento', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.or': 'o', // 或者
  'modules.infinite.cube.without.share': 'sin compartir y agregar al carrito', // 不分享直接加车

  // 增值产品-relatedProduct
  'increment.related.product.title': 'Elija un paquete súper sorpresa para su regalo', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'No, Gracias', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': 'La mejor opción para procesar pedidos con prioridad de producción y envío', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'SERVICIO VIP', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': '¡Error de Red!', // 网络错误
  'modules.new.sock.ai.crop.error': '¡Error de Cultivo de IA!', // 切图服务错误
  'modules.new.sock.replace.photo': 'Reemplazar Foto', // 替换照片
  'modules.new.sock.crop.myself': 'Cultivar Solo', // 自己切图
  'modules.new.sock.try.again': 'Inténtalo de Nuevo', // 重试
  'modules.new.sock.edit.avatar': 'Editar Avatar', // 编辑头像
  'modules.new.sock.add.line.text.title': 'Añadir Texto?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': '¡El 80% de los clientes prefieren agregar texto personalizado para hacer regalos perfectos!', // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': 'AÑADIR TEXTO', // 添加文字
  'modules.new.sock.without.text': 'SIN TEXTO', // 不刻字
  'modules.new.sock.color': 'COLOR', // 颜色
  'modules.new.sock.size': 'TALLA', // 尺寸
  'modules.new.sock.sock': 'CALCETÍN', // 袜子
  'modules.new.sock.share.title': 'Compartir para Obtener', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'Imagen de Perfil Social Personalizada.', // 你的社交新头像
  'modules.new.sock.free.download': 'Descarga Gratis', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'Fondo de Pantalla Móvil Personalizado.', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'Fondo de Pantalla PC Personalizado.', // 你的电脑新壁纸
  'modules.new.sock.discount': 'Descuento', // 折扣
  'modules.new.sock.discount.price': '$value APAGADO', // 立减 $value 块
  'modules.new.sock.share.now': 'COMPARTE AHORA', // 立刻分享
  'modules.new.sock.no.thanks': 'NO, GRACIAS', // 不分享
  'modules.new.sock.shared.title': '¡FELICIDADES!', // 恭喜
  'modules.new.sock.download': 'Descargar', // 下载
  'modules.new.sock.generating.image': 'Generando imagen compartida', // 正在生成分享图
  'modules.new.sock.total': 'total', // 总计

  // 2020 11 17 add
  // 台历
  'modules.desktop.calendar.cover': 'Cubrir', // 封面
  'modules.desktop.calendar.change.layout': 'Cambiar el Diseño de la Foto', // 更改照片布局
  'modules.desktop.calendar.validator': 'faltan fotos de $value', // 缺 $value 张照片
  'modules.desktop.calendar.replace.image': 'Reemplazar Foto', // 替换照片
  'modules.desktop.calendar.remove.image': 'Eliminar', // 移除

  // 挂历
  'modules.wall.calendar.tap.day': 'Tocar Fecha y Agregar Evento de Calendario', // 点击日期和添加日历事件
  'modules.wall.calendar.cover': 'Cubrir', // 封面
  'modules.wall.calendar.change.layout': 'Cambiar el Diseño de la Foto', // 更改照片布局
  'modules.wall.calendar.validator': 'Suba $value más fotos para', // 缺 $value 张照片
  'modules.wall.calendar.replace.image': 'Reemplazar Foto', // 替换照片
  'modules.wall.calendar.remove.image': 'Eliminar', // 移除
  'modules.wall.calendar.and': 'Y', // 和
  'modules.wall.calendar.event.add': 'Agregar su evento', // 添加你的事件
  'modules.wall.calendar.event.choose': 'Elija el tipo de evento', // 选择你的事件类型
  'modules.wall.calendar.event.edit': 'Editar evento', // 编辑事件
  'modules.wall.calendar.event.clear': 'Evento claro', // 清除事件
  'modules.wall.calendar.event.create': 'Crear nuevo evento', // 创建新事件
  'modules.wall.calendar.event.graduation': 'graduación', // 毕业季
  'modules.wall.calendar.event.holidays': 'vacaciones', // 度假
  'modules.wall.calendar.event.wedding': 'Boda', // 婚礼
  'modules.wall.calendar.event.bar': 'bar', // 酒吧
  'modules.wall.calendar.event.party': 'partido', // 派对
  'modules.wall.calendar.event.birthday': 'cumpleaños', // 生日
  'modules.wall.calendar.event.anniversarie': 'aniversario', // 周年纪念日
  'modules.wall.calendar.event.other': 'otro', // 其他
  'modules.wall.calendar.event.tips': 'Tocar y editar', // 点击编辑
  'modules.wall.calendar.cover.title.show': 'Mostrar Título', // 显示标题
  'modules.wall.calendar.cover.title.hide': 'Ocultar Título', // 隐藏标题

  //  other
  'modules.global.revoke': 'Revocar', // 撤销
  'modules.global.warning.title': 'advertencia', // 警告
  'modules.global.zoom.pinch': 'pellizcar y hacer zoom', // 移动和放大
  'modules.global.edit.confirm': 'No ha terminado de editar. ¿Está seguro de dejarlo?', // 您还没有编辑完，确定要退出吗
  'modules.global.delete.tips': '¿Está seguro de eliminar esto?', // 删除提示

  // Spotify Code
  'modules.spotify.code.search.hot': 'Búsquedas populares', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Resultados de búsqueda', // 搜索结果
  'modules.spotify.code.placeholder': 'Buscar Canción、 Artista、Álbum', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Sube tu foto', // 上传你的图片
  'modules.spotify.code.album.track': 'Usar la portada del álbum', // 使用相册封面
  'modules.spotify.code.change.color': 'Elige tu color', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'Puedes personalizar el artista tú mismo', // 你可以自己定制演奏者
  'modules.spotify.code.search.no.result': 'Vaya, No Hay Resultado.', // 没结果
  'modules.spotify.code.add.text.ask': '¿Necesitaría Agregar Texto?', // 你是否需要添加刻字
  'modules.spotify.code.add.text': 'Agregar el Texto', // 添加刻字
  'modules.spotify.code.skip.add.cart': 'No, Gracias. Añadir al Carrito', // 不谢谢 加车
  'modules.spotify.code.add.text.title': 'Agregar su texto aquí', // 在这添加你的刻字
  'modules.spotify.code.add.text.or': 'o', // 或
  'modules.spotify.code.buy.it': 'Claro, cómprelo ahora', // 是的 买它
  'modules.spotify.code.template.title': 'Elija Su Estilo Favorito',
  "modules.spotify.code.multi.upload": "Sube tus fotos", // 上传你的照片
}
