import React, { useRef, useEffect } from 'react';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import { Wrapper } from '@/components';
import animationJson from '@/assets/spotify-default-loading.json';
import styles from './style.less';

export interface DefaultTrackLoadingProps {

}

const DefaultTrackLoading: React.FC<DefaultTrackLoadingProps> = ({ }) => {
  /** 动画挂载 dom */
  const _animation = useRef<any>(null);
  /** 动画实例 */
  const lottieAnimation = useRef<AnimationItem>();

  useEffect(() => {
    lottie.setLocationHref(location.href);

    if (_animation.current) {
      lottieAnimation.current = lottie.loadAnimation({
        container: _animation.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationJson,
      });
    }

    return () => {
      lottieAnimation.current?.destroy();
    }
  }, []);

  return (
    <Wrapper className={styles.defaultTrackWrapper}>
      <div className={styles.loadingAnimation} ref={_animation} />
    </Wrapper>
  );
};

export default DefaultTrackLoading;
