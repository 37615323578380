// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C {\n  position: relative;\n  width: 30px;\n  height: 30px;\n  transform-style: preserve-3d;\n  animation: sunzi__rotate3d-1VZ0m 0.8s infinite;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C .sunzi__border-3dJSm {\n  position: absolute;\n  width: 7px;\n  height: 7px;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C .sunzi__left-3JICx {\n  left: 0;\n  border-left: 1px solid #fff;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C .sunzi__top-3iR4J {\n  top: 0;\n  border-top: 1px solid #fff;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C .sunzi__right-3f0Gu {\n  right: 0;\n  border-right: 1px solid #fff;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C .sunzi__bottom-28wJ_ {\n  bottom: 0;\n  border-bottom: 1px solid #fff;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div {\n  position: absolute;\n  width: 30px;\n  height: 30px;\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(1) {\n  transform: translateZ(15px);\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(2) {\n  transform: rotateX(180deg) translateZ(15px);\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(3) {\n  transform: rotateY(-90deg) translateZ(15px);\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(4) {\n  transform: rotateY(90deg) translateZ(15px);\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(5) {\n  transform: rotateX(90deg) translateZ(15px);\n}\n.sunzi__threed-loading-2eCz3 .sunzi__cube-qQA3C div:nth-child(6) {\n  transform: rotateX(-90deg) translateZ(15px);\n}\n@keyframes sunzi__rotate3d-1VZ0m {\n  from {\n    transform: rotateX(-35deg) rotateY(45deg);\n  }\n  to {\n    transform: rotateX(-35deg) rotateY(135deg);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"threed-loading": "sunzi__threed-loading-2eCz3",
	"threedLoading": "sunzi__threed-loading-2eCz3",
	"cube": "sunzi__cube-qQA3C",
	"rotate3d": "sunzi__rotate3d-1VZ0m",
	"rotate3D": "sunzi__rotate3d-1VZ0m",
	"border": "sunzi__border-3dJSm",
	"left": "sunzi__left-3JICx",
	"top": "sunzi__top-3iR4J",
	"right": "sunzi__right-3f0Gu",
	"bottom": "sunzi__bottom-28wJ_"
};
module.exports = exports;
