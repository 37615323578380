import React, { useState, useRef, useEffect, Fragment } from "react";
import classnames from "classnames";
import { fabric } from "sunzi-fabric";
import dayjs from "dayjs";
import { useLocal } from "@/hooks";
import {
  RelatedProductIncrement,
  VipIncrement,
} from "@/increment/increment";
import { Vip } from "@/increment";
import { Layout, Wrapper, Loading, Drawer } from "@/components";
import {
  i18n,
  createObjectURL,
  uuid,
  uploadKey,
  upload7nObjectURL,
  getToken7n,
  canvasToBlobURL,
  priceFormat,
  removeEmojis,
  thumbnail7n,
} from "@/utils";
import {
  SpotifyColor,
  SpotifyTrack,
  SpotifyTrackLineText,
} from "../spotify-code";
import { SpotifyCodeProps } from '..';
import spotifySearch, { getSpotifyTrack } from "../request";
import TrackCanvas, { getFitContentLyric } from "./track-canvas";
import { SpotifyLyricLayout } from './lyric';
import Icon from "../icon";
import TrackItem from "../track-item";
import Croppie from "../croppie";
import Preview from "./preview";
import DefaultTrackLoading from '../default-track-loading';
import LyricContent from './lyric-content';
import styles from "../style.less";

export interface LyricProps extends Omit<SpotifyCodeProps, 'layout' | 'bindProduct'> {
  layout: SpotifyLyricLayout;
  minLyricLines?: number;
  maxLyricLines?: number;
}

const Lyric: React.FC<LyricProps> = ({
  shop,
  theme = {
    r: 29,
    g: 184,
    b: 84,
  },
  gtag,
  price,
  colors,
  defaultTrackId,
  defaultColor,
  layout,
  bottomImage,
  minLyricLines = 15,
  maxLyricLines = 22,
  recommendTracks = [],
  defaultCustomAlbum,
  customArtistsTitle,
  increment,
  onClose,
  onConfirm,
}) => {
  // 设置国际化
  useLocal(shop.language);
  /** 共享canvas，避免重复创建canvas */
  const _canvas = useRef<fabric.Canvas[]>([]);
  /** 输入框实例 */
  const _input = useRef<HTMLInputElement>(null);
  /** 搜索列表实例 */
  const _list = useRef<HTMLDivElement>(null);
  /** 搜索结果游标 */
  const _pagination = useRef<number>(0);
  /** 搜索结果总数 */
  const _total = useRef<number>(0);
  /** 搜索结果key*/
  const _listKey = useRef<string>();
  /** 搜索内容 */
  const [searchValue, setSearchValue] = useState<string>("");
  /** 搜索加载状态 */
  const [searchLoading, setSearchLoading] = useState<boolean>();
  /** 搜索加载更多状态 */
  const [searchLoadmore, setSearchLoadmore] = useState<boolean>();
  /** 搜索加载状态 */
  const [tracks, setTracks] = useState<SpotifyTrack[]>();
  /** 滚动渐变 */
  const [scrollGradient, setScrollGradient] = useState<boolean[]>([]);
  /** 当前颜色值 */
  const [currentColor, setCurrentColor] = useState<SpotifyColor>(
    defaultColor ?? colors[0]
  );
  /** 当前激活的歌曲 */
  const [currentTrack, setCurrentTrack] = useState<SpotifyTrack>();
  /** 封面是否自定义 */
  const [customAlbum, setCustomAlbum] = useState<boolean>(!!layout.albumCoord);
  /** 自定义演奏者 */
  const [artistsValue, setArtistsValue] = useState<string>("");
  /** 封面图 */
  const [customAlbumImage, setCustomAlbumImage] = useState<string>();
  /** 封面裁剪原图 */
  const [croppieImage, setCroppieImage] = useState<string>();
  /** 预览显隐 */
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  /** 上传显隐 */
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  /** 默认歌曲显隐 */
  const [defaultTrackVisible, setDefaultTrackVisible] = useState<boolean>(false);
  /** 歌词显隐 */
  const [lyricVisible, setLyricVisible] = useState<boolean>(false);
  /** 歌词的值 */
  const [lyrics, setLyrics] = useState<string[]>([]);
  /** VIP显隐 */
  const [vipIncrementVisible, setVipIncrementVisible] = useState<boolean>(
    false
  );
  /** 关联产品显示隐藏 */
  const [relatedProductVisible, setRelatedProductVisible] = useState<boolean>(
    false
  );
  /** 歌曲加载状态 */
  const [trackLoading, setTrackLoading] = useState<boolean>(false);
  /** 绑定产品加载状态 */
  const [bindTrackLoading, setBindTrackLoading] = useState<boolean>(false);
  /** 刻字询问显隐 */
  const [lineTextDrawerVisible, setLineTextDrawerVisible] = useState<boolean>(
    false
  );
  /** 刻字显隐 */
  const [lineTextVisible, setLineTextVisible] = useState<boolean>(false);
  /** 刻字值 */
  const [lineTextValue, setLineTextValue] = useState<string>();
  /** 布局中刻字值 */
  const [layoutLineText, setLayoutLineText] = useState<SpotifyTrackLineText>();
  // 判断封面和演奏者
  const trackVisible = layout.albumCoord || layout.artistCoord;
  // 关联产品
  const relatedProduct = (increment?.relatedProduct || [])[0];

  useEffect(() => {
    setCustomAlbum(!!layout.albumCoord);
  }, [layout.albumCoord]);

  useEffect(() => {
    _canvas.current = [
      new fabric.Canvas(document.createElement("canvas")),
      new fabric.Canvas(document.createElement("canvas")),
      new fabric.Canvas(document.createElement("canvas")),
      new fabric.Canvas(document.createElement("canvas")),
    ];
  }, []);

  useEffect(() => {
    if (defaultTrackId) {
      setDefaultTrackVisible(true);
      getSpotifyTrack(defaultTrackId).then(({ status, data }) => {
        setDefaultTrackVisible(false);
        if (status)
          setCurrentTrack(data);
      });
    }
  }, [ defaultTrackId ]);

  useEffect(() => {
    if (layout.lineTextCoord)
      setLayoutLineText({
        font: layout.lineTextCoord.fonts[0],
      });
  }, [layout.lineTextCoord]);

  useEffect(() => {
    setCurrentColor(defaultColor ?? colors[0]);
    gtag("event", "tap_change_color");
  }, [colors, defaultColor]);

  useEffect(() => {
    gtag("event", "tap_custom_album", {
      event_label: customAlbum,
    });
  }, [customAlbum]);

  useEffect(() => {
    if (lineTextVisible) setLineTextValue(layoutLineText?.value ?? "");
    else setLineTextValue(undefined);
  }, [lineTextVisible]);

  useEffect(() => {
    if (currentTrack) {
      setArtistsValue(currentTrack.artists);
      // 如果layout没有封面和演奏者
      // TODO
      setLyricVisible(!trackVisible);
      gtag("event", "tap_spotify_track", {
        event_label: currentTrack.name,
      });
    }
  }, [currentTrack]);

  /** 监听搜索按键 */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) handleSearch();
  };

  /** 搜索监听 */
  const handleSearch = async () => {
    setSearchLoading(true);
    _input.current?.blur();
    // 每次搜索生成一个新的key，强制dom更新
    _listKey.current = uuid();
    await _spotifySearch();
    gtag("event", "tap_search_track", {
      event_label: searchValue,
    });
    setSearchLoading(false);
  };

  /** spotify api 搜索 */
  const _spotifySearch = async (pagination: number = 0) =>
    spotifySearch(searchValue, pagination).then((response) => {
      if (response.status && response.data) {
        setTracks(
          pagination === 0
            ? response.data.tracks
            : (tracks || []).concat(response.data.tracks)
        );
        _total.current = response.data.total;
        _pagination.current = pagination;
        if (_list.current) {
          const { scrollHeight, offsetHeight } = _list.current;
          setScrollGradient([false, scrollHeight > offsetHeight]);
        }
      } else {
        gtag("event", "tap_search_error");
      }
    });

  /** 搜索列表滚动监听 */
  const handleTrackListScroll = async () => {
    if (_list.current) {
      const { scrollTop, scrollHeight, offsetHeight } = _list.current;
      setScrollGradient([
        scrollTop > 100,
        scrollHeight - scrollTop - offsetHeight > 100,
      ]);
      if (
        scrollHeight - scrollTop - offsetHeight <= 80 &&
        !searchLoadmore &&
        (tracks?.length ?? 0) < _total.current
      ) {
        setSearchLoadmore(true);
        await _spotifySearch(_pagination.current + 1);
        setSearchLoadmore(false);
      }
    }
  };

  /** content渲染 */
  const renderContent = () => {
    if (tracks === undefined)
      return (
        <div className={classnames(styles.trackListWrapper, styles.recommend)}>
          <div className={styles.recommendTitle}>
            🔥{i18n.format("modules.spotify.code.search.hot")}
          </div>
          <div className={styles.trackList}>
            {recommendTracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() =>
                  setCurrentTrack({ ...item, name: narrowTrackName(item.name) })
                }
              />
            ))}
          </div>
        </div>
      );
    else if (tracks.length > 0)
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format("modules.spotify.code.search.reuslt")}
          </div>
          <div
            ref={_list}
            key={_listKey.current}
            className={styles.trackList}
            onScrollCapture={handleTrackListScroll}
          >
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() =>
                  setCurrentTrack({ ...item, name: narrowTrackName(item.name) })
                }
              />
            ))}
            {tracks.length < _total.current && (
              <div className={styles.loadmore}>
                <Loading size={20} />
              </div>
            )}
          </div>
          <Wrapper className={styles.trackListGradient}>
            <div
              className={classnames({
                [styles.topGradient]: scrollGradient[0],
              })}
            />
            <div
              className={classnames({
                [styles.bottomGradient]: scrollGradient[1],
              })}
            />
          </Wrapper>
        </div>
      );
    else
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format("modules.spotify.code.search.reuslt")}
          </div>
          <div className={classnames(styles.trackList, styles.trackListEmpty)}>
            <img src={require("@/assets/search-no-result.png")} />
            <span>{i18n.format("modules.spotify.code.search.no.result")}</span>
          </div>
        </div>
      );
  };

  /** 上传自定义封面监听 */
  const handleCustomAlbumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files)
      setCroppieImage(createObjectURL(event.target.files[0]));
  };

  /** 裁剪确认 */
  const handleCroppieConfirm = (result: string) => {
    setCustomAlbumImage(result);
    setCroppieImage(undefined);
    setLyricVisible(true);
  };

  /** 歌词确认 */
  const handleLyricConfirm = (lyrics: string[]) => {
    setPreviewVisible(true);
    setLyrics(lyrics);
  }

  /** 刻字文字监听 */
  const handleLineTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeEmojis(event.target.value);
    if (layout.lineTextCoord && value.length <= layout.lineTextCoord.maxLength)
      setLineTextValue(value);
  };

  /** 确认刻字内容 */
  const handleLineTextValueConfirm = () => {
    setLayoutLineText({
      ...layoutLineText,
      value: lineTextValue,
    });
  };

  /** 预览关闭 */
  const handlePreviewClose = () => {
    setPreviewVisible(false);
    if (!trackVisible)
      // 如果layout没有封面和演奏者
      setCurrentTrack(undefined);
    setCustomAlbumImage(undefined);
  };

  /** 刻字关闭 */
  const handleLineTextClose = () => {
    setLineTextDrawerVisible(false);
    setLineTextVisible(false);
  };

  /** 生成并上传专辑图 */
  const _uploadTrackAlbum = async (token: string, key: string) => {
    if (!customAlbumImage) return currentTrack?.album ?? "";
    // 上传到七牛
    const result = await upload7nObjectURL({
      token,
      key,
      blobURL: customAlbumImage,
    });
    return host7n["prefix-na0"] + result.key;
  };

  /** 生成并上传生产图 */
  const _uploadTrack = async (token: string, key: string) => {
    if (!currentTrack) return "";
    let canvas = _canvas.current[0];
    if (lineTextVisible) canvas = _canvas.current[1];
    // 生成生产图
    const blobURL = await canvasToBlobURL(canvas.getElement());
    // 上传到七牛
    const result = await upload7nObjectURL({ blobURL, token, key });
    return host7n["prefix-na0"] + result.key;
  };

  /** 加车校验 */
  const handleValidatorConfirm = (type: number) => {
    if (type === 2)
      if (layout.lineTextCoord)
        // 如果存在刻字
        setLineTextDrawerVisible(true);
      else handleValidatorConfirm(type - 1);
    else if (type === 1)
      if (increment?.relatedProduct)
        // 如果存在增值服务
        setRelatedProductVisible(true);
      else if (increment?.vip) setVipIncrementVisible(true);
      else handleValidatorConfirm(type - 1);
    else handleConfirm();
  };

  /** 确认回调监听 */
  const handleConfirm = async (
    increment?: VipIncrement | RelatedProductIncrement
  ) => {
    if (!currentTrack) return;
    try {
      setVipIncrementVisible(false);
      setRelatedProductVisible(false);
      setLineTextDrawerVisible(false);
      setUploadVisible(true);
      const token = await getToken7n();
      const key = uploadKey("soptify-code");
      // 构建上传队列
      const [effect, source] = await Promise.all<string>([
        _uploadTrack(token, `${key}.png`),
        _uploadTrackAlbum(token, `${key}-source.png`),
      ]);

      // 如果存在额外的直线刻字
      if (layout.lineTextCoord && layoutLineText?.value)
        layout.lineTextCoord = {
          ...layout.lineTextCoord,
          ...layoutLineText,
          font: layout.lineTextCoord.fonts[0],
        };

      const _lyrics = await getFitContentLyric(lyrics, layout.lyricCoord);

      layout.lyricCoord = {
        ...layout.lyricCoord,
        value: _lyrics
      }

      onConfirm(
        effect,
        {
          source,
          color: currentColor,
          track: {
            ...currentTrack,
            artists: artistsValue || currentTrack.artists,
          },
          layout
        },
        increment
      );
      handleClose();
    } catch {
      alert(i18n.format("modules.error.upload.result"));
      setUploadVisible(false);
    }
  };

  /** 关闭回调监听 */
  const handleClose = () => {
    onClose();
    setCurrentTrack(undefined);
    setUploadVisible(false);
    setPreviewVisible(false);
    setCustomAlbumImage(undefined);
    setCustomAlbum(!!layout.albumCoord);
    setLineTextVisible(false);
    setLineTextDrawerVisible(false);
    setLineTextValue(undefined);
    setLayoutLineText(undefined);
  };

  const { nameCoord, artistCoord, trackCoord, lineTextCoord, lyricCoord } = layout;
  const innerFonts = [];

  innerFonts.push(`
    @font-face {
      font-weight: normal;
      font-style: normal;
      font-family: ${lyricCoord.font.name};
      src: url('${lyricCoord.font.url}');
    }`);

  if (nameCoord)
    innerFonts.push(`
      @font-face {
        font-weight: normal;
        font-style: normal;
        font-family: ${nameCoord.font.name};
        src: url('${nameCoord.font.url}');
      }`);
  if (artistCoord)
    innerFonts.push(`
      @font-face {
        font-weight: normal;
        font-style: normal;
        font-family: ${artistCoord.font.name};
        src: url('${artistCoord.font.url}');
      }`);
  if (trackCoord)
    innerFonts.push(`
      @font-face {
        font-weight: normal;
        font-style: normal;
        font-family: ${trackCoord.time.font.name};
        src: url('${trackCoord.time.font.url}');
      }`);
  if (lineTextCoord)
    innerFonts.push(
      lineTextCoord.fonts.map(
        (item) => `
      @font-face {
        font-weight: normal;
        font-style: normal;
        font-family: ${item.name};
        src: url('${item.url}');
      }`
      )
    );

  return (
    <Layout theme={theme} plugins={[]}>
      <style>{innerFonts.join("\n")}</style>
      <div className="sunzi-spotify-code">
        <div
          className={classnames(styles.inputWrapper, {
            [styles.inputFocusWrapper]: searchValue,
          })}
        >
          <input
            ref={_input}
            value={searchValue}
            placeholder={i18n.format("modules.spotify.code.placeholder")}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          <div className={styles.searchWrapper}>
            <Icon type="search" />
          </div>
          <div className={styles.confirm} onClick={handleSearch}>
            {searchLoading ? (
              <Loading size={24} />
            ) : (
              <div className={styles.iconWrapper}>
                <Icon type="search" />
              </div>
            )}
          </div>
        </div>
        {renderContent()}
        <div
          className={classnames(styles.closeWrapper, {
            [styles.closeWrapperTrack]: !!tracks?.length,
          })}
          onClick={handleClose}
        >
          <Icon type="arrow-left" />
        </div>
        {defaultTrackVisible &&
          <DefaultTrackLoading />
        }
        {currentTrack && (
          <Wrapper className={styles.trackWrapper}>
            <div
              className={classnames(styles.trackContent, {
                [styles.trackContentHide]: !layout.albumCoord,
              })}
            >
              <div
                className={styles.trackCanvasWrapper}
                onClick={() => setCustomAlbum(true)}
              >
                <TrackCanvas
                  canvas={_canvas.current[2]}
                  track={currentTrack}
                  layout={layout}
                  color={currentColor}
                  album={defaultCustomAlbum}
                  bottomImage={bottomImage}
                />
                <div className={styles.title}>
                  {i18n.format("modules.spotify.code.album.upload")}
                </div>
                <div className={styles.radioWrapper}>
                  <div
                    className={classnames(styles.radio, {
                      [styles.radioActive]: customAlbum,
                    })}
                  >
                    <Icon type="confirm" />
                  </div>
                </div>
              </div>
              <div
                className={styles.trackCanvasWrapper}
                onClick={() => setCustomAlbum(false)}
              >
                <TrackCanvas
                  canvas={_canvas.current[3]}
                  track={currentTrack}
                  layout={layout}
                  color={currentColor}
                  bottomImage={bottomImage}
                />
                <div className={styles.title}>
                  {i18n.format("modules.spotify.code.album.track")}
                </div>
                <div className={styles.radioWrapper}>
                  <div
                    className={classnames(styles.radio, {
                      [styles.radioActive]: !customAlbum,
                    })}
                  >
                    <Icon type="confirm" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classnames(styles.trackArtist, {
                [styles.trackArtistHide]: !layout.artistCoord,
              })}
            >
              <div className={styles.title}>
                {customArtistsTitle ??
                  i18n.format("modules.spotify.code.custom.artists")}
              </div>
              <div className={styles.inputWrapper}>
                <input
                  value={artistsValue}
                  onChange={(e) =>
                    setArtistsValue(removeEmojis(e.target.value))
                  }
                />
              </div>
            </div>
            <div className={styles.trackFooter}>
              <div
                className={styles.previous}
                onClick={() => setCurrentTrack(undefined)}
              >
                <Icon type="arrow-left" />
              </div>
              {customAlbum ? (
                <div
                  className={classnames(styles.next, {
                    [styles.nextDisabled]: !artistsValue,
                  })}
                >
                  <span>
                    {i18n.format("modules.spotify.code.album.upload")}
                  </span>
                  <Icon type="arrow-right" />
                  <input
                    type="file"
                    onChange={handleCustomAlbumChange}
                    accept="image/*"
                  />
                </div>
              ) : (
                <div
                  className={classnames(styles.next, {
                    [styles.nextDisabled]: !artistsValue,
                  })}
                  onClick={() => setLyricVisible(true)}
                >
                  <span>{i18n.format("modules.global.next")}</span>
                  <Icon type="arrow-right" />
                </div>
              )}
            </div>
          </Wrapper>
        )}
        {croppieImage && (
          <Croppie
            image={croppieImage}
            albumCoord={layout.albumCoord}
            onClose={() => setCroppieImage(undefined)}
            onConfirm={handleCroppieConfirm}
          />
        )}
        {lyricVisible &&
          <LyricContent
            minLyricLines={minLyricLines}
            maxLyricLines={maxLyricLines}
            track={currentTrack as SpotifyTrack}
            onClose={() => setLyricVisible(false)}
            onConfirm={handleLyricConfirm}
          />
        }
        {previewVisible && (
          <Preview
            className={styles.previewWrapper}
            trackCanvas={currentTrack &&
              <TrackCanvas
                canvas={_canvas.current[0]}
                track={currentTrack}
                layout={layout}
                color={currentColor}
                artists={artistsValue}
                album={customAlbumImage}
                bottomImage={bottomImage}
                lyrics={lyrics}
                asyncLoading={setTrackLoading}
              />
            }
          >
            <div
              className={styles.previousWrapper}
              onClick={handlePreviewClose}
            >
              <Icon type="arrow-left" />
            </div>
            {colors.length > 1 && (
              <Fragment>
                <div className={styles.title}>
                  {i18n.format("modules.spotify.code.change.color")}
                </div>
                <div
                  className={classnames(styles.colorWrapper, {
                    [styles.colorDisabled]: trackLoading || bindTrackLoading,
                  })}
                >
                  {colors.map((item, index) => (
                    <div
                      key={index}
                      className={classnames(styles.color, {
                        [styles.colorActive]: item === currentColor,
                      })}
                      onClick={() => setCurrentColor(item)}
                    >
                      <div
                        className={styles.colorContent}
                        style={{
                          backgroundColor: item.value,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Fragment>
            )}
            <div
              className={classnames(styles.confirm, {
                [styles.confirmDisabled]: trackLoading || bindTrackLoading,
              })}
              onClick={() => handleValidatorConfirm(2)}
            >
              <div className={styles.iconWrapper}>
                <Icon type="add-cart" />
              </div>
              <span className={styles.price}>{price}</span>
              <span>
                {i18n.format("modules.global.add.cart").toLowerCase()}
              </span>
            </div>
          </Preview>
        )}
        {lineTextDrawerVisible && (
          <Wrapper className={styles.lineTextDrawerWrapper}>
            <Drawer mask className={styles.lineTextDrawer}>
              <div className={styles.drawerContent}>
                <div className={styles.imageWrapper}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${layout.lineTextCoord?.image})`,
                    }}
                  />
                </div>
                <div className={styles.title}>
                  {i18n.format("modules.spotify.code.add.text.ask")}
                </div>
                {layout.lineTextCoord?.increase && (
                  <div className={styles.increase}>
                    {layout.lineTextCoord.increase.price === 0
                      ? i18n.format("modules.global.free")
                      : `+ ${priceFormat(
                          shop.currencySymbol,
                          layout.lineTextCoord.increase.price
                        )}`}
                  </div>
                )}
                <div className={styles.buttonGroup}>
                  <div
                    className={classnames(styles.button, styles.confirm)}
                    onClick={() => setLineTextVisible(true)}
                  >
                    <div className={styles.iconWrapper}>
                      <Icon type="edit" />
                    </div>
                    <span>{i18n.format("modules.spotify.code.add.text")}</span>
                  </div>
                  <div className={styles.divider}>
                    {i18n.format("modules.spotify.code.add.text.or")}
                  </div>
                  <div
                    className={classnames(styles.button, styles.addCart)}
                    onClick={() => handleValidatorConfirm(1)}
                  >
                    <div className={styles.iconWrapper}>
                      <Icon type="add-cart" />
                    </div>
                    <span>
                      {i18n.format("modules.spotify.code.skip.add.cart")}
                    </span>
                  </div>
                </div>
                <div
                  className={styles.cancel}
                  onClick={() => setLineTextDrawerVisible(false)}
                >
                  {i18n.format("modules.global.cancel")}
                </div>
              </div>
            </Drawer>
          </Wrapper>
        )}
        {lineTextVisible && (
          <Wrapper className={styles.lineTextWrapper}>
            <div
              className={styles.previousWrapper}
              onClick={handleLineTextClose}
            >
              <Icon type="arrow-left" />
            </div>
            {currentTrack && (
              <div className={styles.trackCanvasWrapper}>
                <TrackCanvas
                  canvas={_canvas.current[1]}
                  track={currentTrack}
                  layout={layout}
                  color={currentColor}
                  artists={artistsValue}
                  album={customAlbumImage}
                  bottomImage={bottomImage}
                  lineText={layoutLineText}
                  lyrics={lyrics}
                  asyncLoading={setBindTrackLoading}
                />
              </div>
            )}
            <div className={styles.title}>
              {i18n.format("modules.spotify.code.add.text.title")}
            </div>
            <div className={styles.inputWrapper}>
              <input
                placeholder={lineTextCoord?.defaultValue}
                value={lineTextValue}
                onChange={handleLineTextChange}
              />
              {lineTextValue && (
                <div
                  className={styles.confirm}
                  onClick={handleLineTextValueConfirm}
                >
                  <div className={styles.iconWrapper}>
                    <Icon type="confirm" />
                  </div>
                </div>
              )}
            </div>
            <div
              className={classnames(styles.addCart, {
                [styles.addCartDisabled]: !layoutLineText?.value,
              })}
              onClick={() => handleValidatorConfirm(1)}
            >
              <div className={styles.iconWrapper}>
                <Icon type="add-cart" />
              </div>
              <span>
                {i18n.format("modules.global.add.cart").toLowerCase()}
              </span>
            </div>
          </Wrapper>
        )}
        {vipIncrementVisible && (
          <Wrapper className={styles.vipIncrementWrapper}>
            <Vip
              mask
              className={styles.vipIncrement}
              vipIncrement={increment?.vip}
              currencySymbol={shop.currencySymbol}
              onClose={() => setVipIncrementVisible(false)}
              onConfirm={handleConfirm}
              gtag={gtag}
            />
          </Wrapper>
        )}
        {relatedProductVisible && (
          <Wrapper className={styles.lineTextDrawerWrapper}>
            <Drawer mask className={styles.lineTextDrawer}>
              <div className={styles.drawerContent}>
                <div className={styles.imageWrapper}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${thumbnail7n(
                        relatedProduct.thumbnail,
                        500
                      )})`,
                    }}
                  />
                </div>
                <div className={styles.title}>{relatedProduct.title}</div>
                <div className={styles.increase}>
                  + {priceFormat(shop.currencySymbol, relatedProduct.price)}
                </div>
                <div className={styles.buttonGroup}>
                  <div
                    className={classnames(styles.button, styles.confirm)}
                    onClick={() => handleConfirm(relatedProduct)}
                  >
                    <div className={styles.iconWrapper}>
                      <Icon type="add-cart" />
                    </div>
                    <span>{i18n.format("modules.spotify.code.buy.it")}</span>
                  </div>
                  <div className={styles.divider}>
                    {i18n.format("modules.spotify.code.add.text.or")}
                  </div>
                  <div
                    className={classnames(styles.button, styles.addCart)}
                    onClick={() => handleValidatorConfirm(0)}
                  >
                    <span>
                      {i18n.format("modules.spotify.code.skip.add.cart")}
                    </span>
                  </div>
                </div>
              </div>
            </Drawer>
          </Wrapper>
        )}
        {uploadVisible && (
          <Wrapper className={styles.uploadWrapper}>
            <Loading.Line />
          </Wrapper>
        )}
      </div>
    </Layout>
  );
};

export const formatTime = (time: number) =>
  dayjs(time).subtract(1, "s").format("mm:ss");

/** 缩小歌曲名字 */
export const narrowTrackName = (value: string) => {
  if (value.length > 20) {
    value = value.replace(/\[([\s\S]*?)\]/g, "");
    if (value.length > 20) {
      value = value.replace(/\(([\s\S]*?)\)/g, "");
    }
  }
  return value.trim();
};

export default Lyric;
