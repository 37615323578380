export default {
  'modules.global.uoload.photo': 'Caricare foto', // 上传图片
  'modules.global.upload': 'Caricare', // 上传
  'modules.global.confirm': 'CONFERMARE', // 确认
  'modules.global.processing': 'Lavorare sulla tua immagine', // 确认
  'modules.global.confirm.design': 'CONFERMARE IL DESIGN', // 确认设计
  'modules.global.free': 'GRATUITO', // 免费
  'modules.global.add.text': 'Aggiungere un\'iscrizione', // 添加刻字
  'modules.global.preview.confirm': 'Caricamento di un\'immagine', // 正常上传图片
  'modules.global.preview.tips': 'I nostri designer adatteranno il tuo design per una migliore stampa.', // 预览提示

  'modules.global.add.cart': 'AGGIUNGI AL CARRELLO', // 加车
  'modules.global.previous': 'PRECEDENTE', // 上一步
  'modules.global.next': 'IL PROSSIMO', // 下一步

  'modules.global.preview': 'Anteprima', // 预览
  'modules.global.loading': 'Caricamento', // 正在加载
  'modules.global.uploading': 'CARICAMENTO', // 正在上传

  'modules.global.add.photo': 'Aggiungi foto', // 添加图片
  'modules.global.checkout': 'check-out', // 结账
  'modules.global.cancel': 'Annulla', // 取消
  'modules.global.complete': 'Annulla', // 完成
  'modules.global.change': 'Modificare', // 更换
  'modules.global.cropper': 'Ritaglia', // 裁剪
  'modules.global.cropper.image': 'Immagine Cropper', // 裁剪照片
  'modules.global.replace': 'Modificare', // 替换
  'modules.global.done': 'Annulla', // 完成
  'modules.global.delete': 'Elimina', // 删除
  'modules.global.ai.crop.complete': 'Sì, completo!', // 切图完成
  'modules.global.ai.croping.photo': 'AI Ritaglio della tua foto', // ai切图中

  'components.cropper.title': 'Immagine Cropper', // 裁剪照片
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': 'Completare il contenuto personalizzato in base alle seguenti condizioni.', // 请根据以下条件完成定制内容
  'components.oop.submit': 'Lo cambierò', // 我会改变的

  'components.footer.zoom.in': 'Zoom avanti', // 放大
  'components.footer.zoom.out': 'Zoom indietro', // 缩小
  'components.footer.rotate.left': 'Gira a sinistra', // 向左旋转
  'components.footer.rotate.right': 'Ruota a destra', // 向右旋转

   // 20200602 add
   'components.edit.title': 'MODIFICA MODELLO', //  编辑模式
   'components.edit.type': 'GENERE', //  类别
   'components.edit.apply': 'APPLICARE', //  应用
   'components.guide.title': 'Istruzioni', //  操作说明
   'components.edit.font.text': 'Testo', //  文字内容
   'components.edit.font.family': 'Font', //  字体
   'components.edit.font.size': 'Dimensione carattere', //  字号
   'components.edit.font.color': 'Colore del carattere', //  字体颜色
   'components.edit.font.shadow.color': 'Colore ombreggiatura carattere', //  字体阴影颜色
   'components.cropper.back': 'Indietro', // 返回
   'components.cropper.crop': 'Ritaglia', // 裁剪
   'components.cropper.move': 'Mossa', // 移动

   'modules.mug.font.validator': 'Si prega di modificare "nome1"', //  没有被更改，请编辑它
   'modules.bottom.background': 'Opzioni di scenario', //  风景选项
   'modules.bottom.layout': 'disposizione', //  图层
   'modules.bottom.help': 'Aiuto', //  帮助
   'modules.bottom.next': 'prossimo', //  下一步
   'modules.bottom.confirm': 'Confermare', //  确认
   'modules.bottom.layout.tip': "Fare clic sull'opzione corrente verrà temporaneamente inserito e modificabile", //  点击当前选项将暂时被置顶并为可编辑状态
   'modules.bottom.layout.close': 'VICINO', //  关闭
   'modules.bottom.operator.top': 'Superiore', //  置顶
   'modules.bottom.operator.bottom': 'Parte inferiore', //  置底
   'modules.bottom.operator.up': 'Su', //  上一层
   'modules.bottom.operator.down': 'Giù', //  下一层
   'modules.error.load.font': 'Errore di caricamento del carattere, riprovare', //  字体加载错误，请重新尝试
   'modules.error.upload.result': 'Errore di caricamento, riprova', // 上传失败 请重试

    // 多图模板
   'modules.template.tips': 'Fare clic sulla foto per modificarla o ritagliarla', // 点击图片区域可以更换图片或裁剪
   'modules.template.validator.images': 'Devi caricare foto di $value.', // 需要传多少张图
   'modules.template.validator.texts': 'Devi compilare i testi di $value.', // 需要输入多少段文字
   'modules.template.bottom.template': 'Modello', // 模版
   'modules.template.default.texts': 'Testo', // 文字

  // 影刻
  'modules.sketch.cropper.tips': 'Assicurarsi che il volto e il corpo della persona al centro della foto', // 请保证照片中人像边缘尽量清晰可见
  'modules.sketch.title': 'ANTERPRIMA 3D', // 3d预览
  'modules.sketch.request.stage0': 'Caricamento di foto…', // 正在上传照片…
  'modules.sketch.request.stage1': 'Rimuovere lo sfondo…', // 正在将人像从背景抠出…
  'modules.sketch.request.stage2': 'Aggiungere un filtro…', // 正在加入滤镜效果…
  'modules.sketch.request.stage3': 'Lavorare con le foto in bianco e nero…', // 正在去色处理…
  'modules.sketch.request.stage4': 'Attendi un momento per favore，preparazione per l\'anteprima 3d…', // 请稍后, 预览结果即将呈现…
  'modules.sketch.request.accomplish': 'Completo，Si prega di fare clic sull\'anteprima', // 影刻处理完成, 请点击预览查看
  'modules.sketch.request.error': 'Fallito，È possibile partecipare direttamente al carrello.', // 预览处理失败，您可以跳过直接加车
  'modules.sketch.try.again': 'Anteprima di nuovo', // 重新预览
  'modules.sketch.add.text.placeholder': 'Il tipo di testo qui（Lo faremo esattamente come mostrato nell\'anteprima qui sopra）.', // 刻字提示文案
  'modules.sketch.footer.preview': 'Anteprima', // 预览并加车
  'modules.sketch.footer.skip': 'Salta l\'anteprima', // 跳过预览
  'modules.sketch.accomplish.later': 'Controlla più tardi', // 稍后再说
  'modules.sketch.accomplish.prompt': 'L\'anteprima 3D è pronta, controlla ora!', // 稍后再说

  // 撕拉盒子
  'modules.box.step': 'PASSO', // 步数
  'modules.box.side': 'Lato', // 面
  'modules.box.image.upload': 'Caricare', // 上传
  'modules.box.image.change': 'Modificare', // 切换
  'modules.box.step1.title': 'AGGIUNGI IMMAGINE', // 添加图片
  'modules.box.step1.tips': 'Puoi personalizzarlo con la tua foto caricata o utilizzare l\'immagine corrente.', // 添加图片说明文案
  'modules.box.step2.title': 'AGGIUNGI CONFETTI', // 添加纸屑
  'modules.box.step2.tips': 'Crea la tua bomba boomf esplosiva personalizzata！', // 添加纸屑说明文案
  'modules.box.oop.title': 'PUNTE', // 添加纸屑说明文案
  'modules.box.oop.description': 'Lo produrremo nell\'ordine delle immagini qui sotto.', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': 'Modifica immagine', // 编辑照片
  'modules.photo.film.tips.sort': 'Premere a lungo e trascinare l\'immagine per regolarne la posizione.', // 长按拖动图片来排序
  'modules.photo.film.tips.count': 'È necessario caricare $value foto.', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'Devi caricare almeno $value foto', // 最少需要传多少张图
  'modules.photo.film.change': 'Modificare', // 更换
  'modules.photo.film.cropper': 'Ritaglia', // 裁剪
  'modules.photo.film.sort': 'Ordinare', // 排序
  'modules.photo.film.delete': 'Elimina', // 删除

  // 简版照片书
  'modules.photo.book.deleted.page': 'Pagina eliminata', // 已删除页面
  'modules.photo.book.delete.page': 'elimina pagina', // 删除页面
  'modules.photo.book.change.layout': 'cambiare layout', // 更改布局
  'modules.photo.book.add.photo.page': 'Aggiungi la pagina della foto', // 添加照片页面
  'modules.photo.book.add.compose.page': 'Aggiungi pagina collage', // 添加拼贴页面
  'modules.photo.book.validator': 'Aggiungi $ value più pagine da acquistare', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': 'Sostituisci foto', // 替换照片
  'modules.photo.book.remove.image': 'Rimuovere', // 移除
  'modules.photo.book.page': 'Pagina', // 页
  'modules.photo.book.type': 'materiale del libro', // 图书材质
  'modules.photo.book.size': 'dimensione del libro', // 图书尺寸
  'modules.photo.book.order.qty': 'qty', // 数量
  'modules.photo.book.order.total': 'totale', // 总计
  'modules.photo.book.warning.title': 'avvertimento', // 警告
  'modules.photo.book.warning.cover.title': 'Aggiungi il titolo per la copertina del fotolibro', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': 'Fare clic sulla copertina del fotolibro per aggiungere un titolo', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': 'Trovate immagini a bassa risoluzione', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': 'Potrebbe essere sfocato durante la stampa.', // 打印出来可能比较模糊
  'modules.photo.book.add.title': 'Aggiungi titolo', // 添加标题
  'modules.photo.book.zuploader.subtitle': 'Servono almeno $value foto', // 至少需要 value 张照片
  'modules.photo.book.guide.title': `Guida all'acquisto`, // 操作引导
  'modules.photo.book.guide.position': `Premere a lungo e trascinarlo per regolare l'ordine`, // 长按图片可调整顺序
  'modules.photo.book.guide.layout': `Fare clic sull'icona a lato per modificare il layout`, // 点击侧边更多可切换图片样式
  'modules.photo.book.guide.button': 'Ok grazie', // 好的 我知道了
  // 照片墙
  'modules.photo.wall.choose.frame': 'scegli una cornice', // 选择边框
  'modules.photo.wall.choose.style': 'scegli uno stile', // 选择样式
  'modules.photo.wall.zoom.pinch': 'pizzica e ingrandisci', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'scorrere per ingrandire', // 滚动放大
  'modules.photo.wall.choose.design': 'Scegli un design', // 选择设计
  'modules.photo.wall.choose.design.tips': 'Scegli tra le pareti della galleria per personalizzare', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'montatura', // 框
  'modules.photo.wall.image.list.title': 'Aggiungi foto per visualizzare più disegni', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': 'Le mie foto', // 我的图片
  'modules.photo.wall.zuploader.subtitle': 'Puoi caricare fino a $value foto',  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': "Supportiamo foto fino a $value, l'eccesso verrà ignorato",  // 我们最多支持 $value 张照片，超出的将被忽略

  // zuploader
  'plugin.zuploader.from.album': 'Scegli dal tuo album', // 从相册中选择
  'plugin.zuploader.from.social': 'Scegli tra i servizi in linea', // 从在线服务中选择
  'plugin.zuploader.from.camera': 'Scattare una foto', // 拍照
  'plugin.zuploader.wait.upload': 'In attesa di caricamento', // 等待上传
  'plugin.zuploader.choose.source': 'Scegli le foto', // 选择照片来源
  'plugin.zuploader.choose.image.title': 'Foto caricate', // 已上传照片
  'plugin.zuploader.choose.image.choose': 'Seleziona le foto', // 选择照片
  'plugin.zuploader.choose.image.range': 'Seleziona articoli $value', // 选择 $value 照片

  // lowQuality
  'plugin.low.quality.title': "Bassa qualità dell'immagine", // 低图像质量
  'plugin.low.quality.tips': 'Questa foto è in realtà piuttosto piccola. Probabilmente creerà una tessera sfocata!', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'Mantieni comunque', // 不管怎样都保留
  'plugin.low.quality.remove': "Rimuovi dall'ordine", // 从订单中删除

  // 无限魔方
  'modules.infinite.cube.zuploader.title': 'Ho bisogno di foto di $value', // 需要 $value 张照片
  'modules.infinite.cube.input.tip': "Trascina o fai clic per regolare l'immagine", // 拖动或点击来调整图片
  'modules.infinite.cube.preview3d.tip': 'Trascina il cubo per vedere', // 拖动观察魔方
  'modules.infinite.cube.preview3d.tap': 'Toccami', // 点击我(按钮)
  'modules.infinite.cube.preview.choose': 'Scegli la tua foto di copertina', // 选择你的封面
  'modules.infinite.cube.save': 'Salva', // 保存
  'modules.infinite.cube.share': 'quota e $elm $value di sconto $end', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.and': 'quota e', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.off': 'di', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.or': 'o', // 或者
  'modules.infinite.cube.without.share': 'senza condividere e aggiungere al carrello', // 不分享直接加车

  // 增值产品-relatedProduct
  'increment.related.product.title': 'Scegli un pacchetto super sorpresa per il tuo regalo', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'No grazie', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': 'La scelta migliore per elaborare gli ordini con priorità di produzione e spedizione', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'SERVIZIO VIP', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': 'Errore di rete!', // 网络错误
  'modules.new.sock.ai.crop.error': 'Errore di ritaglio AI!', // 切图服务错误
  'modules.new.sock.replace.photo': 'Sostituisci foto', // 替换照片
  'modules.new.sock.crop.myself': 'Ritaglia da solo', // 自己切图
  'modules.new.sock.try.again': 'Riprova', // 重试
  'modules.new.sock.edit.avatar': 'Modifica avatar', // 编辑头像
  'modules.new.sock.add.line.text.title': 'Aggiungi testo?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': "L'80% dei clienti preferisce aggiungere testo personalizzato per realizzare regali perfetti!", // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': 'AGGIUNGI TESTO', // 添加文字
  'modules.new.sock.without.text': 'SENZA TESTO', // 不刻字
  'modules.new.sock.color': 'COLORE', // 颜色
  'modules.new.sock.size': 'TAGLIA', // 尺寸
  'modules.new.sock.sock': 'CALZINO', // 袜子
  'modules.new.sock.share.title': 'Condividi per ottenere', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'Immagine del profilo social personalizzato.', // 你的社交新头像
  'modules.new.sock.free.download': 'Download gratuito', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'Sfondo mobile personalizzato.', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'Sfondo personalizzato per PC.', // 你的电脑新壁纸
  'modules.new.sock.discount': 'Sconto', // 折扣
  'modules.new.sock.discount.price': '$value OFF', // 立减 $value 块
  'modules.new.sock.share.now': 'CONDIVIDI ORA', // 立刻分享
  'modules.new.sock.no.thanks': 'NO GRAZIE', // 不分享
  'modules.new.sock.shared.title': 'CONGRATULAZIONI!', // 恭喜
  'modules.new.sock.download': 'Scarica', // 下载
  'modules.new.sock.generating.image': "Generazione dell'immagine condivisa", // 正在生成分享图
  'modules.new.sock.total': 'totale', // 总计

  // 2020 11 17 add
  // 台历
  'modules.desktop.calendar.cover': 'Copertina', // 封面
  'modules.desktop.calendar.change.layout': 'Cambia Layout Foto', // 更改照片布局
  'modules.desktop.calendar.validator': 'Foto Di Valore $value Mancanti', // 缺 $value 张照片
  'modules.desktop.calendar.replace.image': 'Sostituisci Foto', // 替换照片
  'modules.desktop.calendar.remove.image': 'Rimuovere', // 移除

  // 挂历
  'modules.wall.calendar.tap.day': 'Tocca Data E Aggiungi Evento Del Calendario', // 点击日期和添加日历事件
  'modules.wall.calendar.cover': 'Copertina', // 封面
  'modules.wall.calendar.change.layout': 'Cambia Layout Foto', // 更改照片布局
  'modules.wall.calendar.validator': 'Carica Altre Foto Di $value Per', // 缺 $value 张照片
  'modules.wall.calendar.replace.image': 'Sostituisci Foto', // 替换照片
  'modules.wall.calendar.remove.image': 'Rimuovere', // 移除
  'modules.wall.calendar.and': 'E', // 和
  'modules.wall.calendar.event.add': 'Aggiungi Il Tuo Evento', // 添加你的事件
  'modules.wall.calendar.event.choose': "Scegli Il Tipo Di Evento", // 选择你的事件类型
  'modules.wall.calendar.event.edit': "Modifica Evento", // 编辑事件
  'modules.wall.calendar.event.clear': "Cancella Evento", // 清除事件
  'modules.wall.calendar.event.create': 'Crea Nuovo Evento', // 创建新事件
  'modules.wall.calendar.event.graduation': "La Laurea", // 毕业季
  'modules.wall.calendar.event.holidays': 'Vacanze', // 度假
  'modules.wall.calendar.event.wedding': 'Nozze', // 婚礼
  'modules.wall.calendar.event.bar': 'Bar', // 酒吧
  'modules.wall.calendar.event.party': 'Festa', // 派对
  'modules.wall.calendar.event.birthday': 'Compleanno', // 生日
  'modules.wall.calendar.event.anniversarie': 'Anniversarie', // 周年纪念日
  'modules.wall.calendar.event.other': 'Altro', // 其他
  'modules.wall.calendar.event.tips': 'Tocca E Modifica', // 点击编辑
  'modules.wall.calendar.cover.title.show': "Mostra Titolo", // 显示标题
  'modules.wall.calendar.cover.title.hide': 'Nascondi Titolo', // 隐藏标题

  //  other
  'modules.global.revoke': 'Revocare', // 撤销
  'modules.global.warning.title': 'Avvertimento', // 警告
  'modules.global.zoom.pinch': 'Pizzica E Ingrandisci', // 移动和放大
  'modules.global.edit.confirm': "Non Hai Finito Di Modificare. Sei Sicuro Di Smettere?", // 您还没有编辑完，确定要退出吗
  'modules.global.delete.tips': 'Sei sicuro di cancellarlo?', // 删除提示

  // Spotify Code
  'modules.spotify.code.search.hot': 'Ricerca a Caldo', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Risultati di Ricerca', // 搜索结果
  'modules.spotify.code.placeholder': 'Cerca brano、Artista、Album', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Carica la Tua Foto', // 上传你的图片
  'modules.spotify.code.album.track': "Usa la Copertina Dell'album", // 使用相册封面
  'modules.spotify.code.change.color': 'Scegli Il Tuo Colore', // 选择你的颜色
  'modules.spotify.code.custom.artists': "Nome Personalizzato Dell'artista", // 你可以自己定制演奏者
  'modules.spotify.code.search.no.result': 'Oops, nessun risultato.', // 没结果
  'modules.spotify.code.add.text.ask': 'Avresti bisogno di aggiungere testo', // 你是否需要添加刻字
  'modules.spotify.code.add.text': 'Aggiungi il testo', // 添加刻字
  'modules.spotify.code.skip.add.cart': 'No grazie. Aggiungi al carrello', // 不谢谢 加车
  'modules.spotify.code.add.text.title': 'Aggiungi il tuo testo qui', // 在这添加你的刻字
  'modules.spotify.code.add.text.or': 'o', // 或
  'modules.spotify.code.buy.it': 'Certo, compralo ora', // 是的 买它
  'modules.spotify.code.template.title': 'Scegli il tuo stile preferito',
  "modules.spotify.code.multi.upload": "Carica le tue foto", // 上传你的照片
}
