export default {
  'modules.global.uoload.photo': 'Télécharger des photos', // 上传图片
  'modules.global.upload': 'Télécharger', // 上传
  'modules.global.confirm': 'CONFIRMER', // 确认
  'modules.global.processing': 'Traitement de Votre Photo', // 确认
  'modules.global.confirm.design': 'CONFIRMER LE DESIGN', // 确认设计
  'modules.global.free': 'GRATUIT', // 免费
  'modules.global.add.text': 'Ajouter La Gravure', // 添加刻字
  'modules.global.preview.confirm': 'Téléchargement d\'une photo', // 正常上传图片
  'modules.global.preview.tips': 'Notre artisan  ajustera votre design pour un meilleur effet d\'impression.', // 预览提示

  'modules.global.add.cart': 'AJOUTER AU PANIER', // 加车
  'modules.global.previous': 'PRÉCÉDENT', // 上一步
  'modules.global.next': 'SUIVANT', // 下一步
  'modules.global.preview': 'APERÇU', // 预览
  'modules.global.loading': 'Chargement', // 正在加载
  'modules.global.uploading': 'TÉLÉCHARGEMENT', // 正在上传

  'modules.global.add.photo': 'Ajouter des photos', // 添加图片
  'modules.global.checkout': 'Caisse', // 结账
  'modules.global.cancel': 'Annuler', // 取消
  'modules.global.complete': 'achevé', // 完成
  'modules.global.change': 'Changement', // 更换
  'modules.global.cropper': 'Surgir', // 裁剪
  'modules.global.cropper.image': 'Image de recadrage', // 裁剪照片
  'modules.global.replace': 'Changement', // 替换
  'modules.global.done': 'SUIVANT', // 完成
  'modules.global.delete': 'Effacer', // 删除
  'modules.global.ai.crop.complete': 'Oui, Terminé!', // 切图完成
  'modules.global.ai.croping.photo': 'AI Recadrer votre photo', // ai切图中

  'components.cropper.title': 'Image de recadrage', // 裁剪照片
  'components.edit.title': 'MODIFIER LE MODÈLE', // 编辑模式
  'components.edit.type': 'TYPE', // 类别
  'components.edit.apply': 'APPLIQUER', // 应用
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': 'Veuillez compléter le contenu personnalisé selon les conditions suivantes.', // 请根据以下条件完成定制内容
  'components.oop.submit': 'Je vais le changer', // 我会改变的
  'components.guide.title': 'Instructions', // 使用说明
  'components.edit.font.text': 'Texte', // 文字内容
  'components.edit.font.family': 'Police de caractère', // 字体
  'components.edit.font.size': 'Taille de police', // 文字大小
  'components.edit.font.color': 'Couleur de la police', // 文字颜色
  'components.edit.font.shadow.color': 'Couleur de l\'ombre de la police', // 文字阴影颜色
  'modules.mug.font.validator': ' Veuillez éditer ', // 请编辑
  'modules.bottom.background': 'paysage', // 背景
  'modules.bottom.layout': 'disposition', // 图层
  'modules.bottom.help': 'aide', // 帮助
  'modules.bottom.next': 'suivant', // 下一步
  'modules.bottom.confirm': 'confirmer', //确认
  'modules.bottom.layout.mug.tip': 'En cliquant sur l\'option en cours sera temporairement placé et éditable', // 图层操作提示文案
  'modules.bottom.layout.close': 'FERMER', //关闭
  'modules.bottom.operator.top': 'Haut', // 置顶
  'modules.bottom.operator.up': 'Niveau supérieur', // 上一层
  'modules.bottom.operator.down': 'Niveau  suivante', // 下一层
  'modules.bottom.operator.bottom': 'Bas', // 置底
  'modules.error.load.font': 'Erreur de chargement de la police, veuillez réessayer.', // 字体加载失败 请重试
  'modules.error.upload.result': 'Erreur de téléchargement, veuillez réessayer', // 上传失败 请重试

  'components.footer.zoom.in': 'Agrandir', // 放大
  'components.footer.zoom.out': 'Dézoomer', // 缩小
  'components.footer.rotate.left': 'Tourner à gauche', // 向左旋转
  'components.footer.rotate.right': 'Tourner à droite', // 向右旋转

  'components.cropper.back': 'Retour', // 返回
  'components.cropper.crop': 'Recadrer', // 裁剪
  'components.cropper.move': 'Déplacer ', // 移动

  // 多图模板
  'modules.template.tips': 'Cliquez sur la photo pour la modifier ou la recadrer', // 点击图片区域可以更换图片或裁剪
  'modules.template.validator.images': "Vous devez télécharger des photos d'une valeur de $value.", // 需要传多少张图
  'modules.template.validator.texts': 'Vous devez remplir des textes $value.', // 需要输入多少段文字
  'modules.template.bottom.template': 'Modèle', // 模版
  'modules.template.default.texts': 'Texte', // 文字

  // 影刻
  'modules.sketch.cropper.tips': 'Veuillez ajuster le visage et le corps des gens au milieu', // 请保证照片中人像边缘尽量清晰可见
  'modules.sketch.title': 'APERÇU 3D', // 3d预览
  'modules.sketch.request.stage0': 'Téléchargement…', // 正在上传照片…
  'modules.sketch.request.stage1': 'Supprimez  l\'arrière-plan…', // 正在将人像从背景抠出…
  'modules.sketch.request.stage2': 'Ajout d\'un filtre…', // 正在加入滤镜效果…
  'modules.sketch.request.stage3': 'Traitement en photo blanche et noire…', // 正在去色处理…
  'modules.sketch.request.stage4': 'Attendez, préparez l\'aperçu 3D…', // 请稍后, 预览结果即将呈现…
  'modules.sketch.request.accomplish': 'Terminé, veuillez cliquer pour prévisualiser', // 影刻处理完成, 请点击预览查看
  'modules.sketch.request.error': 'Échoué, vous pouvez l\'ajouter au panier.', // 预览处理失败，您可以跳过直接加车
  'modules.sketch.try.again': 'Aperçu à nouveau', // 重新预览
  'modules.sketch.add.text.placeholder': 'Ajoutez la gravure ici ... (Nous allons le faire exactement comme indiqué dans l\'aperçu ci-dessus).', // 刻字提示文案
  'modules.sketch.footer.preview': 'Aperçu', // 预览并加车
  'modules.sketch.footer.skip': 'Ignorer l\'aperçu', // 跳过预览
  'modules.sketch.accomplish.later': 'Vérifiez plus tard', // 稍后再说
  'modules.sketch.accomplish.prompt': '3D preview est prêt, Vérifier maintenant!', // 稍后再说

  // 撕拉盒子
  'modules.box.step': 'ÉTAPE', // 步数
  'modules.box.side': 'Côté', // 面
  'modules.box.image.upload': 'Télécharger', // 上传
  'modules.box.image.change': 'Changement', // 切换
  'modules.box.step1.title': 'AJOUTER UNE PHOTO', // 添加图片
  'modules.box.step1.tips': 'Vous pouvez le personnaliser avec votre photo téléchargée, ou utiliser la photo actuelle.', // 添加图片说明文案
  'modules.box.step2.title': 'AJOUTER CONFETTI', // 添加纸屑
  'modules.box.step2.tips': 'Créez votre bombe boomf explosive personnalisée！', // 添加纸屑说明文案
  'modules.box.oop.title': 'NOTE', // 添加纸屑说明文案
  'modules.box.oop.description': 'Nous le produirons dans l\'ordre des photos ci-dessous.', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': 'Modifier Photo', // 编辑照片
  'modules.photo.film.tips.sort': 'Appuyez longuement et faites glisser la photo pour ajuster sa position.', // 长按拖动图片来排序
  'modules.photo.film.tips.count': 'Vous devez télécharger $value photos.', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'Vous devez télécharger au moins $value photos.', // 最少需要传多少张图
  'modules.photo.film.change': 'Changer', // 更换
  'modules.photo.film.cropper': 'Couper', // 裁剪
  'modules.photo.film.sort': 'Trier', // 排序
  'modules.photo.film.delete': 'Effacer', // 删除

  // 简版照片书
  'modules.photo.book.deleted.page': 'Page supprimée', // 已删除页面
  'modules.photo.book.delete.page': 'supprimer la page', // 删除页面
  'modules.photo.book.change.layout': 'changer la disposition', // 更改布局
  'modules.photo.book.add.photo.page': 'Ajouter une page de photo', // 添加照片页面
  'modules.photo.book.add.compose.page': 'Ajouter une page de collage', // 添加拼贴页面
  'modules.photo.book.validator': 'Ajouter une valeur en $ plus de pages à acheter', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': 'Remplacer la photo', // 替换照片
  'modules.photo.book.remove.image': 'Retirer', // 移除
  'modules.photo.book.page': 'Page', // 页
  'modules.photo.book.type': 'matériel de livre', // 图书材质
  'modules.photo.book.size': 'taille du livre', // 图书尺寸
  'modules.photo.book.order.qty': 'quantité', // 数量
  'modules.photo.book.order.total': 'total,e', // 总计
  'modules.photo.book.warning.title': 'Avertissement', // 警告
  'modules.photo.book.warning.cover.title': 'Ajouter un titre pour la couverture du livre photo', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': 'Cliquez sur la couverture du livre photo pour ajouter un titre', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': 'Images basse résolution trouvées', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': `Il peut être flou lors de l'impression.`, // 打印出来可能比较模糊
  'modules.photo.book.add.title': 'Ajouter un titre', // 添加标题
  'modules.photo.book.zuploader.subtitle': 'Au moins besoin de $value photos', // 至少需要 value 张照片
  'modules.photo.book.guide.title': `Guide d'achat`, // 操作引导
  'modules.photo.book.guide.position': `Appuyez longuement et faites-le glisser pour ajuster l'ordre`, // 长按图片可调整顺序
  'modules.photo.book.guide.layout': `Cliquez sur l'icône sur le côté pour changer la disposition`, // 点击侧边更多可切换图片样式
  'modules.photo.book.guide.button': 'OK merci', // 好的 我知道了

  // 照片墙
  'modules.photo.wall.choose.frame': 'choisissez un cadre', // 选择边框
  'modules.photo.wall.choose.style': 'choisissez un style', // 选择样式
  'modules.photo.wall.zoom.pinch': 'pincer et zoomer', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'faire défiler pour zoomer', // 滚动放大
  'modules.photo.wall.choose.design': 'Choisissez un design', // 选择设计
  'modules.photo.wall.choose.design.tips': 'Choisissez parmi les murs de la galerie à personnaliser', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'cadres', // 框
  'modules.photo.wall.image.list.title': 'Ajoutez des photos pour voir plus de designs', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': 'Mes photos', // 我的图片
  'modules.photo.wall.zuploader.subtitle': "Vous pouvez télécharger des photos d'une valeur maximale de $value",  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': "Nous prenons en charge les photos d'une valeur maximale de $value,l'excès sera ignoré",  // 我们最多支持 $value 张照片，超出的将被忽略

  // 增值产品-relatedProduct
  'increment.related.product.title': 'Choisissez un forfait super surprise pour votre cadeau', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'Non Merci', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': 'Le meilleur choix pour traiter la production et la livraison des commandes avec priorité.', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'SERVICE VIP', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': 'Erreur Réseau!', // 网络错误
  'modules.new.sock.ai.crop.error': 'Erreur de Récolte AI!', // 切图服务错误
  'modules.new.sock.replace.photo': 'Remplacer la Photo', // 替换照片
  'modules.new.sock.crop.myself': 'Recadrer par moi-même', // 自己切图
  'modules.new.sock.try.again': 'Réessayer', // 重试
  'modules.new.sock.edit.avatar': 'Modifier Avatar', // 编辑头像
  'modules.new.sock.add.line.text.title': 'Ajouter du Texte?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': '80% des clients préfèrent ajouter du texte personnalisé pour faire des cadeaux parfaits!', // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': 'AJOUTER DU TEXTE', // 添加文字
  'modules.new.sock.without.text': 'SANS TEXTE', // 不刻字
  'modules.new.sock.color': 'COULEUR', // 颜色
  'modules.new.sock.size': 'TAILLE', // 尺寸
  'modules.new.sock.sock': 'CHAUSSETTE', // 袜子
  'modules.new.sock.share.title': 'Partager pour Obtenir', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'Image de Profil Social Personnalisée.', // 你的社交新头像
  'modules.new.sock.free.download': 'Téléchargement Gratuit', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'Fond d\'écran Mobile Personnalisé.', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'Fond d\'écran PC Personnalisé.', // 你的电脑新壁纸
  'modules.new.sock.discount': 'Rabais', // 折扣
  'modules.new.sock.discount.price': '$value Rabais', // 立减 $value 块
  'modules.new.sock.share.now': 'PARTAGE MAINTENANT', // 立刻分享
  'modules.new.sock.no.thanks': 'Non Merci', // 不分享
  'modules.new.sock.shared.title': 'TOUTES NOS FÉLICITATIONS!', // 恭喜
  'modules.new.sock.download': 'Télécharger', // 下载
  'modules.new.sock.generating.image': 'Générer une image de partage', // 正在生成分享图
  'modules.new.sock.total': 'total,e', // 总计

  // zuploader
  'plugin.zuploader.from.album': 'Choisissez dans votre album', // 从相册中选择
  'plugin.zuploader.from.social': 'Choisissez parmi les services en ligne', // 从在线服务中选择
  'plugin.zuploader.from.camera': 'Prendre une photo', // 拍照
  'plugin.zuploader.wait.upload': 'En attente de téléchargement', // 等待上传
  'plugin.zuploader.choose.source': 'Choisissez des photos', // 选择照片来源
  'plugin.zuploader.choose.image.title': 'Photos téléchargées', // 已上传照片
  'plugin.zuploader.choose.image.choose': 'Sélectionnez des photos', // 选择照片
  'plugin.zuploader.choose.image.range': 'Sélectionnez des articles de $value', // 选择 $value 照片

  // lowQuality
  'plugin.low.quality.title': "Faible qualité d'image", // 低图像质量
  'plugin.low.quality.tips': 'Cette photo est en fait assez petite. Cela fera probablement une tuile floue!', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'Gardez quand même', // 不管怎样都保留
  'plugin.low.quality.remove': 'Retirer de la commande', // 从订单中删除

  // 无限魔方
  'modules.infinite.cube.zuploader.title': 'Besoin $value photos', // 需要 $value 张照片
  'modules.infinite.cube.input.tip': "Faites glisser ou cliquez pour ajuster l'image", // 拖动或点击来调整图片
  'modules.infinite.cube.preview3d.tip': 'Faites glisser le cube pour afficher', // 拖动观察魔方
  'modules.infinite.cube.preview3d.tap': 'Touche moi', // 点击我(按钮)
  'modules.infinite.cube.preview.choose': 'Choisissez votre photo de couverture', // 选择你的封面
  'modules.infinite.cube.save': 'Sauver', // 保存
  'modules.infinite.cube.share': 'partager et $elm $value de rabais $end', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.and': 'partager et', // and
  'modules.infinite.cube.share.off': 'de rabais', // off
  'modules.infinite.cube.or': 'ou', // 或者
  'modules.infinite.cube.without.share': 'sans partage & ajouter au panier', // 不分享直接加车

  // 2020 11 17 add
  // 台历
  'modules.desktop.calendar.cover': 'Couverture', // 封面
  'modules.desktop.calendar.change.layout': 'Cambiar el Diseño de la Foto', // 更改照片布局
  'modules.desktop.calendar.validator': 'photos $value manquantes', // 缺 $value 张照片
  'modules.desktop.calendar.replace.image': 'Remplacer la photo', // 替换照片
  'modules.desktop.calendar.remove.image': 'Enlever', // 移除

  // 挂历
  'modules.wall.calendar.tap.day': 'Appuyez sur Date & ajouter un événement de calendrier', // 点击日期和添加日历事件
  'modules.wall.calendar.cover': 'Couverture', // 封面
  'modules.wall.calendar.change.layout': 'Modifier la disposition de la photo', // 更改照片布局
  'modules.wall.calendar.validator': 'Veuillez télécharger $value plus de photos pour', // 缺 $value 张照片
  'modules.wall.calendar.replace.image': 'Remplacer la photo', // 替换照片
  'modules.wall.calendar.remove.image': 'Enlever', // 移除
  'modules.wall.calendar.and': 'Et', // 和
  'modules.wall.calendar.event.add': 'Ajoutez votre événement', // 添加你的事件
  'modules.wall.calendar.event.choose': "Choisissez le type d'événement", // 选择你的事件类型
  'modules.wall.calendar.event.edit': "Modifier l'événement", // 编辑事件
  'modules.wall.calendar.event.clear': "Effacer l'événement", // 清除事件
  'modules.wall.calendar.event.create': 'Créer un nouvel événement', // 创建新事件
  'modules.wall.calendar.event.graduation': "l'obtention du diplôme", // 毕业季
  'modules.wall.calendar.event.holidays': 'vacances', // 度假
  'modules.wall.calendar.event.wedding': 'mariage', // 婚礼
  'modules.wall.calendar.event.bar': 'bar', // 酒吧
  'modules.wall.calendar.event.party': 'fête', // 派对
  'modules.wall.calendar.event.birthday': 'anniversaire', // 生日
  'modules.wall.calendar.event.anniversarie': 'anniversaire', // 周年纪念日
  'modules.wall.calendar.event.other': 'autre', // 其他
  'modules.wall.calendar.event.tips': 'Appuyez et modifiez', // 点击编辑
  'modules.wall.calendar.cover.title.show': "Montrer le titre", // 显示标题
  'modules.wall.calendar.cover.title.hide': 'Masquer le titre', // 隐藏标题

  //  other
  'modules.global.revoke': 'Révoquer', // 撤销
  'modules.global.warning.title': 'Attention', // 警告
  'modules.global.zoom.pinch': 'pincer et zoomer', // 移动和放大
  'modules.global.edit.confirm': "Vous n'avez pas terminé l'édition. Êtes-vous sûr de cesser de fumer?", // 您还没有编辑完，确定要退出吗
  'modules.global.delete.tips': 'Êtes-vous sûr de vouloir le supprimer?', // 删除提示

  // 袜子
  'components.footer.revoke': 'Révoquer', // 撤回
  'components.footer.gesture.erasure': 'Effacement', // 橡皮擦模式
  'components.footer.help': 'Aide', // 缩小
  'modules.sock.change': 'Remplacer', // 更换
  'modules.sock.preview': 'Aperçu', // 使用头像预览
  'modules.sock.preview.hot': 'Chaud', // 热门
  'modules.sock.erasure': 'Effacer la Partie Extra', // 擦掉多余部分
  'modules.sock.cropper.tips': `Nous ne pouvons recadrer qu'un seul visage à la fois. Veuillez garder votre plein visage dans la zone de récolte.`, // 请确保您的面部五官在所选取裁剪区域内, 且裁剪区内仅限一人.
  'modules.sock.cuting.testing': 'Détection…', // 正在检测...
  'modules.sock.clip': 'Coupez par Vous-même', // 擦掉多余部分
  'modules.sock.cut.or': 'ou', // 或者
  'modules.sock.change.photo': 'Changer la Photo', // 更换图片
  'modules.sock.preview.avatar.delete': 'Êtes-vous sûr de vouloir le supprimer?', // 确定要删除该头像吗
  'modules.sock.cut.recommend': `S'il vous plaît`, // 推荐您
  'modules.sock.compose.title': 'Personnalisez vos Chaussettes', // 制作你的袜子
  'modules.sock.compose.background': 'Le Fond', // 背景
  'components.footer.gesture.move': 'Bouger', // 移动模式
  'utils.meitu.error.20001': 'Erreur', // 处理错误
  'utils.meitu.error.20003': 'Aucun Visage Trouvé', // 人脸缺失
  'utils.meitu.error.20004': `Plus d'un Visage Détecté`, // 人脸数大于1
  'utils.meitu.error.20005': `N'acceptez que JPG et Photos PNG`, // 不支持的type
  'utils.meitu.error.other': 'Erreur', // 系统错误，请稍后再试

  // Spotify Code
  'modules.spotify.code.search.hot': 'Recherche Chaude', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Résultats de Recherche', // 搜索结果
  'modules.spotify.code.placeholder': 'Recherchez de Chanson、Artiste、Album', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Téléchargez Votre Photo', // 上传你的图片
  'modules.spotify.code.album.track': 'Utiliser La Couverture de l\'Album', // 使用相册封面
  'modules.spotify.code.change.color': 'Choisissez Votre Couleur', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'Nom Personnalisé de l\'Artiste', // 你可以自己定制演奏者
  'modules.spotify.code.search.no.result': 'Oups, aucun résultat.', // 没结果
  'modules.spotify.code.add.text.ask': "Auriez-vous besoin d'ajouter du texte", // 你是否需要添加刻字
  'modules.spotify.code.add.text': 'Ajouter du texte', // 添加刻字
  'modules.spotify.code.skip.add.cart': 'Non merci. Ajouter au chariot', // 不谢谢 加车
  'modules.spotify.code.add.text.title': 'Ajoutez votre texte ici', // 在这添加你的刻字
  'modules.spotify.code.add.text.or': 'ou', // 或
  'modules.spotify.code.buy.it': 'Bien sûr, achetez-le maintenant', // 是的 买它
  'modules.spotify.code.template.title': 'Choisissez votre style préféré',
  "modules.spotify.code.multi.upload": "Téléchargez vos photos", // 上传你的照片
}
