import React, { useState } from "react";
import SpotifyCode, { SpotifyCodeProps } from "../index";
import Template, { TemplateProps } from "./template";
import { ISpotifyData } from "../spotify-code";

const withTemplate: React.FC<SpotifyCodeProps & TemplateProps> = ({
  theme,
  products = [],
  onClose,
  ...otherData
}) => {

  const [activeData, setActiveData] = useState<ISpotifyData>(
    products[0]?.data
  );
  const [templateVisiable, setTemplateVisiable] = useState(true);

  const onSpotifyCodeClose = () => {
    setTemplateVisiable(true);
  };

  const onTemplateConfirm = (data: ISpotifyData) => {
    setActiveData(data);
    setTemplateVisiable(false);
  };

  return (
    <Template
      theme={theme}
      templateVisiable={templateVisiable}
      products={products}
      onConfirm={onTemplateConfirm}
      onClose={onClose}
    >
      <SpotifyCode
        {...otherData}
        {...activeData}
        onClose={onSpotifyCodeClose}
      />
    </Template>
  );
};

export default withTemplate;
