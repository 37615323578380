import React, { Dispatch, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { fabric } from 'sunzi-fabric';
import dayjs from 'dayjs';
import { Wrapper, Loading } from '@/components';
import { loadImageFromURL, loadFont, canvasToBlobURL, thumbnail7n, loadHTMLImage } from '@/utils';
import { spotifyCodeHost } from '@/config';
import {
  SpotifyColor,
  SpotifyTextCoord,
  SpotifyTrack,
  SpotifyTrackCoord,
  SpotifyCodeCoord
} from '../spotify-code';
import { SpotifyLayoutChildren, SpotifyTrackLineText } from './post-card';
import styles from './style.less';

export interface TrackCanvasProps {
  /** canvas对象 */
  canvas: fabric.Canvas;
  /** 歌曲信息 */
  track: SpotifyTrack;
  /** 颜色 */
  color: SpotifyColor;
  /** 制作宽度 */
  makeWidth: number;
  /** 制作高度 */
  makeHeight: number;
  /** 绘制布局信息 */
  layout: SpotifyLayoutChildren;
  /** 刻字信息 */
  lineTextValue?: string;
  /** 多行刻字信息 */
  textAreaValue?: string;
  /** 演奏者 */
  artistsValue?: string;
  /** 封面图 */
  album?: string;
  /** 同步loading状态 */
  asyncLoading?: Dispatch<React.SetStateAction<boolean>>
}

const TrackCanvas: React.FC<TrackCanvasProps> = ({
  canvas,
  track,
  layout,
  color,
  album,
  makeWidth,
  makeHeight,
  artistsValue,
  lineTextValue,
  textAreaValue,
  asyncLoading
}) => {
  const _timer = useRef<NodeJS.Timeout>();
  /** 容器实例 */
  const _content = useRef<HTMLDivElement>(null);
  /** 加载状态 */
  const [ loading, setLoading ] = useState<boolean>();
  /** 高度 */
  const [ imageSize, setImageSize ] = useState<number[]>([]);
  /** 结果图 */
  const [ trackImage, setTrackImage ] = useState<string>();

  useEffect(() => {
    _canvasCreate();
  }, []);

  useEffect(() => {
    setLoading(true);
    asyncLoading && asyncLoading(true);
    // 防抖实现预览
    if (_timer.current)
      clearTimeout(_timer.current);
    _timer.current = setTimeout(() => {
      renderTrack(canvas, makeWidth, makeHeight, track, layout, color, artistsValue, album, lineTextValue, textAreaValue)
        .then((image: string) => {
          setTrackImage(image);
          setLoading(false);
          asyncLoading && asyncLoading(false);
        });
    }, 300);
    // 销毁后清空计时器
    return () => {
      if (_timer.current)
        clearTimeout(_timer.current);
    }
  }, [ color, artistsValue, lineTextValue, textAreaValue ]);

  /** 初始化画布 */
  const _canvasCreate = async () => {
    if (_content.current) {
      const { offsetWidth, offsetHeight } = _content.current;
      if (makeWidth / makeHeight > offsetWidth / offsetHeight)
        setImageSize([
          offsetWidth,
          offsetWidth / makeWidth * makeHeight
        ]);
      else
        setImageSize([
          offsetHeight / makeHeight * makeWidth,
          offsetHeight
        ]);
      // 生成预览图
      if (_content.current) {
        setLoading(true);
        asyncLoading && asyncLoading(true);
        // 生成预览图
        const image = await renderTrack(
          canvas,
          makeWidth,
          makeHeight,
          track,
          layout,
          color,
          artistsValue,
          album,
          lineTextValue,
          textAreaValue
        );
        setTrackImage(image);
        setLoading(false);
        asyncLoading && asyncLoading(false);
      }
    }
  }

  return (
    <div
      ref={_content}
      className={styles.trackCanvas}
      style={{
        width: imageSize[0],
        height: imageSize[1]
      }}
    >
      <img className={styles.trackCanvasImage} src={trackImage} />
      {loading &&
        <Wrapper className={styles.loadingWrapper}>
          <Loading size={30} />
        </Wrapper>
      }
    </div>
  )
};

/**
 * 绘制方法
 * @param canvas HTMLCanvasElement
 * @param bottomImage 底图信息
 * @param layout 绘制布局信息
 * @param color 颜色信息
 * @param track 歌曲信息
 * @param artists 演奏者
 * @param album 封面图
 */
export const renderTrack = async (
  canvas: fabric.Canvas,
  makeWidth: number,
  makeHeight: number,
  track: SpotifyTrack,
  layoutChildren: SpotifyLayoutChildren,
  color: SpotifyColor,
  artistsValue?: string,
  album?: string,
  lineTextValue?: string,
  textAreaValue?: string,
  catchError: boolean = false,
) => {

  const width = 300;
  canvas.clear();
  // 设置宽高
  canvas.setDimensions({
    width,
    height: makeHeight * width / makeWidth
  });

  let scale = width / makeWidth;

  /** left top 增加偏移量 */
  const _left = (value: number) => value * scale;
  const _top = (value: number) => value * scale;

  /** 绘制底图 */
  const _renderBottomImage = (image: string) => new Promise<void>(resolve => {
    loadImageFromURL(thumbnail7n(image, 400))
      .then(bottom => {
        bottom.scaleToWidth(width);
        canvas.setBackgroundImage(bottom, () => {
          canvas.renderAll();
          resolve();
        });
      });
  });

  /** 绘制文字 */
  const _renderTrackText = async (value: string, textCoord: SpotifyTextCoord) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);
      const _text = new fabric.Text(value, {
        left: _left(textCoord.left),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: color.value,
        originY: 'center',
      });

      // 如果文字居中
      if (textCoord.textAlign === 'center')
        _text.set({
          left: _left(textCoord.left + textCoord.width / 2),
          originX: 'center',
          textAlign: 'center'
        });

      const { width = 0, height = 0 } = _text;
      _text.scale(Math.min(
        (textCoord.width * scale) / width,
        (textCoord.height * scale) / height
      ));

      canvas.add(_text);

    } catch {
      if (catchError)
        throw Error();
    }
  }

  /** 绘制文字 */
  const _renderTrackNameText = async (value: string, textCoord: SpotifyTextCoord) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);

      const _text = new fabric.Text(value, {
        left: _left(textCoord.left),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: color.value,
        originY: 'center',
      });

      // 如果文字居中
      if (textCoord.textAlign === 'center')
        _text.set({
          left: _left(textCoord.left + textCoord.width / 2),
          originX: 'center',
          textAlign: 'center'
        });

      const { width = 0, height = 0 } = _text;
      _text.scale(Math.min(
        (textCoord.width * scale) / width,
        (textCoord.height * scale) / height
      ));

      canvas.add(_text);

    } catch {
      if (catchError)
        throw Error();
    }
  }

  /** 绘制直线刻字 */
  const _renderTrackLineText = async (value: string, textCoord: SpotifyTrackLineText) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);
      const _text = new fabric.Text(value, {
        left: _left(textCoord.left + textCoord.width / 2),
        top: _top(textCoord.top + textCoord.height / 2),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: color.value,
        originX: 'center',
        originY: 'center',
      });

      const { width = 0, height = 0 } = _text;
      _text.scale(Math.min(
        (textCoord.width * scale) / width,
        (textCoord.height * scale) / height
      ));

      canvas.add(_text);

    } catch {
      if (catchError)
        throw Error();
    }
  }

  /** 绘制直线刻字 */
  const _renderTrackTextArea = async (value: string, textCoord: SpotifyTrackLineText) => {
    try {
      // 加载字体
      await loadFont(textCoord.font.name);
      const _text = new fabric.Text(value, {
        left: _left(textCoord.left),
        top: _top(textCoord.top),
        fontSize: textCoord.fontSize * scale,
        fontFamily: textCoord.font.name,
        fill: color.value
      });

      const { width = 0, height = 0 } = _text;
      _text.scale(Math.min(Math.min(
        (textCoord.width * scale) / width,
        (textCoord.height * scale) / height
      ), 1));

      canvas.add(_text);

    } catch {
      if (catchError)
        throw Error();
    }
  }

  const getAlbumImage = async (image: string, maskImage: string) => {
    // 结合ai图
    const [ _image, _maskImage ] = await Promise.all([
      loadHTMLImage(image),
      loadHTMLImage(maskImage)
    ]);

    const canvas = document.createElement('canvas');
    const maskCanvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = canvas.width * _maskImage.height / _maskImage.width;

    maskCanvas.width = canvas.width;
    maskCanvas.height = canvas.height;

    const ctx = canvas.getContext('2d');
    const maskCtx = maskCanvas.getContext('2d');

    const scale = Math.min(_image.width / canvas.width, _image.height / canvas.height);
    ctx?.drawImage(
      _image,
      (_image.width - canvas.width * scale) / 2,
      (_image.height - canvas.height * scale) / 2,
      canvas.width * scale,
      canvas.height * scale,
      0,
      0,
      canvas.width,
      canvas.height
    );
    maskCtx?.drawImage(_maskImage, 0, 0, _maskImage.width, _maskImage.height, 0, 0, maskCanvas.width, maskCanvas.height);


    if (ctx && maskCtx) {
      // 像素比对
      let x, y, left, top, right, bottom, width, height;
      const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const maskPixels = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
      const l = maskPixels.data.length;
      const ltr = maskPixels.data[0], ltg = maskPixels.data[1],
        ltb = maskPixels.data[2], lta = maskPixels.data[3];

      for (let i = 0; i < l; i += 4) {
        if (!(ltr === maskPixels.data[i] && ltg === maskPixels.data[i + 1] && ltb === maskPixels.data[i + 2] && lta === maskPixels.data[i + 3])) {
          x = (i / 4) % canvas.width;
          y = ~~((i / 4) / canvas.width);
          // 获取上下左右坐标
          if (top === undefined) top = y; // 上
          if (left === undefined) left = x; // 左
          else if (x < left) left = x;
          if (right === undefined) right = x; // 右
          else if (right < x) right = x;
          if (bottom === undefined) bottom = y; // 下
          else if (bottom < y) bottom = y;
        } else {
          pixels.data[i + 3] = 0;
        }
      }
      left = left || 0,
      top = top || 0,
      height = (bottom || 0)  - top,
      width = (right || 0) - left;
      ctx.putImageData(pixels, 0, 0);
      const trimmed = ctx.getImageData(left, top, width, height);

      // 重新绘制像素点
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = width;
      canvas.height = height;
      ctx.putImageData(trimmed, 0, 0);
    }
    const result = await canvasToBlobURL(canvas);
    canvas.width = canvas.height = 0;
    return result;
  }

  /** 绘制图片 */
  const _renderTrackAlbumImage = async (image: string, left: number, top: number, width: number, height: number, maskImage: string): Promise<fabric.Object | undefined> => {
    try {
      const _imageURL = await getAlbumImage(image, maskImage);
      const _image = await loadImageFromURL(_imageURL);
      const _width = width * scale,
        _height = height * scale;

      const {
        width: imgWidth = 0,
        height: imgHeight = 0
      } = _image;

      _image.set({
        left: _left(left + width / 2),
        top: _top(top + height / 2),
        originX: 'center',
        originY: 'center'
      });

      _image.scale(Math.max(
        _width / imgWidth,
        _height / imgHeight
      ));

      canvas.add(_image);
      return _image;

    } catch {
      if (catchError)
        throw Error();
      return;
    }
  }

  /** 绘制图片 */
  const _renderTrackImage = async (image: string, left: number, top: number, width: number, height: number): Promise<fabric.Object | undefined> => {
    try {
      const _image = await loadImageFromURL(image);
      const _width = width * scale,
        _height = height * scale;

      const {
        width: imgWidth = 0,
        height: imgHeight = 0
      } = _image;

      _image.set({
        left: _left(left + width / 2),
        top: _top(top + height / 2),
        originX: 'center',
        originY: 'center'
      });

      _image.scale(Math.max(
        _width / imgWidth,
        _height / imgHeight
      ));

      canvas.add(_image);
      return _image;

    } catch {
      if (catchError)
        throw Error();
      return;
    }
  }

  /** 绘制进度条 */
  const _renderTrackTrail = async (coord: SpotifyTrackCoord) => {
    try {
      let { left, top, width, height, time } = coord;

      left = _left(left);
      top = _top(top);
      width *= scale,
      height *= scale;

      // 绘制底层进度条
      const trail = new fabric.Rect({
        left,
        top,
        width,
        height,
        fill: color.value,
        rx: height / 2,
        ry: height / 2,
      });

      // 绘制激活部分
      const played = new fabric.Circle({
        left: left + width * 0.2,
        top: top + height / 2,
        radius: height,
        originX: 'center',
        originY: 'center',
        fill: color.value,
      });

      canvas.add(trail);
      canvas.add(played);

      /** 绘制时间进度 */
      const _renderTrackTime = async (value: string, left: number, originX: 'left' | 'right' = 'left') => {
        const _text = new fabric.Text(value, {
          left,
          top: _top(time.top),
          fontSize: time.fontSize * scale,
          fontFamily: time.font.name,
          fill: color.value,
          originX
        });

        canvas.add(_text);
      }

      // 加载字体
      await loadFont(time.font.name);

      _renderTrackTime(dayjs(track.duration_ms * 0.2).format('mm:ss'), left);
      _renderTrackTime(`-${dayjs(track.duration_ms * 0.8).format('mm:ss')}`, left + width, 'right');

    } catch {
      if (catchError)
        throw Error();
    }
  }

  /** 绘制 Spotify Code */
  const _renderSpotifyCode = async (color: 'black' | 'white', coord: SpotifyCodeCoord) => {
    try {
      /** 记载spotify svg code */
      const _loadSVGFromURL = (code: string) => new Promise<fabric.Group | fabric.Object>(resolve => (
        fabric.loadSVGFromURL(code, objects => {
          objects.forEach(item => {
            item.set({
              fill: color
            });
          });
          objects[0].fill = 'transparent';
          resolve(fabric.util.groupSVGElements(objects));
        })
      ));
      // 获取 SpotifyCode
      const _code = await _loadSVGFromURL(`${spotifyCodeHost}/svg/000000/white/640/${track.uri}`);
      const { left, top, width, height } = coord;
      _code.set({
        left: _left(left + width / 2),
        top: _top(top + height / 2),
        originX: 'center',
        originY: 'center',
        angle: coord.angle,
      }).scaleToWidth(coord.width * scale);

      canvas.add(_code);
    } catch {
      if (catchError)
        throw Error();
    }
  }

  const { albumCoord, heartCoord, controlCoord, trackCoord, spotifyCodeCoord, nameCoord, artistCoord, lineTextCoord, textAreaCoord } = layoutChildren.layout;

  const queue = [];
  // 如果存在封面
  albumCoord && queue.push(_renderTrackAlbumImage(album || track.album, albumCoord.left, albumCoord.top, albumCoord.width, albumCoord.height, albumCoord.maskImage));

  // 如果存在❤
  heartCoord && queue.push(_renderTrackImage(heartCoord.image, heartCoord.left, heartCoord.top, heartCoord.width, heartCoord.height));

  // 如果存在控制器
  controlCoord && queue.push(_renderTrackImage(color.controlImage, controlCoord.left, controlCoord.top, controlCoord.width, controlCoord.height));

  // 如果存在进度条
  trackCoord && queue.push(_renderTrackTrail(trackCoord));

  // 如果存在歌名
  nameCoord && queue.push(_renderTrackNameText(track.name, nameCoord));

  // 如果存在演奏者
  artistCoord && queue.push(_renderTrackText(artistsValue || track.artists, artistCoord));

  // 如果存在刻字信息 & 刻字值
  if (lineTextCoord && lineTextValue)
    queue.push(_renderTrackLineText(lineTextValue, lineTextCoord));

  // 如果存在多行刻字信息 & 刻字值
  if (textAreaCoord && textAreaValue)
    queue.push(_renderTrackTextArea(textAreaValue, textAreaCoord));

  // 如果存在底图
  if (layoutChildren.bottomImage)
    queue.push(_renderBottomImage(layoutChildren.bottomImage));

  queue.push(_renderSpotifyCode(color.value, spotifyCodeCoord));

  // 并行绘制
  const [ _album ] = await Promise.all<any>(queue);
  _album?.sendToBack();

  canvas.renderAll();
  return await canvasToBlobURL(canvas.getElement());
}

export default TrackCanvas;
