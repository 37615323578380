import React, { useState, ReactElement, useEffect } from 'react';
import classnames from 'classnames';
import { Icon, Drawer } from '@/components';
import { i18n, priceFormat } from '@/utils';
import { VipIncrement } from '../increment';
import styles from './style.less';

interface VipProps {
  className?: string;
  /** 产品信息 */
  productContent?: ReactElement;
  /** vip 增值服务 */
  vipIncrement?: VipIncrement;
  /** 钱符号 */
  currencySymbol?: string;
  /** 是否展示遮罩 */
  mask?: boolean;
  /** 关闭回调 */
  onClose: () => void;
  /** 确认回调 */
  onConfirm: (product?: VipIncrement) => void;
  /** 谷歌分析 */
  gtag: any;
}

const Vip: React.FC<VipProps> = ({ className, productContent, vipIncrement, currencySymbol, mask, onClose, onConfirm, gtag }) => {

  const [ isVip, setIsVip ] = useState<boolean>(false);

  useEffect(() => {
    if (isVip)
      gtag('event', 'event_select_vip');
  }, [ isVip ])

  return (
    <div className={classnames(className, styles.vip, {
      [styles.vipMask]: mask,
    })}>
      <Drawer
        mask={mask}
        className={classnames(styles.vipContent, {
          [styles.vipContentNoMask]: !mask,
        })}
      >
        <div className={styles.close} onClick={onClose}>
          <Icon type='close' color='#000000' size={30} />
        </div>
        <div className={styles.productInfo}>
          {productContent}
        </div>
        <div className={styles.vipButtonLayout}>
          <div
            className={classnames(styles.vipButton, {
              [styles.vipButtonSelected]: isVip,
            })}
            onClick={() => setIsVip(!isVip)}
          >
            <div className={styles.vipCheckbox}>
              <div
                className={classnames(styles.vipCheckboxInner, {
                  [styles.vipCheckboxSelected]: isVip,
                })}
              >
                {isVip && <Icon type='confirm' color='#fbe4a4' size={18} />}
              </div>
            </div>
            <div className={styles.vipButtonContent}>
              {vipIncrement?.price ?
                `${i18n.format('increment.vip.service')} +${priceFormat(currencySymbol!, vipIncrement?.price)}` :
                `${i18n.format('increment.vip.service')} ${i18n.format('modules.global.free')}}`
              }
            </div>
            <div className={styles.vipIcon}>
              <img src={require('@/assets/vip-crown.png')} />
            </div>
            <i className={styles.light}></i>
          </div>
        </div>
        <div className={styles.addCart}>
          <div className={styles.addCartTips}>
            {i18n.format('increment.vip.tip')}
          </div>
          <div
            className={classnames('add-cart-button', styles.addCartButton)}
            onClick={() => onConfirm(isVip ? vipIncrement : undefined)}
          >
            {i18n.format('modules.global.add.cart')}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Vip;
