import React from 'react';
import classnames from 'classnames';
import styles from './style.less';

export interface WrapperProps {
  /** 定位方式 */
  position?: 'absolute' | 'fixed',
  /** 样式 */
  className?: string;
  /** 点击事件 */
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  /** style */
  style?: React.CSSProperties;
}


const Wrapper: React.FC<WrapperProps> = ({ position = "absolute", className, onClick, style, children }) => (
  <div
    className={classnames(styles.wrapper, className)}
    style={{
      ...style,
      position
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Wrapper;
