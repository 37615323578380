import {
  SpotifyCoord,
  SpotifyTextCoord,
  SpotifyTrackCoord,
  SpotifyCodeCoord,
  SpotifyHeartCoord,
  SpotifyLineText
} from '../spotify-code';

export interface SpotifyAlbumCoord extends SpotifyCoord {
  aiImage: string;
  maskImage: string;
}

export interface SpotifyLayout {
  sku: string;
  makeWidth: number;
  makeHeight: number;
  previewImage: string;
  makeDpi?: number;
  bottomImage?: string;
  albumCoord?: SpotifyAlbumCoord;
  nameCoord?: SpotifyTextCoord;
  artistCoord?: SpotifyTextCoord;
  trackCoord?: SpotifyTrackCoord;
  controlCoord?: SpotifyCoord;
  spotifyCodeCoord: SpotifyCodeCoord;
  heartCoord?: SpotifyHeartCoord;
  lineTextCoord?: SpotifyLineText;
}

export enum ProductType {
  ColorLamp, // 彩色变换灯 黑色底座
  WoodLamp, // 木质白光夜灯
  WoodFrostingLamp, // 木质磨砂白光夜灯
  ColorBluetoothLamp, // 蓝牙音箱底座彩色变换灯
}
