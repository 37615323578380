export default {
  'modules.global.uoload.photo': '写真を添付する', // 上传图片
  'modules.global.upload': '添付', // 上传
  'modules.global.order': 'レジに進む', // 结算
  'modules.global.add.photo': '写真を添付する', // 添加图片
  'modules.global.checkout': 'レジに進む', // 结账
  'modules.global.complete': '完了', // 完成

  'modules.global.confirm': '確認する', // 确认
  'modules.global.processing': '画像を処理している', // 确认
  'modules.global.confirm.design': 'デザインを確認する', // 确认设计
  'modules.global.free': '無料', // 免费
  'modules.global.add.text': '名入れ文字を追加する', // 添加刻字
  'modules.global.preview.confirm': '写真をアップロードしている', // 正常上传图片
  'modules.global.preview.tips': '当社のデザイナーは、より良い印刷効果のためにあなたのデザインを調整します。', // 预览提示
  'modules.global.add.cart': 'この商品をカートに入れる', // 加车
  'modules.global.previous': '前に戻る', // 上一步
  'modules.global.next': '次へ進む', // 下一步

  'modules.global.preview': '仕上がりイメージ', // 预览
  'modules.global.loading': '読み込み中', // 正在加载
  'modules.global.uploading': 'アップロードしている', // 正在上传
  'modules.global.delete': '削除', // 删除

  'modules.global.ai.crop.complete': 'はい、決定!', // 切图完成
  'modules.global.ai.croping.photo': 'AI写真をトリミング中', // ai切图中

  // 'components.oop.title': '', // OOP
  // 'components.oop.description': '', // 请根据以下条件完成定制内容
  // 'components.oop.submit': '', // 我会改变的

  'components.footer.zoom.in': '大きくする', // 放大
  'components.footer.zoom.out': '小さくする', // 缩小
  'components.footer.rotate.left': '左に回転', // 向左旋转
  'components.footer.rotate.right': '右に回転', // 向右旋转
  'components.cropper.title': '画像を切り取る', // 裁剪照片

  'components.edit.title': '編集モード', // 编辑模式
  'components.edit.type': 'タイプ', // 类别
  'components.edit.apply': 'アプリ', // 应用
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': '以下の条件に従ってカスタマイズされたコンテンツを完了してください.', // 请根据以下条件完成定制内容
  'components.oop.submit': '変更します', // 我会改变的
  'components.guide.title': '説明書', // 使用说明
  'components.edit.font.text': '名入れ文字', // 文字内容
  'components.edit.font.family': 'フォント', // 字体
  'components.edit.font.size': 'フォントサイズ', // 文字大小
  'components.edit.font.color': 'フォントカラー', // 文字颜色
  'components.edit.font.shadow.color': 'フォントの影の色', // 文字阴影颜色
  'components.cropper.back': '戻る', // 返回
  'components.cropper.crop': '切り取る', // 裁剪
  'components.cropper.move': '置き換える', // 移动

  'modules.mug.font.validator': '編集してください ', // 请编辑
  'modules.bottom.background': '背景', // 背景
  'modules.bottom.layout': 'レイアウト', // 图层
  'modules.bottom.help': 'ヘルプ', // 帮助
  'modules.bottom.next': '次へ', // 下一步
  'modules.bottom.confirm': '確認する', //确认
  'modules.bottom.layout.mug.tip': '現在のオプションをタップして編集できます', // 图层操作提示文案
   'modules.bottom.layout.close': '閉じる', //关闭
  'modules.bottom.operator.top': 'トップ', // 置顶
  'modules.bottom.operator.up': 'アップ', // 上一层
  'modules.bottom.operator.down': 'ダウン', // 下一层
  'modules.bottom.operator.bottom': '一番下', // 置底
  'modules.error.load.font': 'フォント記録に失敗しました', // 字体加载失败 请重试
  'modules.error.upload.result': 'アップロードに失敗しました', // 上传失败 请重试

  // 影刻
  // 'modules.sketch.cropper.tips': '', // 请保证照片中人像边缘尽量清晰可见
  // 'modules.sketch.title': '', // 3d预览
  // 'modules.sketch.request.stage0': '', // 正在上传照片…
  // 'modules.sketch.request.stage1': '', // 正在将人像从背景抠出…
  // 'modules.sketch.request.stage2': '', // 正在加入滤镜效果…
  // 'modules.sketch.request.stage3': '', // 正在去色处理…
  // 'modules.sketch.request.stage4': '', // 请稍后, 预览结果即将呈现…
  // 'modules.sketch.request.accomplish': '', // 影刻处理完成, 请点击预览查看
  // 'modules.sketch.request.error': '', // 预览处理失败，您可以跳过直接加车
  // 'modules.sketch.try.again': '', // 重新预览
  // 'modules.sketch.add.text.placeholder': '', // 刻字提示文案
  // 'modules.sketch.footer.preview': '', // 预览并加车
  // 'modules.sketch.footer.skip': '', // 跳过预览
  // 'modules.sketch.accomplish.later': '', // 稍后再说
  // 'modules.sketch.accomplish.prompt': '', // 稍后再说

  // 撕拉盒子
  'modules.box.step': '', // 步数
  'modules.box.side': '', // 面
  'modules.box.image.upload': '', // 上传
  'modules.box.image.change': '', // 切换
  'modules.box.step1.title': '', // 添加图片
  'modules.box.step1.tips': '', // 添加图片说明文案
  'modules.box.step2.title': '', // 添加纸屑
  'modules.box.step2.tips': '', // 添加纸屑说明文案
  'modules.box.oop.title': '', // 添加纸屑说明文案
  'modules.box.oop.description': '', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': '画像のトリミング', // 编辑照片
  'modules.photo.film.tips.sort': '画像長押しをして並べ替える可能', // 长按拖动图片来排序
  'modules.photo.film.tips.count': '写真 $value 枚をアップロードする必要があります', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'せめて写真 $value 枚をアップロードしてください', // 最少需要传多少张图
  'modules.photo.film.change': '変更する', // 更换
  'modules.photo.film.cropper': '切り取る', // 裁剪
  'modules.photo.film.sort': '並べ替え', // 排序
  'modules.photo.film.delete': '削除', // 删除
  'modules.photo.film.preview.tips': 'アップロードが完了するとデザイン確認ボタンをクリックしてカートに入れてください', // 上传完成后点击确认设计按钮来加车

  // 照片书
  'modules.photo.book.design.type': 'デザインタイプ', // 设计类型
  'modules.photo.book.design.type.auto': 'デザイナーにお任せ', // 我们的设计师为你设计
  'modules.photo.book.design.type.user': '自分でデザインする', // 我自己设计
  'modules.photo.book.auto.design': 'オリジナルデザイン', // 我们的设计师设计
  'modules.photo.book.need.images': 'フォトブック', // 照片书需要
  'modules.photo.book.photos': 'フォト', // 照片
  'modules.photo.book.pages': 'ページ', // 页数
  'modules.photo.book.add.photos': '写真を添付する', // 添加照片
  'modules.photo.book.photos.received': '写真を$value枚受け取りました', // 我们已经收到了 $value 张照片
  'modules.photo.book.photos.upload': '写真が$value 枚添付する必要がある', // 需要 $value 张照片
  'modules.photo.book.pages.included': 'ページ数が含まれている', // 含有页数
  'modules.photo.book.manage.photos': '写真を管理する', // 管理图片
  'modules.photo.book.title.size': 'サイズ', // 图书尺寸
  'modules.photo.book.title.page': 'ページ', // 图书页数
  'modules.photo.book.auto.typesetting.tips': '＄valueの写真はまたレイアウトされていません、残りの写真は自動的にレイアウトいただけますでしょうか。', // 您还有 $value 个照片位没完成，剩余的是否使用自动排版帮您完成？
  'modules.photo.book.auto.typesetting': '自動でレイアウトする', // 使用自动排版
  'modules.photo.book.continue.edit': '編集を続ける', // 继续编辑
  'modules.photo.book.method.typesetting.tips': '既存の$valueの写真をそのまま進めるか、新しい写真を再アップロードしてレイアウトするか', // 使用您现有的 $value 张照片或重新上传新的照片进行排版？
  'modules.photo.book.uploaded.typesetting': 'アップロードしたの写真をそのまま進める', // 使用已经上传的图片
  'modules.photo.book.new.typesetting': '画像追加', // 上传新的照片
  'modules.photo.book.pagination.front.cover': '表紙', // 封面
  'modules.photo.book.pagination.inset': '中ページ', // 里面
  'modules.photo.book.pagination.last.page': '最終ページ', // 最后一页
  'modules.photo.book.pagination.back.cover': '裏表紙', // 封底
  'modules.photo.book.tabbar.image': '写真', // 照片
  'modules.photo.book.tabbar.layout': 'レイアウト', // 布局
  'modules.photo.book.tabbar.background': '背景', // 底图
  'modules.photo.book.tabbar.text': '文字', // 文字
  'modules.photo.book.before.enter.page': 'ページを選択すると、編集できます', // 进入 $value 前，请选择要在哪个页面进行编辑？
  'modules.photo.book.front.cover.tips': '画像をページに引く', // 拖拽图片到照片位
  'modules.photo.book.back.cover.tips': '仕上がりを確認', // 去看看效果啊
  'modules.photo.book.uncomplete.tips': '$valueの写真はまた入力されていません、編集を続けてください', // 还有 $value 个照片位 未完成，返回
  'modules.photo.book.exit.tips': 'また編集されていません、画面を閉じるよろしいですか', // 您还没有编辑完，确定要退出吗

  // 简版照片书
  'modules.photo.book.deleted.page': '削除されたページ', // 已删除页面
  'modules.photo.book.delete.page': 'ページを削除する', // 删除页面
  'modules.photo.book.change.layout': 'レイアウトを変更する', // 更改布局
  'modules.photo.book.add.photo.page': '写真ページを追加する', // 添加照片页面
  'modules.photo.book.add.compose.page': 'コラージュページを追加する', // 添加拼贴页面
  'modules.photo.book.validator': 'あと$valueページを添付すると購入できる', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': '写真を置き換える', // 替换照片
  'modules.photo.book.remove.image': '削除', // 移除
  'modules.photo.book.page': 'ページ', // 页
  'modules.photo.book.type': 'マテリアル', // 图书材质
  'modules.photo.book.size': 'サイズ', // 图书尺寸
  'modules.photo.book.order.qty': '数量', // 数量
  'modules.photo.book.order.total': 'トータル', // 总计
  'modules.photo.book.warning.title': 'ご注意', // 警告
  'modules.photo.book.warning.cover.title': 'フォトブックにタイトルを追加する', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': 'クリックするとフォトブックにタイトルが追加される', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': '低解像度の画像が見つかりました', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': '低解像度の画像が見つかりました', // 打印出来可能比较模糊
  'modules.photo.book.add.title': 'タイトルの追加', // 添加标题
  'modules.photo.book.zuploader.subtitle': '少なくとも$value枚写真が必要', // 至少需要 value 张照片
  'modules.photo.book.guide.title': '操作ガイド', // 操作引导
  'modules.photo.book.guide.position': '画像を長押しして順序を調整できる', // 长按图片可调整顺序
  'modules.photo.book.guide.layout': '画像スタイルを切り替えるにはサイドアイコンをクリックしてください', // 点击侧边更多可切换图片样式
  'modules.global.cropper': '切り取り', // 裁剪
  'modules.photo.book.guide.button': '了解しました。ありがとうございます。', // 好的 我知道了

  // 照片墙
  'modules.photo.wall.choose.frame': 'フレームを選択する', // 选择边框
  'modules.photo.wall.choose.style': 'スタイルを選択する', // 选择样式
  'modules.photo.wall.zoom.pinch': '移動してズームインする', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'スクロールして拡大する', // 滚动放大
  'modules.photo.wall.choose.design': 'デザインを選択する', // 选择设计
  'modules.photo.wall.choose.design.tips': 'カスタマイズする壁を選択する', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'フレーム', // 框
  'modules.photo.wall.image.list.title': '写真を追加すると、デザイン オプションが表示されます。', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': '私の写真', // 我的图片
  'modules.photo.wall.zuploader.subtitle': '最大$value枚の写真を添付できます',  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': '最大$value枚の写真が制作できますが、伸びた分は省略されることご了承ください',  // 我们最多支持 $value 张照片，超出的将被忽略
  'modules.global.replace': '置換', // 替换
  'modules.global.zoom.pinch': 'ピンチとズーム', // 移动和放大
  'modules.global.done': '完了', // 完成

  // zuploader
  'plugin.zuploader.from.album': 'アルバムから選択する', // 从相册中选择
  'plugin.zuploader.from.camera': '写真を撮る', // 拍照
  'plugin.zuploader.from.social': 'オンラインサービスから選択', // 从在线服务中选择
  'plugin.zuploader.wait.upload': 'アップロードを待機中', // 等待上传
  'plugin.zuploader.choose.source': '画像を選択する', // 选择照片来源
  'plugin.zuploader.choose.image.title': '画像を添付しました', // 已上传照片
  'plugin.zuploader.choose.image.choose': '写真を選択する', // 选择照片
  'plugin.zuploader.choose.image.range': '写真$value選択する', // 选择 $value 照片


  // lowQuality
  'plugin.low.quality.title': '低解像度画質', // 低图像质量
  'plugin.low.quality.tips': '小さくてぼやける恐れがあります', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'とにかく維持する', // 不管怎样都保留
  'plugin.low.quality.remove': '注文から削除', // 从订单中删除

  // 多图模板
  'modules.template.tips': '画像のところをクリックすると画像を変更したりトリミングしたりできます', // 点击图片区域可以更换图片或裁剪
  'modules.template.validator.images': '何枚の写真をアップロードする必要があります', // 你需要传n张图
  'modules.template.validator.texts': '名入れ文字がいくつまで入力する必要があります', // 你需要输入n段文字
  'modules.template.bottom.template': 'テンプレート', //  模版
  'modules.template.default.texts': '文字', // 文字

  // 增值产品-relatedProduct
  'increment.related.product.title': 'ギフトとしてスーパーサプライズパッケージを選択してください', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'いえ 結構です', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': '通常の製作期間を縮めることが出来る優先出力サービスです。', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'VIPサービス', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': 'ネットワーク エラーです', // 网络错误
  'modules.new.sock.ai.crop.error': 'AIカットエラー!', // 切图服务错误
  'modules.new.sock.replace.photo': '写真を置き換える', // 替换照片
  'modules.new.sock.crop.myself': '自分でトリミングする', // 自己切图
  'modules.new.sock.try.again': 'もう一度やり直してください', // 重试
  'modules.new.sock.edit.avatar': 'アバターの編集', // 编辑头像
  'modules.new.sock.add.line.text.title': '刻み文字を追加しますか?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': '顧客の80%は、完璧な贈り物をするためにパーソナライズされた刻み文字を追加することを好みます!', // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': '刻み文字の追加', // 添加文字
  'modules.new.sock.without.text': '刻み文字なし', // 不刻字
  'modules.new.sock.color': '色', // 颜色
  'modules.new.sock.size': 'サイズ', // 尺寸
  'modules.new.sock.sock': '靴下', // 袜子
  'modules.new.sock.share.title': '今すぐシェアすると取得', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'カスタムソーシャルプロファイル画像。', // 你的社交新头像
  'modules.new.sock.free.download': '無料ダウンロード', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'カスタムモバイル壁紙。', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'カスタムPCの壁紙。', // 你的电脑新壁纸
  'modules.new.sock.discount': '割引', // 折扣
  'modules.new.sock.discount.price': '$valueオフ', // 立减 $value 块
  'modules.new.sock.share.now': '今すぐシェア', // 立刻分享
  'modules.new.sock.no.thanks': 'いいえ、シェアしません', // 不分享
  'modules.new.sock.shared.title': 'おめでとうございます！', // 恭喜
  'modules.new.sock.download': 'ダウンロード', // 下载
  'modules.new.sock.generating.image': 'シェアイメージの生成', // 正在生成分享图
  'modules.new.sock.total': '合計', // 总计

   // Spotify Code
  'modules.spotify.code.search.hot': 'お気に入りのジャンル', // 热门搜索
  'modules.spotify.code.search.reuslt': '検索結果', // 搜索结果
  'modules.spotify.code.placeholder': 'アーティスト、曲、ポッドキャストなど', // 输入你的歌曲
  'modules.spotify.code.album.upload': '画像を添付してアップロードする', // 上传你的图片
  'modules.spotify.code.album.track': 'Spotifyアルバム画像を選択する', // 使用相册封面
  'modules.spotify.code.change.color': '本体の色を選択する', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'アーティストは自由にカスタマイズする', // 你可以自己定制演奏者
  "modules.spotify.code.multi.upload": "写真をアップロードする", // 上传你的照片
}
