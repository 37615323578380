import React from 'react';
import Notification from 'rmc-notification';
import Icon from '../icon';
import styles from './style.less';

let _instance: any;
let _needHide: boolean;
const prefixCls = 'sunzi-toast';

const getInstance = (
  callback: (notification: any) => void,
) => {
  (Notification as any).newInstance(
    {
      prefixCls,
      style: {}, // clear rmc-notification default style
      transitionName: 'am-fade',
      className: styles.sunziToastContent,
      getContainer() {
        const root = document.getElementById('sunzi-app')
        const wrapper = document.createElement('div');
        root?.append(wrapper)
        return wrapper;
      }
    },
    (notification: any) => callback && callback(notification),
  );
}

const notice = (
  content: React.ReactNode,
  type: string,
  duration: number = 3,
  onClose: (() => void) | undefined | null,
) => {
  getInstance(notification => {
    _needHide = false;

    if (!notification)
      return

    if (_instance) {
      _instance.destroy();
      _instance = null;
    }

    if (_needHide) {
      notification.destroy();
      _needHide = false;
      return
    }

    _instance = notification;

    const renderContent = (type: string) => {
      switch(type) {
        case 'error':
          return (
            <div className="sunzi-toast-error" role="alert" aria-live="assertive">
              <Icon type="close" size={54} color="#fff" />
              <div>{content}</div>
            </div>
          );
        default:
          return (
            <div className="sunzi-toast-info" role="alert" aria-live="assertive">
              <div>{content}</div>
            </div>
          );
      }
    }

    notification.notice({
      duration,
      style: {},
      content: renderContent(type),
      closable: false,
      onClose() {
        if (onClose) {
          onClose();
        }
        notification.destroy();
        notification = null;
        _instance = null;
      },
    });
  });
}

export default {
  error(
    content: React.ReactNode,
    duration?: number,
    onClose?: () => void,
  ) {
    return notice(content, 'error', duration, onClose);
  },
  info(
    content: React.ReactNode,
    duration?: number,
    onClose?: () => void,
  ) {
    return notice(content, 'info', duration, onClose);
  },
  hide() {
    if (_instance) {
      _instance.destroy();
      _instance = null;
    } else {
      _needHide = true;
    }
  },
}