// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sunzi__preview-30Zac .sunzi__bottom-im-6C {\n  position: relative;\n  width: 100%;\n  margin: -60px 0 20px 0;\n  background-size: cover;\n}\n.sunzi__preview-30Zac .sunzi__bottom-content-1ZFXB {\n  padding: 40px 20px;\n  display: flex;\n  align-items: flex-end;\n}\n.sunzi__preview-30Zac .sunzi__bottom-im-6C::before {\n  display: block;\n  padding-top: 100%;\n  content: '';\n}\n", ""]);
// Exports
exports.locals = {
	"preview": "sunzi__preview-30Zac",
	"bottom": "sunzi__bottom-im-6C",
	"bottom-content": "sunzi__bottom-content-1ZFXB",
	"bottomContent": "sunzi__bottom-content-1ZFXB"
};
module.exports = exports;
