import { SpotifyColor } from './modules/spotify-code/spotify-code';
/** fabric 控制器图标 */
export const fabricConfig = {
  control: {
    mirror: 'https://sunzi7n.imaiyuan.com/fabric/icon/mirror.png',
    scale: 'https://sunzi7n.imaiyuan.com/fabric/icon/scale.png',
    edit: 'https://sunzi7n.imaiyuan.com/fabric/icon/edit.png',
    rotate: 'https://sunzi7n.imaiyuan.com/fabric/icon/rotate.png',
    layout: 'https://sunzi7n.imaiyuan.com/fabric/icon/layout.png',
    camera: 'https://sunzi7n.imaiyuan.com/fabric/icon/camera.png',
    deleteImage: 'https://sunzi7n.imaiyuan.com/fabric/icon/delete-1.png'
  },
  attribute: { // 属性值 (在375px下)
    borderColor: '#fff',
    borderScaleFactor: 2.4,
    cornerSize: 26,
    textPadding: 6
  }
};
/** 杯子的3d模型 */
export const mugFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/models/mugs2.fbx';
/** 保温杯 */
export const vacuumCupFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/vacuumcup13-14.fbx';
/** 塑料杯 */
export const plasticCupFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/vacuumcup10-6.fbx';
/** 抱枕模型 */
export const pillowFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-pillow-6.fbx';
/** 正方形油画模型 */
export const canvasFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-frame-14.fbx';
/** 横版油画模型 */
export const hCanvasFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-frame-13.fbx';
/** 竖版油画模型 */
export const vCanvasFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-frame-15.fbx';
/** 竖版毯子模型 */
export const vBlanketFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-blanket-7.fbx';
/** 小圆盘模型 */
export const discFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-circle-3.fbx';
/** 袜子的3d模型 */
export const sockFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/models/b-socks-7.fbx';
/** 平角内裤的3d模型 */
export const boxerFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-boxer-9.fbx';
/** 月球灯的3d模型 */
export const moonLampFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-moonlamp-24.fbx';
/** 情侣杯子的3d模型 */
export const mugCoupleFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-mugs-1.fbx';
/** 惊喜盒子3d模型 */
export const boxFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-cubebox-13.fbx';
/** 旧无限魔方3d模型 */
export const oldInfiniteCubeFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/mofang58.fbx';
/** 无限魔方3d模型 */
export const infiniteCubeFBX = 'https://sunzi7n.imaiyuan.com/infinite-cube/mofang-38.fbx';
/** 无限魔方3d盒子模型 */
export const infiniteCubeBoxFBX = 'https://sunzi7n.imaiyuan.com/infinite-cube/box-53.fbx';
/** 拼图盒子3d模型 */
export const puzzleBoxFBX = 'https://spic.qn.cdn.imaiyuan.com/assets/model/b-puzzle-box-11.fbx';
/** 新袜子3d模型 */
export const newSockFBX = 'https://sunzi7n.myuxc.com/assets/07041.fbx';
/** 请求美图的超时时间 */
export const meituTimeout = 30000;
/** 美图的api_key */
export const meituAppKey = 'ULP6c6ojJQ9fOxoShn6ZmfjE7JpoP_y4';
/** 美图的secret_key */
export const meituSecretKey = 'H9w511uOBYSGHPZPw5_LZhlVzan_4Shy';
/** 请求阿里影刻的超时时间 */
export const aliTimeout = 30000;
/** 阿里的app_code */
export const aliAppCode = 'f3e875b6e28b4520826c9544979eed02';
/** 请求马卡龙的超时时间 */
export const versaTimeout = 30000;
/** 迈远人脸抠图地址 */
export const myFacutHost = 'https://crop.myuxc.com';
/** 迈远人脸抠图加速地址 */
// export const myFacutCdnHost = 'https://static.crop.myuxc.com';
/** 迈远超时时间 */
export const myFacutTimeout = 30000;
/** 图片模糊检测workerjs地址 */
export const imageFuzzyWorkerJs = 'https://sunzi7n.imaiyuan.com/core/worker/image.fuzzy.js';
/** 图片模糊检测-模糊阀值 */
export const FuzzyThreshold = 0.77;
/** fileStackHost */
export const fileStackHost = 'https://cdn.filestackcontent.com'
/** avatar配置 */
export const avatarConfig = {
  username: '8O7QgzTvGzQXmLDmzZTs5r4p7ji811bF7Btgqke7',
  password: 'UpU5fQiFzzXceRXKsW2aJxWiLbyL8weeaXIw1LaBfK4UHK3FlsYNEEZpQ0egNYNXBPSrAWKesXSG37d7A88YwsxPDY6ig6SlVyaNYVwAKeWhFXpgk1DHaMdBfnhDdLf6',
  playerid: '67ae09fa-e5f8-49b3-a6db-d523a6517849'
};
/** 图片卡通化请求地址 */
export const imageCartoonHost = 'https://ai.soufeel.com/cartoon';
/** cutout.pro apiKey */
export const cutoutApiKey = 'd2f265360ace4b0fa09fcbfc514510aa';
/** cutout.pro抠图请求地址 */
export const cutoutHost = 'https://www.cutout.pro/api/v1/matting';
/** 文字转语音地址 */
export const textToSpeechHost = 'https://ai.soufeel.com/google/textToSpeech';
/** 文字转语音配置 */
export const textToSpeechConfig: any = {
  en: {
    male: 'en-US-Standard-B',
    female: 'en-US-Standard-C',
  },
  de: {
    male: 'de-DE-Standard-B',
    female: 'de-DE-Standard-A',
  },
  fr: {
    male: 'fr-FR-Standard-B',
    female: 'fr-FR-Standard-A',
  },
  tw: {
    male: 'cmn-TW-Standard-B',
    female: 'cmn-TW-Standard-A',
  },
  es: {
    female: 'es-ES-Standard-A',
  },
  br: {
    male: 'pt-PT-Standard-B',
    female: 'pt-PT-Standard-A',
  },
  it: {
    male: 'it-IT-Standard-C',
    female: 'it-IT-Standard-B',
  },
  jp: {
    male: 'ja-JP-Standard-D',
    female: 'ja-JP-Standard-B',
  },
}

/** face++ 配置 */
export const faceppConfig = {
  baseUrl: 'https://api-us.faceplusplus.com',
  apiKey: 'cdQSZCKTyTh7mTEvv1Pi-gz7q6lSOJJ7',
  apiSecret: 'HoFsxnPuiFYjDvlQNi-vAD-PsHddnHb0',
}

/** spotify token 地址 */
export const spotifyTokenHost = '';
/** spotify 搜索地址 */
export const spotifySearchHost = '';
/** spotify code 前缀地址 */
export const spotifyCodeHost = 'https://scannables.scdn.co/uri/plain';

