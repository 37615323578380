import React, { Component } from 'react';
import Animal from './animal';
import Book from './book';
import Threed from './threed';
import Roller from './roller';
import Line from './line';
import Facut from './facut';
import styles from './style.less';
export interface LoadingProps {
  size?: number;
  color?: string;
}

class Loading extends Component<LoadingProps> {

  static Animal: typeof Animal;
  static Book: typeof Book;
  static Threed: typeof Threed;
  static Roller: typeof Roller;
  static Line: typeof Line;
  static Facut: typeof Facut;

  render() {
    const { size = 42, color = '#fff' } = this.props;
    return (
      <div className={styles.loading} style={{ width: size, height: size }}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" fill="none" stroke={color}></circle>
        </svg>
      </div>
    )
  }
}

Loading.Animal = Animal;
Loading.Book = Book;
Loading.Threed = Threed;
Loading.Roller = Roller;
Loading.Line = Line;
Loading.Facut = Facut;

export default Loading;
