import React, { useState, useRef, useMemo, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { fabric } from 'sunzi-fabric';
import { useLocal } from '@/hooks';
import { VipIncrement } from '@/increment/increment';
import { Vip } from '@/increment';
import { Layout, Wrapper, Loading } from '@/components';
import { i18n, createObjectURL, uuid, uploadKey, upload7nObjectURL, getToken7n, canvasToBlobURL, removeEmojis } from '@/utils';
import { SpotifyCodeProps, narrowTrackName } from '..';
import { SpotifyColor, SpotifyTrack } from '../spotify-code';
import { SpotifyLayout, SpotifyOuput } from './wallet';
import spotifySearch from '../request';
import Icon from '../icon';
import TrackItem from '../track-item';
import TrackCanvas from './track-canvas';
import Croppie from '../croppie';
import styles from '../style.less';

interface TwoSidedProps extends Omit<SpotifyCodeProps, 'layout' | 'bottomImage' | 'bindProduct' | 'onConfirm'> {
  layout: SpotifyLayout;
  onConfirm: (
    effect: string,
    data: SpotifyOuput,
    vipIncrement?: VipIncrement
  ) => void;
}

const TwoSided: React.FC<TwoSidedProps> = ({
  shop,
  theme = {
    r: 29,
    g: 184,
    b: 84
  },
  gtag,
  price,
  colors,
  defaultColor,
  layout,
  recommendTracks = [],
  defaultCustomAlbum,
  customArtistsTitle,
  increment,
  onClose,
  onConfirm
}) => {
  // 设置国际化
  useLocal(shop.language);
  /** 共享canvas，避免重复创建canvas */
  const _canvas = useRef<fabric.Canvas[]>([]);
  /** 输入框实例 */
  const _input = useRef<HTMLInputElement>(null);
  /** 搜索列表实例 */
  const _list = useRef<HTMLDivElement>(null);
  /** 搜索结果游标 */
  const _pagination = useRef<number>(0);
  /** 搜索结果总数 */
  const _total = useRef<number>(0);
  /** 搜索结果key*/
  const _listKey = useRef<string>();
  /** 搜索内容 */
  const [ searchValue, setSearchValue ] = useState<string>('');
  /** 搜索加载状态 */
  const [ searchLoading, setSearchLoading ] = useState<boolean>();
  /** 搜索加载更多状态 */
  const [ searchLoadmore, setSearchLoadmore ] = useState<boolean>();
  /** 搜索加载状态 */
  const [ tracks, setTracks ] = useState<SpotifyTrack[]>();
  /** 滚动渐变 */
  const [ scrollGradient, setScrollGradient ] = useState<boolean[]>([]);
  /** 当前颜色值 */
  const [ currentColor, setCurrentColor ] = useState<SpotifyColor>(defaultColor ?? colors[0]);
  /** 当前激活的歌曲 */
  const [ currentTrack, setCurrentTrack ] = useState<SpotifyTrack>();
  /** 封面是否自定义 */
  const [ customAlbum, setCustomAlbum ] = useState<boolean>(!!layout.children.find(item => item.albumCoord));
  /** 自定义演奏者 */
  const [ artistsValue, setArtistsValue ] = useState<string>('');
  /** 封面图 */
  const [ customAlbumImage, setCustomAlbumImage ] = useState<string>();
  /** 封面裁剪原图 */
  const [ croppieImage, setCroppieImage ] = useState<string>();
  /** 预览显隐 */
  const [ previewVisible, setPreviewVisible ] = useState<boolean>(false);
  /** 上传显隐 */
  const [ uploadVisible, setUploadVisible ] = useState<boolean>(false);
  /** VIP显隐 */
  const [ vipIncrementVisible, setVipIncrementVisible ] = useState<boolean>(false);
  /** 歌曲加载状态 */
  const [ trackLoading, setTrackLoading ] = useState<boolean>(false);
  // 判断封面和演奏者
  const trackVisible = !!layout.children.find(item => item.albumCoord || item.artistCoord);

  useEffect(() => {
    _canvas.current = [
      new fabric.Canvas(document.createElement('canvas')),
      new fabric.Canvas(document.createElement('canvas')),
      new fabric.Canvas(document.createElement('canvas')),
      new fabric.Canvas(document.createElement('canvas')),
    ];
  }, []);

  useEffect(() => {
    setCurrentColor(defaultColor ?? colors[0]);
    gtag('event', 'tap_change_color');
  }, [ colors, defaultColor ]);

  useEffect(() => {
    gtag('event', 'tap_custom_album', {
      'event_label': customAlbum
    });
  }, [ customAlbum ]);

  useEffect(() => {
    if (currentTrack) {
      setArtistsValue(currentTrack.artists);
      // 如果layout没有封面和演奏者
      setPreviewVisible(!trackVisible);
      gtag('event', 'tap_spotify_track', {
        'event_label': currentTrack.name
      });
    }
  }, [ currentTrack ]);

  /** 监听搜索按键 */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13)
      handleSearch();
  }

  /** 搜索监听 */
  const handleSearch = async () => {
    setSearchLoading(true);
    _input.current?.blur();
    // 每次搜索生成一个新的key，强制dom更新
    _listKey.current = uuid();
    await _spotifySearch();
    gtag('event', 'tap_search_track', {
      'event_label': searchValue
    });
    setSearchLoading(false);
  }

  /** spotify api 搜索 */
  const _spotifySearch = async (pagination: number = 0) =>
    spotifySearch(searchValue, pagination)
      .then(response => {
        if (response.status && response.data) {
          setTracks(pagination === 0 ?
            response.data.tracks :
            (tracks || []).concat(response.data.tracks)
          );
          _total.current = response.data.total;
          _pagination.current = pagination;
          if (_list.current) {
            const { scrollHeight, offsetHeight } = _list.current;
            setScrollGradient([false, scrollHeight > offsetHeight]);
          }
        } else {
          gtag('event', 'tap_search_error');
        }
      });

  /** 搜索列表滚动监听 */
  const handleTrackListScroll = async () => {
    if (_list.current) {
      const { scrollTop, scrollHeight, offsetHeight } = _list.current;
      setScrollGradient([scrollTop > 100, scrollHeight - scrollTop - offsetHeight > 100]);
      if (scrollHeight - scrollTop - offsetHeight <= 80
        && !searchLoadmore
        && (tracks?.length ?? 0) < _total.current
      ) {
        setSearchLoadmore(true);
        await _spotifySearch(_pagination.current + 1);
        setSearchLoadmore(false);
      }
    }
  }

  /** content渲染 */
  const renderContent = () => {
    if (tracks === undefined)
      return (
        <div className={classnames(styles.trackListWrapper, styles.recommend)}>
          <div className={styles.recommendTitle}>🔥{i18n.format('modules.spotify.code.search.hot')}</div>
          <div className={styles.trackList}>
            {recommendTracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => setCurrentTrack({ ...item, name: narrowTrackName(item.name) })}
              />
            ))}
          </div>
        </div>
      );
    else if (tracks.length > 0)
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div
            ref={_list}
            key={_listKey.current}
            className={styles.trackList}
            onScrollCapture={handleTrackListScroll}
          >
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => setCurrentTrack({ ...item, name: narrowTrackName(item.name) })}
              />
            ))}
            {tracks.length < _total.current &&
              <div className={styles.loadmore}>
                <Loading size={20} />
              </div>
            }
          </div>
          <Wrapper className={styles.trackListGradient}>
            <div className={classnames({
              [styles.topGradient]: scrollGradient[0]
            })} />
            <div className={classnames({
              [styles.bottomGradient]: scrollGradient[1]
            })} />
          </Wrapper>
        </div>
      );
    else
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div className={classnames(styles.trackList, styles.trackListEmpty)}>
            <img src={require('@/assets/search-no-result.png')} />
            <span>{i18n.format('modules.spotify.code.search.no.result')}</span>
          </div>
        </div>
      );
  }

  /** 上传自定义封面监听 */
  const handleCustomAlbumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files)
      setCroppieImage(createObjectURL(event.target.files[0]));
  }

  /** 裁剪确认 */
  const handleCroppieConfirm = (result: string) => {
    setCustomAlbumImage(result);
    setCroppieImage(undefined);
    setPreviewVisible(true);
  }

  /** 预览关闭 */
  const handlePreviewClose = () => {
    setPreviewVisible(false);
    setCustomAlbumImage(undefined);
  }


  /** 生成并上传专辑图 */
  const _uploadTrackAlbum = async (token: string, key: string) => {
    if (!customAlbumImage) return currentTrack?.album ?? '';
    // 上传到七牛
    const result = await upload7nObjectURL({
      token,
      key,
      blobURL: customAlbumImage,
    });
    return host7n['prefix-na0'] + result.key;
  }

  /** 生成并上传生产图 */
  const _uploadTrack = async (token: string, key: string) => {
    if (!currentTrack) return '';
    // TODO
    let canvas = _canvas.current[0];
    // 生成生产图
    const blobURL = await canvasToBlobURL(canvas.getElement());
    // 上传到七牛
    const result = await upload7nObjectURL({ blobURL, token, key });
    return host7n['prefix-na0'] + result.key;
  }

  /** 加车校验 */
  const handleValidatorConfirm = (type: number) => {
    if (type === 1) // 如果存在VIP
      if (increment?.vip)
        setVipIncrementVisible(true);
      else handleValidatorConfirm(type - 1);
    else
      handleConfirm();
  }

  /** 确认回调监听 */
  const handleConfirm = async (vipIncrement?: VipIncrement) => {
    if (!currentTrack) return;
    try {
      setVipIncrementVisible(false);
      setUploadVisible(true);
      const token = await getToken7n();
      const key = uploadKey('soptify-code');
      // 构建上传队列
      const [ effect, source ] = await Promise.all<string>([
        _uploadTrack(token, `${key}.png`),
        _uploadTrackAlbum(token, `${key}-source.png`)
      ]);

      onConfirm(
        effect,
        {
          source,
          color: currentColor,
          track: {
            ...currentTrack,
            artists: artistsValue || currentTrack.artists
          },
          layout
        },
        vipIncrement
      );
      handleClose();
    } catch {
      alert(i18n.format('modules.error.upload.result'));
      setUploadVisible(false);
    }
  }

  /** 关闭回调监听 */
  const handleClose = () => {
    onClose();
    setCurrentTrack(undefined);
    setUploadVisible(false);
    setPreviewVisible(false);
    setCustomAlbumImage(undefined);
    setCustomAlbum(!!layout.children.find(item => item.albumCoord));
  }

  const innerFonts = useMemo(() => {
    const innerFonts: string[] = [];
    layout.children.forEach(item => {
      if (item.nameCoord)
        innerFonts.push(`
          @font-face {
            font-weight: normal;
            font-style: normal;
            font-family: ${item.nameCoord.font.name};
            src: url('${item.nameCoord.font.url}');
          }`);
      if (item.artistCoord)
        innerFonts.push(`
          @font-face {
            font-weight: normal;
            font-style: normal;
            font-family: ${item.artistCoord.font.name};
            src: url('${item.artistCoord.font.url}');
          }`);
      if (item.trackCoord)
        innerFonts.push(`
          @font-face {
            font-weight: normal;
            font-style: normal;
            font-family: ${item.trackCoord.time.font.name};
            src: url('${item.trackCoord.time.font.url}');
          }`);
    });
    return innerFonts;
  }, [ layout ]);

  const albumLayout = useMemo(() => {
    return layout.children.find(item => !!item.albumCoord);
  }, [ layout ]);

  return (
    <Layout
      theme={theme}
      plugins={[]}
    >
      <style>{innerFonts.join('\n')}</style>
      <div className="sunzi-spotify-code">
        <div className={classnames(styles.inputWrapper, {
          [styles.inputFocusWrapper]: searchValue
        })}>
          <input
            ref={_input}
            value={searchValue}
            placeholder={i18n.format('modules.spotify.code.placeholder')}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          <div className={styles.searchWrapper}>
            <Icon type="search" />
          </div>
          <div
            className={styles.confirm}
            onClick={handleSearch}
          >
            {searchLoading ?
              <Loading size={24} /> :
              <div className={styles.iconWrapper}>
                <Icon type="search" />
              </div>
            }
          </div>
        </div>
        { renderContent() }
        <div
          className={classnames(styles.closeWrapper, {
            [styles.closeWrapperTrack]: !!tracks?.length
          })}
          onClick={handleClose}
        >
          <Icon type="arrow-left" />
        </div>
        {currentTrack &&
          <Wrapper className={styles.trackWrapper}>
            {albumLayout &&
              <div className={styles.trackContent}>
                <div
                  className={styles.trackCanvasWrapper}
                  onClick={() => setCustomAlbum(true)}
                >
                  <TrackCanvas
                    canvas={_canvas.current[2]}
                    track={currentTrack}
                    layout={albumLayout}
                    color={currentColor}
                    album={defaultCustomAlbum}
                  />
                  <div className={styles.title}>{i18n.format('modules.spotify.code.album.upload')}</div>
                  <div className={styles.radioWrapper}>
                    <div className={classnames(styles.radio, {
                      [styles.radioActive]: customAlbum
                    })}>
                      <Icon type="confirm" />
                    </div>
                  </div>
                </div>
                <div
                  className={styles.trackCanvasWrapper}
                  onClick={() => setCustomAlbum(false)}
                >
                  <TrackCanvas
                    canvas={_canvas.current[3]}
                    track={currentTrack}
                    layout={albumLayout}
                    color={currentColor}
                  />
                  <div className={styles.title}>{i18n.format('modules.spotify.code.album.track')}</div>
                  <div className={styles.radioWrapper}>
                    <div className={classnames(styles.radio, {
                      [styles.radioActive]: !customAlbum
                    })}>
                      <Icon type="confirm" />
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={styles.trackArtist}>
              <div className={styles.title}>{customArtistsTitle ?? i18n.format('modules.spotify.code.custom.artists')}</div>
              <div className={styles.inputWrapper}>
                <input
                  value={artistsValue}
                  onChange={e => setArtistsValue(removeEmojis(e.target.value))}
                />
              </div>
            </div>
            <div className={styles.trackFooter}>
              <div
                className={styles.previous}
                onClick={() => setCurrentTrack(undefined)}
              >
                <Icon type="arrow-left" />
              </div>
              {customAlbum ? (
                <div className={classnames(styles.next, {
                  [styles.nextDisabled]: !artistsValue
                })}>
                  <span>{i18n.format('modules.spotify.code.album.upload')}</span>
                  <Icon type="arrow-right" />
                  <input type="file" onChange={handleCustomAlbumChange} accept="image/*" />
                </div>
              ) : (
                <div
                  className={classnames(styles.next, {
                    [styles.nextDisabled]: !artistsValue
                  })}
                  onClick={() => setPreviewVisible(true)}
                >
                  <span>{i18n.format('modules.global.next')}</span>
                  <Icon type="arrow-right" />
                </div>
              )}
            </div>
          </Wrapper>
        }
        {croppieImage &&
          <Croppie
            image={croppieImage}
            albumCoord={albumLayout?.albumCoord}
            onClose={() => setCroppieImage(undefined)}
            onConfirm={handleCroppieConfirm}
          />
        }
        {previewVisible &&
          <Wrapper className={classnames(styles.previewWrapper, styles.previewTwoSided)}>
            <Wrapper className={styles.bottomGradient} />
            <div
              className={styles.previousWrapper}
              onClick={handlePreviewClose}
            >
              <Icon type="arrow-left" />
            </div>
            <div className={styles.content}>
            {layout.children.map((item, index) => {
              return currentTrack && (
                <div
                  key={index}
                  className={styles.trackCanvasWrapper}
                >
                  <TrackCanvas
                    canvas={_canvas.current[index]}
                    track={currentTrack}
                    layout={item}
                    color={currentColor}
                    artists={artistsValue}
                    album={customAlbumImage}
                    asyncLoading={setTrackLoading}
                  />
                </div>
              );
            })}
            </div>
            {colors.length > 1 &&
              <Fragment>
                <div className={styles.title}>{i18n.format('modules.spotify.code.change.color')}</div>
                <div className={classnames(styles.colorWrapper, {
                  [styles.colorDisabled]: trackLoading
                })}>
                  {colors.map((item, index) => (
                    <div
                      key={index}
                      className={classnames(styles.color, {
                        [styles.colorActive]: item === currentColor
                      })}
                      onClick={() => setCurrentColor(item)}
                    >
                      <div
                        className={styles.colorContent}
                        style={{
                          backgroundColor: item.value
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Fragment>
            }
            <div
              className={classnames(styles.confirm, {
                [styles.confirmDisabled]: trackLoading
              })}
              onClick={() => handleValidatorConfirm(1)}
            >
              <div className={styles.iconWrapper}>
                <Icon type="add-cart" />
              </div>
              <span className={styles.price}>{price}</span>
              <span>{i18n.format('modules.global.add.cart').toLowerCase()}</span>
            </div>
          </Wrapper>
        }
        {vipIncrementVisible &&
          <Wrapper className={styles.vipIncrementWrapper}>
            <Vip
              mask
              className={styles.vipIncrement}
              vipIncrement={increment?.vip}
              currencySymbol={shop.currencySymbol}
              onClose={() => setVipIncrementVisible(false)}
              onConfirm={handleConfirm}
              gtag={gtag}
            />
          </Wrapper>
        }
        {uploadVisible &&
          <Wrapper className={styles.uploadWrapper}>
            <Loading.Line />
          </Wrapper>
        }
      </div>
    </Layout>
  )
}

export default TwoSided;
