import React, { useState, useRef, Fragment } from 'react';
import classnames from 'classnames';
import { Wrapper, Loading } from '@/components';
import { i18n, uuid } from '@/utils';
import Icon from '../../icon';
import { SongLyric } from '../lyric';
import { searchSongLyric } from './request';
import TrackItem from "./track-item";
import styles from '../../style.less';


export interface SearchLyricProps {
  className?: string;
  onClose: () => void;
  onTrackConfirm: (track: SongLyric) => void;
}

const SearchLyric: React.FC<SearchLyricProps> = ({
  className,
  onClose,
  onTrackConfirm
}) => {
   /** 输入框实例 */
   const _input = useRef<HTMLInputElement>(null);
   /** 搜索列表实例 */
   const _list = useRef<HTMLDivElement>(null);
   /** 搜索结果游标 */
   const _pagination = useRef<number>(0);
   /** 搜索结果总数 */
   const _total = useRef<number>(0);
   /** 搜索结果key*/
   const _listKey = useRef<string>();
  /** 搜索内容 */
  const [searchValue, setSearchValue] = useState<string>("");
  /** 搜索加载状态 */
  const [searchLoading, setSearchLoading] = useState<boolean>();
  /** 搜索加载状态 */
  const [tracks, setTracks] = useState<SongLyric[]>();
  /** 滚动渐变 */
  const [scrollGradient, setScrollGradient] = useState<boolean[]>([]);
  /** 搜索加载更多状态 */
  const [searchLoadmore, setSearchLoadmore] = useState<boolean>();

  /** 监听搜索按键 */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) handleSearch();
  };

  /** 搜索监听 */
  const handleSearch = async () => {
    setSearchLoading(true);
    _input.current?.blur();
    // 每次搜索生成一个新的key，强制dom更新
    _listKey.current = uuid();
    await _spotifySearch();
    setSearchLoading(false);
  };

  /** spotify api 搜索 */
  const _spotifySearch = async (pagination: number = 0) =>
    searchSongLyric(searchValue, pagination).then((response) => {
      if (response.status && response.data) {
        setTracks(
          pagination === 0
            ? response.data.map(item => item.result)
            : (tracks || []).concat(response.data.map(item => item.result))
        );
        _pagination.current = pagination;
        if (_list.current) {
          const { scrollHeight, offsetHeight } = _list.current;
          setScrollGradient([false, scrollHeight > offsetHeight]);
        }
      }
    });

    /** content渲染 */
  const renderContent = () => {
    if (tracks === undefined)
      return null;
    else if (tracks.length > 0)
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format("modules.spotify.code.search.reuslt")}
          </div>
          <div
            ref={_list}
            key={_listKey.current}
            className={styles.trackList}
            onScrollCapture={handleTrackListScroll}
          >
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => onTrackConfirm(item)}
              />
            ))}
            {tracks.length < _total.current && (
              <div className={styles.loadmore}>
                <Loading size={20} />
              </div>
            )}
          </div>
          <Wrapper className={styles.trackListGradient}>
            <div
              className={classnames({
                [styles.topGradient]: scrollGradient[0],
              })}
            />
            <div
              className={classnames({
                [styles.bottomGradient]: scrollGradient[1],
              })}
            />
          </Wrapper>
        </div>
      );
    else
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format("modules.spotify.code.search.reuslt")}
          </div>
          <div className={classnames(styles.trackList, styles.trackListEmpty)}>
            <img src={require("@/assets/search-no-result.png")} />
            <span>{i18n.format("modules.spotify.code.search.no.result")}</span>
          </div>
        </div>
      );
  };

  /** 搜索列表滚动监听 */
  const handleTrackListScroll = async () => {
    if (_list.current) {
      const { scrollTop, scrollHeight, offsetHeight } = _list.current;
      setScrollGradient([
        scrollTop > 100,
        scrollHeight - scrollTop - offsetHeight > 100,
      ]);
      if (
        scrollHeight - scrollTop - offsetHeight <= 80 &&
        !searchLoadmore &&
        (tracks?.length ?? 0) < _total.current
      ) {
        setSearchLoadmore(true);
        await _spotifySearch(_pagination.current + 1);
        setSearchLoadmore(false);
      }
    }
  };

  return (
    <Wrapper className={className}>
      <div
        className={classnames(styles.inputWrapper, {
          [styles.inputFocusWrapper]: searchValue,
        })}
      >
        <input
          ref={_input}
          value={searchValue}
          placeholder={i18n.format("modules.spotify.code.placeholder")}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleInputKeyDown}
        />
        <div className={styles.searchWrapper}>
          <Icon type="search" />
        </div>
        <div className={styles.confirm} onClick={handleSearch}>
          {searchLoading ? (
            <Loading size={24} />
          ) : (
            <div className={styles.iconWrapper}>
              <Icon type="search" />
            </div>
          )}
        </div>
      </div>
      {renderContent()}
      <div
        className={classnames(styles.closeWrapper, {
          [styles.closeWrapperTrack]: !!tracks?.length,
        })}
        onClick={onClose}
      >
        <Icon type="arrow-left" />
      </div>
    </Wrapper>
  )
};

export default SearchLyric;
