import React, { useRef, useEffect, useState } from 'react';
import Croppie from 'croppie';
import { createObjectURL, i18n, loadHTMLImage } from '@/utils';
import { Wrapper } from '@/components';
import Icon from '../icon';
import { SpotifyCodeCoord } from '../spotify-code';
import 'croppie/croppie.css';
import styles from '../style.less';

interface CroppieProps {
  /** 封面图片坐标 */
  coord?: SpotifyCodeCoord;
  /** 裁剪图 */
  image: string;
  /** 关闭回调 */
  onClose(): void;
  /** 确认回调 */
  onConfirm(result: string, source: string): void;
}

const Component:  React.FC<CroppieProps> = ({ image, coord, onClose, onConfirm }) => {
  const _content = useRef<HTMLDivElement>(null);
  const _element = useRef<HTMLDivElement>(null);
  const _croppie = useRef<Croppie>();
  const _source = useRef<string>(image);


  /** 裁剪初始化 */
  useEffect(() => {
    _setCroppieContent();
  }, []);

  /** 裁剪初始化 */
  const _setCroppieContent = async () => {
    if (_content.current && _element.current && coord) {
      const { clientWidth, clientHeight } = _content.current;
      const boundaryWidth = clientWidth;
      const boundaryHeight = clientHeight - 100;
      const _image = await loadHTMLImage(_source.current);

      let width = boundaryWidth - 80;
      let height = width * coord.height / coord.width;
      let imageWidth = _image.width;
      let imageHeight = imageWidth * coord.height / coord.width;

      if ((boundaryWidth - 80) / (boundaryHeight - 160) > coord.width / coord.height) {
        height = boundaryHeight - 160;
        width = height * coord.width / coord.height;
      }

      if (_image.width / _image.height > coord.width / coord.height) {
        imageHeight = _image.height;
        imageWidth = imageHeight * coord.width / coord.height;
      }

      // croppie 初始化
      _croppie.current = new Croppie(_element.current, {
        viewport: { width, height },
        boundary: { width: boundaryWidth, height: boundaryHeight },
        showZoomer: true,
        enableOrientation: true
      });
      const left = (_image.width - imageWidth) / 2;
      const top = (_image.height - imageHeight) / 2;
      _croppie.current?.bind({
        url: image,
        points: [ left, top, left + imageWidth, top + imageHeight ]
      });
    }
  }

  const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || [];
    _source.current = createObjectURL(files[0])
    _croppie.current?.bind({
      url: _source.current,
    });
  }

  /** 确认回调 */
  const handleConfirm = async () => {
    if (coord) {
      let width = 500;
      let height = width * coord.height / coord.width;
      if (width > height) {
        height = 500;
        width = height * coord.width / coord.height;
      }

      const blob = await _croppie.current?.result({
        type: 'blob',
        size: { width, height }
      });
      if (blob)
        onConfirm(createObjectURL(blob), _source.current);
    }
  }

  return (
    <Wrapper
      className={styles.croppieWrapper}
    >
      <div
        ref={_content}
        className={styles.croppie}
      >
        <div className={styles.croppieContent}>
          <div ref={_element} />
          <div className={styles.croppieContentReplace}>
            <div className={styles.replace}>
              <div className={styles.iconWrapper}>
                <Icon type="image" size={24}></Icon>
              </div>
              {i18n.format('modules.global.replace')}
              <input type="file" multiple onChange={handleSourceChange} />
            </div>
          </div>
        </div>
        <div className={styles.croppieFooter}>
          <div
            className={styles.previous}
            onClick={onClose}
          >
            <Icon type="arrow-left" />
          </div>
          <div
            className={styles.next}
            onClick={handleConfirm}
          >
            <span>{i18n.format('modules.global.confirm')}</span>
            <Icon type="arrow-right" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Component;
