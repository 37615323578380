import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.less';

export interface LoadingProps {
  finish?: boolean;
  bottomImage?: string;
}


class Loading extends Component<LoadingProps> {
  constructor(props: LoadingProps) {
    super(props);
    this.state = {
      finish: false
    }
  }

  render() {
    const { bottomImage, finish } = this.props;
    return (
      <div className={styles.rollerLoading}>
        <img className={styles.brace} src={bottomImage} />
        <div className={classnames(styles.paint, finish && styles.paintFinish)}>
          <div className={styles.paintItem}>
            <div className={styles.source} style={{ backgroundImage: `url(${bottomImage})` }} />
          </div>
          <div className={styles.paintItem}>
            <div className={styles.source} style={{ backgroundImage: `url(${bottomImage})` }} />
          </div>
          <div className={styles.paintItem}>
            <div className={styles.source} style={{ backgroundImage: `url(${bottomImage})` }} />
          </div>
        </div>
        <div className={classnames(styles.roller, finish && styles.rollerFinish)}>
          <div className={styles.imageWrapper}>
            <img src={require('@/assets/roller.png')} />
          </div>
        </div>
      </div>
    )
  }
};

export default Loading;