import React from "react";
import classnames from 'classnames';
import { Wrapper } from "@/components";
import styles from "./style.less";


const Preview: React.FC<{
  className?: string;
  trackCanvas: React.ReactNode;
}> = ({
  className,
  trackCanvas,
  children
}) => {
  return (
    <Wrapper className={classnames(styles.preview, className)}>
      <div
        className={styles.bottom}
        style={{
          backgroundImage: 'url(https://assets-sunzi.myuxc.com/spotify-code/cbs455-bottom.png)'
        }}
      >
        <Wrapper className={styles.bottomContent}>
          {trackCanvas}
        </Wrapper>
      </div>
      {children}
    </Wrapper>
  )
};

export default Preview;
