// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sunzi__loading-lTTlV svg {\n  width: 100%;\n  height: 100%;\n  animation: sunzi__loading-rotate-3A3mz 2s linear infinite;\n}\n.sunzi__loading-lTTlV svg > circle {\n  animation: sunzi__loading-dash-252wp 1.5s ease-in-out infinite;\n  stroke-dasharray: 90, 150;\n  stroke-dashoffset: 0;\n  stroke-width: 6;\n  stroke-linecap: round;\n}\n@keyframes sunzi__loading-rotate-3A3mz {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n@keyframes sunzi__loading-dash-252wp {\n  0% {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -40px;\n  }\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -120px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "sunzi__loading-lTTlV",
	"loading-rotate": "sunzi__loading-rotate-3A3mz",
	"loadingRotate": "sunzi__loading-rotate-3A3mz",
	"loading-dash": "sunzi__loading-dash-252wp",
	"loadingDash": "sunzi__loading-dash-252wp"
};
module.exports = exports;
