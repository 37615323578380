import React, { useMemo, useState, useEffect } from 'react';
import { Wrapper } from '@/components';
import { SpotifyLayout } from './post-card';
import classnames from 'classnames';
import Icon from '../icon';
import styles from './style.less';
import { i18n } from '@/utils';


interface TemplateProps {
  /** 默认的布局 */
  defaultLayout: SpotifyLayout;
  /** 布局数组 */
  layouts: SpotifyLayout[];
  /** 确认回调 */
  onConfirm(layout: SpotifyLayout): void;
  /** 关闭回调 */
  onClose(): void;
}

const Component:  React.FC<TemplateProps> = ({ layouts, defaultLayout, onClose, onConfirm }) => {

  const [ activeLayout, setActiveLayout ] = useState<SpotifyLayout>(defaultLayout);

  useEffect(() => {
    setActiveLayout(defaultLayout);
  }, [ defaultLayout ])

  const _layouts = useMemo(() => {
    return [ defaultLayout ].concat(layouts.filter(item => item.sku !== defaultLayout.sku));
  }, [ layouts, defaultLayout ]);

  return (
    <Wrapper className={styles.templateWrapper}>
      <div className={styles.tempalte}>
        <div className={styles.title}>{i18n.format('modules.spotify.code.template.title')}</div>
        <div className={styles.templateList}>
          {_layouts.map((item, index) => (
            <div
              key={index}
              className={classnames(styles.template, {
                [styles.templateActive]: activeLayout.sku === item.sku
              })}
              onClick={() => setActiveLayout(item)}
            >
              <div
                className={styles.templateContent}
                style={{
                  paddingTop: `${(item.makeHeight / (item.makeWidth * 2)) * 100}%`,
                  backgroundImage: `url(${item.previewImage})`
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tempalteFooter}>
        <div
          className={styles.previousWrapper}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={styles.confirm}
          onClick={() => onConfirm(activeLayout)}
        >
          <span>{i18n.format('modules.global.next')}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Component;
