import React, { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { Wrapper, Toast } from '@/components';
import { i18n } from '@/utils';
import { SpotifyTrack } from '../../spotify-code';
import Icon from '../../icon';
import { getSpotifyLyric, getSpotifyLyricById } from './request';
import SearchLyric from './search-lyric';
import HandleModify from './handle-modify';
import Loading from './loading';
import styles from './style.less';
import { SongLyric } from '../lyric';


export interface LyricContentProps {
  minLyricLines: number;
  maxLyricLines: number;
  /** 歌曲信息 */
  track: SpotifyTrack;
  /** 关闭回调 */
  onClose: () => void;
  /** 确认回调 */
  onConfirm: (lyrics: string[]) => void;
}

const LyricContent: React.FC<LyricContentProps> = ({
  minLyricLines,
  maxLyricLines,
  track,
  onClose,
  onConfirm
}) => {

  /** 路由栈 */
  const [ stacks, setStacks ] = useState<string[]>(['loading']);
  /** 歌词数据 */
  const [ lyrics, setLyrics ] = useState<string[]>([]);
  /** 歌词数据 */
  const [ activeLyrics, setActiveLyrics ] = useState<number[]>([]);

  useEffect(() => {
    getSpotifyLyric(track.name).then(({ status, data = [] }) => {
      if (status && data.length > 0) {
        setLyrics(data);
        setActiveLyrics(Array.from({ length: Math.min(minLyricLines, data.length) }).map((_, index) => index));
        setStacks(['lyrics']);
      } else {
        setStacks(['error']);
      }
    }).catch(() => {
      setStacks(['error']);
    });
  }, [ track ]);

  useEffect(() => {
    if (stacks.length === 0)
      onClose();
  }, [stacks]);

  /** 路由栈弹出 */
  const popStack = () => {
    setStacks(stacks.slice(0, stacks.length - 1))
  }

  /** 歌词选择变化监听 */
  const handleLyricChange = (index: number) => {
    if (activeLyrics.indexOf(index) > -1)
      setActiveLyrics(activeLyrics.filter(item => item !== index));
    else if (activeLyrics.length === maxLyricLines)
      Toast.info(i18n.format('modules.spotify.code.lyric.limit'));
    else
      setActiveLyrics([...activeLyrics, index]);
  }

  /** 歌词确认 */
  const handleTrackConfirm = async (track: SongLyric) => {
    try {
      setStacks([...stacks, 'loading']);
      const { status, data = [] } = await getSpotifyLyricById(track.id);
      if (status && data.length > 0) {
        setLyrics(data);
        setActiveLyrics(Array.from({ length: Math.min(minLyricLines, data.length) }).map((_, index) => index));
        setStacks([...stacks, 'lyrics']);
      } else {
        setStacks([...stacks, 'error']);
      }
    } catch {
      setStacks([...stacks, 'error']);
    }
  }

  const renderContent = (type: string) => {
    switch(type) {
      case 'loading':
        return <Loading onClose={popStack} />;
      case 'error':
        return (
          <Wrapper className={styles.lyricContentError}>
            <div className={styles.contentIcon}>
              <svg viewBox="0 0 100 100">
                <defs>
                  <path d="M27,8 L59.5275568,8 C63.5961343,8 67.4300658,9.90479051 69.8877398,13.147191 L80.360183,26.9634482 C82.0729675,29.2231187 83,31.9808132 83,34.8162571 L83,82 C83,87.5228475 78.5228475,92 73,92 L27,92 C21.4771525,92 17,87.5228475 17,82 L17,18 C17,12.4771525 21.4771525,8 27,8 Z" id="path-1"></path>
                  <filter x="-3.0%" y="-2.4%" width="106.1%" height="104.8%" filterUnits="objectBoundingBox" id="filter-2">
                      <feOffset dx="-4" dy="-4" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
                      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                      <feColorMatrix values="0 0 0 0 0.0581884617   0 0 0 0 0.629245924   0 0 0 0 0.259522823  0 0 0 0.232954545 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
                  </filter>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g>
                    <use fill="#1DB954" fillRule="evenodd" xlinkHref="#path-1"></use>
                    <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                  </g>
                  <path d="M59.0771195,20.1172543 L44.2395301,22.633596 C44.1912666,22.6405956 44.1464505,22.6580945 44.1016343,22.6720936 L43.5638407,22.6720936 C43.1984168,22.6720936 42.9088355,22.9695749 42.9088355,23.333552 L42.9088355,40.5524686 C42.3296731,40.2129899 41.6539837,40.0205021 40.9369254,40.0205021 C38.761619,40.0205021 37,41.8053898 37,44.010251 C37,46.2116125 38.7650664,48 40.9369254,48 C43.1122319,48 44.8738509,46.2151123 44.8738509,44.010251 C44.8738509,43.8492612 44.8635087,43.6917711 44.8462717,43.534281 C44.8669561,43.4712849 44.8738509,43.4082889 44.8738509,43.3382933 L44.8738509,29.3356743 L58.6599847,26.8683296 L58.6599847,36.5592198 C58.0808223,36.2197412 57.4051328,36.0272533 56.6880746,36.0272533 C54.5127681,36.0272533 52.7511491,37.812141 52.7511491,40.0170023 C52.7511491,42.2218635 54.5162155,44.0067512 56.6880746,44.0067512 C58.8599336,44.0067512 60.625,42.2218635 60.625,40.0170023 C60.625,39.8560124 60.6146578,39.6985223 60.5974208,39.5410322 C60.6181052,39.4780362 60.625,39.4150401 60.625,39.3520441 L60.625,20.7332156 C60.625,20.4077361 60.3905771,20.1347532 60.0837589,20.0787568 C59.9768897,20.0122609 59.8424413,19.9842627 59.6942033,20.0087612 L59.3460163,20.0682574 L59.3115424,20.0682574 C59.2391471,20.0682574 59.1564096,20.0857563 59.0771195,20.1172543 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M39.5,75 C40.8807119,75 42,76.1192881 42,77.5 C42,78.8807119 40.8807119,80 39.5,80 L28.5,80 C27.1192881,80 26,78.8807119 26,77.5 C26,76.1192881 27.1192881,75 28.5,75 L39.5,75 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M57.5,64 C58.8807119,64 60,65.1192881 60,66.5 C60,67.8807119 58.8807119,69 57.5,69 L28.5,69 C27.1192881,69 26,67.8807119 26,66.5 C26,65.1192881 27.1192881,64 28.5,64 L57.5,64 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M71.5,53 C72.8807119,53 74,54.1192881 74,55.5 C74,56.8807119 72.8807119,58 71.5,58 L28.5,58 C27.1192881,58 26,56.8807119 26,55.5 C26,54.1192881 27.1192881,53 28.5,53 L71.5,53 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <rect fill="#FB9F06" transform="translate(86.393994, 83.560660) rotate(-45.000000) translate(-86.393994, -83.560660) " x="82.3939935" y="74.5606602" width="8" height="18" rx="4"></rect>
                  <circle fill="#F8D801" cx="69" cy="65" r="25"></circle>
                  <circle fill="#FAF3BA" cx="69" cy="65" r="19"></circle>
                  <path d="M65.6109127,56.868272 L69.4995924,60.7565924 L73.3890873,56.868272 C74.5606602,55.6966991 76.4601551,55.6966991 77.631728,56.868272 C78.8033009,58.0398449 78.8033009,59.9393398 77.631728,61.1109127 L73.7425924,64.9995924 L77.631728,68.8890873 C78.8033009,70.0606602 78.8033009,71.9601551 77.631728,73.131728 C76.4601551,74.3033009 74.5606602,74.3033009 73.3890873,73.131728 L69.4995924,69.2425924 L65.6109127,73.131728 C64.4393398,74.3033009 62.5398449,74.3033009 61.368272,73.131728 C60.1966991,71.9601551 60.1966991,70.0606602 61.368272,68.8890873 L65.2565924,64.9995924 L61.368272,61.1109127 C60.1966991,59.9393398 60.1966991,58.0398449 61.368272,56.868272 C62.5398449,55.6966991 64.4393398,55.6966991 65.6109127,56.868272 Z" id="路径" fill="#FB9F06"></path>
                </g>
              </svg>
            </div>
            <div className={styles.contentTitle}>{i18n.format('modules.spotify.code.lyric.no.found')}</div>
            <div
              className={classnames(styles.contentButton, styles.active)}
              onClick={() => setStacks([...stacks, 'search'])}
            >
              <span>{i18n.format('modules.spotify.code.lyric.try.another')}</span>
            </div>
            <div
              className={styles.contentButton}
              onClick={() => setStacks([...stacks, 'handle'])}
            >
              <span>{i18n.format('modules.spotify.code.lyric.write.own')}</span>
            </div>
            <div className={styles.footer}>
              <div
                className={styles.previous}
                onClick={popStack}
              >
                <Icon type="arrow-left" />
              </div>
            </div>
          </Wrapper>
        );
      case 'modify':
        return (
          <Wrapper className={styles.lyricContentModify}>
            <div className={styles.contentIcon}>
              <svg viewBox="0 0 100 100">
                <defs>
                  <path d="M27,8 L59.5275568,8 C63.5961343,8 67.4300658,9.90479051 69.8877398,13.147191 L80.360183,26.9634482 C82.0729675,29.2231187 83,31.9808132 83,34.8162571 L83,82 C83,87.5228475 78.5228475,92 73,92 L27,92 C21.4771525,92 17,87.5228475 17,82 L17,18 C17,12.4771525 21.4771525,8 27,8 Z" id="path-1"></path>
                  <filter x="-3.0%" y="-2.4%" width="106.1%" height="104.8%" filterUnits="objectBoundingBox" id="filter-2">
                      <feOffset dx="-4" dy="-4" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
                      <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                      <feColorMatrix values="0 0 0 0 0.0581884617   0 0 0 0 0.629245924   0 0 0 0 0.259522823  0 0 0 0.232954545 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
                  </filter>
                </defs>
                <g id="Symbols-/-No-Result" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g>
                    <use fill="#1DB954" fillRule="evenodd" xlinkHref="#path-1"></use>
                    <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                  </g>
                  <path d="M59.0771195,20.1172543 L44.2395301,22.633596 C44.1912666,22.6405956 44.1464505,22.6580945 44.1016343,22.6720936 L43.5638407,22.6720936 C43.1984168,22.6720936 42.9088355,22.9695749 42.9088355,23.333552 L42.9088355,40.5524686 C42.3296731,40.2129899 41.6539837,40.0205021 40.9369254,40.0205021 C38.761619,40.0205021 37,41.8053898 37,44.010251 C37,46.2116125 38.7650664,48 40.9369254,48 C43.1122319,48 44.8738509,46.2151123 44.8738509,44.010251 C44.8738509,43.8492612 44.8635087,43.6917711 44.8462717,43.534281 C44.8669561,43.4712849 44.8738509,43.4082889 44.8738509,43.3382933 L44.8738509,29.3356743 L58.6599847,26.8683296 L58.6599847,36.5592198 C58.0808223,36.2197412 57.4051328,36.0272533 56.6880746,36.0272533 C54.5127681,36.0272533 52.7511491,37.812141 52.7511491,40.0170023 C52.7511491,42.2218635 54.5162155,44.0067512 56.6880746,44.0067512 C58.8599336,44.0067512 60.625,42.2218635 60.625,40.0170023 C60.625,39.8560124 60.6146578,39.6985223 60.5974208,39.5410322 C60.6181052,39.4780362 60.625,39.4150401 60.625,39.3520441 L60.625,20.7332156 C60.625,20.4077361 60.3905771,20.1347532 60.0837589,20.0787568 C59.9768897,20.0122609 59.8424413,19.9842627 59.6942033,20.0087612 L59.3460163,20.0682574 L59.3115424,20.0682574 C59.2391471,20.0682574 59.1564096,20.0857563 59.0771195,20.1172543 Z" id="路径" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M39.5,75 C40.8807119,75 42,76.1192881 42,77.5 C42,78.8807119 40.8807119,80 39.5,80 L28.5,80 C27.1192881,80 26,78.8807119 26,77.5 C26,76.1192881 27.1192881,75 28.5,75 L39.5,75 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M57.5,64 C58.8807119,64 60,65.1192881 60,66.5 C60,67.8807119 58.8807119,69 57.5,69 L28.5,69 C27.1192881,69 26,67.8807119 26,66.5 C26,65.1192881 27.1192881,64 28.5,64 L57.5,64 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <path d="M71.5,53 C72.8807119,53 74,54.1192881 74,55.5 C74,56.8807119 72.8807119,58 71.5,58 L28.5,58 C27.1192881,58 26,56.8807119 26,55.5 C26,54.1192881 27.1192881,53 28.5,53 L71.5,53 Z" fill="#FFFFFF" opacity="0.800000012"></path>
                  <rect fill="#FB9F06" transform="translate(86.393994, 83.560660) rotate(-45.000000) translate(-86.393994, -83.560660) " x="82.3939935" y="74.5606602" width="8" height="18" rx="4"></rect>
                  <circle fill="#F8D801" cx="69" cy="65" r="25"></circle>
                  <circle fill="#FAF3BA" cx="69" cy="65" r="19"></circle>
                  <path d="M65.6109127,56.868272 L69.4995924,60.7565924 L73.3890873,56.868272 C74.5606602,55.6966991 76.4601551,55.6966991 77.631728,56.868272 C78.8033009,58.0398449 78.8033009,59.9393398 77.631728,61.1109127 L73.7425924,64.9995924 L77.631728,68.8890873 C78.8033009,70.0606602 78.8033009,71.9601551 77.631728,73.131728 C76.4601551,74.3033009 74.5606602,74.3033009 73.3890873,73.131728 L69.4995924,69.2425924 L65.6109127,73.131728 C64.4393398,74.3033009 62.5398449,74.3033009 61.368272,73.131728 C60.1966991,71.9601551 60.1966991,70.0606602 61.368272,68.8890873 L65.2565924,64.9995924 L61.368272,61.1109127 C60.1966991,59.9393398 60.1966991,58.0398449 61.368272,56.868272 C62.5398449,55.6966991 64.4393398,55.6966991 65.6109127,56.868272 Z" id="路径" fill="#FB9F06"></path>
                </g>
              </svg>
            </div>
            <div className={styles.contentTitle}>{i18n.format('modules.spotify.code.lyric.revise')}</div>
            <div
              className={classnames(styles.contentButton, styles.active)}
              onClick={() => setStacks([...stacks, 'search'])}
            >
              <span>{i18n.format('modules.spotify.code.lyric.search')}</span>
            </div>
            <div
              className={styles.contentButton}
              onClick={() => setStacks([...stacks, 'handle'])}
            >
              <span>{i18n.format('modules.spotify.code.lyric.write.own')}</span>
            </div>
            <div className={styles.footer}>
              <div
                className={styles.previous}
                onClick={popStack}
              >
                <Icon type="arrow-left" />
              </div>
            </div>
          </Wrapper>
        );
      case 'search':
        return (
          <SearchLyric
            className={styles.lyricContentSearch}
            onClose={popStack}
            onTrackConfirm={handleTrackConfirm}
          />
        );
      case 'handle':
        return (
          <HandleModify
            maxLyricLines={maxLyricLines}
            album={track.album}
            onClose={popStack}
            onConfirm={onConfirm}
          />
        );
      case 'lyrics':
        return (
          <Wrapper className={styles.lyricContentLyric}>
            <Wrapper
              className={styles.backgroundImage}
              style={{
                backgroundImage: `url(${track.album})`
              }}
            />
            <div className={styles.title}>Choose lyric</div>
            <div className={styles.description}>
              <span>{i18n.format('modules.spotify.code.lyric.wrong')}</span>
              <a onClick={() => setStacks([...stacks, 'modify'])}>{i18n.format('modules.spotify.code.lyric.modify')}</a>
            </div>
            <div className={styles.count}>{activeLyrics.length}/{maxLyricLines}</div>
            <div className={styles.content}>
              {lyrics.map((item, index) => (
                <div
                  key={index}
                  className={styles.lyricItem}
                  onClick={() => handleLyricChange(index)}
                >
                  <span>{item}</span>
                  {activeLyrics.indexOf(index) > -1 &&
                    <div className={styles.radio}>
                      <Icon type="check" size={20} />
                    </div>
                  }
                </div>
              ))}
            </div>
            <div className={styles.footer}>
              <div
                className={styles.previous}
                onClick={popStack}
              >
                <Icon type="arrow-left" />
              </div>
              <div
                className={styles.next}
                onClick={() => onConfirm(lyrics.filter((_, index) => activeLyrics.indexOf(index) > -1))}
              >
                <span>{i18n.format('modules.global.next')}</span>
                <Icon type="arrow-right" />
              </div>
            </div>
          </Wrapper>
        );
      default:
        return null;
    }
  }

  return (
    <Wrapper className={styles.lyricContent}>
      {stacks.map((item, index) => (
        <Fragment key={index}>
          {renderContent(item)}
        </Fragment>
      ))}
    </Wrapper>
  )
};

export default LyricContent;
