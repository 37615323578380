import React, { useState, useRef, useEffect, useMemo, Fragment } from 'react';
import * as THREE from 'three';
import classnames from 'classnames';
import Threed from '@/utils/threed';
import { Wrapper, Loading } from '@/components';
import { SpotifyLayout, ProductType } from './color-light';
import { SpotifyTrack, SpotifyColor } from '../spotify-code';
import { renderTrack } from './track-canvas';
import Icon from '../icon';
import styles from './style.less';
import { i18n } from '@/utils';

interface PreviewProps {
  /** 布局数组 */
  layout: SpotifyLayout;
  /** 关闭回调 */
  onClose(): void;
  /** 确认回调 */
  onConfirm(): void;
  /** canvas对象 */
  canvas: fabric.Canvas;
  /** 歌曲信息 */
  track: SpotifyTrack;
  /** 颜色集合 */
  colors: SpotifyColor[];
  /** 当前选中的颜色 */
  currentColor: SpotifyColor;
  /** 颜色改变监听 */
  onColorChange(color: SpotifyColor): void;
  /** 演奏者 */
  artists?: string;
  /** 封面图 */
  album?: string;
  /** 产品类型 */
  productType: ProductType,
}

// 发光材质
const vertexShader = `
  varying vec3 vNormal;
  varying vec3 vPositionNormal;
  void main()
  {
    vNormal = normalize( normalMatrix * normal ); // 转换到视图空间
    vPositionNormal = normalize(( modelViewMatrix * vec4(position, 1.0) ).xyz);
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`;
const fragmentShader = `
  uniform vec3 glowColor;
  uniform float b;
  uniform float p;
  uniform float s;
  varying vec3 vNormal;
  varying vec3 vPositionNormal;
  void main()
  {
    float a = pow( b + s * abs(dot(vNormal, vPositionNormal)), p );
    gl_FragColor = vec4( glowColor, a );
  }
`;


const Component: React.FC<PreviewProps> = ({
  canvas,
  track,
  layout,
  currentColor,
  colors,
  onColorChange,
  artists,
  album,
  productType,
  onClose,
  onConfirm
}) => {

  const lampColors: any = [
    { // red
      fresnelEmissive: 0xFF6E66,
      emissive: 0xFF0D00,
      emissiveIntensity: 1.5,
    },
    { // green
      fresnelEmissive: 0x80FF9B,
      emissive: 0x00FF37,
    },
    { // blue
      color: 0xD9D9D9,
      fresnelEmissive: 0x4A68FF,
      emissive: 0x002BFF,
    },
    { // yellow
      fresnelEmissive: 0xFFDE59,
      emissive: 0xFFCC00,
    },
    { // purple
      fresnelEmissive: 0xC34CFF,
      emissive: 0x6A00FF,
      emissiveIntensity: 1.1,
    },
    { // cyan
      fresnelEmissive: 0x8CE3FF,
      emissive: 0x005DFF,
      emissiveIntensity: 1.3,
    },
    { // white
      fresnelEmissive: 0xffffff,
      emissive: 0xffffff,
      emissiveIntensity: [0.5, 0.8, 0.8, 0.5][productType],
      roughness: 0.9,
      metalness: 1,
      hasEmissiveMap: [false, true, true, false][productType],
      frostingEmissive: 0x999999,
    }
  ];

  /** 3d容器 */
  const _element = useRef<HTMLDivElement>(null);
  const _colorIndex = useRef<number>([0, 6, 6, 0][productType])
  /** 加载状态 */
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ currentParam, setCurrentParam ] = useState<any>(lampColors[_colorIndex.current]);
  const [ error, setError ] = useState<boolean>();
  const _threed = useRef<Threed>();
  const _emissiveMap = useRef<any>();

  const fbxUrl = useMemo(() => {
    switch (productType) {
      case ProductType.ColorBluetoothLamp:
        return 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-21.fbx'
      case ProductType.WoodFrostingLamp:
        return 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-17.fbx'
      case ProductType.WoodLamp:
        return 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-13.fbx'
      default:
        return 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-10.fbx'
    }
  }, [productType])

  useEffect(() => {
    const div = _element.current;
    if (!div) return

    const threed = new Threed({
      container: div,
      cameraOptions: {
        position: [0, 150, 350],
        target: [0, 100, 0],
      }
    });
    threed.render();
    const controls = threed.orbitControls;
    controls.enablePan = false;;
    controls.enableDamping = true;
    controls.dampingFactor = 1.5;
    controls.maxPolarAngle = Math.PI * 80 / 180;
    controls.minPolarAngle = Math.PI * 45 / 180;
    controls.maxAzimuthAngle = Math.PI * 45 / 180;
    controls.minAzimuthAngle = -Math.PI * 45 / 180;
    controls.update();

    _threed.current = threed;

    setLoading(true);

    const loadFBXPromise = threed.loadFBX({pathFbx: fbxUrl}).then((obj: any) => {
      const scene = threed.scene;
      scene.add(obj);

      // console.log('threed', threed)
      // console.log('obj', obj)

      updateFresnelEmissive();
      const promises = [updateEmissive()];

      // 底座
      {
        let params: any = {
          color: 0x1F1F1F,
          roughness: 0.5,
          metalness: 0.8,
        };
        if (productType === ProductType.WoodLamp || productType === ProductType.WoodFrostingLamp)
          params = {
            envMapIntensity: 1.5,
          };
        const material = new THREE.MeshStandardMaterial({
          ...params,
        })
        const dizuo: any = scene.getObjectByName('dizuo');
        const qiu: any = scene.getObjectByName('qiu');
        if (dizuo)
          dizuo.material = material
        if (qiu)
          qiu.material = material
        if (productType === ProductType.WoodLamp || productType === ProductType.WoodFrostingLamp) {
          promises.push(
            threed.loadTexture({
              source: 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-dizuo-map-2.jpg',
              name: 'dizuo',
              type: 'map',
            })
          )
        }
      }

      // 玻璃
      {
        let material: any = new THREE.ShaderMaterial({
          uniforms: {
            "s":   { value: -1.0},
            "b":   { value: 1.0},
            "p":   { value: 2.0 },
            // glowColor: { value: new THREE.Color(backgroundColor) }
          },
          vertexShader,
          fragmentShader,
          // blending: THREE.AdditiveBlending,
          transparent: true,
          opacity: 0.1,
          side: THREE.DoubleSide,
          depthWrite: false,
        });
        if (productType === ProductType.WoodFrostingLamp) {
          material = new THREE.MeshPhysicalMaterial({
            emissive: currentParam.frostingEmissive,
            // side: THREE.DoubleSide,
            transmission: 0.35,
            transparent: true,
          });
          promises.push(
            threed.loadTexture({
              source: 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-boli-map-5.jpg',
              name: 'boli_li_',
              type: 'map',
            })
          )
          promises.push(
            threed.loadTexture({
              source: 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-emissive-18.jpg',
              name: 'boli_li_',
              type: 'emissive',
            })
          )
        }
        const boli: any = obj.getObjectByName('boli_li_');
        if (boli)
          boli.material = material
      }

      // 地板
      {
        const diban = obj.getObjectByName('diban');
        if (diban) {
          diban.material.transparent = true;
          diban.material.needsUpdate = true;
        }

        promises.push(
          threed.loadTexture({
            source: 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-shadow-7.png',
            name: 'diban',
            type: 'map',
          })
        )
      }

      // 加载贴图,type目前有map纹理贴图,normalMap法线贴图,bumpMap凹凸贴图,roughnessMap粗糙度贴图
      promises.push(renderTrack(canvas, track, layout, currentColor, artists, album).then(image => updateTexture(threed, image)))

      return Promise.all(promises)
    })

    // 加载环境贴图
    const envMapUrl = [
      'https://sunzi7n.imaiyuan.com/name-necklace/360left(3).hdr',
      'https://sunzi7n.imaiyuan.com/yedeng/Beach_Noon_sm(4).hdr',
      'https://sunzi7n.imaiyuan.com/yedeng/Beach_Noon_sm(4).hdr',
      'https://sunzi7n.imaiyuan.com/name-necklace/360left(3).hdr',
    ][productType];

    const loadEvnMapPromise = threed.loadEnvMap({path: envMapUrl});

    Promise.all([
      loadFBXPromise,
      loadEvnMapPromise
    ])
    .catch(() => setError(true))
    .finally(() => {
      setLoading(false)
    });

    if (productType === ProductType.ColorLamp || productType === ProductType.ColorBluetoothLamp) {
      const timer = setInterval(() => {
        _colorIndex.current++;
        setCurrentParam(lampColors[_colorIndex.current % lampColors.length])
      }, 1000);

      return () => {
        clearInterval(timer);
      }
    }
    return
  }, [])

  /** 颜色改变监听 */
  const handleColorChange = async (color: SpotifyColor) => {
    setLoading(true);
    onColorChange(color);
    const image = await renderTrack(canvas, track, layout, color, artists, album);
    if (_threed.current)
      await updateTexture(_threed.current, image);
    setLoading(false);
  }

  // 更改其他材质的发光
  const updateFresnelEmissive = () => {
    // 需要增加加载完模型贴图之类后的判断,不仅仅是scene
    const threed = _threed.current;
    const scene = threed?.scene;
    if (!threed || !scene) return

    const emissive = currentParam.fresnelEmissive;

    let material = new THREE.ShaderMaterial({
      uniforms: {
        "s":   { value: -1.0},
        "b":   { value: 1.0},
        "p":   { value: 2.0 },
        glowColor: { value: new THREE.Color(emissive) }
      },
      vertexShader,
      fragmentShader,
      // blending: THREE.AdditiveBlending,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
    });

    const waifaguang: any = scene.getObjectByName('waifaguang');
    if (waifaguang)
      waifaguang.material = material;

    // 底座发光
    const r = emissive / (256 * 256);
    const g = emissive % (256 * 256) / 256;
    const b = emissive % (256 * 256) % 256;

    // 辉光
    const vertexShaderString = `
      uniform vec3 viewVector;
      varying float intensity;
      void main() {
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4( position, 1.0 );
          vec3 actual_normal = vec3(modelMatrix * vec4(normal, 0.0));
          intensity = pow( dot(normalize(viewVector), actual_normal), 6.0 );
      }
    `;
    const fragmentShaderString = `
      varying float intensity;
      void main() {
        vec3 glow = vec3(${r / 255}, ${g / 255}, ${b / 255}) * intensity * 0.2;
        // vec3 glow = vec3(0, 1, 0) * intensity;
          gl_FragColor = vec4( glow, 1.0 );
      }
    `

    const glowMaterial = new THREE.ShaderMaterial({
      uniforms: {
          viewVector: {
            value: threed.camera.position
          }
      },
      vertexShader: vertexShaderString,
      fragmentShader: fragmentShaderString,
      side: THREE.FrontSide,
      blending: THREE.AdditiveBlending,
      transparent: true
    });
    const dizuoFaguang: any = scene.getObjectByName('dizuo_faguang');
    if (dizuoFaguang)
      dizuoFaguang.material = glowMaterial

    const update = () => {
      if (!dizuoFaguang) return
      let viewVector = new THREE.Vector3().subVectors(threed.camera.position, new THREE.Vector3(0, 0, 350));
      dizuoFaguang.material.uniforms.viewVector.value = viewVector;

      requestAnimationFrame(update);
    }
    update();
  }

  // 更改贴图的发光材质
  const updateEmissive = () => new Promise<void>((resolve, reject) => {
     // 需要增加加载完模型贴图之类后的判断,不仅仅是scene
     const threed = _threed.current;
     const scene = threed?.scene;
     if (!threed || !scene) return

    const params = {
      color: currentParam.color || 0xffffff,
      emissive: currentParam.emissive,
      emissiveIntensity: currentParam.emissiveIntensity || 1,
      roughness: currentParam.roughness || 0.5,
      metalness: currentParam.metalness || 0.8,
      envMapIntensity: [1, 1.2, 1.2, 1][productType],
    };

    const material = new THREE.MeshStandardMaterial({
      ...params,
      side: THREE.DoubleSide,
      transparent: true,
    });
    const material2 = new THREE.MeshStandardMaterial({
      emissive: currentParam.fresnelEmissive,
      transparent: true,
    });

    const bofang: any = scene.getObjectByName('bofang');
    if (bofang) {
      if (bofang.material.map)
        material.map = bofang.material.map;

      if (currentParam.hasEmissiveMap === false)
        resolve()
      else if (_emissiveMap.current)
        material.emissiveMap = _emissiveMap.current;
      else {
        // resolve();
        threed.loadTexture({
          source: 'https://sunzi7n.imaiyuan.com/yedeng/yedeng-emissive-18.jpg',
          name: 'bofang',
          type: 'emissive',
        })
        .then(() => {
          _emissiveMap.current = bofang.material.emissiveMap
          resolve()
        })
      }
      bofang.material = material;
      material.needsUpdate = true;
    }
    const faguang: any = scene.getObjectByName('faguang');
    if (faguang)
      faguang.material = material2;
  })

  // 更改纹理贴图
  const updateTexture = (threed: Threed, source: string) => {
    return threed.loadTexture({
      source,
      name: 'bofang',
      type: 'map',
    })
  }

  useEffect(() => {
    updateFresnelEmissive()
    updateEmissive()
  }, [ currentParam ])

  return (
    <Wrapper className={styles.previewWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.threed} ref={_element} />
          {loading &&
            <Wrapper className={styles.loadingWrapper}>
              <Loading />
            </Wrapper>
          }
        </div>
      </div>
      {colors.length > 1 &&
        <Fragment>
          <div className={styles.title}>{i18n.format('modules.spotify.code.change.color')}</div>
          <div className={classnames(styles.colorWrapper, {
            [styles.colorDisabled]: loading
          })}>
            {colors.map((item, index) => (
              <div
                key={index}
                className={classnames(styles.color, {
                  [styles.colorActive]: item === currentColor
                })}
                onClick={() => handleColorChange(item)}
              >
                <div
                  className={styles.colorContent}
                  style={{
                    backgroundColor: item.value
                  }}
                />
              </div>
            ))}
          </div>
        </Fragment>
      }
      <div className={styles.footer}>
        <div
          className={styles.previousWrapper}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={classnames(styles.confirm, {
            [styles.confirmDisabled]: loading
          })}
          onClick={onConfirm}
        >
          <span>{i18n.format('modules.global.add.cart').toLowerCase()}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Component;
