export default {
  'modules.global.uoload.photo': 'Fotos Hochladen', // 上传图片
  'modules.global.upload': 'Hochladen', // 上传
  'modules.global.confirm': 'BESTÄTIGEN', // 确认
  'modules.global.free': 'KOSTENLOS', // 免费
  'modules.global.add.text': 'Texte Hinzufügen', // 添加刻字
  'modules.global.confirm.design': 'DESIGN BESTÄTIGEN', // 确认设计
  'modules.global.processing': 'Bearbeiten Sie Ihr Bild', // 确认
  'modules.global.preview.confirm': 'Hochladen', // 正常上传图片
  'modules.global.preview.tips': 'Unser Designer werden Ihr Design für einen besseren Druck der Socken anpassen.', // 预览提示

  'modules.global.add.cart': 'IN DEN WARENKORB LEGEN', // 加车
  'modules.global.previous': 'BISHERIGE', // 上一步
  'modules.global.next': 'NÄCHSTER', // 下一步
  'modules.global.preview': 'VORSCHAU', // 预览
  'modules.global.loading': 'Laden', // 正在加载
  'modules.global.uploading': 'HOCHLADEN', // 正在上传

  'modules.global.add.photo': 'Fotos Hinzufügen', // 添加图片
  'modules.global.checkout': 'Auschecken', // 结账
  'modules.global.cancel': 'Stornieren', // 取消
  'modules.global.complete': 'komplett', // 完成
  'modules.global.change': 'Ändern', // 更换
  'modules.global.cropper': 'zuschneiden', // 裁剪
  'modules.global.cropper.image': 'Bild Zuschneiden', // 裁剪照片
  'modules.global.replace': 'Ändern', // 替换
  'modules.global.done': 'komplett', // 完成
  'modules.global.delete': 'Löschen', // 删除

  'modules.global.ai.crop.complete': 'Ja, Komplett!', // 切图完成
  'modules.global.ai.croping.photo': 'AI Beschneide Ihr Foto', // ai切图中

  'components.cropper.title': 'Bild Zuschneiden', // 裁剪照片
  'components.edit.title': 'MODELL BEARBEITEN', // 编辑模式
  'components.edit.type': 'ART', // 类别
  'components.edit.apply': 'BEWERBEN', // 应用
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': 'Bitte vervollständigen Sie den benutzerdefinierten Inhalt gemäß den folgenden Bedingungen.', // 请根据以下条件完成定制内容
  'components.oop.submit': 'Ich werde es ändern', // 我会改变的
  'components.guide.title': 'Anleitung', // 使用说明
  'components.edit.font.text': 'Text', // 文字内容
  'components.edit.font.family': 'Schriftart', // 字体
  'components.edit.font.size': 'Schriftgröße', // 文字大小
  'components.edit.font.color': 'Schriftfarbe', // 文字颜色
  'components.edit.font.shadow.color': 'Schattenfarbe der Schrift', // 文字阴影颜色
  'modules.mug.font.validator': 'Bitte bearbeiten ', // 请编辑
  'modules.bottom.background': 'landschaft', // 背景
  'modules.bottom.layout': 'layout', // 图层
  'modules.bottom.help': 'helfen', // 帮助
  'modules.bottom.next': 'nächste', // 下一步
  'modules.bottom.confirm': 'bestätigen', //确认
  'modules.bottom.layout.mug.tip': 'Die aktuelle Option wird vorübergehend platziert und kann bearbeitet werden', // 图层操作提示文案
  'modules.bottom.layout.close': 'SCHLIESSEN', //关闭
  'modules.bottom.operator.top': 'Oberste', // 置顶
  'modules.bottom.operator.up': 'Nach oben', // 上一层
  'modules.bottom.operator.down': 'Nach unten', // 下一层
  'modules.bottom.operator.bottom': 'Unterseite', // 置底
  'modules.error.load.font': 'Fehler beim Laden der Schrift, Bitte versuche es Noch einmal', // 字体加载失败 请重试
  'modules.error.upload.result': 'Upload-Fehler, Bitte versuche es erneut', // 上传失败 请重试

  // 多图模板
  'modules.template.tips': 'Klicken Sie auf das Foto, um es zu ändern oder zuschneiden', // 点击图片区域可以更换图片或裁剪
  'modules.template.validator.images': 'Sie brauchen $value Fotos hochladen.', // 需要传多少张图
  'modules.template.validator.texts': 'Sie brauchen $value Texte ausfüllen.', // 需要输入多少段文字
  'modules.template.bottom.template': 'Vorlage', // 模版
  'modules.template.default.texts': 'Texte', // 文字

  // 袜子
  'components.footer.revoke': 'Widerrufen', // 撤回
  'components.footer.gesture.erasure': 'Löschen', // 橡皮擦模式
  'components.footer.gesture.pan': 'Spur', // 画线模式
  'components.footer.gesture.move': 'Bewegen', // 移动模式
  'components.footer.zoom.in': 'Vergrößern', // 放大
  'components.footer.zoom.out': 'Verkleinern', // 缩小
  'components.footer.help': 'Hilfe', // 缩小
  'components.footer.rotate.left': 'Nach Links Drehen', // 向左旋转
  'components.footer.rotate.right': 'Nach Rechts Drehen', // 向右旋转
  'modules.sock.cropper.tips': 'Wir können nur ein Gesicht zu einer Zeit beschneiden. Bitte halten Sie Ihr gesamtes Gesicht im Erntebereich.', // 请确保您的面部五官在所选取裁剪区域内, 且裁剪区内仅限一人.
  'modules.sock.change': 'Ändern', // 更换
  'modules.sock.preview': 'Vorschau', // 使用头像预览
  'modules.sock.preview.hot': 'Heiß', // 热门
  'modules.sock.erasure': 'Zusätzliches Teil löschen', // 擦掉多余部分
  'modules.sock.clip': 'Schneiden Sie selbst', // 擦掉多余部分
  'modules.sock.cuting.testing': 'Untersuchen...', // 正在检测...
  'modules.sock.change.photo': 'Foto Ändern', // 更换图片
  'modules.sock.preview.avatar.delete': 'Möchten Sie dies wirklich löschen?', // 确定要删除该头像吗
  'modules.sock.cut.recommend': 'Bitte', // 推荐您
  'modules.sock.cut.or': 'oder', // 或者
  'utils.meitu.error.20001': 'Fehler', // 处理错误
  'utils.meitu.error.20003': 'Kein Gesicht gefunden', // 人脸缺失
  'utils.meitu.error.20004': 'Mehr als ein Gesicht erkannt', // 人脸数大于1
  'utils.meitu.error.20005': 'Nur JPG und PNG Fotos Akzeptieren', // 不支持的type
  'utils.meitu.error.other': 'Fehler', // 系统错误，请稍后再试
  'modules.sock.compose.title': 'Mach Ihre Socken', // 制作你的袜子
  'modules.sock.compose.background': 'Hintergrund', // 背景

  // 影刻
  'modules.sketch.cropper.tips': 'Bitte stellen Sie das Gesicht der Menschen und den Körper in der Mitte ein', // 请保证照片中人像边缘尽量清晰可见
  'modules.sketch.title': '3D VORSCHAU', // 3d预览
  'modules.sketch.request.stage0': 'Hochladen…', // 正在上传照片…
  'modules.sketch.request.stage1': 'Hintergrund entfernen…', // 正在将人像从背景抠出…
  'modules.sketch.request.stage2': 'Filter hinzufügen…', // 正在加入滤镜效果…
  'modules.sketch.request.stage3': 'Verarbeitung zu weißem und schwarzem Bild…', // 正在去色处理…
  'modules.sketch.request.stage4': 'Warte einen Moment,3D Vorschau vorbereiten…', // 请稍后, 预览结果即将呈现…
  'modules.sketch.request.accomplish': 'Abgeschlossen, bitte klicken, um Vorschau anzuzeigen', // 影刻处理完成, 请点击预览查看
  'modules.sketch.request.error': 'Fehlgeschlagen, Sie können es in den Warenkorb hinzufügen.', // 预览处理失败，您可以跳过直接加车
  'modules.sketch.try.again': 'Vorschau noch einmal', // 重新预览
  'modules.sketch.add.text.placeholder': 'Wörter hier eingeben...(Wir machen es genauso wie in der Vorschau oben).', // 刻字提示文案
  'modules.sketch.footer.preview': 'Vorschau', // 预览并加车
  'modules.sketch.footer.skip': 'Vorschau überspringen', // 跳过预览
  'modules.sketch.accomplish.later': 'Später prüfen', // 稍后再说
  'modules.sketch.accomplish.prompt': '3D Vorschau ist fertig, jetzt prüfen!', // 稍后再说

  // 撕拉盒子
  'modules.box.step': 'SCHRITT', // 步数
  'modules.box.side': 'Seite', // 面
  'modules.box.image.upload': 'Hochladen', // 上传
  'modules.box.image.change': 'Ändern', // 切换
  'modules.box.step1.title': 'BILD HINZUFÜGEN', // 添加图片
  'modules.box.step1.tips': 'Sie können es mit Ihrem hochgeladenen Foto anpassen oder das aktuelle Bild verwenden.', // 添加图片说明文案
  'modules.box.step2.title': 'Konfetti hinzufügen', // 添加纸屑
  'modules.box.step2.tips': 'Stellen Sie Ihre personalisierte explodierende boomf Bombe her!', // 添加纸屑说明文案
  'modules.box.oop.title': 'HINWEIS', // 添加纸屑说明文案
  'modules.box.oop.description': 'Wir werden es in der Reihenfolge der Bilder produzieren.', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': 'Bild Bearbeiten', // 编辑照片
  'modules.photo.film.tips.sort': 'Lange drücken und Ziehen Sie das Bild, um seine Position anzupasse.', // 长按拖动图片来排序
  'modules.photo.film.tips.count': 'Sie brauchen $value Fotos hochladen.', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'Sie brauchen mindestens $value Fotos hochladen.', // 最少需要传多少张图
  'modules.photo.film.change': 'Ändern', // 更换
  'modules.photo.film.cropper': 'Zuschneiden', // 裁剪
  'modules.photo.film.sort': 'Sortieren', // 排序
  'modules.photo.film.delete': 'Löschen', // 删除

   // 简版照片书
   'modules.photo.book.deleted.page': 'Gelöschte Seite', // 已删除页面
   'modules.photo.book.delete.page': 'Seite löschen', // 删除页面
   'modules.photo.book.change.layout': 'Layout Ändern', // 更改布局
   'modules.photo.book.add.photo.page': 'Foto Seite hinzufügen', // 添加照片页面
   'modules.photo.book.add.compose.page': 'Collage Seite hinzufügen', // 添加拼贴页面
   'modules.photo.book.validator': 'Fügen Sie $value mehr Seiten hinzu, um zu kaufen', // 再添加 $value 页即可购买
   'modules.photo.book.replace.image': 'Foto Ersetzen', // 替换照片
   'modules.photo.book.remove.image': 'Entfernen', // 移除
   'modules.photo.book.page': 'Seite', // 页
   'modules.photo.book.type': 'Buch Material', // 图书材质
   'modules.photo.book.size': 'Buch Größe', // 图书尺寸
   'modules.photo.book.order.qty': 'Menge', // 数量
   'modules.photo.book.order.total': 'gesamt', // 总计
   'modules.photo.book.warning.title': 'Warnung', // 警告
   'modules.photo.book.warning.cover.title': 'Titel für Foto Buch Titelseite hinzufügen', // 为照片书添加标题
   'modules.photo.book.warning.cover.description': 'Klicken Sie auf das Titelseite des Foto Buchs, um einen Titel hinzuzufügen', // 点击封面即可在照片书封面添加标题。
   'modules.photo.book.warning.image.title': 'Bilder mit niedriger Auflösung gefunden', // 发现低分辨率图片
   'modules.photo.book.warning.image.description': 'Beim Drucken kann es unscharf sein.', // 打印出来可能比较模糊
   'modules.photo.book.add.title': 'Adicionar Título', // 添加标题
   'modules.photo.book.zuploader.subtitle': 'Benötigen sie mindestens $value Fotos', // 至少需要 value 张照片
   'modules.photo.book.guide.title': `Kaufanleitung`, // 操作引导
   'modules.photo.book.guide.position': `Lange Drücken und ziehen Sie es, um die Bestellung anzupassen`, // 长按图片可调整顺序
   'modules.photo.book.guide.layout': `Klicken Sie auf das Symbol an der Seite, um das Layout zu ändern`, // 点击侧边更多可切换图片样式
   'modules.photo.book.guide.button': 'OK Danke', // 好的 我知道了

  // 照片墙
  'modules.photo.wall.choose.frame': 'Einen Rahmen Wählen', // 选择边框
  'modules.photo.wall.choose.style': 'Einen Stil Wählen', // 选择样式
  'modules.photo.wall.zoom.pinch': 'kneifen und zoomen', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'scroll to zoom, um zu zoomen', // 滚动放大
  'modules.photo.wall.choose.design': 'Ein Design Wählen', // 选择设计
  'modules.photo.wall.choose.design.tips': 'Aus Galerie Wänden Wählen, un anzupassen', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'Rahmen', // 框
  'modules.photo.wall.image.list.title': 'Fotos hinzufügen, um mehre Designs anzuzeigen', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': 'Meine Fotos', // 我的图片
  'modules.photo.wall.zuploader.subtitle': 'Sie können bis zu $value Fotos hochladen ',  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': 'Wir unterstützen bis zu $value Fotos, der Überschuss wird ignoriert',  // 我们最多支持 $value 张照片，超出的将被忽略

  // 增值产品-relatedProduct
  'increment.related.product.title': 'Wählen ein Super Überraschungspaket für Ihr Geschenk', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'Nein Danke', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': 'Die beste Wahl, um Bestellungen mit Priorität Produktion & Versand bearbeiten', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'VIP SERVICE', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': 'Netzwerk Fehler!', // 网络错误
  'modules.new.sock.ai.crop.error': 'AI Zuschneiden Fehler!', // 切图服务错误
  'modules.new.sock.replace.photo': 'Foto Ersetzen', // 替换照片
  'modules.new.sock.crop.myself': 'Zuschneiden selbst', // 自己切图
  'modules.new.sock.try.again': 'Nochmal Versuchen', // 重试
  'modules.new.sock.edit.avatar': 'Avatar Bearbeiten', // 编辑头像
  'modules.new.sock.add.line.text.title': 'Text Hinzufügen?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': '80% der Kunden bevorzugen es, personalisierten Text hinzuzufügen, um perfekte Geschenke zu machen!', // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': 'TEXT HINZUFÜGEN?', // 添加文字
  'modules.new.sock.without.text': 'OHNE TEXT', // 不刻字
  'modules.new.sock.color': 'FARBE', // 颜色
  'modules.new.sock.size': 'GRÖßE', // 尺寸
  'modules.new.sock.sock': 'SOCKE', // 袜子
  'modules.new.sock.share.title': 'Teilen, Um Zu Erhalten', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'Benutzerdefiniertes Soziales Profil Bild', // 你的社交新头像
  'modules.new.sock.free.download': 'Frei Herunterladen', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'Benutzerdefiniertes Handy Hintergrundbild', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'Benutzerdefiniertes PC Hintergrundbild.', // 你的电脑新壁纸
  'modules.new.sock.discount': 'Rabatt', // 折扣
  'modules.new.sock.discount.price': '$value Rabatt', // 立减 $value 块
  'modules.new.sock.share.now': 'JETZT TEILEN', // 立刻分享
  'modules.new.sock.no.thanks': 'NEIN, DANKE', // 不分享
  'modules.new.sock.shared.title': 'HERZLICHE GLÜCKWÜNSCHE!', // 恭喜
  'modules.new.sock.download': 'Herunterladen', // 下载
  'modules.new.sock.generating.image': 'Geteilte Bilder Erstellen', // 正在生成分享图
  'modules.new.sock.total': 'gesamt', // 总计

  // zuploader
  'plugin.zuploader.from.album': 'Aus Ihrem Album Wählen', // 从相册中选择
  'plugin.zuploader.from.social': 'Aus Online Servece Wählen', // 从在线服务中选择
  'plugin.zuploader.from.camera': 'Ein Foto Machen', // 拍照
  'plugin.zuploader.wait.upload': 'Auf Upload Warten', // 等待上传
  'plugin.zuploader.choose.source': 'Fotos Wählen', // 选择照片来源
  'plugin.zuploader.choose.image.title': 'Fotos Hochgeladen', // 已上传照片
  'plugin.zuploader.choose.image.choose': 'Fotos Wählen', // 选择照片
  'plugin.zuploader.choose.image.range': '$value Artikel Wählen', // 选择 $value 照片

  // lowQuality
  'plugin.low.quality.title': 'Geringe Bild Qualität', // 低图像质量
  'plugin.low.quality.tips': 'Dieses Foto ist tatsächlich ziemlich klein. Es wird wahrscheinlich eine verschwommene Fliese machen!', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'Behalten wie auch immer', // 不管怎样都保留
  'plugin.low.quality.remove': 'Aus Bestellung entfernen', // 从订单中删除

  // 无限魔方
  'modules.infinite.cube.zuploader.title': 'Brauchen $value Fotos', // 需要 $value 张照片
  'modules.infinite.cube.input.tip': 'Ziehen oder klicken, um das Bild anzupassen', // 拖动或点击来调整图片
  'modules.infinite.cube.preview3d.tip': 'Ziehen Sie den Würfel zur Ansicht', // 拖动观察魔方
  'modules.infinite.cube.preview3d.tap': 'Tippe mich an', // 点击我(按钮)
  'modules.infinite.cube.preview.choose': 'Ihr Titelbild Wählen', // 选择你的封面
  'modules.infinite.cube.save': 'Speichern', // 保存
  'modules.infinite.cube.share': 'Teilen und $elm $value Rabatt $end', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.and': 'Teilen und', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.off': 'Rabatt', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.or': 'Oder', // 或者
  'modules.infinite.cube.without.share': 'ohne Teilen & in den Warenkorb legen', // 不分享直接加车

  // 2020 11 17 add
  // 台历
  'modules.desktop.calendar.cover': 'Startseite', // 封面
  'modules.desktop.calendar.change.layout': 'Ändern Sie das Fotolayout', // 更改照片布局
  'modules.desktop.calendar.validator': 'fehlende $value Fotos', // 缺 $value 张照片
  'modules.desktop.calendar.replace.image': 'Foto ersetzen', // 替换照片
  'modules.desktop.calendar.remove.image': 'Entfernen', // 移除

  // 挂历
  'modules.wall.calendar.tap.day': 'Tipp auf Datum & Kalenderereignis hinzufügen', // 点击日期和添加日历事件
  'modules.wall.calendar.cover': 'Startseite', // 封面
  'modules.wall.calendar.change.layout': 'Ändern Sie das Fotolayout', // 更改照片布局
  'modules.wall.calendar.validator': 'Bitte laden Sie weitere $value Fotos für hoch', // 缺 $value 张照片
  'modules.wall.calendar.replace.image': 'Foto ersetzen', // 替换照片
  'modules.wall.calendar.remove.image': 'Entfernen', // 移除
  'modules.wall.calendar.and': 'Und', // 和
  'modules.wall.calendar.event.add': 'Fügen Sie Ihre Veranstaltung hinzu', // 添加你的事件
  'modules.wall.calendar.event.choose': 'Wählen Sie den Ereignistyp', // 选择你的事件类型
  'modules.wall.calendar.event.edit': 'Ereignis bearbeiten', // 编辑事件
  'modules.wall.calendar.event.clear': 'Ereignis löschen', // 清除事件
  'modules.wall.calendar.event.create': 'Neues Ereignis erstellen', // 创建新事件
  'modules.wall.calendar.event.graduation': 'Abschluss', // 毕业季
  'modules.wall.calendar.event.holidays': 'Ferien', // 度假
  'modules.wall.calendar.event.wedding': 'Hochzeit', // 婚礼
  'modules.wall.calendar.event.bar': 'Bar', // 酒吧
  'modules.wall.calendar.event.party': 'Party', // 派对
  'modules.wall.calendar.event.birthday': 'Geburtstag', // 生日
  'modules.wall.calendar.event.anniversarie': 'Jahrestag', // 周年纪念日
  'modules.wall.calendar.event.other': 'andere', // 其他
  'modules.wall.calendar.event.tips': 'Tippen und bearbeiten', // 点击编辑
  'modules.wall.calendar.cover.title.show': 'Titel anzeigen', // 显示标题
  'modules.wall.calendar.cover.title.hide': 'Titel ausblenden', // 隐藏标题

  //  other
  'modules.global.revoke': 'Widerrufen', // 撤销
  'modules.global.warning.title': 'Warnung', // 警告
  'modules.global.zoom.pinch': 'kneifen und zoomen', // 移动和放大
  'modules.global.edit.confirm': 'Sie haben die Bearbeitung noch nicht abgeschlossen. Bist du sicher aufzuhören?', // 您还没有编辑完，确定要退出吗
  'modules.global.delete.tips': 'Sind Sie sicher, dies zu löschen?', // 删除提示

  // Spotify Code
  'modules.spotify.code.search.hot': 'Heiße Suche', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Suche Ergebnisse', // 搜索结果
  'modules.spotify.code.placeholder': 'Song suchen、Künstler、Album', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Ihr Foto Hochladen', // 上传你的图片
  'modules.spotify.code.album.track': 'Verwenden Sie Das Album Decke', // 使用相册封面
  'modules.spotify.code.change.color': 'Ihre Farbe Wählen', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'Benutzerdefinierter Name des Künstlers', // 你可以自己定制演奏者
  'modules.spotify.code.search.no.result': 'Ups, kein Ergebnis.', // 没结果
  'modules.spotify.code.add.text.ask': 'Müssen Sie Text hinzufügen?', // 你是否需要添加刻字
  'modules.spotify.code.add.text': 'Text hinzufügen', // 添加刻字
  'modules.spotify.code.skip.add.cart': 'Nein Danke. In den Warenkorb legen', // 不谢谢 加车
  'modules.spotify.code.add.text.title': 'Fügen Sie hier Ihren Text hinzu', // 在这添加你的刻字
  'modules.spotify.code.add.text.or': 'oder', // 或
  'modules.spotify.code.buy.it': 'Sicher, kauf es jetzt', // 是的 买它
  'modules.spotify.code.template.title': 'Wählen Sie Ihren Lieblingsstil',
  "modules.spotify.code.multi.upload": "Laden Sie Ihre Fotos hoch", // 上传你的照片
}
