import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import styles from './style.less';

export interface LoadingProps {
  percent?: number;
}

export interface LoadingState {
  percent: number;
}

class Loading extends Component<LoadingProps, LoadingState> {
  private _progress: React.RefObject<HTMLDivElement> = createRef();
  private _done: React.RefObject<HTMLDivElement> = createRef();

  constructor(props: LoadingProps) {
    super(props);
    this.state = {
      percent: 0
    }
  }

  componentDidMount() {
    this.animate();
  }

  componentDidUpdate(prev: LoadingProps) {
    const { percent = 0 } = this.props;
    if (percent > 90 && prev.percent !== percent) {
      this.setState({
        percent
      });
    }
  }

  animate = () => {
    const { percent = 0 } = this.props;
    if (this._progress.current && this._done.current && percent < 90) {
      this.setState({
        percent: this._done.current.offsetWidth * 100 / this._progress.current.offsetWidth
      });
      requestAnimationFrame(this.animate.bind(this));
    }
  }

  render() {
    const { percent } = this.state;
    return (
      <div className={styles.lineLoading}>
        <div ref={this._progress} className={styles.progress}>
          {percent < 90 ?
            <div ref={this._done} className={classnames('progress-done', styles.progressDone, styles.progressDoneImitate)} /> :
            <div className={classnames('progress-done', styles.progressDone)} style={{ width: `${percent}%` }}/>
          }
        </div>
        <div className={styles.percent} style={{ width: `${percent}%` }}>{percent.toFixed()}%</div>
      </div>
    )
  }
};

export default Loading;
