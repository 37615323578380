import React from 'react';
import en from '@/locales/en';
import de from '@/locales/de';
import fr from '@/locales/fr';
import tw from '@/locales/tw';
import es from '@/locales/es';
import br from '@/locales/br';
import it from '@/locales/it';
import jp from '@/locales/jp';
import nl from '@/locales/nl';

class I18n {
  private _local: {
    [id: string]: string
  };

  constructor() {
    this._local = en;
  }

  public setLocal(local: string) {
    if (local === 'de')
      this._local = de;
    else if (local === 'fr')
      this._local = fr;
    else if (local === 'tw')
      this._local = tw;
    else if (local === 'it')
      this._local = it;
    else if (local === 'es')
      this._local = es;
    else if (local === 'br')
      this._local = br;
    else if (local === 'jp')
      this._local = jp;
    else if (local === 'nl')
      this._local = nl;
    else
      this._local = en;
  }

  public format(id: string, value?: any) {
    if (value)
      return this._local[id].replace('$value', value);
    return this._local[id];
  }

  public formatHasElm({id, value, type, props, activeType, activeProps}: {
    id: string,
    value: any,
    type: string,
    props: React.Attributes | {},
    activeType: string,
    activeProps: React.Attributes | {},
  }): JSX.Element {
    let str;
    if (value)
      str = this._local[id].replace('$value', value);
    else
      str = this._local[id];
    const start = str.indexOf('$elm ');
    const end = str.lastIndexOf(' $end');
    const active = str.substring(start + 5, end);
    const before = str.substring(0, start);
    const after = str.substring(end + 5, str.length);
    const activeElm = React.createElement(activeType, activeProps, active);
    return React.createElement(type, props, before, activeElm, after);
  }

}

export default new I18n();
