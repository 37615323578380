export default {
  'modules.global.add.cart': '加入購物車', // 加车
  'modules.global.previous': '上一步', // 上一步
  'modules.global.next': '下一步', // 下一步

  'modules.global.confirm': '確認', // 确认
  'modules.global.processing': '處理照片', // 处理你的图片
  'modules.global.confirm.design': '確認設計', // 确认设计
  'modules.global.free': '免費', // 免费
  'modules.global.add.text': '增加刻字', // 添加刻字
  'modules.global.add.photo': '增加圖片', // 添加图片
  'modules.global.preview.confirm': '上傳圖片', // 正常上传图片
  'modules.global.preview.tips': '我们的设计师会调整你的设计以获得更好的印刷效果  設計師會微調整設計以達到最佳的效果', // 预览提示
  'modules.global.preview': '預覽', // 预览
  'modules.global.loading': '正在加載', // 正在加载
  'modules.global.uploading': '正在上傳', // 正在上传
  'modules.global.uoload.photo': '上傳圖片', // 上传图片
  'modules.global.upload': '上傳', // 上传
  'modules.global.delete.tips': ' 確定刪除？', // 删除提示
  'modules.global.delete': '刪除', // 删除
  'modules.global.cancel': '取消', // 取消
  'modules.global.done': '完成', // 完成
  'modules.global.order': '結算', // 结算
  'modules.global.recommend': '推薦', // 推荐

  'modules.global.change': '更換', // 更换
  'modules.global.cropper': '裁剪', // 裁剪
  'modules.global.cropper.image': '裁剪圖片', // 裁剪图片

  'modules.global.checkout': '結算', // 结账
  'modules.global.replace': '替換', // 替换
  'modules.global.complete': '完成', // 完成




  'components.edit.title': '編輯模式', // 编辑模式
  'components.edit.type': '類別', // 类别
  'components.edit.apply': '應用', // 应用
  'components.oop.title': 'OOP', // OOP
  'components.oop.description': '請根據以下條件完成定制內容', // 请根据以下条件完成定制内容
  'components.oop.submit': '我會改變的', // 我会改变的
  'components.guide.title': '操作說明', // 使用说明
  'components.edit.font.text': '文字內容', // 文字内容
  'components.edit.font.family': '字體', // 字体
  'components.edit.font.size': '字型大小', // 文字大小
  'components.edit.font.color': '字體顏色', // 文字颜色
  'components.edit.font.shadow.color': '字體陰影顏色', // 文字阴影颜色
  'components.cropper.title': '裁剪照片', // 裁剪照片
  'modules.mug.font.validator': '請編輯 ', // 请编辑
  'modules.bottom.background': '風景', // 背景
  'modules.bottom.layout': '圖層', // 图层
  'modules.bottom.help': '幫助', // 帮助
  'modules.bottom.next': '下一步', // 下一步
  'modules.bottom.confirm': '確認', //确认
  'modules.bottom.layout.mug.tip': '點擊當前選項將暫時被置頂並為可編輯狀態', // 图层操作提示文案
  'modules.bottom.layout.close': '關閉', //关闭
  'modules.bottom.operator.top': '置頂', // 置顶
  'modules.bottom.operator.up': '上一層', // 上一层
  'modules.bottom.operator.down': '下一層', // 下一层
  'modules.bottom.operator.bottom': '置底', // 置底
  'modules.error.load.font': '字體加載錯誤，請重新嘗試', // 字体加载失败 请重试
  'modules.error.upload.result': '上傳錯誤，請重新嘗試', // 上传失败 请重试

  // 袜子
  'components.footer.revoke': '撤回', // 撤回
  'components.footer.gesture.erasure': '橡皮擦', // 橡皮擦模式
  'components.footer.gesture.pan': '畫線', // 画线模式
  'components.footer.gesture.move': '移動', // 移动模式
  'components.footer.zoom.in': '放大', // 放大
  'components.footer.zoom.out': '縮小', // 缩小
  'components.footer.help': '幫助', // 缩小
  'components.footer.rotate.left': '向左旋轉', // 向左旋转
  'components.footer.rotate.right': '向右旋轉', // 向右旋转
  'component.cropper.tips': '只能剪裁一張臉，請確認在區域內顯示全臉五官', // 请确保您的面部五官在所选取裁剪区域内, 且裁剪区内仅限一人.

  'modules.sock.change': '更換', // 更换
  'modules.sock.preview': '使用頭像預覽', // 使用头像预览
  'modules.sock.preview.hot': '熱門', // 热门
  'modules.sock.erasure': '擦掉多餘部分', // 擦掉多余部分
  'modules.sock.clip': '手動裁剪頭像', // 擦掉多余部分

  'modules.sock.cuting.testing': '正在檢測...', // 正在检测...
  'modules.sock.change.photo': '更換照片', // 更换图片
  'modules.sock.preview.avatar.delete': '確定要刪除該頭像嗎？', // 确定要删除该头像吗
  'modules.sock.cut.recommend': '推薦您', // 推荐您
  'modules.sock.cut.or': '或者', // 或者
  'utils.meitu.error.20001': '處理錯誤', // 处理错误
  'utils.meitu.error.20003': '人臉缺失', // 人脸缺失
  'utils.meitu.error.20004': '人臉數大於1', // 人脸数大于1
  'utils.meitu.error.20005': '不支持的格式', // 不支持的type
  'utils.meitu.error.other': '處理錯誤', // 系统错误，请稍后再试


  // 照片书
  'modules.photo.book.design.type': '設計類型', // 设计类型
  'modules.photo.book.design.type.auto': '設計師為你設計', // 我们的设计师为你设计
  'modules.photo.book.design.type.user': '想自己設計', // 我自己设计
  'modules.photo.book.auto.design': '專業設計師設計', // 我们的设计师设计
  'modules.photo.book.need.images': '照片書需要', // 照片书需要
  'modules.photo.book.photos': '照片', // 照片
  'modules.photo.book.pages': '頁', // 页数
  'modules.photo.book.add.photos': '增加照片', // 添加照片
  'modules.photo.book.photos.received': '已收到 $value 張照片', // 我们已经收到了 $value 张照片
  'modules.photo.book.photos.upload': '需要 $value 張照片', // 需要 $value 张照片
  'modules.photo.book.pages.included': '包含 $value 頁', // 含有页数
  'modules.photo.book.manage.photos': '管理照片', // 管理图片
  'modules.photo.book.title.size': '圖書尺寸', // 图书尺寸
  'modules.photo.book.title.page': '圖書頁數', // 图书页数
  'modules.photo.book.auto.typesetting.tips': '還剩餘 $value 張照片未完成，是否要使用自動排版進行編排？', // 您还有 $value 个照片位没完成，剩余的是否使用自动排版帮您完成？
  'modules.photo.book.auto.typesetting': '使用自動排版', // 使用自动排版
  'modules.photo.book.continue.edit': '繼續編輯', // 继续编辑
  'modules.photo.book.method.typesetting.tips': '使用現有 $value 張照片或重新上傳新照片進行排版？', // 使用您现有的 $value 张照片或重新上传新的照片进行排版？
  'modules.photo.book.uploaded.typesetting': '使用已經上傳的圖片', // 使用已经上传的图片
  'modules.photo.book.new.typesetting': '上傳新照片', // 上传新的照片
  'modules.photo.book.pagination.front.cover': '封面', // 封面
  'modules.photo.book.pagination.inset': '內頁', // 里面
  'modules.photo.book.pagination.last.page': '最後一頁', // 最后一页
  'modules.photo.book.pagination.back.cover': '封底', // 封底
  'modules.photo.book.tabbar.image': '照片', // 照片
  'modules.photo.book.tabbar.layout': '版面', // 布局
  'modules.photo.book.tabbar.background': '背景', // 底图
  'modules.photo.book.tabbar.text': '文字', // 文字
  'modules.photo.book.before.enter.page': '輸入$value之前，請選擇要編輯的頁面。', // 进入 $value 前，请选择要在哪个页面进行编辑？
  'modules.photo.book.front.cover.tips': '圖片拖曳到照片位置', // 拖拽图片到照片位
  'modules.photo.book.back.cover.tips': '預覽', // 去看看效果啊
  'modules.photo.book.uncomplete.tips': '還有$value張照片未安排位置', // 还有 $value 个照片位 未完成，返回
  'modules.photo.book.exit.tips': '尚未完成編輯，確定要退出嗎？', // 您还没有编辑完，确定要退出吗

  // 简版照片书
  'modules.photo.book.deleted.page': '已刪除頁面', // 已删除页面
  'modules.photo.book.delete.page': '刪除頁面', // 删除页面
  'modules.photo.book.change.layout': '更換版面', // 更改布局
  'modules.photo.book.add.photo.page': '增加頁面', // 添加照片页面
  'modules.photo.book.add.compose.page': '增加多圖頁面', // 添加拼贴页面
  'modules.photo.book.validator': '加購增加$value頁', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': '替換照片', // 替换照片
  'modules.photo.book.remove.image': '移除', // 移除
  'modules.photo.book.page': '頁', // 页
  'modules.photo.book.type': '材質', // 图书材质
  'modules.photo.book.size': '尺寸', // 图书尺寸
  'modules.photo.book.order.qty': '數量', // 数量
  'modules.photo.book.order.total': '總計', // 总计
  'modules.photo.book.warning.title': '警告', // 警告
  'modules.photo.book.warning.cover.title': '增加封面標題', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': '點擊增加封面標題', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': '發現低解析度照片', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': '印出效果可能會模糊', // 打印出来可能比较模糊
  'modules.photo.book.add.title': '增加標題', // 添加标题
  'modules.photo.book.zuploader.subtitle': '至少需要$value張照片', // 至少需要 value 张照片
  'modules.photo.book.guide.title': '操作指南', // 操作引导
  'modules.photo.book.guide.position': '長按圖片調整順序', // 长按图片可调整顺序
  'modules.photo.book.guide.layout': '點擊側面圖標可變更版面', // 点击侧边更多可切换图片样式
  'modules.photo.book.guide.button': '好的，謝謝', // 好的 我知道了

  // 多图
  'modules.photo.film.title': '修改照片', // 编辑照片
  'modules.photo.film.tips.sort': '長按拖動照片排序', // 长按拖动图片来排序
  'modules.photo.film.tips.count': '您需要上傳$value張照片', // 需要传多少张图
  'modules.photo.film.tips.count.min': '您需要上傳至少$value張照片', // 最少需要传多少张图
  'modules.photo.film.change': '更換', // 更换
  'modules.photo.film.cropper': '裁剪', // 裁剪
  'modules.photo.film.sort': '排序', // 排序
  'modules.photo.film.delete': '刪除', // 删除

  // zuploader
  'plugin.zuploader.from.album': '從相冊中選擇', // 从相册中选择
  'plugin.zuploader.from.camera': '拍照', // 拍照
  'plugin.zuploader.from.social': '從在線服務中選擇', // 从在线服务中选择
  'plugin.zuploader.wait.upload': '等待上傳', // 等待上传
  'plugin.zuploader.choose.source': '選擇照片', // 选择照片来源
  'plugin.zuploader.choose.image.title': '已上傳照片', // 已上传照片
  'plugin.zuploader.choose.image.choose': '選擇照片', // 选择照片
  'plugin.zuploader.choose.image.range': '選擇$value照片', // 选择 $value 照片

}
