// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sunzi__loading-2NfbK {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  background-color: #f2f2f2;\n}\n.sunzi__loading-animation-315XK {\n  display: flex;\n  width: 110px;\n}\n.sunzi__loading-animation-315XK > svg {\n  border-radius: 12px;\n  border: 5px solid #fff;\n  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .05);\n}\n.sunzi__loading-tip-HH3Fi {\n  position: relative;\n  font-size: 12px;\n  color: #fff;\n  background-color: #c0c4cc;\n  padding: 8px 16px;\n  border-radius: 15px;\n  margin-top: 18px;\n}\n.sunzi__loading-tip-HH3Fi::after {\n  content: '';\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  border-top: 8px dashed transparent;\n  border-left: 6px dashed transparent;\n  border-right: 6px dashed transparent;\n  border-bottom: 6px solid #c0c4cc;\n  top: -12px;\n}\n.sunzi__loading-tip-success-2h587 {\n  background-color: #67c23a;\n}\n.sunzi__loading-tip-success-2h587::after {\n  border-bottom: 6px solid #67c23a;\n}\n.sunzi__animation-unload-3H8nK {\n  display: none;\n}\n.sunzi__animation-loaded-1ZVSa {\n  display: flex;\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "sunzi__loading-2NfbK",
	"loading-animation": "sunzi__loading-animation-315XK",
	"loadingAnimation": "sunzi__loading-animation-315XK",
	"loading-tip": "sunzi__loading-tip-HH3Fi",
	"loadingTip": "sunzi__loading-tip-HH3Fi",
	"loading-tip-success": "sunzi__loading-tip-success-2h587",
	"loadingTipSuccess": "sunzi__loading-tip-success-2h587",
	"animation-unload": "sunzi__animation-unload-3H8nK",
	"animationUnload": "sunzi__animation-unload-3H8nK",
	"animation-loaded": "sunzi__animation-loaded-1ZVSa",
	"animationLoaded": "sunzi__animation-loaded-1ZVSa"
};
module.exports = exports;
