export default {
  'modules.global.uoload.photo': 'Enviar fotos', // 上传图片
  'modules.global.upload': 'Enviar', // 上传
  'modules.global.confirm': 'CONFIRME', // 确认
  'modules.global.processing': 'Processando Sua Imagem', // 确认
  'modules.global.confirm.design': 'CONFIRME O PROJETO', // 确认设计
  'modules.global.free': 'GRÁTIS', // 免费
  'modules.global.add.text': 'Adicionar Textos', // 添加刻字
  'modules.global.preview.confirm': 'Enviando a Foto', // 正常上传图片
  'modules.global.preview.tips': 'Nosso desenhador ajustará seu design para obter um melhor efeito de impressão.', // 预览提示

  'modules.global.add.cart': 'ADICIONAR AO CARRINHO', // 加车
  'modules.global.previous': 'ANTERIOR', // 上一步
  'modules.global.next': 'PRÓXIMO', // 下一步

  'modules.global.preview': 'Pré-visualização', // 预览
  'modules.global.loading': 'Carregando', // 正在加载
  'modules.global.uploading': 'ENVIANDO', // 正在上传

  'modules.global.ai.crop.complete': 'Sim, Completo!', // 切图完成
  'modules.global.ai.croping.photo': 'AI Cortando sua foto', // ai切图中

  'components.oop.title': 'OOP', // OOP
  'components.oop.description': 'Por favor, preencha o conteúdo personalizado de acordo com as seguintes condições.', // 请根据以下条件完成定制内容
  'components.oop.submit': 'Eu vou mudar isso', // 我会改变的

  'modules.global.add.photo': 'Adicionar Fotos', // 添加图片
  'modules.global.checkout': 'checkout', // 结账
  'modules.global.cancel': 'Cancelar', // 取消
  'modules.global.complete': 'completar', // 完成
  'modules.global.change': 'Mudança', // 更换
  'modules.global.cropper': 'Colheita', // 裁剪
  'modules.global.cropper.image': 'Imagem Cropper', // 裁剪照片
  'modules.global.replace': 'Mudança', // 替换
  'modules.global.done': 'completar', // 完成
  'modules.global.delete': 'Excluir', // 删除

  'components.cropper.title': 'Imagem Cropper', // 裁剪照片
  'components.footer.zoom.in': 'Mais Zoom', // 放大
  'components.footer.zoom.out': 'Reduzir o zoom', // 缩小
  'components.footer.rotate.left': 'Vire à esquerda', // 向左旋转
  'components.footer.rotate.right': 'Vire à direita', // 向右旋转

  // 20200602 add
  'components.edit.title': 'EDITAR O MODELO', //  编辑模式
  'components.edit.type': 'TIPO', //  类别
  'components.edit.apply': 'APLIQUE', //  应用
  'components.guide.title': 'Instruções', //  操作说明
  'components.edit.font.text': 'Texto', //  文字内容
  'components.edit.font.family': 'Fonte', //  字体
  'components.edit.font.size': 'Tamanho da Fonte', //  字号
  'components.edit.font.color': 'Cor da Fonte', //  字体颜色
  'components.edit.font.shadow.color': 'Cor da Sombra da Fonte', //  字体阴影颜色
  'components.cropper.back': 'Verso', // 返回
  'components.cropper.crop': 'Colheita', // 裁剪
  'components.cropper.move': 'Mover', // 移动

  'modules.mug.font.validator': 'Por favor edite "nome1"', //  没有被更改，请编辑它
  'modules.bottom.background': 'Opções de Cenário', //  风景选项
  'modules.bottom.layout': 'disposição', //  图层
  'modules.bottom.help': 'ajuda', //  帮助
  'modules.bottom.next': 'próximo', //  下一步
  'modules.bottom.confirm': 'confirme', //  确认
  'modules.bottom.layout.tip': 'Clicando na opção atual será temporariamente colocado e editável', //  点击当前选项将暂时被置顶并为可编辑状态
  'modules.bottom.layout.close': 'FECHAR', //  关闭
  'modules.bottom.operator.top': 'Topo', //  置顶
  'modules.bottom.operator.bottom': 'Inferior', //  置底
  'modules.bottom.operator.up': 'Acima', //  上一层
  'modules.bottom.operator.down': 'Abaixo', //  下一层
  'modules.error.load.font': 'Errore di caricamento del carattere, riprovare', //  字体加载错误，请重新尝试
  'modules.error.upload.result': 'Erro de upload, por favor tente novamente', // 上传失败 请重试

  // 多图模板
  'modules.template.tips': 'Clique na foto para alterá-la ou recortar', // 点击图片区域可以更换图片或裁剪
  'modules.template.validator.images': 'Você precisa fazer upload de fotos de $value.', // 需要传多少张图
  'modules.template.validator.texts': 'Você precisa preencher os textos de $value.', // 需要输入多少段文字
  'modules.template.bottom.template': 'Modelo', // 模版
  'modules.template.default.texts': 'Texto', // 文字

  // 影刻
  'modules.sketch.cropper.tips': 'Por favor, ajuste o rosto e o corpo das pessoas no meio', // 请保证照片中人像边缘尽量清晰可见
  'modules.sketch.title': 'VISUALIZAÇÃO 3D', // 3d预览
  'modules.sketch.request.stage0': 'Carregando…', // 正在上传照片…
  'modules.sketch.request.stage1': 'Removendo fundo…', // 正在将人像从背景抠出…
  'modules.sketch.request.stage2': 'Adicionando filtro…', // 正在加入滤镜效果…
  'modules.sketch.request.stage3': 'Processando para imagem em branco e preto…', // 正在去色处理…
  'modules.sketch.request.stage4': 'Espere, preparando a visualização 3D…', // 请稍后, 预览结果即将呈现…
  'modules.sketch.request.accomplish': 'Concluído, clique para visualizar', // 影刻处理完成, 请点击预览查看
  'modules.sketch.request.error': 'Falha, você pode adicioná-lo ao carrinho.', // 预览处理失败，您可以跳过直接加车
  'modules.sketch.try.again': 'Visualizar novamente', // 重新预览
  'modules.sketch.add.text.placeholder': 'Digite aqui as palavras...(Tornaremos exatamente o mesmo, como mostrado na visualização acima).', // 刻字提示文案
  'modules.sketch.footer.preview': 'Pré-visualização', // 预览并加车
  'modules.sketch.footer.skip': 'Ignorar a visualização', // 跳过预览
  'modules.sketch.accomplish.later': 'Verifique Mais Tarde', // 稍后再说
  'modules.sketch.accomplish.prompt': 'Visualização 3D está pronto, Verifique Agora!', // 稍后再说

  // 撕拉盒子
  'modules.box.step': 'PASSO', // 步数
  'modules.box.side': 'Lado', // 面
  'modules.box.image.upload': 'Carregar', // 上传
  'modules.box.image.change': 'Mudar', // 切换
  'modules.box.step1.title': 'ADICIONAR IMAGEM', // 添加图片
  'modules.box.step1.tips': 'Você pode personalizá-lo com sua foto carregada ou usar a imagem atual.', // 添加图片说明文案
  'modules.box.step2.title': 'ADICIONAR CONFETE', // 添加纸屑
  'modules.box.step2.tips': 'Crie sua bomba explosiva personalizada explodida!', // 添加纸屑说明文案
  'modules.box.oop.title': 'DICAS', // 添加纸屑说明文案
  'modules.box.oop.description': 'Vamos produzi-lo na ordem das fotos abaixo.', // 添加纸屑说明文案

  // 多图胶卷
  'modules.photo.film.title': 'Editar imagem', // 编辑照片
  'modules.photo.film.tips.sort': 'Mantenha pressionado e arraste a imagem para ajustar sua posição.', // 长按拖动图片来排序
  'modules.photo.film.tips.count': 'Você precisa de fazer o upload de $value fotos.', // 需要传多少张图
  'modules.photo.film.tips.count.min': 'Você precisa de enviar pelo menos $value fotos.', // 最少需要传多少张图
  'modules.photo.film.change': 'Trocar', // 更换
  'modules.photo.film.cropper': 'Cortar', // 裁剪
  'modules.photo.film.sort': 'Ordenar', // 排序
  'modules.photo.film.delete': 'Excluir', // 删除

  // 简版照片书
  'modules.photo.book.deleted.page': 'Página excluída', // 已删除页面
  'modules.photo.book.delete.page': 'deletar página', // 删除页面
  'modules.photo.book.change.layout': 'mudar o plano', // 更改布局
  'modules.photo.book.add.photo.page': 'Adicionar página de foto', // 添加照片页面
  'modules.photo.book.add.compose.page': 'Adicionar página de colagem', // 添加拼贴页面
  'modules.photo.book.validator': 'Adicione $value mais páginas para comprar', // 再添加 $value 页即可购买
  'modules.photo.book.replace.image': 'Substituir Foto', // 替换照片
  'modules.photo.book.remove.image': 'Remover', // 移除
  'modules.photo.book.page': 'Página', // 页
  'modules.photo.book.type': 'material de livro', // 图书材质
  'modules.photo.book.size': 'tamanho do livro', // 图书尺寸
  'modules.photo.book.order.qty': 'quantidade', // 数量
  'modules.photo.book.order.total': 'total', // 总计
  'modules.photo.book.warning.title': 'Aviso', // 警告
  'modules.photo.book.warning.cover.title': 'Adicionar título para a capa do álbum com foto', // 为照片书添加标题
  'modules.photo.book.warning.cover.description': 'Clique na capa do álbum com foto para adicionar um título', // 点击封面即可在照片书封面添加标题。
  'modules.photo.book.warning.image.title': 'Imagens de baixa resolução encontradas', // 发现低分辨率图片
  'modules.photo.book.warning.image.description': 'Pode ficar confuso quando impresso.', // 打印出来可能比较模糊
  'modules.photo.book.add.title': 'Adicionar Título', // 添加标题
  'modules.photo.book.zuploader.subtitle': 'Precisa de pelo menos $value fotos', // 至少需要 value 张照片
  'modules.photo.book.guide.title': `Guia de Compra`, // 操作引导
  'modules.photo.book.guide.position': `Pressione e arraste para ajustar a ordem`, // 长按图片可调整顺序
  'modules.photo.book.guide.layout': `Clique no ícone ao lado para alterar o plano`, // 点击侧边更多可切换图片样式
  'modules.photo.book.guide.button': 'Ok Obrigado', // 好的 我知道了

  // 照片墙
  'modules.photo.wall.choose.frame': 'escolha um quadro', // 选择边框
  'modules.photo.wall.choose.style': 'escolha um estilo', // 选择样式
  'modules.photo.wall.zoom.pinch': 'apertar e ampliar', // 移动和放大
  'modules.photo.wall.zoom.scroll': 'role para ampliar', // 滚动放大
  'modules.photo.wall.choose.design': 'Escolha um Design', // 选择设计
  'modules.photo.wall.choose.design.tips': 'Escolha entre paredes da galeria para personalizar', // 从库墙中选择要自定义的墙
  'modules.photo.wall.frames': 'quadros', // 框
  'modules.photo.wall.image.list.title': 'Adicione fotos para ver mais designs', // 添加照片显示更多设计选项
  'modules.photo.wall.container.photo': 'ADICIONAR AO CARRINHO', // 我的图片
  'modules.photo.wall.zuploader.subtitle': 'Você pode enviar fotos de até $value',  // 你最多可以上传 $value 张照片
  'modules.photo.wall.image.max.count': 'Aceitamos fotos de até $value, o excesso será ignorado',  // 我们最多支持 $value 张照片，超出的将被忽略

  // zuploader
  'plugin.zuploader.from.album': 'Escolha de seu álbum', // 从相册中选择
  'plugin.zuploader.from.social': 'Escolha entre os Serviços Online', // 从在线服务中选择
  'plugin.zuploader.from.camera': 'Tire uma foto', // 拍照
  'plugin.zuploader.wait.upload': 'Aguardando Envio', // 等待上传
  'plugin.zuploader.choose.source': 'Escolha fotos', // 选择照片来源
  'plugin.zuploader.choose.image.title': 'Fotos enviadas', // 已上传照片
  'plugin.zuploader.choose.image.choose': 'Selecione fotos', // 选择照片
  'plugin.zuploader.choose.image.range': 'Selecione $value itens', // 选择 $value 照片

  // lowQuality
  'plugin.low.quality.title': 'Baixa Qualidade de Imagem', // 低图像质量
  'plugin.low.quality.tips': 'Esta foto é realmente muito pequena. Provavelmente fará um ladrilho borrado!', // 这张照片其实很小。它可能会变得模糊
  'plugin.low.quality.keep': 'Continue Assim Mesmo', // 不管怎样都保留
  'plugin.low.quality.remove': 'Remover da Ordem', // 从订单中删除

  // 无限魔方
  'modules.infinite.cube.zuploader.title': 'Precisa de $value de fotos', // 需要 $value 张照片
  'modules.infinite.cube.input.tip': 'Arraste ou clique para ajustar a imagem', // 拖动或点击来调整图片
  'modules.infinite.cube.preview3d.tip': 'Arraste o cubo para ver', // 拖动观察魔方
  'modules.infinite.cube.preview3d.tap': 'Me toque', // 点击我(按钮)
  'modules.infinite.cube.preview.choose': 'Escolha sua foto de capa', // 选择你的封面
  'modules.infinite.cube.save': 'Salve', // 保存
  'modules.infinite.cube.share': 'compartilhar e $elm $value off $end', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.and': 'compartilhar e', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.share.off': 'off', // 分享$elm 优惠$value $end($elm和$end之间的内容表示重点,页面有特殊样式)
  'modules.infinite.cube.or': 'ou', // 或者
  'modules.infinite.cube.without.share': 'sem compartilhar & adicionar ao carrinho', // 不分享直接加车

  // 增值产品-relatedProduct
  'increment.related.product.title': 'Escolha um pacote super surpresa para o seu presente', // 选择超惊喜套餐作为礼物
  'increment.related.product.no.thanks': 'Não, Obrigado', // 不用，谢谢

  // 增值产品-vip
  'increment.vip.tip': 'A melhor escolha para processar ordens com produção & envio prioritários', // 优先生产和运输订单的最佳选择
  'increment.vip.service': 'SERVIÇO VIP', // vip 服务

  // 新袜子
  'modules.new.sock.network.error': 'Erro de Rede!', // 网络错误
  'modules.new.sock.ai.crop.error': 'Erro da Colheita AI!', // 切图服务错误
  'modules.new.sock.replace.photo': 'Substituir Foto', // 替换照片
  'modules.new.sock.crop.myself': 'Colher sozinho', // 自己切图
  'modules.new.sock.try.again': 'Tente novamente', // 重试
  'modules.new.sock.edit.avatar': 'Editar Avatar', // 编辑头像
  'modules.new.sock.add.line.text.title': 'Adicione o Texto?', // 是否需要刻字
  'modules.new.sock.add.line.text.promotion': '80% dos clientes preferem adicionar texto personalizado para fazer presentes perfeitos!', // 80％的客户更喜欢添加个性化文字来制作精美的礼物！
  'modules.new.sock.add.line.text': 'ADICIONE TEXTO', // 添加文字
  'modules.new.sock.without.text': 'SEM TEXTO', // 不刻字
  'modules.new.sock.color': 'COR', // 颜色
  'modules.new.sock.size': 'TAMANHO', // 尺寸
  'modules.new.sock.sock': 'MEIA', // 袜子
  'modules.new.sock.share.title': 'Compartilhe para Obter', // 分享后你将会得到
  'modules.new.sock.social.avatar': 'Imagem de Perfil Social Personalizada.', // 你的社交新头像
  'modules.new.sock.free.download': 'Baixar Grátis', // 免费下载
  'modules.new.sock.new.mobile.wallpaper': 'Papel de Parede Móvel Personalizado.', // 你的手机新壁纸
  'modules.new.sock.new.pc.wallpaper': 'Papel de Parede de PC Personalizado.', // 你的电脑新壁纸
  'modules.new.sock.discount': 'Desconto', // 折扣
  'modules.new.sock.discount.price': '$value OFF', // 立减 $value 块
  'modules.new.sock.share.now': 'COMPARTILHE AGORA', // 立刻分享
  'modules.new.sock.no.thanks': 'NÃO, OBRIGADO', // 不分享
  'modules.new.sock.shared.title': 'PARABÉNS!', // 恭喜
  'modules.new.sock.download': 'Baixar', // 下载
  'modules.new.sock.generating.image': 'Gerando imagem de compartilhamento', // 正在生成分享图
  'modules.new.sock.total': 'total', // 总计

  // 2020 11 17 add
  // 台历
  'modules.desktop.calendar.cover': 'Capa', // 封面
  'modules.desktop.calendar.change.layout': 'Alterar Plano da Foto', // 更改照片布局
  'modules.desktop.calendar.validator': 'falta $value de fotos', // 缺 $value 张照片
  'modules.desktop.calendar.replace.image': 'Substituir Foto', // 替换照片
  'modules.desktop.calendar.remove.image': 'Remover', // 移除

  // 挂历
  'modules.wall.calendar.tap.day': 'Toque em Data & Adicionar Evento de Calendário', // 点击日期和添加日历事件
  'modules.wall.calendar.cover': 'Capa', // 封面
  'modules.wall.calendar.change.layout': 'Alterar Plano da Foto', // 更改照片布局
  'modules.wall.calendar.validator': 'Envie $value mais fotos para', // 缺 $value 张照片
  'modules.wall.calendar.replace.image': 'Substituir Foto', // 替换照片
  'modules.wall.calendar.remove.image': 'Remover', // 移除
  'modules.wall.calendar.and': 'E', // 和
  'modules.wall.calendar.event.add': 'Adicione o seu evento', // 添加你的事件
  'modules.wall.calendar.event.choose': 'Escolha o tipo de evento', // 选择你的事件类型
  'modules.wall.calendar.event.edit': 'Editar Evento', // 编辑事件
  'modules.wall.calendar.event.clear': 'Limpar Evento', // 清除事件
  'modules.wall.calendar.event.create': 'Criar novo evento', // 创建新事件
  'modules.wall.calendar.event.graduation': 'graduação', // 毕业季
  'modules.wall.calendar.event.holidays': 'feriados', // 度假
  'modules.wall.calendar.event.wedding': 'Casamento', // 婚礼
  'modules.wall.calendar.event.bar': 'barra', // 酒吧
  'modules.wall.calendar.event.party': 'festa', // 派对
  'modules.wall.calendar.event.birthday': 'aniversário', // 生日
  'modules.wall.calendar.event.anniversarie': 'aniversário', // 周年纪念日
  'modules.wall.calendar.event.other': 'outros', // 其他
  'modules.wall.calendar.event.tips': 'Toque e Edite', // 点击编辑
  'modules.wall.calendar.cover.title.show': 'Mostrar Título', // 显示标题
  'modules.wall.calendar.cover.title.hide': 'Ocultar Título', // 隐藏标题

  //  other
  'modules.global.revoke': 'Revogar', // 撤销
  'modules.global.warning.title': 'Atenção', // 警告
  'modules.global.zoom.pinch': 'apertar e zunir', // 移动和放大
  'modules.global.edit.confirm': 'Você não terminou a edição. Tem certeza que deseja sair?', // 您还没有编辑完，确定要退出吗
  'modules.global.delete.tips': 'Tem certeza que deseja deletar isso?', // 删除提示

  // Spotify Code
  'modules.spotify.code.search.hot': 'Pesquisa Imperdível', // 热门搜索
  'modules.spotify.code.search.reuslt': 'Resultados da Pesquisa', // 搜索结果
  'modules.spotify.code.placeholder': 'Pesquisar a Canção、Artista、Álbum', // 输入你的歌曲
  'modules.spotify.code.album.upload': 'Envie Sua Foto', // 上传你的图片
  'modules.spotify.code.album.track': 'Use a Capa do Álbum', // 使用相册封面
  'modules.spotify.code.change.color': 'Escolha Sua Cor', // 选择你的颜色
  'modules.spotify.code.custom.artists': 'Nome Personalizado do Artista', // 你可以自己定制演奏者
  'modules.spotify.code.search.no.result': 'Ops, Sem Resultado.', // 没结果
  'modules.spotify.code.add.text.ask': 'Você Precisaria Adicionar Texto', // 你是否需要添加刻字
  'modules.spotify.code.add.text': 'Adicione o Texto', // 添加刻字
  'modules.spotify.code.skip.add.cart': 'Não, Obrigado. Adicionar ao Carrinho', // 不谢谢 加车
  'modules.spotify.code.add.text.title': 'Adicione seu texto aqui', // 在这添加你的刻字
  'modules.spotify.code.add.text.or': 'ou', // 或
  'modules.spotify.code.buy.it': 'Claro, compre agora', // 是的 买它
  'modules.spotify.code.template.title': 'Escolha o seu estilo favorito',
  "modules.spotify.code.multi.upload": "Envie suas fotos", // 上传你的照片
}
