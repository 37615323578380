import * as THREE from 'three';
import OrbitControls from 'sunzi-three-orbitcontrols';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import FBXAnimationLoader from 'three-fbxloader-offical';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export interface ThreedOptions {
  /** 容器 */
  container: HTMLDivElement;
  /** 物体名称和属性值 */
  objectNames?: ThreedProperties[];
  /** 相机属性 */
  cameraOptions?: {
    position?: [number, number, number];
    target?: [number, number, number];
    option?: [number, number, number];
  };
}

export interface ThreedProperties {
  name: string;
  map: string;
  color?: number;
}

export interface ThreedObject {
  [key: string]: any;
}

export interface IsLoadingModelMap {
  [key: string]: boolean;
}

class Threed {
  public container: HTMLDivElement;
  public objectNames: ThreedProperties[];

  public camera: THREE.PerspectiveCamera;
  public scene: THREE.Scene;
  public renderer: THREE.WebGLRenderer;
  public orbitControls: any;
  public clock: THREE.Clock;
  public textureloader: THREE.TextureLoader;
  public rafs: number[] = [];

  // 3d模型Map对象
  public modelMap: any = {};
  private isLoadingModelMap: IsLoadingModelMap = {};
  public objects: ThreedObject = {};
  public animationsMap: any = {};
  public mixerMap: any = {};

  /** 预加载fbx文件 */
  static preLoadFBX = (pathFbx: string) =>
    new Promise<void>(resolve => {
      new FBXLoader().load(pathFbx, () => {
        resolve();
      });
    });

  constructor(options: ThreedOptions) {
    const { container, objectNames, cameraOptions } = options;
    this.container = container;
    this.objectNames = objectNames || [];

    const { option: cameraOption, position: cameraPosition = [], target: cameraTarget = [] } = cameraOptions || {};
    const { offsetWidth, offsetHeight } = container;
    const camera = (this.camera = new THREE.PerspectiveCamera(
      ...(cameraOption || [45, offsetWidth / offsetHeight, 0.1, 5000])
    ));
    camera.position.set(cameraPosition[0] || 0, cameraPosition[1] || 50, cameraPosition[2] || 500);
    // camera.lookAt(new THREE.Vector3(...(cameraTarget || [0, 0, 0])));

    this.scene = new THREE.Scene();

    const renderer = (this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, precision: 'highp' }));
    renderer.setPixelRatio(window.devicePixelRatio);

    const orbitControls = this.orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.target.copy(new THREE.Vector3(...(cameraTarget || [0, 0, 0])));
    orbitControls.enableZoom = false; // 禁止缩放
    orbitControls.update();

    this.clock = new THREE.Clock();

    this.textureloader = new THREE.TextureLoader();
  }

  /** 加载fbx文件 */
  public loadFBX({
    pathFbx,
    timeout = 30 * 1000,
    isDoubleSide = false
  }: {
    pathFbx: string;
    timeout?: number;
    isDoubleSide?: boolean;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.modelMap[pathFbx]) {
        resolve(this.modelMap[pathFbx]);
        return;
      }
      this.isLoadingModelMap[pathFbx] = true;
      new FBXLoader().load(pathFbx, (object: any) => {
        if (this.modelMap[pathFbx]) {
          resolve(this.modelMap[pathFbx]);
          return;
        }
        this.modelMap[pathFbx] = object;
        object.traverse((child: any) => {
          this.objects[child.name] = child;
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
          // 双面材质
          if (isDoubleSide) {
            const materials = child.material;
            if (Array.isArray(materials)) {
              materials.forEach(material => {
                material.side = THREE.DoubleSide;
                material.needsUpdate = true;
              });
            } else if (materials) {
              materials.side = THREE.DoubleSide;
              materials.needsUpdate = true;
            }
          }
        });
        const name = object.name;
        if (!this.animationsMap[name]) {
          new FBXAnimationLoader().load(pathFbx, (obj: any) => {
            this.modelMap[pathFbx].animations = obj.animations;
            this.animationsMap[name] = obj.animations;
            resolve(object);
          });
        } else {
          this.modelMap[pathFbx].animations = this.animationsMap[name];
          resolve(object);
        }
        this.isLoadingModelMap[pathFbx] = false;
        this.mixerMap[name] = new THREE.AnimationMixer(object);
      });
      setTimeout(() => {
        reject();
      }, timeout);
    });
  }

  /** 渲染到dom节点 */
  public async render() {
    const container = this.container;
    this.camera.aspect = container.clientWidth / container.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(this.renderer.domElement);
    this.animate();
  }

  /** 更新纹理和颜色 */
  public updateTexture() {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.objectNames.map(
          objectName =>
            new Promise<void>((resolve, reject) => {
              const { map, name, color } = objectName;
              const child = this.objects[name];
              if (child && child.isMesh && typeof color === 'number') {
                const materials = child.material;
                if (Array.isArray(materials)) {
                  materials.forEach(material => {
                    material.color.set(color);
                  });
                } else {
                  materials.color.set(color);
                }
              }
              if (map) this.loadTexture({ source: map, name, type: 'map' }).then(resolve).catch(reject);
              else resolve();
            })
        )
      ])
        .then(resolve)
        .catch(reject);
    });
  }

  /** 针对某个object改变贴图 */
  public loadTexture({
    source,
    name,
    type,
    isRepeatWrapping = true,
    bumpScale
  }: {
    source: string;
    name: string;
    type: string;
    isRepeatWrapping?: boolean;
    bumpScale?: number;
  }) {
    return new Promise<void>((resolve, reject) => {
      this.textureloader.load(
        source,
        texture => {
          const obj = this.scene.getObjectByName(name);
          let mapType: string;
          switch (type) {
            case 'normal':
              mapType = 'normalMap';
              break;
            case 'bump':
              mapType = 'bumpMap';
              break;
            case 'roughness':
              mapType = 'roughnessMap';
              break;
            case 'emissive':
              mapType = 'emissiveMap';
              break;
            case 'light':
              mapType = 'lightMap';
              break;
            default:
              mapType = 'map';
          }
          obj &&
            obj.traverse((child: any) => {
              if (child.isMesh) {
                if (isRepeatWrapping) {
                  texture.wrapS = THREE.RepeatWrapping;
                  texture.wrapT = THREE.RepeatWrapping;
                }
                const materials = child.material;
                if (Array.isArray(materials)) {
                  materials.forEach(material => {
                    material[mapType] = texture;
                    if (mapType === 'bumpMap')
                      child.material.bumpScale = typeof bumpScale === 'number' ? bumpScale : 0.02;
                    material.needsUpdate = true;
                  });
                } else {
                  materials[mapType] = texture;
                  if (mapType === 'bumpMap')
                    child.material.bumpScale = typeof bumpScale === 'number' ? bumpScale : 0.02;
                  materials.needsUpdate = true;
                }
              }
            });
          resolve();
        },
        () => {},
        () => reject('loadTextureError')
      );
    });
  }

  /**
   * 加载环境贴图,整个环境(所有物体)/某些物体
   * 高光材质目前不起作用,标准材质物理材质可
   */
  public loadEnvMap = ({
    path,
    objectName,
    envMapIntensity,
    background = false,
  }: {
    path: string;
    objectName?: string;
    envMapIntensity?: number;
    background?: boolean;
  }) =>
    new Promise<void>((resolve, reject) => {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();
      new RGBELoader().setDataType(THREE.UnsignedByteType).load(
        path,
        texture => {
          const renderTarget = pmremGenerator.fromEquirectangular(texture);
          const envMap = renderTarget.texture;
          pmremGenerator.dispose();

          if (background)
            this.scene.background = envMap;
          if (!objectName) {
            this.scene.environment = envMap; // 给场景添加环境光效果
          } else {
            const obj: any = this.scene.getObjectByName(objectName);
            if (obj && obj.isMesh) {
              const materials = obj.material;
              if (Array.isArray(materials)) {
                materials.forEach(material => {
                  material.envMap = renderTarget.texture;
                  if (envMapIntensity) material.envMapIntensity = envMapIntensity;
                  material.needsUpdate = true;
                });
              } else {
                materials.envMap = renderTarget.texture;
                if (envMapIntensity) materials.envMapIntensity = envMapIntensity;
                materials.needsUpdate = true;
              }
            }
          }
          resolve();
        },
        () => {},
        () => reject('loadEnvMapError')
      );
    });

  /** 更换场景背景色 */
  public updateBackground(r: number, g: number, b: number) {
    const rgb = `rgb(${r}, ${g}, ${b})`;
    this.scene.background = new THREE.Color(rgb);
  }

  /** 执行模型中的动画,index是第几个AnimationClip */
  public execAnimationClip({
    name,
    index = 0,
    start = 0,
    duration = 1,
    timeScale
  }: {
    name: string;
    index?: number;
    start?: number;
    duration?: number;
    timeScale?: number;
  }) {
    const object = this.objects[name];
    const animations = this.animationsMap[name];
    const mixer = this.mixerMap[name];
    if (!object || !animations || !mixer) return;
    const animationClip = animations[index];
    const animationAction = mixer.clipAction(animationClip);
    if (typeof timeScale === 'number') {
      animationAction.setEffectiveTimeScale(timeScale);
    } else {
      animationAction.setEffectiveTimeScale(1);
    }
    animationAction.clampWhenFinished = true;
    animationAction.paused = false;
    animationAction.loop = THREE.LoopOnce;
    animationAction.time = start;
    animationClip.duration = start + duration;
    animationAction.play();
  }

  /** 导出图片
   * @shootPoint 拍摄点
   */
  public exportImage({
    shootPoint,
    rendererWidth,
    rendererHeight
  }: {
    shootPoint: [number, number, number];
    rendererWidth?: number;
    rendererHeight?: number;
  }) {
    return new Promise(resolve => {
      // 创建导出相机
      const cameraExport = this.camera.clone();
      cameraExport.position.set(...shootPoint);

      // 因为这里改变渲染 DOM 的宽高，需要保存一个原始的宽高，导出完成后设置回去
      const renderer = this.renderer;
      const origin = new THREE.Vector2();
      renderer.getSize(origin);
      const width = rendererWidth || origin.width;
      const height = rendererHeight || origin.height;

      cameraExport.aspect = width / height;
      cameraExport.updateProjectionMatrix();
      renderer.setSize(width, height, false);
      renderer.render(this.scene, cameraExport);

      renderer.domElement.toBlob((blob: any) => resolve(blob));

      renderer.setSize(origin.width, origin.height);
    });
  }

  public animate() {
    this.cancel();
    this.orbitControls.update();
    this.rafs.push(requestAnimationFrame(this.animate.bind(this)));
    const mixerMap = this.mixerMap;
    const delta = this.clock.getDelta();
    for (const key in mixerMap) {
      mixerMap[key].update(delta);
    }
    this.renderer.render(this.scene, this.camera);
  }

  public cancel() {
    this.rafs.forEach(item => {
      cancelAnimationFrame(item);
    });
  }
}

export default Threed;
