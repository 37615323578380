import React, { Component } from 'react';
import styles from './style.less';

export interface LoadingProps {
  names: string[];
}

class Loading extends Component<LoadingProps> {
  render() {
    const { names } = this.props;
    return (
      <div className={styles.animalLoading}>
      <div className={styles.cube}>
        <div className={styles.cubeItem}>
          <img src={require('@/assets/icon-cat.svg')} />
          <span className={styles.tipCat}>{name[0]}</span>
        </div>
        <div className={styles.cubeItem}>
          <img src={require('@/assets/icon-dog.svg')} />
          <span className={styles.tipDog}>{name[1]}</span>
        </div>
      </div>
    </div>
    )
  }
};

export default Loading;