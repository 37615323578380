import React, { useState, useRef, useEffect, useMemo, Fragment } from 'react';
import classnames from 'classnames';
import { fabric } from 'sunzi-fabric';
import dayjs from 'dayjs';
import { useLocal } from '@/hooks';
import { RelatedProductIncrement, VipIncrement } from '@/increment/increment';
import { Vip } from '@/increment';
import { Layout, Wrapper, Loading, Drawer } from '@/components';
import { i18n, createObjectURL, uuid, uploadKey, upload7nObjectURL, getToken7n, canvasToBlobURL, priceFormat, removeEmojis, thumbnail7n } from '@/utils';
import { SpotifyColor, SpotifyTrack } from '../spotify-code';
import { SpotifyCodeProps, narrowTrackName } from '..';
import { SpotifyLayout, SpotifyOuput } from './post-card';
import spotifySearch from '../request';
import Template from './template';
import TrackCanvas from './track-canvas';
import Icon from '../icon';
import TrackItem from '../track-item';
import Cropai from './cropai';
import styles from '../style.less';

interface PostCardProps extends Omit<SpotifyCodeProps, 'price' | 'layout' | 'bottomImage' | 'bindProduct' | 'onConfirm'> {
  defaultLayoutSku?: string;
  layouts: SpotifyLayout[];
  /** 确认回调 */
  onConfirm: (
    effect: string,
    data: SpotifyOuput,
    vipIncrement?: VipIncrement | RelatedProductIncrement
  ) => void;
}

const PostCard: React.FC<PostCardProps> = ({
  shop,
  theme = {
    r: 29,
    g: 184,
    b: 84
  },
  gtag,
  colors,
  defaultColor,
  defaultLayoutSku,
  layouts,
  recommendTracks = [],
  defaultCustomAlbum,
  customArtistsTitle,
  increment,
  onClose,
  onConfirm
}) => {
  // 设置国际化
  useLocal(shop.language);
  /** 共享canvas，避免重复创建canvas */
  const _canvas = useRef<fabric.Canvas[]>([]);
  /** 输入框实例 */
  const _input = useRef<HTMLInputElement>(null);
  /** 搜索列表实例 */
  const _list = useRef<HTMLDivElement>(null);
  /** 搜索结果游标 */
  const _pagination = useRef<number>(0);
  /** 搜索结果总数 */
  const _total = useRef<number>(0);
  /** 搜索结果key*/
  const _listKey = useRef<string>();
  /** 布局信息 */
  const [ layout, setLayout ] = useState<SpotifyLayout>(layouts.find(item => item.sku === defaultLayoutSku) ?? layouts[0]);
  /** 模版选择显示隐藏 */
  const [ templateVisible, setTemplateVisible ] = useState<boolean>(layouts.length > 1);
  /** 搜索内容 */
  const [ searchValue, setSearchValue ] = useState<string>('');
  /** 搜索加载状态 */
  const [ searchLoading, setSearchLoading ] = useState<boolean>();
  /** 搜索加载更多状态 */
  const [ searchLoadmore, setSearchLoadmore ] = useState<boolean>();
  /** 搜索加载状态 */
  const [ tracks, setTracks ] = useState<SpotifyTrack[]>();
  /** 滚动渐变 */
  const [ scrollGradient, setScrollGradient ] = useState<boolean[]>([]);
  /** 当前颜色值 */
  const [ currentColor, setCurrentColor ] = useState<SpotifyColor>(defaultColor ?? colors[0]);
  /** 当前激活的歌曲 */
  const [ currentTrack, setCurrentTrack ] = useState<SpotifyTrack>();
  /** 封面是否自定义 */
  const [ customAlbum, setCustomAlbum ] = useState<boolean>(false);
  /** 自定义演奏者 */
  const [ artistsValue, setArtistsValue ] = useState<string>('');
  /** 自定义刻字内容 */
  const [ lineTextValue, setLineTextValue ] = useState<string>('');
  /** 自定义多行刻字内容 */
  const [ textAreaValue, setTextAreaValue ] = useState<string>('');
  /** 当前激活的歌曲 */
  const [ currentLayoutIndex, setCurrentLayoutIndex ] = useState<number>(0);
  /** 封面图 */
  const [ customAlbumImage, setCustomAlbumImage ] = useState<string>();
  /** 封面裁剪原图 */
  const [ cropaiImage, setCropaiImage ] = useState<string>();
  /** 预览显隐 */
  const [ previewVisible, setPreviewVisible ] = useState<boolean>(false);
  /** 上传显隐 */
  const [ uploadVisible, setUploadVisible ] = useState<boolean>(false);
  /** VIP显隐 */
  const [ vipIncrementVisible, setVipIncrementVisible ] = useState<boolean>(false);
  /** 关联产品显示隐藏 */
  const [ relatedProductVisible, setRelatedProductVisible ] = useState<boolean>(false);
  /** 歌曲加载状态 */
  const [ trackLoading, setTrackLoading ] = useState<boolean>(false);
  // 关联产品
  const relatedProduct = (increment?.relatedProduct || [])[0];
  // 当前的布局
  const currentLayout = useMemo(() => {
    return layout.children[currentLayoutIndex].layout;
  }, [ layout, currentLayoutIndex ]);

  useEffect(() => {
    setLayout(layouts.find(item => item.sku === defaultLayoutSku) ?? layouts[0]);
  }, [ layouts, defaultLayoutSku ])

  useEffect(() => {
    _canvas.current = Array.from({ length: layout.children.length * 2 }).map(() =>
      new fabric.Canvas(document.createElement('canvas'))
    );
  }, []);

  useEffect(() => {
    setCurrentColor(defaultColor ?? colors[0]);
    gtag('event', 'tap_change_color');
  }, [ colors, defaultColor ]);

  useEffect(() => {
    gtag('event', 'tap_custom_album', {
      'event_label': customAlbum
    });
  }, [ customAlbum ]);

  useEffect(() => {
    if (currentTrack) {
      setArtistsValue(currentTrack.artists);
      gtag('event', 'tap_spotify_track', {
        'event_label': currentTrack.name
      });
    }
  }, [ currentTrack ]);

  /** 歌曲变化监听 */
  const handleTrackChange = (track?: SpotifyTrack) => {
    if (track) {
      setCurrentTrack({ ...track, name: narrowTrackName(track.name) });
      setCurrentLayoutIndex(0);
    } else {
      setCurrentTrack(track);
    }
  }

  /** 监听布局信息改变 */
  const handleTemplateConfirm = (layout: SpotifyLayout) => {
    setLayout(layout);
    setTemplateVisible(false);
  }

  /** 歌曲布局变化监听 */
  const handleTrackLayoutChange = (index: number = currentLayoutIndex) => {
    if (index < layout.children.length - 1) {
      if (Object.keys(layout.children[index + 1].layout).length === 0) {
        handleTrackLayoutChange(index + 1);
      } else
        setCurrentLayoutIndex(currentLayoutIndex + 1);
    } else
      setPreviewVisible(true);
  }

  useEffect(() => {
    setCustomAlbum(!!currentLayout.albumCoord);
  }, [ currentLayout ]);

  /** 监听搜索按键 */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13)
      handleSearch();
  }

  /** 搜索监听 */
  const handleSearch = async () => {
    setSearchLoading(true);
    _input.current?.blur();
    // 每次搜索生成一个新的key，强制dom更新
    _listKey.current = uuid();
    await _spotifySearch();
    gtag('event', 'tap_search_track', {
      'event_label': searchValue
    });
    setSearchLoading(false);
  }

  /** spotify api 搜索 */
  const _spotifySearch = async (pagination: number = 0) =>
    spotifySearch(searchValue, pagination)
      .then(response => {
        if (response.status && response.data) {
          setTracks(pagination === 0 ?
            response.data.tracks :
            (tracks || []).concat(response.data.tracks)
          );
          _total.current = response.data.total;
          _pagination.current = pagination;
          if (_list.current) {
            const { scrollHeight, offsetHeight } = _list.current;
            setScrollGradient([false, scrollHeight > offsetHeight]);
          }
        } else {
          gtag('event', 'tap_search_error');
        }
      });

  /** 搜索列表滚动监听 */
  const handleTrackListScroll = async () => {
    if (_list.current) {
      const { scrollTop, scrollHeight, offsetHeight } = _list.current;
      setScrollGradient([scrollTop > 100, scrollHeight - scrollTop - offsetHeight > 100]);
      if (scrollHeight - scrollTop - offsetHeight <= 80
        && !searchLoadmore
        && (tracks?.length ?? 0) < _total.current
      ) {
        setSearchLoadmore(true);
        await _spotifySearch(_pagination.current + 1);
        setSearchLoadmore(false);
      }
    }
  }

  /** content渲染 */
  const renderContent = () => {
    if (tracks === undefined)
      return (
        <div className={classnames(styles.trackListWrapper, styles.recommend)}>
          <div className={styles.recommendTitle}>🔥{i18n.format('modules.spotify.code.search.hot')}</div>
          <div className={styles.trackList}>
            {recommendTracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => handleTrackChange(item)}
              />
            ))}
          </div>
        </div>
      );
    else if (tracks.length > 0)
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div
            ref={_list}
            key={_listKey.current}
            className={styles.trackList}
            onScrollCapture={handleTrackListScroll}
          >
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => handleTrackChange(item)}
              />
            ))}
            {tracks.length < _total.current &&
              <div className={styles.loadmore}>
                <Loading size={20} />
              </div>
            }
          </div>
          <Wrapper className={styles.trackListGradient}>
            <div className={classnames({
              [styles.topGradient]: scrollGradient[0]
            })} />
            <div className={classnames({
              [styles.bottomGradient]: scrollGradient[1]
            })} />
          </Wrapper>
        </div>
      );
    else
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div className={classnames(styles.trackList, styles.trackListEmpty)}>
            <img src={require('@/assets/search-no-result.png')} />
            <span>{i18n.format('modules.spotify.code.search.no.result')}</span>
          </div>
        </div>
      );
  }

  const renderTrackContent = (track: SpotifyTrack) => {
    // 当前激活的layoutChildren
    const layoutChildren = layout.children[currentLayoutIndex];

    return (
      <Wrapper
        key={currentLayoutIndex}
        className={classnames(styles.trackWrapper, styles.trackWrapperPostCard)}
      >
        {layoutChildren.layout.albumCoord ? (
          <div className={classnames(styles.trackContent, styles.trackContentAlbum)}>
            <div
              className={styles.trackCanvasWrapper}
              onClick={() => setCustomAlbum(true)}
            >
              <TrackCanvas
                canvas={_canvas.current[layout.children.length + currentLayoutIndex]}
                track={track}
                makeWidth={layout.makeWidth}
                makeHeight={layout.makeHeight}
                layout={layoutChildren}
                color={currentColor}
                album={defaultCustomAlbum}
                artistsValue={artistsValue}
                lineTextValue={lineTextValue}
                textAreaValue={textAreaValue}
              />
              <div className={styles.title}>{i18n.format('modules.spotify.code.album.upload')}</div>
              <div className={styles.radioWrapper}>
                <div className={classnames(styles.radio, {
                  [styles.radioActive]: customAlbum
                })}>
                  <Icon type="confirm" />
                </div>
              </div>
            </div>
            <div
              className={styles.trackCanvasWrapper}
              onClick={() => setCustomAlbum(false)}
            >
              <TrackCanvas
                canvas={_canvas.current[layout.children.length + currentLayoutIndex + 1]}
                track={track}
                makeWidth={layout.makeWidth}
                makeHeight={layout.makeHeight}
                layout={layoutChildren}
                color={currentColor}
                artistsValue={artistsValue}
                lineTextValue={lineTextValue}
                textAreaValue={textAreaValue}
              />
              <div className={styles.title}>{i18n.format('modules.spotify.code.album.track')}</div>
              <div className={styles.radioWrapper}>
                <div className={classnames(styles.radio, {
                  [styles.radioActive]: !customAlbum
                })}>
                  <Icon type="confirm" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classnames(styles.trackContent)}>
            <TrackCanvas
              canvas={_canvas.current[layout.children.length + currentLayoutIndex]}
              track={track}
              makeWidth={layout.makeWidth}
              makeHeight={layout.makeHeight}
              layout={layoutChildren}
              color={currentColor}
              album={defaultCustomAlbum}
              artistsValue={artistsValue}
              lineTextValue={lineTextValue}
              textAreaValue={textAreaValue}
            />
          </div>
        )}
        <div className={styles.trackLayoutName}>
          {[
            i18n.format('modules.spotify.code.side.front'),
            i18n.format('modules.spotify.code.side.back')
          ][currentLayoutIndex]
        }</div>
        <div className={styles.trackEditContent}>
          <div className={classnames(styles.trackArtist, {
            [styles.trackArtistHide]: !layoutChildren.layout.artistCoord
          })}>
            <div className={styles.title}>{customArtistsTitle ?? i18n.format('modules.spotify.code.custom.artists')}</div>
            <div className={styles.inputWrapper}>
              <input
                value={artistsValue}
                onChange={e => setArtistsValue(removeEmojis(e.target.value))}
              />
            </div>
          </div>
          <div className={classnames(styles.trackLineText, {
            [styles.trackLineTextHide]: !layoutChildren.layout.lineTextCoord
          })}>
            <div className={styles.title}>{i18n.format('modules.spotify.code.custom.line.text')}</div>
            <div className={styles.inputWrapper}>
              <input
                value={lineTextValue}
                onChange={e => setLineTextValue(removeEmojis(e.target.value))}
              />
            </div>
          </div>
          <div className={classnames(styles.trackTextArea, {
            [styles.trackTextAreaHide]: !layoutChildren.layout.textAreaCoord
          })}>
            <div className={styles.title}>{i18n.format('modules.spotify.code.custom.line.text')}</div>
            <div className={styles.inputWrapper}>
              <textarea
                value={textAreaValue}
                onChange={e => setTextAreaValue(removeEmojis(e.target.value))}
              />
            </div>
          </div>
          <div className={styles.trackFooter}>
            <div
              className={styles.previous}
              onClick={() => handleTrackChange(undefined)}
            >
              <Icon type="arrow-left" />
            </div>
            {customAlbum ? (
              <div className={classnames(styles.next, {
                [styles.nextDisabled]: !artistsValue
              })}>
                <span>{i18n.format('modules.spotify.code.album.upload')}</span>
                <Icon type="arrow-right" />
                <input type="file" onChange={handleCustomAlbumChange} accept="image/*" />
              </div>
            ) : (
              <div
                className={classnames(styles.next, {
                  [styles.nextDisabled]: !artistsValue
                })}
                onClick={() => handleTrackLayoutChange()}
              >
                <span>{i18n.format('modules.global.next')}</span>
                <Icon type="arrow-right" />
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }

  /** 上传自定义封面监听 */
  const handleCustomAlbumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files)
      setCropaiImage(createObjectURL(event.target.files[0]));
  }

  /** 裁剪确认 */
  const handleCropaiConfirm = (result: string) => {
    setCustomAlbumImage(result);
    setCropaiImage(undefined);
    handleTrackLayoutChange();
  }

  /** 预览关闭 */
  const handlePreviewClose = () => {
    setPreviewVisible(false);
  }

  /** 生成并上传专辑图 */
  const _uploadTrackAlbum = async (token: string, key: string) => {
    if (!customAlbumImage) return currentTrack?.album ?? '';
    // 上传到七牛
    const result = await upload7nObjectURL({
      token,
      key,
      blobURL: customAlbumImage,
    });
    return host7n['prefix-na0'] + result.key;
  }

  /** 生成并上传生产图 */
  const _uploadTrack = async (token: string, key: string) => {
    if (!currentTrack) return '';
    let canvas = _canvas.current[0];
    // 生成生产图
    const blobURL = await canvasToBlobURL(canvas.getElement(), 'image/jpeg');
    // 上传到七牛
    const result = await upload7nObjectURL({ blobURL, token, key });
    return host7n['prefix-na0'] + result.key;
  }

  /** 加车校验 */
  const handleValidatorConfirm = (type: number) => {
    if (type === 1) // 如果存在增值服务
      if (increment?.relatedProduct)
        setRelatedProductVisible(true);
      else if (increment?.vip)
        setVipIncrementVisible(true);
      else handleValidatorConfirm(type - 1);
    else
      handleConfirm();
  }

  /** 搜索关闭回调 */
  const handleSearchClose = () => {
    if (layouts.length > 1)
      setTemplateVisible(true);
    else
      onClose();
  }

  /** 确认回调监听 */
  const handleConfirm = async (increment?: VipIncrement | RelatedProductIncrement) => {
    if (!currentTrack) return;
    try {
      setVipIncrementVisible(false);
      setRelatedProductVisible(false);
      setUploadVisible(true);
      const token = await getToken7n();
      const key = uploadKey('soptify-code');
      // 构建上传队列
      const [ effect, source ] = await Promise.all<string>([
        _uploadTrack(token, `${key}.png`),
        _uploadTrackAlbum(token, `${key}-source.png`)
      ]);

      // 如果存在额外的直线刻字
      if (lineTextValue)
        layout.children.forEach(item => {
          if (item.layout.lineTextCoord)
            item.layout.lineTextCoord.value = lineTextValue;
        });

      // 如果存在额外的textarea刻字
      if (textAreaValue)
        layout.children.forEach(item => {
          if (item.layout.textAreaCoord)
            item.layout.textAreaCoord.value = textAreaValue;
        });

      onConfirm(
        effect,
        {
          source,
          color: currentColor,
          track: {
            ...currentTrack,
            artists: artistsValue || currentTrack.artists
          },
          layout
        },
        increment
      );
      handleClose();
    } catch {
      alert(i18n.format('modules.error.upload.result'));
      setUploadVisible(false);
    }
  }

  /** 关闭回调监听 */
  const handleClose = () => {
    onClose();
    setCurrentTrack(undefined);
    setUploadVisible(false);
    setPreviewVisible(false);
    setCustomAlbumImage(undefined);
    setCustomAlbum(false);
    setTemplateVisible(true);
    setLineTextValue('')
  }

  const innerFonts: string[] = [];

  layout.children.forEach(({ layout }) => {
    const { nameCoord, artistCoord, trackCoord, lineTextCoord, textAreaCoord } = layout;
    if (nameCoord)
      innerFonts.push(`
        @font-face {
          font-weight: normal;
          font-style: normal;
          font-family: ${nameCoord.font.name};
          src: url('${nameCoord.font.url}');
        }`);
    if (artistCoord)
      innerFonts.push(`
        @font-face {
          font-weight: normal;
          font-style: normal;
          font-family: ${artistCoord.font.name};
          src: url('${artistCoord.font.url}');
        }`);
    if (trackCoord)
      innerFonts.push(`
        @font-face {
          font-weight: normal;
          font-style: normal;
          font-family: ${trackCoord.time.font.name};
          src: url('${trackCoord.time.font.url}');
        }`);
    if (lineTextCoord)
      innerFonts.push(`
        @font-face {
          font-weight: normal;
          font-style: normal;
          font-family: ${lineTextCoord.font.name};
          src: url('${lineTextCoord.font.url}');
        }`);
    if (textAreaCoord)
      innerFonts.push(`
        @font-face {
          font-weight: normal;
          font-style: normal;
          font-family: ${textAreaCoord.font.name};
          src: url('${textAreaCoord.font.url}');
        }`);
  });

  return (
    <Layout
      theme={theme}
      plugins={[]}
    >
      <style>{innerFonts.join('\n')}</style>
      <div className="sunzi-spotify-code">
        <div className={classnames(styles.inputWrapper, {
          [styles.inputFocusWrapper]: searchValue
        })}>
          <input
            ref={_input}
            value={searchValue}
            placeholder={i18n.format('modules.spotify.code.placeholder')}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          <div className={styles.searchWrapper}>
            <Icon type="search" />
          </div>
          <div
            className={styles.confirm}
            onClick={handleSearch}
          >
            {searchLoading ?
              <Loading size={24} /> :
              <div className={styles.iconWrapper}>
                <Icon type="search" />
              </div>
            }
          </div>
        </div>
        { renderContent() }
        <div
          className={classnames(styles.closeWrapper, {
            [styles.closeWrapperTrack]: !!tracks?.length
          })}
          onClick={handleSearchClose}
        >
          <Icon type="arrow-left" />
        </div>
        {templateVisible &&
          <Template
            defaultLayout={layout}
            layouts={layouts}
            onConfirm={handleTemplateConfirm}
            onClose={handleClose}
          />
        }
        {currentTrack && renderTrackContent(currentTrack)}
        {cropaiImage && currentLayout.albumCoord &&
          <Cropai
            source={cropaiImage}
            aiImage={currentLayout.albumCoord.aiImage}
            onClose={() => setCropaiImage(undefined)}
            onConfirm={handleCropaiConfirm}
          />
        }
        {previewVisible &&
          <Wrapper className={classnames(styles.previewWrapper, styles.previewPostCard)}>
            <div className={styles.content}>
              {layout.children.map((item, index) => {
                return currentTrack && (
                  <div
                    key={index}
                    className={styles.trackCanvasWrapper}
                  >
                    <TrackCanvas
                      canvas={_canvas.current[index]}
                      track={currentTrack}
                      layout={item}
                      makeWidth={layout.makeWidth}
                      makeHeight={layout.makeHeight}
                      color={currentColor}
                      album={customAlbumImage}
                      artistsValue={artistsValue}
                      lineTextValue={lineTextValue}
                      textAreaValue={textAreaValue}
                      asyncLoading={setTrackLoading}
                    />
                  </div>
                );
              })}
            </div>
            {colors.length > 1 &&
              <Fragment>
                <div className={styles.title}>{i18n.format('modules.spotify.code.change.color')}</div>
                <div className={classnames(styles.colorWrapper, {
                  [styles.colorDisabled]: trackLoading
                })}>
                  {colors.map((item, index) => (
                    <div
                      key={index}
                      className={classnames(styles.color, {
                        [styles.colorActive]: item === currentColor
                      })}
                      onClick={() => setCurrentColor(item)}
                    >
                      <div
                        className={styles.colorContent}
                        style={{
                          backgroundColor: item.value
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Fragment>
            }
            <div className={styles.footer}>
              <div className={styles.title}>{i18n.format('modules.global.preview')}</div>
              <div
                className={styles.previous}
                onClick={handlePreviewClose}
              >
                <Icon type="arrow-left" />
              </div>
              <div
                className={classnames(styles.confirm, {
                  [styles.confirmDisabled]: trackLoading
                })}
                onClick={() => handleValidatorConfirm(1)}
              >
                <span>{i18n.format('modules.global.add.cart').toLowerCase()}</span>
                <div className={styles.arrowWrapper}>
                  <Icon type="arrow-right" />
                </div>
              </div>
            </div>
          </Wrapper>
        }
        {vipIncrementVisible &&
          <Wrapper className={styles.vipIncrementWrapper}>
            <Vip
              mask
              className={styles.vipIncrement}
              vipIncrement={increment?.vip}
              currencySymbol={shop.currencySymbol}
              onClose={() => setVipIncrementVisible(false)}
              onConfirm={handleConfirm}
              gtag={gtag}
            />
          </Wrapper>
        }
        {relatedProductVisible &&
          <Wrapper className={styles.lineTextDrawerWrapper}>
            <Drawer
              mask
              maskStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)'
              }}
              className={styles.lineTextDrawer}
            >
              <div className={styles.drawerContent}>
                <div className={styles.imageWrapper}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${thumbnail7n(relatedProduct.thumbnail, 500)})`
                    }}
                  />
                </div>
                <div className={styles.title}>{relatedProduct.title}</div>
                <div className={styles.increase}>
                  + {priceFormat(shop.currencySymbol, relatedProduct.price)}
                </div>
                <div className={styles.buttonGroup}>
                  <div
                    className={classnames(styles.button, styles.confirm)}
                    onClick={() => handleConfirm(relatedProduct)}
                  >
                    <div className={styles.iconWrapper}>
                      <Icon type="add-cart" />
                    </div>
                    <span>{i18n.format('modules.spotify.code.buy.it')}</span>
                  </div>
                  <div className={styles.divider}>{i18n.format('modules.spotify.code.add.text.or')}</div>
                  <div
                    className={classnames(styles.button, styles.addCart)}
                    onClick={() => handleValidatorConfirm(0)}
                  >
                    <span>{i18n.format('modules.spotify.code.skip.add.cart')}</span>
                  </div>
                  <div
                    className={styles.cancel}
                    onClick={() => setRelatedProductVisible(false)}
                  >{i18n.format('modules.global.cancel')}</div>
                </div>
              </div>
            </Drawer>
          </Wrapper>
        }
        {uploadVisible &&
          <Wrapper className={styles.uploadWrapper}>
            <Loading.Line />
          </Wrapper>
        }
      </div>
    </Layout>
  )
}

export const formatTime = (time: number) => dayjs(time).subtract(1, 's').format('mm:ss');

export default PostCard;
