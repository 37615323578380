import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './style.less';

interface Props {
  color?: string;
}

class Loading extends Component<Props> {
  render() {
    const { color: borderColor = '#fff' } = this.props;

    return (
      <div className={styles.threedLoading}>
        <div className={styles.cube}>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
          <div>
            <span className={classnames(styles.border, styles.left, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.top)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.left, styles.bottom)} style={{ borderColor }} />
            <span className={classnames(styles.border, styles.right, styles.bottom)} style={{ borderColor }} />
          </div>
        </div>
      </div>
    )
  }
};

export default Loading;