import React, { Component } from 'react';
import styles from './style.less';

class Loading extends Component {
  render() {
    return (
      <div className={styles.bookLoading}>
        <div className={styles.inner}>
          <div className={styles.left} />
          <div className={styles.middle} />
          <div className={styles.right} />
        </div>
        <ul>
          {Array.from({ length: 18 }).map((item, index) => 
            <li key={index} />
          )}
        </ul>
      </div>
    )
  }
};

export default Loading;