import React, { Dispatch, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { fabric } from 'sunzi-fabric';
import { Wrapper, Loading } from '@/components';
import { loadImageFromURL, canvasToBlobURL, thumbnail7n } from '@/utils';
import { SpotifyLayoutImage, SpotifyImageCoord, ImageLayout, SpotifyImage } from './multi-photo';
import styles from '../style.less';


export interface MultiCanvasProps {
  /** canvas对象 */
  canvas: fabric.Canvas;
  /** 歌曲信息 */
  layoutImage: SpotifyLayoutImage;
  /** 绘制布局信息 */
  layout: ImageLayout;
  /**  */
  images: SpotifyImage[];
  /** 同步loading状态 */
  asyncLoading?: Dispatch<React.SetStateAction<boolean>>
}

const MultiCanvas: React.FC<MultiCanvasProps> = ({
  canvas,
  layoutImage,
  layout,
  images,
  asyncLoading,
}) => {
  /** 容器实例 */
  const _content = useRef<HTMLDivElement>(null);
  /** 加载状态 */
  const [ loading, setLoading ] = useState<boolean>();
  /** 高度 */
  const [ offsetHeight, setOffsetHeight ] = useState<number>(0);
  /** 结果图 */
  const [ trackImage, setTrackImage ] = useState<string>();

  useEffect(() => {
    _canvasCreate();
  }, []);

  /** 初始化画布 */
  const _canvasCreate = async () => {
    if (_content.current) {
      const { offsetWidth } = _content.current;
      setLoading(true);
      asyncLoading && asyncLoading(true);
      // 缩放倍数
      const scale = offsetWidth / layoutImage.width;
      setOffsetHeight(layoutImage.height * scale);
      // 生成预览图
      const image = await renderMulti(canvas, layoutImage, layout, images);
      setTrackImage(image);
      setLoading(false);
      asyncLoading && asyncLoading(false);
    }
  }

  return (
    <div
      ref={_content}
      className={classnames(styles.trackCanvas, {
        [styles.trackCanvasLoading]: loading
      })}
      style={{
        height: offsetHeight,
        backgroundImage: `url(${trackImage})`
      }}
    >
      {loading &&
        <Wrapper className={styles.loadingWrapper}>
          <Loading size={30} />
        </Wrapper>
      }
    </div>
  )
};


export const renderMulti = async (
  canvas: fabric.Canvas,
  layoutImage: SpotifyLayoutImage,
  layout: ImageLayout,
  images: SpotifyImage[],
  catchError: boolean = false,
) => {
  const width = 300;
  const height = layoutImage.height * width / layoutImage.width;
  canvas.clear();
  // 设置宽高
  canvas.setDimensions({
    width,
    height
  });

  const scale = layoutImage.contentCoord.width * width / layoutImage.width / layout.makeWidth;

  /** left top 增加偏移量 */
  const _left = (value: number) =>
    layoutImage.contentCoord.left * width / layoutImage.width + value * scale;
  const _top = (value: number) =>
    layoutImage.contentCoord.top * width / layoutImage.width + value * scale;

  /** 绘制遮照图 */
  const _renderCropImage = (image: string) => new Promise<void>(resolve => {
    loadImageFromURL(thumbnail7n(image, 400))
      .then(bottom => {
        bottom.scaleToWidth(width);
        canvas.setOverlayImage(bottom, () => {
          canvas.renderAll();
          resolve();
        });
      });
  });

  /** 绘制底图 */
  const _renderBottomImage = (image: string) => new Promise<void>(resolve => {
    loadImageFromURL(thumbnail7n(image, 400))
      .then(bottom => {
        bottom.set({
          scaleX: width / (bottom.width ?? 1),
          scaleY: height / (bottom.height ?? 1),
        });
        canvas.setBackgroundImage(bottom, () => {
          canvas.renderAll();
          resolve();
        });
      });
  });

  /** 绘制图片 */
  const _renderImage = async (image: string | undefined, { left, top, width, height }: SpotifyImageCoord): Promise<any> => {
    try {
      const _image = await loadImageFromURL(image as string);
      const _width = width * scale,
        _height = height * scale;

      const {
        width: imgWidth = 0,
        height: imgHeight = 0
      } = _image;

      _image.set({
        left: _left(left + width / 2),
        top: _top(top + height / 2),
        originX: 'center',
        originY: 'center'
      });

      _image.scale(Math.max(
        _width / imgWidth,
        _height / imgHeight
      ));

      canvas.add(_image);
      return _image;

    } catch {
      if (catchError)
        throw Error();
      return;
    }
  }

  const queue = layout.images.map((item, index) => _renderImage(images[index].croppie, item));

  // 如果存在遮照图
  if (layoutImage.bottomImage)
    queue.push(_renderBottomImage(layoutImage.bottomImage));

  // 如果存在遮照图
  if (layoutImage.cropImage)
    queue.push(_renderCropImage(layoutImage.cropImage));

  // 并行绘制
  await Promise.all<any>(queue);

  canvas.renderAll();
  return await canvasToBlobURL(canvas.getElement());
}

export default MultiCanvas;
