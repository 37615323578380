import React, { useRef, useEffect, useState, Fragment } from 'react';
import classnames from 'classnames';
import clonedeep from 'lodash.clonedeep';
import { Wrapper } from '@/components';
import { i18n, createObjectURL, autoCropperImage, eventStopBubbles } from '@/utils';
import Icon from '../icon';
import { SpotifyLayoutImage, ImageLayout, SpotifyImage } from './multi-photo';
import Croppie from './croppie';
import styles from './style.less';

export interface MultiProps {
  /** 歌曲信息 */
  layoutImage: SpotifyLayoutImage;
  /** 绘制布局信息 */
  layout: ImageLayout;
  images: SpotifyImage[];
  setImages: React.Dispatch<React.SetStateAction<SpotifyImage[]>>;
  onClose: () => void;
  onConfirm: () => void;
}

const Multi: React.FC<MultiProps> = ({ layoutImage, layout, images, setImages, onClose, onConfirm }) => {

  const _content = useRef<HTMLDivElement>(null);
  const _scale = useRef<number>(0);

  const [offset, setOffset] = useState([0, 0]);
  const [croppieIndex, setCroppieIndex] = useState<number>(-1);

  useEffect(() => {
    if (_content.current) {
      const { offsetWidth, offsetHeight } = _content.current;
      let width = offsetWidth;
      let height = width * layoutImage.height / layoutImage.width;
      if (layoutImage.width / layoutImage.height < offsetWidth / offsetHeight) {
        height = offsetHeight;
        width = height * layoutImage.width / layoutImage.height;
      }
      setOffset([ width, height ]);
      _scale.current = layoutImage.contentCoord.width * width / layoutImage.width / layout.makeWidth;
    }
  }, []);

  /** left top 增加偏移量 */
  const _left = (value: number) =>
    layoutImage.contentCoord.left * offset[0] / layoutImage.width + value * _scale.current;

  const _top = (value: number) =>
    layoutImage.contentCoord.top * offset[0] / layoutImage.width + value * _scale.current;

  /** 上传照片监听 */
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const files = event.target.files || [];
    if (files.length === 1) {
      images[index] = {
        source: createObjectURL(files[0])
      };
      setImages(clonedeep(images));
      setCroppieIndex(index);
    } else {
      let cursor = 0
      await Promise.all(layout.images.map((_, i) => {
        const _index = (i + index) % layout.images.length;

        const _image = layout.images[_index];
        if (images[_index]) return;
        const source = createObjectURL(files[cursor++]);

        return autoCropperImage(
          source,
          _image.width,
          _image.height
        ).then(image => {
          images[_index] = {
            source,
            croppie: image
          };
        });
      }));
      setImages(clonedeep(images));
    }
  };

  const handleCroppieConfirm = (image: string, source: string) => {
    images[croppieIndex].source = source;
    images[croppieIndex].croppie = image;
    setImages(clonedeep(images));
    setCroppieIndex(-1);
  }

  return (
    <Wrapper className={styles.multiWrapper}>
      <div
        ref={_content}
        className={styles.content}
      >
        <Wrapper className={styles.contentWrapper}>
          <div
            className={styles.multi}
            style={{
              width: offset[0],
              height: offset[1],
              backgroundImage: `url(${layoutImage.bottomImage})`
            }}
          >
            {layout.images.map((item, index) => (
              <div
                key={index}
                className={classnames(styles.image, {
                  [styles.borderNone]: !!images[index]
                })}
                style={{
                  left: _left(item.left),
                  top: _top(item.top),
                  width: item.width * _scale.current,
                  height: item.height * _scale.current,
                }}
              >
                {images[index] ?
                  <Wrapper
                    className={styles.imageContent}
                    style={{
                      backgroundImage: `url(${images[index]?.croppie})`,
                    }}
                    onClick={() => setCroppieIndex(index)}
                  />
                  :
                  <Fragment>
                    <Icon type="camera-add" size={30} />
                    <input type="file" multiple onChange={(e) => handleImageChange(e, index)} />
                  </Fragment>
                }
              </div>
            ))}
            <Wrapper
              className={styles.cropImage}
              style={{
                backgroundImage: `url(${layoutImage.cropImage})`
              }}
            />
          </div>
        </Wrapper>
      </div>
      <div className={styles.description}>
        {i18n.format('modules.spotify.code.multi.upload')}
      </div>
      <div className={styles.footer}>
        <div
          className={styles.previous}
          onClick={onClose}
        >
          <Icon type="arrow-left" />
        </div>
        <div
          className={classnames(styles.next, {
            [styles.nextDisabled]: images.filter(item => item?.croppie).length < layout.images.length
          })}
          onClick={onConfirm}
        >
          <span>{i18n.format('modules.global.next')}</span>
          <Icon type="arrow-right" />
        </div>
      </div>
      {croppieIndex > -1 &&
        <Croppie
          coord={layout.images[croppieIndex]}
          image={images[croppieIndex].source}
          onClose={() => setCroppieIndex(-1)}
          onConfirm={handleCroppieConfirm}
        />
      }
    </Wrapper>
  );
};

export default Multi;
