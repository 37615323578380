import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import { i18n } from '@/utils';
import animationData from '@/assets/new-sock/loading.json';
import styles from './style.less';

interface LoadingProps {
  /** 裁剪完毕 */
  cutState: boolean;
  /** 裁剪动画播放完毕 */
  doneCallback: () => void
  /** 初始帧率 */
  initialSegment?: [number, number],
  /** 完成帧率 */
  completeSegment?: [number, number],
  /** animationJson */
  animationJson?: any,
  assetsPath?: string,
  /** 是否有文字 */
  hasCompleteText?: boolean,
}

const Loading: React.SFC<LoadingProps> = ({
  cutState,
  doneCallback,
  initialSegment,
  completeSegment,
  animationJson,
  assetsPath,
  hasCompleteText = true,
}) => {
  /** 动画挂载 dom */
  const _animation = useRef<any>(null);
  /** 动画是否加载完毕 */
  const [ animationLoaded, setAnimationLoaded ] = useState<boolean>(false);
  /** 动画实例 */
  const lottieAnimation = useRef<AnimationItem>();

  /** 图片加载完毕回调 */
  const loadedImages = () => {
    setAnimationLoaded(true);
    lottieAnimation.current?.play();
  }

  /** 动画播放完毕回调 */
  const animationComplete = () => {
    doneCallback();
  }

  useEffect(() => {
    lottie.setLocationHref(location.href);

    if (_animation.current) {
      lottieAnimation.current = lottie.loadAnimation({
        container: _animation.current,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        initialSegment: initialSegment ?? [0, 45],
        animationData: animationJson ?? animationData,
        assetsPath: assetsPath ?? 'https://sunzi7n.myuxc.com/assets/',
      });
      lottieAnimation.current.addEventListener('DOMLoaded', loadedImages);
    }

    return () => {
      if (lottieAnimation.current) {
        lottieAnimation.current.removeEventListener('DOMLoaded', loadedImages);
        lottieAnimation.current.removeEventListener('loopComplete', animationComplete);
        lottieAnimation.current.destroy();
      }
    }
  }, []);

  useEffect(() => {
    if (cutState) {
      if (completeSegment)
        lottieAnimation.current?.playSegments(completeSegment, false);
      else
        lottieAnimation.current?.playSegments([45, 75], false);
      lottieAnimation.current?.addEventListener('loopComplete', animationComplete);
    }
  }, [ cutState ]);

  return (
    <div className={classnames(styles.loading, {
        [styles.animationUnload]: animationLoaded === false,
        [styles.animationLoaded]: animationLoaded === true,
      })}>
      <div className={styles.loadingAnimation} ref={_animation} />
      {hasCompleteText && <div className={classnames(styles.loadingTip, {
            [styles.loadingTipSuccess]: cutState,
          })}>
          {cutState ? i18n.format('modules.global.ai.crop.complete') : i18n.format('modules.global.ai.croping.photo')}
        </div>
      }
    </div>
  )
}

export default Loading;
