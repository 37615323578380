import jhttp from 'jhttp';
import { ApiResponse } from '@/sunzi';
import { SpotifyTrack } from './spotify-code';;

/** 获取spotify api token */
const _getSpotifyToken = async (): Promise<string> => {
  const response: any = await jhttp.post('https://ai.soufeel.com/spotify/tokens');
  if (response.error === 0)
    return response.token;
  else
    throw Error('get token error');
}

/** 根据ID获取单曲 */
export const getSpotifyTrack = async (trackId: string) => {
  try {
    // 获取token
    const token = await _getSpotifyToken();
    const response: any = await jhttp.get(`https://api.spotify.com/v1/tracks/${trackId}`, {}, {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`,
    });
    if (response.id) {
      return {
        status: true,
        data: {
          album: response.album?.images[0]?.url,
          albumThumbnail: response.album?.images[2]?.url,
          name: response.name,
          artists: response.artists.map((artist: any) => artist.name).join(", "),
          duration_ms: response.duration_ms,
          uri: response.uri,
        }
      };
    } else
      throw Error();
  } catch(e) {
    return {
      status: false,
      message: e.message,
    }
  }
}

/** spotify api 搜索 */
export default async (name: string, pagination: number = 0, limit: number = 20): Promise<ApiResponse<{
  total: number;
  tracks: SpotifyTrack[]
}>> => {
  try {
    // 获取token
    const token = await _getSpotifyToken();
    const response: any = await jhttp.get(`https://api.spotify.com/v1/search`, {
      q: name,
      type: 'track',
      offset: pagination * limit,
      limit,
    }, {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`,
    });
    if (response.tracks) {
      return {
        status: true,
        data: {
          total: response.tracks.total,
          tracks: response.tracks.items.map((item: any) => ({
            album: item.album?.images[0]?.url,
            albumThumbnail: item.album?.images[2]?.url,
            name: item.name,
            artists: item.artists.map((artist: any) => artist.name).join(", "),
            duration_ms: item.duration_ms,
            uri: item.uri,
          }))
        }
      };
    } else
      throw Error();
  } catch(e) {
    return {
      status: false,
      message: e.message,
    }
  }
}
