import React from 'react';
import { SpotifyTrack } from './spotify-code';
import styles from './style.less';

export interface TrackItemProps {
  track: SpotifyTrack;
  onClick?: () => void;
}

const TrackItem: React.FC<TrackItemProps> = ({ track, onClick }) => (
  <div
    className={styles.track}
    onClick={onClick}
  >
    <div
      className={styles.trackAlbum}
      style={{
        backgroundImage: `url(${track.albumThumbnail})`
      }}
    />
    <div className={styles.trackContent}>
      <div className={styles.trackName}>{track.name}</div>
      <div className={styles.trackArtists}>{track.artists}</div>
    </div>
  </div>
);

export default TrackItem;
