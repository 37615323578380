import React, { useMemo, useState, useEffect } from "react";
import { Wrapper, Layout } from "@/components";
import { SpotifyLayout } from "../spotify-code";
import classnames from "classnames";
import Icon from "../icon";
import styles from "./style.less";
import { i18n } from "@/utils";
import { RGB } from "@/sunzi";
import { ISpotifyTemplateItem, ISpotifyData } from "../spotify-code";

export interface TemplateProps {
  /**  主题色 */
  theme?: RGB;
  /** 模版显影 */
  templateVisiable: boolean;
  /** 所有产品 */
  products?: ISpotifyTemplateItem[];
  /** 关闭回调 */
  onClose(): void;
  /** 确认回调 */
  onConfirm(data: ISpotifyData): void;
}

const Component = <T extends object>({
  templateVisiable,
  products = [],
  children,
  theme = {
    r: 29,
    g: 184,
    b: 84,
  },
  onClose,
  onConfirm,
}: React.PropsWithChildren<TemplateProps & T>): React.ReactElement => {
  const [selectData, setSelectData] = useState<ISpotifyData>(
    products[0]?.data
  );

  const onTemplateConfirm = (data: ISpotifyData) => {
    onConfirm(data);
  };

  return (
    <Layout theme={theme} plugins={[]}>
      {templateVisiable && (
        <Wrapper className={styles.templateWrapper}>
          <div className={styles.tempalte}>
            <div className={styles.title}>
              {i18n.format("modules.spotify.code.template.title")}
            </div>
            <div className={styles.templateList}>
              {products.map((item) => {
                const { data, preview } = item;
                return (
                  <div
                    key={item.sku}
                    className={classnames(styles.template, {
                      [styles.templateActive]:
                        selectData.layout.sku === item.sku,
                    })}
                    onClick={() => setSelectData(data)}
                  >
                    <div
                      className={styles.templateContent}
                      style={{
                        backgroundImage: `url(${preview})`,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.tempalteFooter}>
            <div className={styles.previousWrapper} onClick={onClose}>
              <Icon type="arrow-left" />
            </div>
            <div
              className={styles.confirm}
              onClick={() => onTemplateConfirm(selectData)}
            >
              <span>{i18n.format("modules.global.next")}</span>
              <Icon type="arrow-right" />
            </div>
          </div>
        </Wrapper>
      )}
      {!templateVisiable && children}
    </Layout>
  );
};

export default Component;
