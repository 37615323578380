import jhttp from 'jhttp';
import { ApiResponse } from '@/sunzi';
import { SongLyric } from '../lyric';

/** 根据歌名&歌手获取歌词 */
export const getSpotifyLyric = async (q: string): Promise<ApiResponse<string[]>> => {
  try {
    const response: any = await jhttp.post(`https://ai.soufeel.com/genius/search-lyrics`, {
      q,
      type: 'array'
    });
    if (response.error === 0) {
      return {
        status: true,
        data: response.data
      };
    } else
      throw Error();
  } catch(e) {
    return {
      status: false,
      message: e.message,
    }
  }
}

/** 根据歌名&歌手获取歌词 */
export const getSpotifyLyricById = async (id: number): Promise<ApiResponse<string[]>> => {
  try {
    const response: any = await jhttp.post(`https://ai.soufeel.com/genius/lyrics`, {
      q: id,
      type: 'array'
    });
    if (response.error === 0) {
      return {
        status: true,
        data: response.data
      };
    } else
      throw Error();
  } catch(e) {
    return {
      status: false,
      message: e.message,
    }
  }
}

export const searchSongLyric = async (q: string, pagination: number = 1): Promise<ApiResponse<{ result: SongLyric }[]>> => {
  try {
    const response: any = await jhttp.post(`https://ai.soufeel.com/genius/search?q=${q}%26page=${pagination}`);
    if (response.error === 0) {
      return {
        status: true,
        data: response.data
      };
    } else
      throw Error();
  } catch(e) {
    return {
      status: false,
      message: e.message,
    }
  }
}
