import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useLocal } from '@/hooks';
import { VipIncrement } from '@/increment/increment';
import { Vip } from '@/increment';
import { spotifyCodeHost } from '@/config';
import { Layout, Wrapper, Loading } from '@/components';
import { i18n, uuid } from '@/utils';
import { SpotifyCodeProps } from '..';
import { SpotifyTrack } from '../spotify-code';
import spotifySearch from '../request';
import Icon from '../icon';
import TrackItem from '../track-item';
import styles from '../style.less';

interface OnlyCodeProps extends Omit<SpotifyCodeProps, 'layout' | 'colors' | 'bottomImage' | 'bindProduct' | 'onConfirm' | 'defaultColor' | 'defaultCustomAlbum'> {
  onConfirm: (
    effect: string,
    data: SpotifyTrack,
    vipIncrement?: VipIncrement
  ) => void;
}

const OnlyCode: React.FC<OnlyCodeProps> = ({
  shop,
  theme = {
    r: 29,
    g: 184,
    b: 84
  },
  gtag,
  price,
  recommendTracks = [],
  increment,
  onClose,
  onConfirm
}) => {
  // 设置国际化
  useLocal(shop.language);
  /** 输入框实例 */
  const _input = useRef<HTMLInputElement>(null);
  /** 搜索列表实例 */
  const _list = useRef<HTMLDivElement>(null);
  /** 搜索结果游标 */
  const _pagination = useRef<number>(0);
  /** 搜索结果总数 */
  const _total = useRef<number>(0);
  /** 搜索结果key*/
  const _listKey = useRef<string>();
  /** 搜索内容 */
  const [ searchValue, setSearchValue ] = useState<string>('');
  /** 搜索加载状态 */
  const [ searchLoading, setSearchLoading ] = useState<boolean>();
  /** 搜索加载更多状态 */
  const [ searchLoadmore, setSearchLoadmore ] = useState<boolean>();
  /** 搜索加载状态 */
  const [ tracks, setTracks ] = useState<SpotifyTrack[]>();
  /** 滚动渐变 */
  const [ scrollGradient, setScrollGradient ] = useState<boolean[]>([]);
  /** 当前激活的歌曲 */
  const [ currentTrack, setCurrentTrack ] = useState<SpotifyTrack>();
  /** VIP显隐 */
  const [ vipIncrementVisible, setVipIncrementVisible ] = useState<boolean>(false);

  /** 监听搜索按键 */
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13)
      handleSearch();
  }

  /** 搜索监听 */
  const handleSearch = async () => {
    setSearchLoading(true);
    _input.current?.blur();
    // 每次搜索生成一个新的key，强制dom更新
    _listKey.current = uuid();
    await _spotifySearch();
    gtag('event', 'tap_search_track', {
      'event_label': searchValue
    });
    setSearchLoading(false);
  }

  /** spotify api 搜索 */
  const _spotifySearch = async (pagination: number = 0) =>
    spotifySearch(searchValue, pagination)
      .then(response => {
        if (response.status && response.data) {
          setTracks(pagination === 0 ?
            response.data.tracks :
            (tracks || []).concat(response.data.tracks)
          );
          _total.current = response.data.total;
          _pagination.current = pagination;
          if (_list.current) {
            const { scrollHeight, offsetHeight } = _list.current;
            setScrollGradient([false, scrollHeight > offsetHeight]);
          }
        } else {
          gtag('event', 'tap_search_error');
        }
      });

  /** 搜索列表滚动监听 */
  const handleTrackListScroll = async () => {
    if (_list.current) {
      const { scrollTop, scrollHeight, offsetHeight } = _list.current;
      setScrollGradient([scrollTop > 100, scrollHeight - scrollTop - offsetHeight > 100]);
      if (scrollHeight - scrollTop - offsetHeight <= 80
        && !searchLoadmore
        && (tracks?.length ?? 0) < _total.current
      ) {
        setSearchLoadmore(true);
        await _spotifySearch(_pagination.current + 1);
        setSearchLoadmore(false);
      }
    }
  }

  /** content渲染 */
  const renderContent = () => {
    if (tracks === undefined)
      return (
        <div className={classnames(styles.trackListWrapper, styles.recommend)}>
          <div className={styles.recommendTitle}>🔥{i18n.format('modules.spotify.code.search.hot')}</div>
          <div className={styles.trackList}>
            {recommendTracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => setCurrentTrack(item)}
              />
            ))}
          </div>
        </div>
      );
    else if (tracks.length > 0)
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div
            ref={_list}
            key={_listKey.current}
            className={styles.trackList}
            onScrollCapture={handleTrackListScroll}
          >
            {tracks.map((item, index) => (
              <TrackItem
                key={index}
                track={item}
                onClick={() => setCurrentTrack(item)}
              />
            ))}
            {tracks.length < _total.current &&
              <div className={styles.loadmore}>
                <Loading size={20} />
              </div>
            }
          </div>
          <Wrapper className={styles.trackListGradient}>
            <div className={classnames({
              [styles.topGradient]: scrollGradient[0]
            })} />
            <div className={classnames({
              [styles.bottomGradient]: scrollGradient[1]
            })} />
          </Wrapper>
        </div>
      );
    else
      return (
        <div className={classnames(styles.trackListWrapper, styles.result)}>
          <div className={styles.resultTitle}>
            {i18n.format('modules.spotify.code.search.reuslt')}
          </div>
          <div className={classnames(styles.trackList, styles.trackListEmpty)}>
            <img src={require('@/assets/search-no-result.png')} />
            <span>{i18n.format('modules.spotify.code.search.no.result')}</span>
          </div>
        </div>
      );
  }

  /** 加车校验 */
  const handleValidatorConfirm = (type: number) => {
    if (type === 1) // 如果存在VIP
      if (increment?.vip)
        setVipIncrementVisible(true);
      else handleValidatorConfirm(type - 1);
    else
      handleConfirm();
  }

  /** 确认回调监听 */
  const handleConfirm = async (vipIncrement?: VipIncrement) => {
    if (!currentTrack) return;
    setVipIncrementVisible(false);
    onConfirm(createSpotifyCode(currentTrack.uri), currentTrack, vipIncrement);
    handleClose();
  }

  /** 关闭回调监听 */
  const handleClose = () => {
    onClose();
    setCurrentTrack(undefined);
  }

  /** 创建spotify code */
  const createSpotifyCode = (uri: string) => `${spotifyCodeHost}/png/FFFFFF/black/640/${uri}`;

  return (
    <Layout
      theme={theme}
      plugins={[]}
    >
      <div className="sunzi-spotify-code">
        <div className={classnames(styles.inputWrapper, {
          [styles.inputFocusWrapper]: searchValue
        })}>
          <input
            ref={_input}
            value={searchValue}
            placeholder={i18n.format('modules.spotify.code.placeholder')}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
          />
          <div className={styles.searchWrapper}>
            <Icon type="search" />
          </div>
          <div
            className={styles.confirm}
            onClick={handleSearch}
          >
            {searchLoading ?
              <Loading size={24} /> :
              <div className={styles.iconWrapper}>
                <Icon type="search" />
              </div>
            }
          </div>
        </div>
        { renderContent() }
        <div
          className={classnames(styles.closeWrapper, {
            [styles.closeWrapperTrack]: !!tracks?.length
          })}
          onClick={handleClose}
        >
          <Icon type="arrow-left" />
        </div>
        {currentTrack &&
          <Wrapper className={styles.previewWrapper}>
            <div
              className={styles.previousWrapper}
              onClick={() => setCurrentTrack(undefined)}
            >
              <Icon type="arrow-left" />
            </div>
            {currentTrack &&
              <div className={styles.content}>
                <div className={styles.codeImageWrapper}>
                  <div
                    className={styles.codeImage}
                    style={{
                      backgroundImage: `url(${createSpotifyCode(currentTrack.uri)})`
                    }}
                  />
                </div>
              </div>
            }
            <div
              className={styles.confirm}
              onClick={() => handleValidatorConfirm(1)}
            >
              <div className={styles.iconWrapper}>
                <Icon type="add-cart" />
              </div>
              <span className={styles.price}>{price}</span>
              <span>{i18n.format('modules.global.add.cart').toLowerCase()}</span>
            </div>
          </Wrapper>
        }
        {vipIncrementVisible &&
          <Wrapper className={styles.vipIncrementWrapper}>
            <Vip
              mask
              className={styles.vipIncrement}
              vipIncrement={increment?.vip}
              currencySymbol={shop.currencySymbol}
              onClose={() => setVipIncrementVisible(false)}
              onConfirm={handleConfirm}
              gtag={gtag}
            />
          </Wrapper>
        }
      </div>
    </Layout>
  )
}

export default OnlyCode;
